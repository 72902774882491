import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{useState,useEffect} from "react";
import { Tooltip } from "react-tooltip";

const CustomTooltop = ({ text,size,disable }) => {

    const [tooltipId, setTooltipId] = useState('');

    useEffect(() => {
        setTooltipId(`my-tooltip-${Math.random().toString(36).substr(2, 9)}`);
      }, []);
  return (
    <div className={disable ? "hidden" : ""}>
    <button data-tooltip-id={tooltipId}>
      <FontAwesomeIcon
        size={size ? size : "sm"}
        style={{ color: "gray" }}
        icon={faCircleInfo}
      />
    </button>
    <div>
      <Tooltip
        style={{ backgroundColor: "#90EE90", color: "#222",zIndex:"41" }}
        id={tooltipId}
      >
        <div
          className="z-50"
          style={{
            display: "flex",
            maxWidth: "220px",
            flexDirection: "column",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </Tooltip>
    </div>
  </div>
  );
};

export default CustomTooltop;

import React from 'react'

const UploadPage = () => {
    return (
        <div>
            <div className='w-[30%] mx-auto flex justify-center items-center mt-16 mb-10'>
                <img src='/upload-bills.png' alt='upload-bills' />
            </div>
            <p className='text-3xl font-medium text[#000000] text-center '>"Upload Bills/Excel" -  enables users to upload their utility bills onto the platform for analysis.</p>
        </div>
    )
}

export default UploadPage

import React, { useState, useEffect } from "react";
import { ConstantUtils } from "../../../utils/ConstantUtils";
import LineChartEnergyView2Y from "../../../containers/graphs/LineChartEnergyView2Y";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CustomTooltop from "../../../components/tooltips/CustomTooltop";
import LegendComponent from "../../../components/chart/LegendComponent";
import LoaderComponent from "../../../components/loaderCompent/LoaderComponent";

const constantUtils = new ConstantUtils();

const FutureLoadMonthlyCostView = ({
  energyMonthlyData,
  selectedFutureLoadView,
  setselectedFutureLoadView,
  selectedFilterYear,
  selectedMeter,
  selectedUnit,
  isLoading,
  slotConfigData
}) => {
  const [full, setFull] = useState(false);

  //==============================================================
  //          graph data
  //================================================================

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,

          text: 2022,
        },
        position: "bottom",
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Amount (Rs)",
        },
        grid: {
          display: false,
        },

        ticks: {
        callback: function(value) {
          if (value >= 1000) {
            return value / 1000 + 'k'; // Converts 1000 to 1k, 2000 to 2k, etc.
          }
          return value;
        }
      }
      },
    },
    elements: {
      line: {
        tension: 0.4, // This will make the line curve
      },
    },
  });

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      scales: {
        ...prevOptions.scales,
        x: {
          ...prevOptions.scales.x,
          title: {
            ...prevOptions.scales.x.title,
            text: selectedFilterYear,
          },
          position: "bottom",
        },
      },
    }));
  }, [selectedFilterYear]);

  const [lableValue, setLableValue] = useState(
    constantUtils.getAllStaticMonthsFromApril()
  );

  const [labels, setLabels] = useState(
    constantUtils.getMonthsFromAprilFinancial(selectedFilterYear)
  );

  const [data, setdata] = useState({
    labels,
    datasets: [],
  });

  useEffect(() => {
    setLabels(constantUtils.getMonthsFromAprilFinancial(selectedFilterYear));
  }, [selectedFilterYear])
  
  
    useEffect(() => {
      setdata((prevData) => ({
          ...prevData,
          labels: labels,
      }));
  }, [labels]);

  useEffect(() => {
    //    console.log({ energyMonthlyData });
    if (Object.keys(energyMonthlyData).length !== 0) {
      //      console.log({ energyMonthlyData, selectedFilterYear, selectedMeter });
      if (selectedMeter === "") {
        setdata((prevData) => ({
          ...prevData,
          datasets: prevData.datasets.map((dataset) => ({
            ...dataset,
            data: [],
          })),
        }));
      } else {
        if (
          energyMonthlyData !== undefined &&
          energyMonthlyData[selectedMeter] !== undefined &&
          energyMonthlyData[selectedMeter][selectedFilterYear] !== undefined &&
          energyMonthlyData[selectedMeter][selectedFilterYear][
            "costMonthLyData"
          ] !== undefined
        ) {
          //          console.log({ energyMonthlyData: "condi satisfied" });
          //          console.log({
          //     energyMonthlyData:
          //       energyMonthlyData[selectedMeter][selectedFilterYear][
          //         "costMonthLyData"
          //       ],
          //   });
          //          console.log({
          //     energyMonthlyData:
          //       energyMonthlyData,
          //   });
          setdata((prevData) => {
            let newDatasets = prevData.datasets
              .map((dataset) => {
                if (dataset && dataset.label === "Billed Demand") {
                  return dataset;
                }
              })
              .filter(Boolean);
            //          console.log({newDatasets})
            let dynamicDatasets =
              energyMonthlyData[selectedMeter][selectedFilterYear][
                "costMonthLyData"
              ];
            newDatasets.push(...dynamicDatasets);

            //          console.log({
            //   ...prevData,
            //   datasets: newDatasets,
            // })
            return {
              ...prevData,
              datasets: newDatasets,
            };
          });
        }
      }
    }
  }, [energyMonthlyData, selectedMeter, selectedFilterYear]);
  return (
    <div
      className={` ${
        selectedFutureLoadView === "cost" ? "" : "hidden"
      } shadow-lg  mt-1 p-4 border border-gray-300 rounded-md`}
    >
      <div className={`flex flex-col sm:flex-row items-center justify-between`}>
        <div className="py-2 px-2 pb-6 sm:pb-0">
          Monthly : Projection Of ToD Slot Wise Total Bill : Discom{" "}
        </div>
        <div className="flex gap-2">
          <div>
            <label className="inline-flex items-center me-5 cursor-pointer">
              <input
                type="checkbox"
                checked={selectedFutureLoadView === "energy" ? true : false}
                onChange={(e) =>
                  setselectedFutureLoadView(
                    e.target.checked ? "energy" : "cost"
                  )
                }
                className="sr-only peer"
              />
              <div className="relative w-[100px] h-7 bg-gray-200 rounded-full peer dark:bg-blue-700 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-[72px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute   after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all dark:border-gray-600 peer-checked:bg-green-600">
                <span
                  className={`${
                    selectedFutureLoadView === "cost"
                      ? "left-[35px]  "
                      : "left-[10px]"
                  }  top-[2px] absolute text-white `}
                >
                  {selectedFutureLoadView === "cost" ? "cost" : "energy"}
                </span>
              </div>{" "}
            </label>
          </div>
          <Link to={`/update-unit-setting/${selectedUnit}`}>
            <div>
              {" "}
              <FontAwesomeIcon icon={faEdit} size="lg" />
            </div>
          </Link>
          <div className="flex gap-5 mx-5">
            <CustomTooltop
              size={"lg"}
              disable={
                constantUtils.getGraphToolTipData().futureCost.disableToolTip
              }
              text={constantUtils.getGraphToolTipData().futureCost.text}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="w-full h-full min-h-[200px] sm:min-h-[600px] flex justify-center items-center">
          <LoaderComponent />
        </div>
      ) : (
      <div className=" sm:px-[35px] w-full  sm:pb-[25px]">
        <div>
          <LegendComponent slotConfigData={slotConfigData} data={data} columns={"grid-cols-6"} />
        </div>
        <LineChartEnergyView2Y
          data={data}
          options={options}
          full={full}
          setFull={setFull}
        />
      </div>)}
      {/* </div> */}
    </div>
  );
};

export default FutureLoadMonthlyCostView;

import { useState, useEffect, useRef } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";

function Index({ pdfUrl, selectedText }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const searchPluginInstance = searchPlugin();
  const { highlight } = searchPluginInstance;

  // pdf file onChange state
  // const [pdfFile, setPdfFile] = useState("http://localhost:7071/public/ocr/document/1715235135795.pdf");
  const [pdfFile, setPdfFile] = useState("");
  const [searchText, setSearchText] = useState("");
  const [runCount, setRunCount] = useState(1);

  const viewerRef = useRef(null);

  useEffect(() => {
    if (searchText && viewerRef.current) {
      searchTextInWindow(searchText);
    }
  }, [searchText]);

  function searchTextInWindow(text) {
    const words = text.trim().split(/\s+/);
    highlight({ keyword: words[0], matchSubstring: true, matchCase: false });

    setTimeout(() => {
      AdjustSelectedText();
    }, 100);
  }

  useEffect(() => {
    if (pdfUrl) {
      setPdfFile(pdfUrl);
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (selectedText) {
      setSearchText(selectedText);
    }
  }, [selectedText]);

  const AdjustSelectedText = () => {
    const element = document.querySelector(".rpv-search__highlight--current");
    if (element) {
      const currentTop = window.getComputedStyle(element).top;
      const currentLeft = window.getComputedStyle(element).left;
      const currentWidth = window.getComputedStyle(element).width;
      const currentHeight = window.getComputedStyle(element).height;

      const ccurrentTop = parseFloat(currentTop);
      const ccurrentLeft = parseFloat(currentLeft);
      const ccurrentWidth = parseFloat(currentWidth);
      const ccurrentHeight = parseFloat(currentHeight);

      const l1 = ccurrentWidth + 10;
      const l2 = ccurrentLeft - 3;
      const l3 = ccurrentTop - 5;
      const l4 = ccurrentHeight + 9;

      if (runCount === 1) {
        element.style.width = `${l1}px`;
        element.style.left = `${l2}px`;
        element.style.top = `${l3}px`;
        element.style.height = `${l4}px`;
        setRunCount(runCount + 1);
      }
    }
  };
  // rpv-search__highlight rpv-search__highlight--current
  return (
    <div className="w-full h-full">
      {/* <button onClick={getElement}>find</button> */}

      <div className="viewer" ref={viewerRef}>
        {pdfFile ? (
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}
          >
            <Viewer
              fileUrl={pdfFile}
              plugins={[defaultLayoutPluginInstance, searchPluginInstance]}
            ></Viewer>
          </Worker>
        ) : (
          <> Bill is not Available</>
        )}
      </div>
    </div>
  );
}

export default Index;

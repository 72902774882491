import { GraphUtils } from "../../utils/graphUtils";

const graphUtils = new GraphUtils();
const TodData = graphUtils.generateTodDataForYears(2022, 2023, 2024);
const TodData1 = graphUtils.generateTodDataForYears(2022, 2023, 2024);
const TodData2 = graphUtils.generateTodDataForYears(2022, 2023, 2024);
const TodData3 = graphUtils.generateTodDataForYears(2022, 2023, 2024);
const TodData4 = graphUtils.generateTodDataForYears(2022, 2023, 2024);

const annualEnergyPowerConsumption = graphUtils.generateRandomEnergyData(
  ["2022", "2023", "2024"],
  12,
  80,
  200
);

const monthlyEnergyPowerConsumption = graphUtils.generateMonthlyEnergyData(
  80,
  200,
  ["2022", "2023", "2024"]
);

const monthlyCostPowerConsumption = graphUtils.generateMonthlyEnergyData(
  80,
  200,
  ["2022", "2023", "2024"]
);

const annualCostPowerConsumption = graphUtils.generateRandomEnergyData(
  ["2022", "2023", "2024"],
  12,
  80,
  200
);
const meter1PowerConsumption = graphUtils.generateRandomEnergyData(
    ["2022", "2023", "2024"],
    12,
    80,
    200
  );
  const meter2PowerConsumption = graphUtils.generateRandomEnergyData(
    ["2022", "2023", "2024"],
    12,
    80,
    200
  );
  const meter3PowerConsumption = graphUtils.generateRandomEnergyData(
    ["2022", "2023", "2024"],
    12,
    80,
    200
  );
  const meter4PowerConsumption = graphUtils.generateRandomEnergyData(
    ["2022", "2023", "2024"],
    12,
    80,
    200
  );
export const graphData = [
  {
    organisations: {
      organisation1: {
        annualEnergyPowerConsumption,
        annualCostPowerConsumption,
        monthlyEnergyPowerConsumption,
        monthlyCostPowerConsumption,
        TodData,
        factories: {
          factory1: {
            units: {
              unit1: {
                name: "unit1",
                meters: {
                  meter1: {
                    number: 2313,
                    TodData: TodData1,
                    annualPowerConsumption: meter1PowerConsumption
                  },
                  meter2: {
                    number: 2313,
                    TodData: TodData2,
                    annualPowerConsumption: meter2PowerConsumption

                  },
                  meter3: {
                    number: 2313,
                    TodData: TodData3,
                    annualPowerConsumption: meter3PowerConsumption

                  },
                  meter4: {
                    number: 2313,
                    TodData: TodData4,
                    annualPowerConsumption: meter4PowerConsumption

                  },
                },
              },
            },
          },
        },
      },
      organisation2: {
        annualEnergyPowerConsumption,
        annualCostPowerConsumption,
        monthlyEnergyPowerConsumption,
        monthlyCostPowerConsumption,
        TodData,
        factories: {
          factory1: {
            units: {
              unit1: {
                name: "unit1",
                meters: {
                  meter1: {
                    number: 2313,
                    TodData: TodData1,
                    annualPowerConsumption: meter1PowerConsumption
                  },
                  meter2: {
                    number: 2313,
                    TodData: TodData2,
                    annualPowerConsumption: meter2PowerConsumption

                  },
                  meter3: {
                    number: 2313,
                    TodData: TodData3,
                    annualPowerConsumption: meter3PowerConsumption

                  },
                  meter4: {
                    number: 2313,
                    TodData: TodData4,
                    annualPowerConsumption: meter4PowerConsumption

                  },
                },
              },
            },
          },
        },
      },
      organisation3: {
        annualEnergyPowerConsumption,
        annualCostPowerConsumption,
        monthlyEnergyPowerConsumption,
        monthlyCostPowerConsumption,
        TodData,
        factories: {
          factory1: {
            units: {
              unit1: {
                name: "unit1",
                meters: {
                  meter1: {
                    number: 2313,
                    TodData: TodData1,
                    annualPowerConsumption: meter1PowerConsumption
                  },
                  meter2: {
                    number: 2313,
                    TodData: TodData2,
                    annualPowerConsumption: meter2PowerConsumption

                  },
                  meter3: {
                    number: 2313,
                    TodData: TodData3,
                    annualPowerConsumption: meter3PowerConsumption

                  },
                  meter4: {
                    number: 2313,
                    TodData: TodData4,
                    annualPowerConsumption: meter4PowerConsumption

                  },
                },
              },
            },
          },
        },
      },
      organisation4: {
        annualEnergyPowerConsumption,
        annualCostPowerConsumption,
        monthlyEnergyPowerConsumption,
        monthlyCostPowerConsumption,
        TodData,
        factories: {
          factory1: {
            units: {
              unit1: {
                name: "unit1",
                meters: {
                  meter1: {
                    number: 2313,
                    TodData: TodData1,
                    annualPowerConsumption: meter1PowerConsumption
                  },
                  meter2: {
                    number: 2313,
                    TodData: TodData2,
                    annualPowerConsumption: meter2PowerConsumption

                  },
                  meter3: {
                    number: 2313,
                    TodData: TodData3,
                    annualPowerConsumption: meter3PowerConsumption

                  },
                  meter4: {
                    number: 2313,
                    TodData: TodData4,
                    annualPowerConsumption: meter4PowerConsumption

                  },
                },
              },
            },
          },
        },
      },
    },
  },
];

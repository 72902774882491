import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ValidatePassword } from "../../services/validations/Password";
import { validateEmail } from "../../services/validations/email";
import ErrorDisplay from "../../components/errorDisplay/ErrorDisplay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBox from "../../components/inputbox/InputBox";
import Abutton from "../../components/button/Abutton";
import SignUpPopup from "../../components/popup/Popup";
import BillPopup from "../../components/popup/BillsPopup";
import { sendOTP, userLogin } from "../../services/apis/user";
import jwtDecode from "jwt-decode";
import { postList } from "../../api/apiDataExchange";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { user, isAuthenticated, login, logout, storeConfig } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    const payload = {
      "email": email,
      "password": password
    };
    if (payload.email != "" && payload.password != "") {
      try {
        const user = await login(payload);
        console.log(user, 'user');

        if (user.success) {
          const token = user?.result?.token;
          let decodedToken = null;
          if (token) {
            decodedToken = jwtDecode(token);
            setUserId(decodedToken?._id);
          
            //TODO: add for otp  otp functionality
            // setOpenPopup(true);


            // TODO : remove when add otp functionality;

            const orgData = await postList(`${process.env.REACT_APP_API_URL}/organisation/list`)
            if (orgData?.data?.results?.length > 0) {
              navigate('/dashboard');
            } else {
              navigate('/my-data');
            }

            const config = JSON.parse(localStorage.getItem('config'));
            if (!config) {
              await storeConfig();
            }





          }

          //TODO: add for otp  otp functionality
          // if (decodedToken) {
          //   try {
          //     const otpResponse = await sendOTP(decodedToken?._id)
          //     if (otpResponse.success) {
          //       toast.success('OTP Sent.', {
          //         position: "top-center"
          //       });
          //     } else {
          //       console.error("Error sending OTP:", otpResponse.error);
          //     }
          //   } catch (error) {
          //     console.error("Error sending OTP:", error);
          //   }
          // }
        } else {
          if (user instanceof Error && user.response) {
            const errorMessage = user?.response?.data?.error?.message;
            console.log(errorMessage, 'errorMessage')
            if (errorMessage === "Invalid Credentials") {
              // Handle the case of invalid credentials
              toast.error(errorMessage, {
                position: "top-center"
              });
            } else if (errorMessage === "Only Buyer user type is allowed!") {
              // Handle the case where only 'buyer' user type is allowed
              toast.error(errorMessage, {
                position: "top-center"
              });
            } else {
              // Handle other types of errors
              toast.error('Failed to log in. Please try again later.', {
                position: "top-center"
              });
            }
          } else {
            // Handle other types of errors
            toast.error('Failed to log in. Please try again later.', {
              position: "top-center"
            });
          }
        }
      } catch (error) {
        console.error("Error logging user:", error);
        toast.error('Failed to log in. Please try again later.', {
          position: "top-center"
        });
      }
    };
  }

  useEffect(() => {
    const fetchData = async () => {
      const checkItsVerified = localStorage.getItem("isOtpVerified");
      if (checkItsVerified === 'true') {
        navigate("/dashboard");
        const config = JSON.parse(localStorage.getItem('config'));
        if (!config) {
          await storeConfig();
        }
      }
    };
    fetchData();

  }, []);



  const handleErrors = (errorObj) => {

    // console.log(errorObj)


    const key = Object.keys(errorObj)[0];
    const value = errorObj[key];

    const existingIndex = errors.findIndex(error => Object.keys(error)[0] === key);

    if (existingIndex !== -1) {
      const updatedErrors = [...errors];
      updatedErrors[existingIndex][key] = value;
      setErrors(updatedErrors);
    } else {
      setErrors(prevErrors => [...prevErrors, { [key]: value }]);
    }
  }

  return (
    <>
      <div className="flex   h-screen flex-col  justify-center items-center w-full max-w-2xl m-auto">
        <div className="max-w-[961px] h-screen md:max-h-[780px] pt-[18px] px-[30px] w-full flex flex-col justify-center items-center shadow-lg bg-[#F7FCFA]">
          <div className="w-[90%] sm:w-[60%] h-[20%] sm:h-[30%] mx-auto">
            <img src="/logo.png" alt="Ampera" className="w-[100%] h-[100%] mx-auto" />
          </div>
          <div className="w-full">
            <InputBox
              placeholder="Enter Email Id"
              id="test"
              type="email"
              validationtypes={["email", "required"]}
              showError={formSubmitted}
              label={"Email"}
              value={email}
              fieldName={"Email"}

              setInputValue={setEmail}
              handleErrors={handleErrors}
            />
          </div>
          <div className="w-full">
            <InputBox
              placeholder="Enter Password"
              id="test"
              fieldName={"Password"}
              type="password"
              validationtypes={["password", "required"]}
              showError={formSubmitted}
              label={"Password"}
              value={password}
              setInputValue={setPassword}
              handleErrors={handleErrors}
            />
          </div>
          <div className="flex md:mt-[11px] font-medium underline justify-end w-full md:max-w-[500px]">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <div className="mt-[28px]">
            <Abutton
              text={"Login"}
              handleOnclick={(e) => {
                handleSubmit(e);
              }}
              Mheight={44}
              Mwidth={170}
              DHeight={44}
              DWidth={299}
            />
          </div>

          <div className="font-normal mt-[33px] ">
            <Link to={'/signup'}>
              Don’t have an account? <span className="underline">Sign Up</span>
            </Link>
          </div>
        </div>
      </div>
      <SignUpPopup setOpenPopup={setOpenPopup} openPopUp={openPopup} userId={userId} />
    </>
  );
};
export default Login;

import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Abutton from "../../components/button/Abutton";
import { getData, postData, updateData } from "../../api/apiDataExchange";
import { Link, useNavigate, useParams } from 'react-router-dom';
import ViewBox from "../../components/viewBox/ViewBox";
import { useAuth } from "../../hooks/useAuth";

const ViewUnit = () => {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [name, setName] = useState("");
  const [org, setOrg] = useState("");
  const [orgName, setOrgName] = useState("");
  const [organisationList, setOrganisationList] = useState([]);

  const [factorylist, setFactorylist] = useState([])
  const [factory, setfactory] = useState("")
  const [factoryName, setfactoryName] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [existingSolarTop, setExistingSolarTop] = useState("");
  const [solarCapacity, setSolarCapacity] = useState("");
  const [solarNetBilling, setSolarNetBilling] = useState("");
  const [solarNetMetering, setSolarNetMetering] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState('');
  const [selectedDiscomCode, setSelectedDiscomCode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const unit = await getData(`${process.env.REACT_APP_API_URL}/unit/${id}`);
      
      if (unit.result.name) {
        setName(unit.result.name);
      }
      if (unit.result.organisationId._id) {
        setOrg(unit.result.organisationId._id);
      }
      if (unit.result.organisationId.name) {
        setOrgName(unit.result.organisationId.name);
      }
      if (unit.result.factoryId._id) {
        setfactory(unit.result.factoryId._id);
      }
      if (unit.result.factoryId.name) {
        setfactoryName(unit.result.factoryId.name);
      }
      if (unit.result.existingSolarRooftop) {
        setExistingSolarTop(unit.result.existingSolarRooftop);
      }
      if (unit.result.solarRooftopDetails?.Capacity) {
        setSolarCapacity(unit.result.solarRooftopDetails?.Capacity);
      }
      if (unit.result.solarRooftopDetails?.netBilling) {
        setSolarNetBilling(unit.result.solarRooftopDetails?.netBilling);
      }
      if (unit.result.solarRooftopDetails?.netMetering) {
        setSolarNetMetering(unit.result.solarRooftopDetails?.netMetering);
      }
      if (unit.result.stateCode) {
        setSelectedStateCode(unit.result.stateCode);
      }
      if (unit.result.discomCode) {
        setSelectedDiscomCode(unit.result.discomCode);
      }
      if (unit?.result?.location?.coordinates) {
        setLatitude(unit?.result?.location?.coordinates[0]);
      }
      if (unit?.result?.location?.coordinates) {
        setLongitude(unit?.result?.location?.coordinates[1]);
      }
    };
    fetchData().catch(console.error);
  }, [id]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    // console.log({ errors });
    const isErrorResolved =
      errors.some((item) => item["EmailError"] === "") &&
      errors.some((item) => item["PasswordError"] === "");

    const allStateData = {
      buyerId: localStorage.getItem('userId'),
      organisationId: org,
      factoryId: factory,
      name: name,
      location: {
        type: "Point",
        coordinates: [40.712776, -74.005974],
      },
      existingSolarRooftop: existingSolarTop,
      solarRooftopDetails: {
        Capacity: solarCapacity,
        netBilling: solarNetBilling,
        netMetering: solarNetMetering,
      },
    };
    // console.log(allStateData);
    const response = await updateData(
      `${process.env.REACT_APP_API_URL}/unit/${id}`,
      allStateData
    );

    // console.log(response);
    if (response?.data?.error?.code === 200) {
      toast.success('Unit Successfully Updated', {
        position: "top-center",
        autoClose: 3000
      })
      navigate('/my-data');
    } else {
      toast.error(response.error.response.data.error.message);
    }
    if (isErrorResolved) {
      setOpenPopup(true);
    }
  };

  useEffect(() => {
    const getdata = async () => {

      const allStateData = {
        buyerId: localStorage.getItem('userId'),
      };
      // console.log(allStateData);
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        allStateData
      );
      setOrganisationList(response?.data?.data?.results);
    };
    getdata();
  }, []);


  useEffect(() => {
    const getfactorydata = async () => {
      if (org !== "") {
        const orgdata = {
          organisationId: org,
        };
        // console.log(orgdata);
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/factory/list`,
          orgdata
        );
        // console.log({ response })
        setFactorylist(response?.data?.data?.results);
      }
    };
    getfactorydata();
  }, [org]);

  const handleErrors = (errorObj) => {
    // console.log(errorObj);

    const key = Object.keys(errorObj)[0];
    const value = errorObj[key];

    const existingIndex = errors.findIndex(
      (error) => Object.keys(error)[0] === key
    );

    if (existingIndex !== -1) {
      const updatedErrors = [...errors];
      updatedErrors[existingIndex][key] = value;
      setErrors(updatedErrors);
    } else {
      setErrors((prevErrors) => [...prevErrors, { [key]: value }]);
    }
  };

  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[170px] pb-20">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen?'sm:ml-[300px]':'sm:ml-[80px]'}`}>
        <div className=" rounded-lg  bg-[#E2F5EC]    w-full flex flex-col pb-[50px] shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">
          <div className="text-[30px] py-8 text-center font-medium leading-[42px]">
            {" "}
            Unit Details
          </div>
          <div className="w-full">
            <ViewBox
              placeholder="Enter factory name"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Name"}
              value={name}
              fieldName={"Name"}
              setInputValue={setName}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className=" w-full">
            {" "}
            <ViewBox
              label={"Organisation"}
              isflexed={true}
              defaultValue={"Select option"}
              validationtypes={"required"}
              imputBoxMaxWidth={545}
              showLable={true}
              value={orgName}
              onChange={(e) => {
                setOrg(e.target.value);
              }}
              options={organisationList.map((organisation) => {
                return { label: organisation.name, value: organisation._id };
              })}
            />
          </div>
          <br />
          <br />
          <div className=" w-full">
            {" "}
            <ViewBox
              label={"Factory"}
              isflexed={true}
              defaultValue={"Select factory"}
              validationtypes={"required"}
              imputBoxMaxWidth={545}
              value={factoryName}
              showLable={true}
              onChange={(e) => {
                setfactory(e.target.value);
              }}
              options={factorylist.map((factory) => {
                return { label: factory.name, value: factory._id };
              })}
            />
          </div>
          <br />

          <div className=" w-full">
            {" "}
            <ViewBox
              label={"Existing Solar Rooftop"}
              isflexed={true}
              defaultValue={"Existing Solar Rooftop"}
              imputBoxMaxWidth={545}
              value={existingSolarTop}
              showLable={true}
              onChange={(e) => {
                setExistingSolarTop(e.target.value);
              }}
              options={[

                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
            />
          </div>
          <br />
          <div className="flex justify-between items-center">
            <div className="flex justify-start w-full">
              <div className="relative w-full   max-w-[540px] ">
                <div className="">
                  <div>
                    <ViewBox
                      placeholder="capacity"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"Solar Rooftop capacity"}
                      maxWidth220={true}
                      value={solarCapacity}
                      fieldName={"Capacity"}
                      displayLabel={false}
                      setInputValue={setSolarCapacity}
                      handleErrors={handleErrors}
                    />
                  </div>
                  <div>
                    <ViewBox
                      placeholder="Net Billing"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"Solar Rooftop Net Billing"}
                      value={solarNetBilling}
                      fieldName={"Net Billing"}
                      displayLabel={false}
                      setInputValue={setSolarNetBilling}
                      handleErrors={handleErrors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Net Metering"
              id="test"
              type="email"
              validationtypes={""}
              showError={formSubmitted}
              label={"Solar Rooftop Net Metering"}
              value={solarNetMetering}
              fieldName={"Net Metering"}
              setInputValue={setSolarNetMetering}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Discom"
              id="test"
              type="email"
              validationtypes={""}
              showError={formSubmitted}
              label={"Discom"}
              value={selectedDiscomCode}
              fieldName={"Discom"}
              setInputValue={setSelectedDiscomCode}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Latitude"
              id="test"
              type="email"
              validationtypes={""}
              showError={formSubmitted}
              label={"Latitude"}
              value={latitude}
              fieldName={"Latitude"}
              setInputValue={setLatitude}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Longitude"
              id="test"
              type="email"
              validationtypes={""}
              showError={formSubmitted}
              label={"Longitude"}
              value={longitude}
              fieldName={"Longitude"}
              setInputValue={setLongitude}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <div className="mt-[33px] w-full px-[14px] sm:px-[55px] sm:mt-[70px] flex font-normal justify-center gap-[80px]">
            <Link to={'/my-data'}>
              <Abutton
                text={"Cancel"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={299}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUnit;

import React from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import GoTODashboard from "../../../components/button/GoTODashboard";
import AnalyticsSidebar from "../../../components/sidebar/AnalyticsSidebar";

const FutureLoad = ({}) => {
  const { isSidebarOpen } = useAuth();
  return (
    <>
      <div className="lg:max-w-[1920px] mt-[58px]  pb-[200px] bg-[#ebedeb] sm:mt-[70px] items-center m-auto flex">

        <div className="hidden sm:block">
        {/* <AnalyticsSidebar /> */}
          <Sidebar type={2} />
        </div>
        <div
          className={`${
            isSidebarOpen ? "lg:ml-[300px]" : "sm:ml-[80px]"
          } w-full px-[10px] sm:px-[50px]    gap-2  mt-12 transition-width duration-300`}
        >
        <div className="flex justify-end items-center w-full  ">
            <GoTODashboard/>
          </div>
          <div className=" flex gap-2 items-center  mt-2  sm:flex-row  flex-col-reverse   rounded-md   justify-between w-full ">
            <div className=" w-full p-2 shadow-xl bg-white sm:min-h-[60vh] rounded-md border border-gray-400   ">
            <main>
                <div class="pattern-container  p-4 ">

                  <h2 className="font-bold">Add future load estimations to view your future consumption and billing</h2>
                  <div class="pattern-unit w-[100%] sm:w-[70%] mx-auto">
                    <ul className="list-disc pl-4">
                      <li className="py-4">
                      In the dashboard and analytics you see data based on your historic bills which are at monthly granularity.
                      </li>
                      <li className="py-1">
                        <strong className="text-[#067C4E]">Ampera AI</strong>  you to customize this where you can provide future load information which will help you get deeper insights in your energy consumption patterns and bills in the future.
                      </li>
                      <li className="py-1">
                      It will also help Ampera AI to give you accurate estimation of possible future savings.
                      </li>
                      <li className="py-1">
                        By clicking the <strong className="text-[blue]"> <Link to="/unit-list">Customize Future Unit Level Pattern</Link></strong> you can provide unit future load information easily which can be replicated across different units easily.
                      </li>
                      <li className="py-1">
                      Once future load information is provided, you can view the updated analytics by hitting the <strong className="text-[blue]"> <Link to="/analytics/futuredata-view">View Future Analytics</Link></strong> button at the right bottom.
                      </li>
                    </ul>
                  </div>
                </div>

              </main>
            </div>
            <div className=" bg-white p-4 shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] sm:min-h-[60vh] rounded-lg  flex sm:flex-col gap-[5vh] justify-around h-full border border-gray-400   ">
            <Link to="/unit-list">
              <div className="border bg-[#067C4E] cursor-pointer  font-medium  text-white shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.2)] py-5 text-center p-2  rounded-md  border-gray-400 ">
                Customise Future Unit Level Pattern
              </div>
              </Link>
              <Link to="/analytics/futuredata-view">
              <div className="border bg-[#067C4E]  cursor-pointer font-medium justify-center flex items-center py-5 sm:py-0 p-2 text-white shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.2)] text-center rounded-md  border-gray-400 ">
              <br className={`hidden sm:block`}/>
                View Future Analytics
                <br className={`hidden sm:block`}/>
                <br className={`hidden sm:block`}/>
            
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FutureLoad;


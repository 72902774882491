import React from "react";
import { RotatingLines } from "react-loader-spinner";

const LoaderComponent = ({width,height}) => {
  return (
    <div className="flex  justify-center items-center">
      {/* <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-gray-900"></div> */}
      <RotatingLines
        visible={true}
        height={height?height:"96"}
        width={width?width:"96"}
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default LoaderComponent;

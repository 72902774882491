import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BillConsumptionView from "../../views/BillConsumptionView";
import CustomisedDataView from "../../views/CustomisedDataView";
import FutureLoadSimulationView from "../../views/FutureLoadSimulationView";
import AggregatedAndComparisonView from "../../views/AggregatedAndComparisonView";
import { useAuth } from "../../hooks/useAuth";
import AnalyticsSidebar from "../../components/sidebar/AnalyticsSidebar";
import SolarEnergyView from "../../views/billConsumption/SolarEnergyView";
import TolalEnergyView from "../../views/billConsumption/TolalEnergyView";
import BillConsumptionTest from "../../views/BillConsumptionTest";
import Sidebar from "../../components/sidebar/Sidebar";

const Analytics = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  useEffect(() => {
    if (location.state && location.state.prop) {
      setActiveTab(location.state.prop);
    }
    if (location.state && location.state.submenu) {
      setActiveSubMenu(location.state.submenu);
    }
  }, [location.state]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "app":
        return (
          <div
            className={`${activeTab === "app" ? "block opacity-100" : "hidden opacity-0"
              }`}
            id="app"
            role="tabpanel"
          >
            <BillConsumptionView />

          </div>
        );
      case "message":
        return (
          <div
            className={
              activeTab === "message" ? "block opacity-100" : "hidden opacity-0"
            }
            id="message"
            role="tabpanel"
          >
            <CustomisedDataView submenu={activeSubMenu} />
          </div>
        );
      case "settings":
        return (
          <div
            className={
              activeTab === "settings"
                ? "block opacity-100"
                : "hidden opacity-0"
            }
            id="settings"
            role="tabpanel"
          >
            <FutureLoadSimulationView />
          </div>
        );
      case "graphs":
        return (
          <div
            className={
              activeTab === "graphs" ? "block opacity-100" : "hidden opacity-0"
            }
            id="settings"
            role="tabpanel"
          >
            <AggregatedAndComparisonView />
          </div>
        );
      default:
        return <div>default component</div>;
    }
  };

  return (
    <div className="max-w-[1920px] mt-[58px] lg:mt-[100px] sm:pr-[50px]">
      <div className="relative ">
         <div className="hidden sm:block">
          {/* <AnalyticsSidebar /> */}
          <Sidebar type={2} />
        </div> 
        {/* </div>
      <div className={`${isSidebarOpen ? "ml-[320px]" : "ml-[150px]"}`}> */}
        <div data-tab-content="" className={`sm:pb-[20px] sm:ml-28 mx-[10px]`}>
        <BillConsumptionTest/>
        </div>

      </div>
    </div>
  );
};

export default Analytics;

import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;


export const uploadImage = async (file, objectType, type) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.result?.token;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('objectType', objectType);
    formData.append('type', type ? type : "image");
    formData.append('storage', process.env.REACT_APP_MEDIA_STORAGE);

    const response = await axios.post(`${apiUrl}/media`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `bearer ${token}`
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    return error;
    // throw new Error('Failed to upload image');
  }
};

export const getImageById = async (id) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.result?.token;
    const response = await axios.get(`${apiUrl}/media/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `bearer ${token}`
      },
    })
    return response.data;
  } catch (error) {
    console.error('Error getting image:', error);
    return error;
    // throw new Error('Failed to upload image');
  }
}

export const deleteImageById = async (id) => {

  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.result?.token;
    const response = await axios.delete(`${apiUrl}/media/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `bearer ${token}`
      },
    })
    return response.data;
  } catch (error) {
    console.error('Error getting image:', error);
    return error;
    // throw new Error('Failed to upload image');
  }
}




export const convertImageToPdf = async (images) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.result?.token;
    const formData = new FormData();
    formData.append('storageType', process.env.REACT_APP_MEDIA_STORAGE);

    images.forEach((file, index) => {
      formData.append(`images[${index}]`, file);
    });

    const response = await axios.post(`${apiUrl}/media/createPdfFromImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    return error;
  }
};

import React from 'react';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';

const ShiftSlotSelector = ({ selectedSlots, setSelectedSlots, numberOfShifts }) => {
    const handleSlotChange = (index, timeType, time) => {
        setSelectedSlots(prevSlots => {
            const newSlots = [...prevSlots];
            newSlots[index] = {
                ...newSlots[index],
                [timeType]: time
            };
            return newSlots;
        });
    };
    const shiftPeriods = Array.from({ length: numberOfShifts }, (_, index) => index + 1);

    return (
        <div>
            {Array.from({ length: numberOfShifts }, (_, index) => (
                <div className='flex flex-row items-center mt-4' key={index}>
                    <div className='flex flex-row w-[100%]'>
                        <div className='w-[50%] sm:w-[30%]'>
                            <TimePicker
                                value={selectedSlots[index]?.startTime  || "00:00"}
                                onChange={(time) => handleSlotChange(index, 'startTime', time)}
                                format="h:m: a"
                                disableClock={true}
                                clearIcon={null}
                                
                            />
                        </div>
                        <div className='w-[30%]'>
                            <TimePicker
                                value={selectedSlots[index]?.endTime || "12:00 pm"}
                                onChange={(time) => handleSlotChange(index, 'endTime', time)}
                                format="h:m: a"
                                disableClock={true}
                                clearIcon={null}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ShiftSlotSelector;

import React, { useState, useEffect, useRef } from "react";
import LineChartEnergyView2Y from "../../containers/graphs/LineChartEnergyView2Y";
import LIneChartEnergyTOD from "../../containers/graphs/LIneChartEnergyTOD";
import { GraphUtils } from "../../utils/graphUtils";
import { graphData } from "../../files/sampleData/graphdata";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import { ConstantUtils } from "../../utils/ConstantUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import LegendComponent from "../../components/chart/LegendComponent";

const graphUtils = new GraphUtils();

const MonthlyTodEnergyView = ({
  monthlyTodEnergyData,
  selectedEnergyGraph,
  selectedEnergyMonth,
  selectedEnergyYear,
  metersList,
  selectedEnergyMeter,
  energyMonthlyBillingDemand,
  unitTodLabels,
  slotConfigData,
}) => {
  const constantUtils = new ConstantUtils();
  const [yearsList, setYearsList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [isOpenDropwonView, setIsOpenDropwonView] = useState(false);
  const [slotNamesUpdated, setSlotNamesUpdated] = useState([]);
  const handleEnergyMenuClick = () => {
    setIsOpenDropwonView(!isOpenDropwonView);
  };

  //==============================================================
  //          graph data
  //================================================================

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return value === 0 ? "N/A" : value?.toLocaleString('en-IN');;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Energy(kWh)", // Add your label here
        },

        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return value / 1000 + "k"; // Converts 1000 to 1k, 2000 to 2k, etc.
            }
            return value;
          },
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: "Energy(kva)", // Add your label here
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  });

  const [labels, setLabels] = useState("");
  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: "contract demand",
        data: [],
        yAxisID: "y1",
        type: "line",
        borderColor: constantUtils.getGraphColors().contractDemand,
        backgroundColor: constantUtils.getGraphColors().contractDemand,
        fill: false,
      },
      {
        label: "% contract demand",
        data: [],
        yAxisID: "y1",
        type: "line",
        borderColor: constantUtils.getGraphColors().percentContractDemand,
        backgroundColor: constantUtils.getGraphColors().percentContractDemand,
        fill: false,
      },

      {
        label: "L F",
        data: [],
        yAxisID: "y1",
        type: "line",
        borderColor: constantUtils.getGraphColors().LF,
        backgroundColor: constantUtils.getGraphColors().LF,
        fill: false,
      },
      {
        label: "Demand",
        data: [], // replace with your actual data
        yAxisID: "y1",
        type: "line",
        borderColor: constantUtils.getGraphColors().demand,
        backgroundColor: constantUtils.getGraphColors().demand,
        fill: false,
      },
    ],
  });

  useEffect(() => {
    //    console.log({labels})
    if (labels) {
      setData((prevData) => ({
        ...prevData,
        labels: labels,
      }));
    }
  }, [labels]);

  useEffect(() => {
    // console.log("updating labels",unitTodLabels);
    setLabels(unitTodLabels);
  }, [unitTodLabels]);

  useEffect(() => {
    //    console.log({ energyMonthlyBillingDemand });
    if (energyMonthlyBillingDemand) {
      const updatedData = [...data.datasets];
      updatedData.forEach((dataset) => {
        if (dataset.label === "contract demand") {
          dataset.data = energyMonthlyBillingDemand.contractDemand
            ? Array(labels.length).fill(
                energyMonthlyBillingDemand.contractDemand
              )
            : 0;
        } else if (dataset.label === "% contract demand") {
          dataset.data = Array(labels.length).fill(
            energyMonthlyBillingDemand.percentageOfContract
          );
        }
      });
      //   console.log({updatedData})
      //   setData({ ...data, datasets: updatedData });
    }
  }, [energyMonthlyBillingDemand]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log({
    //   monthlyTodEnergyData,
    //   selectedEnergyMeter,
    //   selectedEnergyYear,
    //   selectedEnergyMonth,
    // });
    // console.log({ isLoading });

    if (
      (monthlyTodEnergyData["year"] === selectedEnergyYear.toString() &&
      monthlyTodEnergyData["month"] === selectedEnergyMonth.toLowerCase() &&
      monthlyTodEnergyData["meter"] === selectedEnergyMeter)  || monthlyTodEnergyData.length === 0
    ) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      setIsLoading(true);
    }
  }, [
    monthlyTodEnergyData,
    selectedEnergyYear,
    selectedEnergyMonth,
    selectedEnergyMeter,
  ]);

  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    if (selectedEnergyMonth) {
      setSelectedMonth(selectedEnergyMonth.toLowerCase());
    }
  }, [selectedEnergyMonth]);

  const [full, setFull] = useState(false);

  const dropdownRefEnergyTod = useRef(null);

  //   useEffect(() => {
  //     if(selectedUnit !== ''){
  //         const existingUnitData = slotnames.find(slot => selectedUnit in slot);
  //         console.log({existingUnitData})
  //         if(existingUnitData && existingUnitData[selectedUnit] !== undefined  ){
  // //            console.log({existingUnitData:existingUnitData[selectedUnit]});
  //             setSlotNamesUpdated(existingUnitData[selectedUnit]);
  //             setLabels(getLabels(existingUnitData[selectedUnit], slotTimelables));
  //         }
  //     }
  //   }, [selectedUnit,slotnames])

  //   useEffect(() => {
  //     const selectedMeterUnit = metersList.find(
  //       (meterData) => meterData._id === selectedEnergyMeter
  //     );

  //     if (selectedMeterUnit["unitId"]["_id"]) {
  //       setSelectedUnit(selectedMeterUnit["unitId"]["_id"]);
  //     }
  //   }, [selectedEnergyMeter]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefEnergyTod.current &&
        !dropdownRefEnergyTod.current.contains(event.target)
      ) {
        setIsOpenDropwonView(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    //  console.log({ updatedData: monthlyTodEnergyData });
    if (data !== undefined && monthlyTodEnergyData !== undefined) {
      setData((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => {
          if (dataset.label === "Demand") {
            return { ...dataset, data: monthlyTodEnergyData.demand };
          } else if (dataset.label === "L F") {
            return { ...dataset, data: monthlyTodEnergyData.lf };
          } else {
            return dataset;
          }
        }),
      }));
    }
  }, [monthlyTodEnergyData]);

  useEffect(() => {
    if (
      data !== undefined &&
      monthlyTodEnergyData !== undefined &&
      monthlyTodEnergyData["data"]
    ) {
      //   console.log({ monthlyTodEnergyData });
      setData((prevData) => {
        let newDatasets = prevData.datasets
          .map((dataset) => {
            if (dataset && dataset.label === "Demand") {
              return dataset;
            } else if (dataset && dataset.label === "contract demand") {
              return dataset;
            } else if (dataset && dataset.label === "% contract demand") {
              return dataset;
            } else if (dataset && dataset.label === "L F") {
              return dataset;
            }
          })
          .filter(Boolean);

        // console.log({ newDatasets: monthlyTodEnergyData["data"] });

        let dynamicDatasets = Array.isArray(monthlyTodEnergyData["data"])
          ? monthlyTodEnergyData["data"]
          : [];
        // console.log({ dynamicDatasets });
        newDatasets.push(...dynamicDatasets);
        // console.log({ newDatasets });
        return {
          ...prevData,
          datasets: newDatasets,
        };
      });
    } else {
      setData((prevData) => {
        let newDatasets = prevData.datasets.filter((dataset) => {
          return dataset.label !== "slots";
        });

        return {
          ...prevData,
          datasets: newDatasets,
        };
      });
    }
  }, [monthlyTodEnergyData]);

  return (
    <div
      className={`${
        selectedEnergyGraph === "TOD" ? "flex" : "hidden"
      }  flex-col shadow-[0_4px_4px_0_#00000040] border border-[#00000040]  sm:min-h-[400px] rounded-[30px]  lg:min-w-[50%]`}
    >
      <div className={`px-[20px] sm:px-[35px]`}>
        <div
          className={` flex justify-between px-[20px] sm:px-[35px] py-[10px] sm:py-[25px] w-full`}
        >
          <div>Monthly: ToD Energy Distribution : Discom </div>
          <div className="cursor-pointer flex gap-[20px]">
            <button onClick={() => setFull(true)}>
              <FontAwesomeIcon icon={faExpand} />
            </button>
            <CustomTooltop
              disable={
                constantUtils.getGraphToolTipData().MonthlyTodEnergy
                  .disableToolTip
              }
              text={constantUtils.getGraphToolTipData().MonthlyTodEnergy.text}
            />
          </div>
          {/*  <img
            onClick={handleEnergyMenuClick}
            alt="menuIcon"
            src="/graphMenuIcon.svg"
          />
          {isOpenDropwonView && (
            <div className=" absolute ml-[100px] ">
              {isOpenDropwonView && (
                <div className={`absolute  pt-[35px]`}>
                  <ul
                    className={` text-[16px] flex flex-col rounded-[10px] bg-white  font-medium leading-[24px] border py-[10px] px-[10px] shadow-[0_4px_4px_0_#00000040] ml-[-390px] border-[#D9D9D9]`}
                  >
                    <li
                      onClick={() =>
                        handleEnergygraph("Annual", selectedEnergyYear, "")
                      }
                      className="inline-flex cursor-pointer my-2 gap-[10px]"
                    >
                      <img alt="menu" src="/leftCircleArrow.svg" />
                      <span>Annual Power Consumption View (KWH)</span>{" "}
                    </li>
                    <li
                      onClick={() =>
                        handleEnergygraph("Monthly", selectedEnergyYear, "")
                      }
                      className="inline-flex  my-2 cursor-pointer gap-[10px]"
                    >
                      <img alt="menu" src="/leftCircleArrow.svg" />
                      <span>Monthly Power Consumption View (KWH)</span>{" "}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )} */}
        </div>
        <div>
          <LegendComponent
            data={data}
            position={"place-items-start"}
            columns={"grid-cols-4"}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <LoaderComponent />
        </div>
      ) : (
        <>
          {/* <div className="flex justify-end px-10"> */}
          {/* <select
              value={selectedEnergyYear}
              onChange={handleYear}
              className="px-2"
            >
              {yearsList.map((meter) => (
                <option key={meter} value={meter}>
                  {meter}
                </option>
              ))}
            </select>
            <select
              onChange={handleMonth}
              className="px-2 uppercase"
              value={selectedMonth}
            >
              {monthss.map((meter, index) => (
                <option key={meter} value={meter}>
                  {meter}
                </option>
              ))}
            </select>
            <select
              onChange={handleMeter}
              value={selectedEnergyMeter.meterNo}
              className="px-2 uppercase"
            >
              {metersList.map((meter, index) => (
                <option key={meter} value={meter._id}>
                  {meter.meterNo}
                </option>
              ))}
            </select> */}
          {/* </div> */}

          <div className="sm:px-[35px] pb-[20px] sm:py-[25px]">
            {monthlyTodEnergyData?.year === "" &&
            monthlyTodEnergyData?.month === "" ? (
              <div className="w-full text-red-800 flex justify-center items-center min-h-[300px]">
                Data Not Found
              </div>
            ) : (
              <LIneChartEnergyTOD
                data={data}
                options={options}
                full={full}
                setFull={setFull}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MonthlyTodEnergyView;

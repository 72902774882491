import React, { useState, useEffect } from "react";
import useInputBox from "../../hooks/useInputBox";

const ViewBox = ({ label, value, }) => {
  return (
    <div className="px-5 sm:px-7">
      <div className="flex flex-row text-2xl ">
        <div className={`font-medium min-w-[50%] max-w-[50%] sm:min-w-[40%] text-[20px] sm:text-[24px] sm:w-[250px]`}>
          {label} :
        </div>
        <div className="ml-3 sm:ml-5 text-[19px] sm:text-[24px] ">{value}</div>
      </div>
      <br />
    </div>
  );
};

export default ViewBox;

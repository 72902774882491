import React from "react";

const AbuttonDisable = ({ text, Mheight, Mwidth, DHeight, DWidth,  }) => {
  return (
    <button
     
      className={`text-center rounded-[10px] p-[10px] text-[16px] font-medium leading-[24px] text-black border border-black-50 bg-[#c4c2c2] h-[${Mheight}px] w-[${Mwidth}px] md:h-[${DHeight}]px md:w-[${DWidth}px] `}
    >
      {text}
    </button>
  );
};

export default AbuttonDisable;

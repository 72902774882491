import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import InputBoxV2 from "../../components/inputbox/InputboxV2";
import Abutton from "../../components/button/Abutton";
import ASelectv1 from "../../components/select/ASelectV1";
import { Link, useNavigate } from "react-router-dom";

import InputBoxV1 from "../../components/inputbox/InputBoxV1";
import { postData } from "../../api/apiDataExchange";
import { useAuth } from "../../hooks/useAuth";
import GooglePlacesMap from "../../components/googlePlacesMap/GooglePlacesMap";


const AddFactory = () => {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [org, setOrg] = useState("");
  const [organisationList, setOrganisationList] = useState([]);

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const [bssrYear, setBssrYear] = useState();
  const [carbonFootprintYear, setCarbonFootprintYear] = useState();
  const [sector, setSector] = useState("");
  const [landOwnedBy, setLandOwnedBy] = useState("");
  const [leaseTenure, setLeaseTenure] = useState("");
  const [landPart, setLandPart] = useState("");

  const [outstandingDuties, setOutstandingDuties] = useState("");
  const [existingSolarTop, setExistingSolarTop] = useState("");
  const [solarCapacity, setSolarCapacity] = useState("");
  const [solarNetBilling, setSolarNetBilling] = useState("");
  const [solarNetMetering, setSolarNetMetering] = useState("");
  const [errors, setErrors] = useState([]);

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [clickedCoords, setClickedCoords] = useState(null);

  const handleBrsrYear = (date) => {
    setBssrYear(date);
  };

  const handleCarbonFootprintYear = (date) => {
    setCarbonFootprintYear(date);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log({ errors });
    const isErrorResolved =
      errors.some((item) => item["EmailError"] === "") &&
      errors.some((item) => item["PasswordError"] === "");

    const allStateData = {
      buyerId: localStorage.getItem("userId"),
      organisationId: org,
      name: name,
      landPart: landPart,
      address: address,
      location: {
        type: "Point",
        coordinates: [latitude, longitude],
      },
      sector: sector,
      country: country,
      state: state,
      district: district,
      pinCode: pincode,
      landOwnedBy: landOwnedBy,
      leaseTenure: leaseTenure,
      outstandingDuesFromDISCOM: outstandingDuties,
      existingSolarRooftop: existingSolarTop,
      solarRooftopDetails: {
        Capacity: solarCapacity,
        netBilling: solarNetBilling,
        netMetering: solarNetMetering,
      },
    };
    if (
      allStateData.name != "" &&
      allStateData.organisationId != "" &&
      allStateData.buyerId != "" &&
      allStateData.country != "" &&
      allStateData.state != "" &&
      allStateData.district != "" &&
      allStateData.pinCode != "" &&
      allStateData.sector != "" &&
      allStateData.leaseTenure != "" &&
      allStateData.outstandingDuesFromDISCOM != "" &&
      allStateData.leaseTenure != "" &&
      allStateData.leaseTenure != ""
    ) {
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/factory`,
        allStateData
      );

      if (response?.data?.error?.code === 200) {
        toast.success("Factory Successfully created", {
          position: "top-center",
          autoClose: 3000,
        });

        navigate("/my-data", { replace: true });
      } else {
        toast.error(response.error.response.data.error.message);
      }
    }
  };

  useEffect(() => {
    const getdata = async () => {
      const allStateData = {
        buyerId: localStorage.getItem("userId"),
      };
      // console.log(allStateData);
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        allStateData
      );
      setOrganisationList(response?.data?.data?.results);
    };
    getdata();
  }, []);

  const handleErrors = (errorObj) => {
    console.log(errorObj);

    const key = Object.keys(errorObj)[0];
    const value = errorObj[key];

    const existingIndex = errors.findIndex(
      (error) => Object.keys(error)[0] === key
    );

    if (existingIndex !== -1) {
      const updatedErrors = [...errors];
      updatedErrors[existingIndex][key] = value;
      setErrors(updatedErrors);
    } else {
      setErrors((prevErrors) => [...prevErrors, { [key]: value }]);
    }
  };

  const handleViewOnMapClick = () => {
    // if (address) {
      setIsOverlayOpen(true);
    // } else {
    //   alert('Please Provide a Valid Address.');
    // }
  };

  const handleOverlayClose = () => {
    setIsOverlayOpen(false);
  };

  const handleMapClick = (coords) => {
    setClickedCoords(coords);
    setLongitude(coords.lng.toFixed(4));
    setLatitude(coords.lat.toFixed(4));
    // console.log('Clicked coordinates:', coords);
  };

  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[170px] pb-20">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"}`}>
        <div className=" rounded-lg bg-[#E2F5EC] px-4 sm:px-0 w-full flex flex-col pb-[50px] shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">
          <div className="text-[22px] py-8 text-center font-medium leading-[42px]">
            {" "}
            Add Factory
          </div>
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter factory name"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Name"}
              value={name}
              maxWidth600={true}
              fieldName={"Name"}
              setInputValue={setName}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Select Organisation"}
              isflexed={true}
              // defaultValue={"Select option"}
              maxWidth600={true}
              showLable={true}
              fieldName={"Organisation"}
              showError={formSubmitted}
              value={org}
              validationtypes={["required"]}
              onChange={(e) => {
                setOrg(e.target.value);
              }}
              options={organisationList.map((organisation) => {
                return { label: organisation.name, value: organisation._id };
              })}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Land Part"}
              isflexed={true}
              // defaultValue={"Select option"}
              maxWidth600={true}
              value={landPart}
              showLable={true}
              onChange={(e) => {
                setLandPart(e.target.value);
              }}
              options={[
                {
                  label: "Goverment Define Industrial",
                  value: "Goverment Define Industrial",
                },
                { label: "Private Area", value: "Private Area" },
              ]}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
            <div className="pl-[30px] pb-[18px] sm:pb-0 md:min-w-[220px]">
              Country <span className="text-[red] ml-[4px]">*</span>{" "}
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-between w-full">
              <div className="relative w-full max-w-[600px] ">
                <div className="flex flex-col sm:flex-row w-full">
                  <div className=" w-full">
                    <InputBoxV1
                      placeholder="Enter country"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"Country"}
                      maxWidth220={true}
                      value={country}
                      fieldName={"Country"}
                      displayLabel={false}
                      setInputValue={setCountry}
                      handleErrors={handleErrors}
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row w-full sm:justify-between sm:items-center">
                    <div className="pl-[30px] sm:pl-0 pb-[18px] pt-[18px] sm:w-[90px] sm:pt-0 sm:pb-0 ">
                      State<span className="text-[red] ml-[4px]">*</span>{" "}
                    </div>
                    <InputBoxV1
                      placeholder="Enter state"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"State"}
                      maxWidth220={true}
                      value={state}
                      fieldName={"State"}
                      displayLabel={false}
                      setInputValue={setState}
                      handleErrors={handleErrors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
            <div className="pl-[30px] pb-[18px] sm:pb-0 md:min-w-[220px]">
              District <span className="text-[red] ml-[4px]">*</span>{" "}
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-between w-full">
              <div className="relative  w-full   max-w-[600px] ">
                <div className="flex flex-col sm:flex-row w-full">
                  <div className=" w-full">
                    <InputBoxV1
                      placeholder="Enter District"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"District"}
                      maxWidth220={true}
                      value={district}
                      fieldName={"District"}
                      displayLabel={false}
                      setInputValue={setDistrict}
                      handleErrors={handleErrors}
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row w-full sm:justify-between sm:items-center">
                    <div className="pl-[30px] pb-[18px] sm:pl-0 sm:w-[90px] sm:pb-0 pt-[18px] sm:pt-0 ">
                      Pincode <span className="text-[red] ml-[4px]">*</span>{" "}
                    </div>
                    <InputBoxV1
                      placeholder="Enter Pincode"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"Pincode"}
                      maxWidth220={true}
                      value={pincode}
                      fieldName={"Pincode"}
                      displayLabel={false}
                      setInputValue={setPincode}
                      handleErrors={handleErrors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter Address"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Address"}
              value={address}
              maxWidth600={true}
              fieldName={"Address"}
              setInputValue={setAddress}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter Sector"
              id="test"
              type="email"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Sector"}
              maxWidth600={true}
              value={sector}
              fieldName={"Sector"}
              setInputValue={setSector}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Land Owned By"}
              isflexed={true}
              // defaultValue={"Select option"}
              imputBoxMaxWidth={545}
              maxWidth600={true}
              value={landOwnedBy}
              showLable={true}
              onChange={(e) => {
                setLandOwnedBy(e.target.value);
              }}
              options={[
                { label: "Lease", value: "Lease" },
                { label: "organisation", value: "Organisation" },
              ]}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="20 years"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Lease Tenure"}
              value={leaseTenure}
              maxWidth600={true}
              fieldName={"Lease Tenure"}
              setInputValue={setLeaseTenure}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="10 years"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              maxWidth600={true}
              label={"Outstanding duties from DISCOM"}
              value={outstandingDuties}
              fieldName={"Outstanding duties"}
              setInputValue={setOutstandingDuties}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Existing Solar Rooftop"}
              isflexed={true}
              // defaultValue={"Carbon Goal"}
              maxWidth600={true}
              value={existingSolarTop}
              showLable={true}
              onChange={(e) => {
                setExistingSolarTop(e.target.value);
              }}
              options={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
            <div className="pl-[30px] pb-[18px] sm:pb-0 md:min-w-[220px]">Credit Rating</div>
            <div className="flex justify-start w-full">
              <div className="relative w-full   max-w-[600px] ">
                <div className="flex justify-between gap-[10px]">
                  <div>
                    <InputBoxV1
                      placeholder="Enter Capacity"
                      id="test"
                      type="text"
                      // validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"TAN"}
                      value={solarCapacity}
                      fieldName={"Solar Capacity"}
                      displayLabel={false}
                      maxWidth220={true}
                      setInputValue={setSolarCapacity}
                      handleErrors={handleErrors}
                    />
                  </div>
                  <div>
                    <InputBoxV1
                      placeholder="Enter Net Billing"
                      id="test"
                      type="text"
                      // validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"TAN"}
                      value={solarNetBilling}
                      fieldName={"Rating"}
                      displayLabel={false}
                      setInputValue={setSolarNetBilling}
                      handleErrors={handleErrors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="Net Metering"
              id="test"
              type="email"
              validationtypes={[]}
              showError={formSubmitted}
              label={""}
              value={solarNetMetering}
              fieldName={"PAN"}
              maxWidth600={true}
              setInputValue={setSolarNetMetering}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full flex flex-row">
              <div className="py-[25px] pl-[30px] pb-[18px] p-0  font-normal text-[18px] leading-[22px] md:min-w-[212px] md:max-w-[212px] ">
                Location:
              </div>
            <button onClick={handleViewOnMapClick} className="text-blue-500 underline ml-4">
              View on Map
            </button>
          </div>
          <br />
          {/* Add Latitude and Longitude inputs here */}
          {/* Latitude Input */}
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter latitude"
              id="latitude"
              type="text"
              validationtypes={[""]}
              showError={formSubmitted}
              label="Latitude"
              value={latitude}
              fieldName="Latitude"
              setInputValue={setLatitude}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          {/* Longitude Input */}
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter longitude"
              id="longitude"
              type="text"
              validationtypes={[""]}
              showError={formSubmitted}
              label="Longitude"
              value={longitude}
              fieldName="Longitude"
              setInputValue={setLongitude}
              handleErrors={handleErrors}
            />
          </div>
          <div className="mt-[33px] w-full px-[14px] sm:px-[55px] sm:mt-[70px] flex font-normal justify-center gap-[10px] sm:gap-[80px]">
            <Abutton
              handleOnclick={handleSubmit}
              text={"Save & Next"}
              Mheight={44}
              Mwidth={170}
              DHeight={44}
              DWidth={299}
            />
            <Link to={"/my-data"}>
              <Abutton
                text={"Cancel"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={299}
              />
            </Link>
          </div>
        </div>
      </div>
      {isOverlayOpen && (
        <GooglePlacesMap
          address={address}
          onClose={handleOverlayClose}
          onMapClick={handleMapClick}
        />
      )}
    </div>
  );
};

export default AddFactory;

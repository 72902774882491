import React, { useEffect, useState } from "react";
import YearlyTodView from "../../components/yearlyTodView/YearlyTodView";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import { getData, postData } from "../../api/apiDataExchange";
import { ConstantUtils } from "../../utils/ConstantUtils";
import { GraphUtils } from "../../utils/graphUtils";

const billService = new BillConsumptionService();
const constantUtils = new ConstantUtils();
const graphUtils = new GraphUtils();





const AggregatedTodView = ({ todToggle }) => {
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [organisationList, setOrganisationList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [selectedFactory, setSelectedFactory] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState([]);
  const [unitDetails, setUnitDetails] = useState();
  const [slotnames, setSlotnames] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date(2023, 0));
  const [isLoading, setIsLoading] = useState(false);
  const [todSlots, setTodSlots] = useState([]);
  const [selectedMeterOptions, setSelectedMeterOptions] = useState([]);
  const [slotColors, setSlotColors] = useState({});

  const [slotConfigData, setslotConfigData] = useState("");
  const [slotDataUpdated, setSlotDataUpdated] = useState(false);


  useEffect(() => {
    const getOrganisationList = async () => {
      try {
        const organisationListData = await billService.getAllOrganisations();
        if (organisationListData && organisationListData.length > 0) {
          setSelectedOrg({
            label: organisationListData[0].name,
            value: organisationListData[0]._id,
          });
          setSelectedOptions({
            label: organisationListData[0].name,
            value: organisationListData[0]._id,
          });
          setOrganisationList(organisationListData);
        } else {
          // Handle case when organisationListData is empty or null
//          console.log("No organisation data found.");
        }
      } catch (error) {
        console.error("Error fetching organisation list:", error);
      }
    };

    getOrganisationList();
  }, []);

  const [months, setMonths] = useState([
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ]);

  function findUnitDetailsByMeterId(units, meterId) {
    for (const unit of units) {
      for (const meter of unit.meters) {
        if (meter._id === meterId) {
          return {
            stateCode: unit.stateCode,
            discomCode: unit.discomCode,
          };
        }
      }
    }
    return null;
  }

//   useEffect(() => {
//     console.log({selectedMeter,selectedUnit,unitDetails})

//     if (selectedOrg.length > 0  && selectedMeter.length > 0 && slotnames.length > 0) {
//       const filters = {
//         type: "month",
//         meterId: [selectedMeter.value],
//         organisationId: [selectedOrg.value],
//         year: selectedYear.getFullYear(),
//       };

//         fetchData(filters);

    
//     }

//   }, [selectedMeter, selectedYear, selectedOrg, slotnames,slotColors]);

  const [todEnergy, setTodEnergy] = useState([]);
  const [todCost, setTodCost] = useState([]);

  const fetchData = async (filters) => {
    setIsLoading(true);
//    console.log({ slotnames });
    try {
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/visualization/todSlot`,
        filters
      );
//      console.log(
    //     response?.data?.data !== undefined && response?.data?.data.length > 0,
    //     "dddd"
    //   );
      if (
        response?.data?.data !== undefined &&
        response?.data?.data.length > 0
      ) {
        const todData = response.data.data;
        let yearDataArray = [];
        months.forEach((month, index) => {
//          console.log({ month });
          let monthData = todData.find((item) => item.month === month);
//          console.log({ monthName: monthData });
          if (monthData) {
            yearDataArray[index] = monthData.slots;
          }
        });
//        console.log({ yearDataArray });
        let slotsEnergyData = [];

        let slotsCostData = [];
        let slotsData = {};
        yearDataArray.forEach((data, index) => {
          const slotKeys = Object.keys(data);
          const slots = [...new Set(slotKeys)];

          slots.forEach((key) => {
//            console.log({ index });
            slotnames.forEach((slotKey) => {
              if (key === slotKey) {
                const arrayname1 = slotKey + "EnergyDataArray";
                if (!slotsData[arrayname1]) {
                  slotsData[arrayname1] = [];
                }
                slotsData[arrayname1][index] = Math.floor(data[key].units);
                const arrayname2 = slotKey + "CostDataArray";
                if (!slotsData[arrayname2]) {
                  slotsData[arrayname2] = [];
                }
                slotsData[arrayname2][index] = Math.floor(data[key].cost);
              }
            });
          });
        });

//        console.log({ slotsData });

        slotnames.forEach((slotKey) => {
          const arrayname1 = slotKey + "EnergyDataArray";
          if (slotsData[arrayname1]) {
            slotsEnergyData.push({
              label:  graphUtils.fomatSlotName(slotKey,slotConfigData),
              backgroundColor: slotConfigData["slotColor"][slotKey],
              borderWidth: 1,
              data: slotsData[arrayname1],
              yAxisID: "y",
              order: 2,
            });
          }

          const arrayname2 = slotKey + "CostDataArray";
          if (slotsData[arrayname2]) {
            slotsCostData.push({
              label: graphUtils.fomatSlotName(slotKey,slotConfigData),
              backgroundColor:slotConfigData["slotColor"][slotKey],
              data: slotsData[arrayname2],
              yAxisID: "y",
              order: 2,
            });
          }
        });

//        console.log({ slotsEnergyData });
//        console.log({ slotsCostData });
        setTodEnergy(slotsEnergyData);
        setTodCost(slotsCostData);
        setTodSlots(todData);
        setTimeout(() => {
            setIsLoading(false);    
        }, 400);
        
      } else {
        setTodEnergy([]);
        setTodCost([]);
        setTodSlots([]);
        setTimeout(() => {
            setIsLoading(false);    
        }, 400);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTimeout(() => {
        setIsLoading(false);    
    }, 400);
    }
  };

  useEffect(() => {
//    console.log({ todToggle });
  }, [todToggle]);

  function findUnitDetailsByMeterId(units, meterId) {
    for (const unit of units) {
      for (const meter of unit.meters) {
        if (meter._id === meterId) {
          return {
            stateCode: unit.stateCode,
            discomCode: unit.discomCode,
          };
        }
      }
    }
    return null;
  }







const getSlotData=()=>{

    //    console.log({ unitList });
//    console.log({ selectedMeter });
const unitdetails = findUnitDetailsByMeterId(unitList, selectedMeter.value);
//    console.log({ unitdetails });
    if (unitdetails !== null) {
      setUnitDetails(unitdetails);

      const configData = JSON.parse(localStorage.getItem("config"));
      const statecode = unitdetails["stateCode"];
      const disComCode = unitdetails["discomCode"];
//      console.log({ configData: configData });
//      console.log({ statecode });
//      console.log({ disComCode });
      if (
        configData &&
        configData.slotConfig &&
        configData.slotConfig[statecode] &&
        configData.slotConfig[statecode][disComCode] &&
        configData.slotConfig[statecode][disComCode]["slotName"] !== undefined
      ) {
        const getSlotNames = Object.keys(
          configData.slotConfig[statecode][disComCode]["slotName"]
        );
//        console.log({ getSlotNames });
const meter=selectedMeter;
        setSlotnames(getSlotNames);
        const allSlotTimeData = configData.slotConfig[statecode][disComCode] ;
        setslotConfigData(allSlotTimeData);
    
        return {allSlotTimeData,disComCode,getSlotNames};
      }
    }
}



  

  useEffect(() => {
    const allSlotData= getSlotData();
// console.log({slotConfigData, allSlotData,selectedMeter, unitDetails,"dasd":"dsada"})
    if (selectedMeter.length === 0) return;


    if (slotnames.length > 0) {

        // console.log({selectedMeter})
      const filters = {
        type: "month",
        meterId: [selectedMeter.value],
        organisationId: [selectedOrg.value],
        year: selectedYear.getFullYear(),
      };





    }
  }, [selectedMeter, selectedYear, selectedOrg]);


  useEffect(() => {
    if (slotConfigData) {
        const filters = {
            type: "month",
            meterId: [selectedMeter.value],
            organisationId: [selectedOrg.value],
            year: selectedYear.getFullYear(),
        };

        fetchData(filters);
    }
}, [slotConfigData]);

  useEffect(() => {
    const getTreeData = async () => {
      setFactoryList([]);
      setUnitList([]);
      setMeterList([]);
//      console.log(selectedOrg);
      if (selectedOrg.value != "" && selectedOrg.value != undefined) {
        const organisationsData = await getData(
          `${process.env.REACT_APP_API_URL}/organisation/tree/${selectedOrg.value}`
        );
//        console.log(organisationsData);
        if (organisationsData && organisationsData.data) {
          const { factories } = organisationsData.data; // Extract factories array from data object
          setFactoryList(factories);

          const allUnits = factories.flatMap((factory) =>
            factory.units.flatMap((unit) => unit)
          );
          setUnitList(allUnits);
          // Collect all meters from factories, units, and meters
          const allMeters = factories.flatMap((factory) =>
            factory.units.flatMap((unit) => unit.meters)
          );

          setMeterList(allMeters);
          setSelectedMeter({
            label: allMeters[0]?.consumerNo,
            value: allMeters[0]?._id,
          });
          setSelectedMeterOptions({
            label: allMeters[0]?.consumerNo,
            value: allMeters[0]?._id,
          });
        }
      }
    };
    getTreeData();
  }, [selectedOrg]);

  const [labels, setLabels] = useState(
    constantUtils.getMonthsFromAprilFinancial(selectedYear.getFullYear())
  );

  const [data, setData] = useState({
    labels,
    datasets: [],
  });

  useEffect(() => {
    setLabels(constantUtils.getMonthsFromAprilFinancial(selectedYear.getFullYear()));
  }, [selectedYear])
  
  
    useEffect(() => {
      setData((prevData) => ({
          ...prevData,
          labels: labels,
      }));
  }, [labels]);
  useEffect(() => {
    if (todToggle) {
      setData((prevData) => ({
        ...prevData,
        datasets: todEnergy,
      }));
    } else if (!todToggle) {
      setData((prevData) => ({
        ...prevData,
        datasets: todCost,
      }));
    }
  }, [todEnergy, todCost, todToggle]);




  

  return (
    <div>
      <div
        className={`${
          todToggle ? "block" : "hidden"
        }    lg:px-[25px]  `}
      >
        <YearlyTodView
          selectedYear={selectedYear}
          selectedOptions={selectedOptions}
          organisationList={organisationList}
          selectedOrg={selectedOrg}
          setSelectedOptions={setSelectedOptions}
          setSelectedOrg={setSelectedOrg}
          setSelectedMeterOptions={setSelectedMeterOptions}
          setSelectedMeter={setSelectedMeter}
          setSelectedYear={setSelectedYear}
          meterList={meterList}
          selectedMeterOptions={selectedMeterOptions}
          isLoading={isLoading}
          data={data}
          key="TodEnergyView"
          view="TodEnergyView"
          slotConfigData={slotConfigData}
        />
      </div>

      <div
        className={`${
          todToggle ? "hidden" : "block"
        }    lg:px-[25px] mt-[-10px]`}
      >
        <YearlyTodView
          selectedYear={selectedYear}
          selectedOptions={selectedOptions}
          organisationList={organisationList}
          selectedOrg={selectedOrg}
          setSelectedOptions={setSelectedOptions}
          setSelectedOrg={setSelectedOrg}
          setSelectedMeterOptions={setSelectedMeterOptions}
          setSelectedMeter={setSelectedMeter}
          setSelectedYear={setSelectedYear}
          meterList={meterList}
          selectedMeterOptions={selectedMeterOptions}
          isLoading={isLoading}
          data={data}
            slotConfigData={slotConfigData}
          key="TodCostView"
          view="TodCostView"
        />
      </div>
    </div>
  );
};

export default AggregatedTodView;

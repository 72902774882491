import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Tree } from "@minoru/react-dnd-treeview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faEye, faTrashCan, faMinus, faPlus, faCircleQuestion, faTools, faCaretDown, faCaretSquareRight, faCaretRight, faGear, faXmark, faEllipsisVertical, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/sidebar/Sidebar';
import { Link } from 'react-router-dom';
import { deleteData, getData, postList } from '../../api/apiDataExchange';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Abutton from '../../components/button/Abutton';
import LoaderComponent from '../../components/loaderCompent/LoaderComponent';
import OcrStatus from '../../components/OcrStatus/OcrStatus';
import { useJobProgress } from '../../hooks/billsJob';
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import GoTODashboard from '../../components/button/GoTODashboard';


const MyData = () => {
    const navigate = useNavigate();
    // const handleDrop = (newTreeData) => setTreeData(newTreeData);
    const { succesfullJobLength } = useJobProgress();

    const [organisations, setOrganisations] = useState([]);
    const [factories, setFactories] = useState([]);
    const [units, setUnits] = useState([]);
    const [meters, setMeters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [listUpadated, setListUpadated] = useState(false);
    const { setIsSidebarOpen, isSidebarOpen, isMyDataAvailable, setIsMyDataAvailable } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const organisationsResponse = await postList(`${process.env.REACT_APP_API_URL}/organisation/list`);
                const organisationsList = organisationsResponse?.data?.results;
                const organisationIds = organisationsList.map(org => org._id);
                if (organisationIds?.length > 0) {

                    const organisationsData = await Promise.all(
                        organisationIds.map(orgId =>
                            getData(`${process.env.REACT_APP_API_URL}/organisation/tree/${orgId}`)
                        )
                    );

                    const allFactories = organisationsData?.flatMap(data =>
                        (data?.data?.factories || []).map(factory => ({
                            ...factory,
                            organisationId: data.data._id
                        }))
                    );

                    const allUnits = allFactories?.flatMap(data =>
                        (data?.units || []).map(unit => ({
                            ...unit,
                            factoryId: data._id
                        }))
                    );

                    const allMeters = allUnits?.flatMap(data =>
                        (data?.meters || []).map(meter => ({
                            ...meter,
                            unitId: data._id
                        }))
                    );
                    setFactories(allFactories);
                    setUnits(allUnits);
                    setMeters(allMeters);
                }
                setOrganisations(organisationsList);
                setLoading(false);
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [listUpadated, succesfullJobLength]);


    useEffect(() => {
        if (organisations?.length > 0) {
            setIsMyDataAvailable(true)
        }
    }, [organisations])

    function createHierarchy(organisations, factories, units, meters) {
        const hierarchyOrganisation = organisations?.map(org => ({
            id: org?._id,
            parent: 0,
            name: org.name,
            droppable: factories?.some(factory => factory.organisationId === org._id),
            editAction: "edit-organisation",
            viewAction: "view-organisation",
            deleteAction: "organisation",
            data: "Organisation"
        }));

        const hierarchyFactory = factories?.map(fact => ({
            id: fact?._id,
            parent: fact.organisationId,
            name: fact.name,
            droppable: units?.some(unit => unit.factoryId === fact._id),
            editAction: "edit-factory",
            viewAction: "view-factory",
            deleteAction: "factory",
            data: "Factory"
        }));

        const hierarchyUnits = units?.map(unit => ({
            id: unit._id,
            parent: unit.factoryId,
            name: unit.name,
            droppable: meters?.some(meter => meter.unitId === unit._id),
            editAction: "edit-unit",
            viewAction: "view-unit",
            deleteAction: "unit",
            settingAction: "update-unit-setting",
            data: "Unit"
        }));

        const hierarchyMeters = meters?.map(meter => ({
            id: meter?._id,
            parent: meter.unitId,
            name: meter.consumerNo,
            editAction: "edit-meter",
            viewAction: "view-meter",
            deleteAction: "meter",
            data: "Consumer"
        }));

        let allHierarchies = [];
        if (hierarchyOrganisation) {
            allHierarchies.push(...hierarchyOrganisation);
        }

        if (hierarchyFactory) {
            allHierarchies.push(...hierarchyFactory);
        }

        if (hierarchyUnits) {
            allHierarchies.push(...hierarchyUnits);
        }

        if (hierarchyMeters) {
            allHierarchies.push(...hierarchyMeters);
        }
        return allHierarchies;
    }
    let hierarchicalData = [];
    hierarchicalData = createHierarchy(organisations, factories, units, meters);

    useEffect(() => {
        hierarchicalData = createHierarchy(organisations, factories, units, meters);

    }, [listUpadated, organisations, factories, units, meters]);

    const handleDelete = async (nodeAction, id, event) => {
        event.preventDefault();

        let response;

        confirmAlert({
            title: '',
            closeOnClickOutside: false,
            message: `Are you sure to delete ${nodeAction}`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        switch (nodeAction) {
                            case 'organisation':
                                response = await deleteData(`${process.env.REACT_APP_API_URL}/organisation/${id}`)
                                break;

                            case 'factory':
                                response = await deleteData(`${process.env.REACT_APP_API_URL}/factory/${id}`)
                                break;

                            case 'unit':
                                response = await deleteData(`${process.env.REACT_APP_API_URL}/unit/${id}`)
                                break;

                            case 'meter':
                                response = await deleteData(`${process.env.REACT_APP_API_URL}/meter/${id}`)
                                break;

                            default:
                                break;
                        }
                        if (response.success) {
                            setListUpadated(!listUpadated);
                            toast.success(`${nodeAction} is deleted successfully`, {
                                position: "top-center",
                                autoClose: 1500,
                            });
                        } else {
                            toast.error(`${nodeAction} could not be deleted`, {
                                position: "top-center",
                                autoClose: 1500,
                            });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        // Do nothing
                    }
                }
            ]
        });

    }
    const [popupNode, setPopupNode] = useState(null);


    const togglePopup = (nodeId) => {
        if (popupNode === nodeId) {
            setPopupNode(null); // Close the popup if it's already open
        } else {
            setPopupNode(nodeId); // Open the popup for the clicked node
        }
    };

    const canDrop = (currentTree, { dragSourceId, dropTargetId, dragSource, dropTarget }) => {
        return false;
    };


    const [expandedNodes, setExpandedNodes] = useState([]);

    const handleToggle = (nodeId) => {
        if (nodeId) {
            // Use a setTimeout to defer the state update until after the rendering phase
            setTimeout(() => {
                setExpandedNodes(prevExpandedNodes => {
                    if (!prevExpandedNodes.includes(nodeId)) {
                        return [...prevExpandedNodes, nodeId];
                    }
                    return prevExpandedNodes;
                });
            });
        }
    };

    function capitalizeWords(str) {
        return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    }

    const [myDataGuideOpen, setMyDataGuideOpen] = useState(true);
    const handleMyDataGuide = () => {
        setMyDataGuideOpen(!myDataGuideOpen);
    }

    return (
        <>
            <div className='lg:max-w-[1920px] mt-[58px] sm:mt-[70px] m-auto flex'>

                <div className='hidden sm:block'>
                    <Sidebar />
                </div>
                <div className={`${isSidebarOpen ? "lg:ml-[300px]" : "sm:ml-[80px]"} w-full px-[10px] sm:px-[50px] transition-width duration-300`}>
                    <div className="flex flex-col sm:flex-row  sm:px-6 py-6 justify-between  w-full items-center">
                        <Link to={"/my-data"}>
                            <div className='font-medium text-[20px]'>My Data</div>
                        </Link>
                        <div className="flex flex-row gap-[10px] sm:gap-[20px] mt-5 sm:mt-0 flex-wrap">
                            <Link to={"/add-organization"}>
                                <button className="bg-[#067C4E] text-xs sm:text-base font-medium rounded-xl px-5 py-2 text-white">
                                    Add Organization/ Headquarters
                                </button>
                            </Link>
                            {organisations?.length > 0 ?
                                <Link to={"/factory/add"}>
                                    <button className="bg-[#067C4E] text-xs sm:text-base font-medium  rounded-xl  px-5 py-2  text-white">
                                        Add Factory
                                    </button>
                                </Link>
                                :
                                <button className="text-black bg-[white] border-2 border-gray-500 text-xs sm:text-base font-medium  rounded-xl  px-5 py-2">
                                    Add Factory
                                </button>
                            }
                            {factories?.length > 0 ?
                                <Link to={"/unit/add"}>
                                    <button className="bg-[#067C4E] text-xs sm:text-base font-medium rounded-xl  px-5 py-2  text-white">
                                        Add Unit
                                    </button>
                                </Link>
                                :
                                <button className="text-black bg-[white] border-2 border-gray-500 text-xs sm:text-base font-medium  rounded-xl  px-5 py-2">
                                    Add Unit
                                </button>
                            }
                            <GoTODashboard />
                        </div>
                    </div>
                    <div className='w-[100%] mx-auto '>
                        <div className="rounded-lg  w-full flex flex-col sm:min-h-[250px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:mt-[30px]">
                            {loading ? (
                                <div className='flex w-full sm:min-h-[500px] items-center justify-center'><LoaderComponent width={150} height={150} /></div>

                            ) :
                                hierarchicalData && hierarchicalData?.length > 0 ?
                                    // navigate('/dashboard')
                                    <div className='w-[100%] flex flex-col relative sm:flex-row justify-between h-[100%] mt-10 sm:mt-0'>
                                        <div className='w-[100%] sm:p-10 h-[100%]'>
                                            <DndProvider backend={HTML5Backend}>
                                                <div className={`w-fit ${isSidebarOpen ? "mx-auto" : "mx-auto"} transition-all duration-300 mt-1 sm:mt-10`}>
                                                    <Tree
                                                        tree={hierarchicalData}
                                                        rootId={0}
                                                        droppable={false}
                                                        canDrag={false}
                                                        // onDrop={handleDrop}
                                                        canDrop={canDrop}

                                                        initialOpen={expandedNodes}
                                                        enableAnimateExpand={true}
                                                        render={(node, { depth, isOpen, onToggle }) => {
                                                            // if (depth == 0) {
                                                                handleToggle(node.id);
                                                                // setExpandedNodes((prevValue)=>[...prevValue,node.id]);
                                                            // }
                                                            return (
                                                                <div style={{
                                                                    marginLeft: (window.innerWidth <= 768) ? (depth * 15) : window.innerWidth <= 1120 ? depth * 25 : depth * 40,
                                                                    width: (window.innerWidth <= 768) ? (350 - depth * 15) : window.innerWidth <= 1120 ? 500 - depth * 25 : 800 - depth * 40
                                                                }} className='w-[100%] h-[69px] bg-[#EFEFEF] mt-4 my-auto flex flex-row justify-between px-1 sm:px-4 items-center' >
                                                                    <div className='flex flex-row'>
                                                                        <div className='sm:w-[30px]'>
                                                                            {node.droppable && (
                                                                                <div draggable={false} onClick={onToggle}>
                                                                                    {isOpen ? <FontAwesomeIcon icon={faMinus} className=" text-black mx-2 text-xs sm:text-base cursor-pointer" /> : <FontAwesomeIcon icon={faPlus} className=" text-black text-base cursor-pointer mx-2 " />}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className='text-xs sm:text-base font-medium'>
                                                                            {node.data === "Consumer" ? node.name : capitalizeWords(node.name)} <span className='text-[12px]'>({node.data})</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-row w-[30%] sm:w-auto'>
                                                                        <div className=''>
                                                                            <span title={`${node.editAction.toUpperCase()}`}>
                                                                                <Link to={`/${node.editAction}/${node.id}`}>
                                                                                    <FontAwesomeIcon icon={faPenToSquare} className=" text-black text-xs sm:text-base mx-1 sm:mx-2 cursor-pointer" />
                                                                                </Link>
                                                                            </span>
                                                                            {node.viewAction === "view-meter" ? "" :
                                                                                <span title={`${node.viewAction?.toUpperCase()}`}>
                                                                                    <Link to={`/${node.viewAction}/${node.id}`}>
                                                                                        <FontAwesomeIcon icon={faEye} className=" text-black text-xs sm:text-base mx-1 sm:mx-2 cursor-pointer" />
                                                                                    </Link>
                                                                                </span>
                                                                            }
                                                                            <span onClick={(event) => handleDelete(node.deleteAction, node.id, event)} title={`DELETE ${node.deleteAction.toUpperCase()}`}>
                                                                                <FontAwesomeIcon icon={faTrashCan} className=" text-black text-xs sm:text-base mx-1 sm:mx-2 cursor-pointer" />
                                                                            </span>
                                                                            <span className='relative'>
                                                                                <FontAwesomeIcon onClick={() => togglePopup(node.id)} icon={faCircleQuestion} className=" text-black text-xs sm:text-base mx-1 sm:mx-2 cursor-pointer -z-0 " />
                                                                                {popupNode === node.id && (
                                                                                    <div className="absolute mt-6 left-[-120px] transform z-20 -translate-x-1/2 w-[400px] h-[200px] p-5 bg-white border border-gray-300 shadow-lg rounded-md animate-zoomOut">
                                                                                        <p>This is the content of the popup for {node.name} {node.deleteAction}!</p>
                                                                                        {node.deleteAction == "organisation" ? <p>Organisation is parent component</p> : ""}
                                                                                        {node.deleteAction == "factory" ? <p>Factory is child of Organisation</p> : ""}
                                                                                        {node.deleteAction == "unit" ? <p>Unit is child of Factory</p> : ""}
                                                                                        {node.deleteAction == "meter" ? <p>Meter is child of Unit</p> : ""}
                                                                                        <div className='w-fit mx-auto'>
                                                                                            <button onClick={() => togglePopup(node.id)} className="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                                                                                                Close
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </span>
                                                                            {
                                                                                node.settingAction ?
                                                                                    <span className="relative inline-block" style={{ width: '1em', height: '1em' }} title='CUSTOMISE UNIT SETTING'>
                                                                                        <Link to={`/${node.settingAction}/${node.id}`}>
                                                                                            <FontAwesomeIcon icon={faTools} className=" text-black text-xs sm:text-base mx-1 sm:mx-2 cursor-pointer" />
                                                                                        </Link>
                                                                                    </span>
                                                                                    : ""
                                                                            }
                                                                        </div>

                                                                    </div>


                                                                </div>

                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </DndProvider>
                                            {!myDataGuideOpen &&
                                                <>
                                                    <button className='absolute right-1 top-[-50px] text-white bg-[#067C4E] rounded-lg sm:top-1 cursor-pointer p-3 font-bold' onClick={handleMyDataGuide}>
                                                        User Guide
                                                    </button>

                                                </>
                                            }
                                        </div>
                                        {myDataGuideOpen &&
                                            // <div className='absolute top-0 right-0  bg-opacity-50 z-20 flex justify-end'>
                                            <div className='flex absolute w-[70%] top-0 right-0 justify-end'>

                                                <div className='relative w-[80%] sm:w-[50%] h-fit p-4 rounded-lg sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] bg-white'>
                                                    <div className='block absolute right-2 top-0  cursor-pointer' onClick={handleMyDataGuide}>
                                                        <FontAwesomeIcon className={` hover:opacity-100 transition-all duration-300 text-[black]`} icon={faXmark} />
                                                    </div>
                                                    <ul className='list-disc p-3'>
                                                        <li>
                                                            This data is extracted from the bills you have uploaded.
                                                        </li>
                                                        <li>
                                                            Here, we are showing details of the organization, factory, unit, and consumer numbers.
                                                        </li>
                                                        <li>
                                                            Please verify this data with your uploaded bills.
                                                        </li>
                                                        <li>
                                                            Please check the Dashboard to see your stats and savings through green energy according to the uploaded bills.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            // </div>
                                        }

                                    </div>

                                    : <div className='flex flex-col justify-center px-20'>
                                        <p className='font-medium text-[30px] text-center mt-[10%]'>
                                            Ampera AI needs your bills data to power its analytics. Please upload bills and see the magic.
                                        </p>
                                        <div className='w-fit mx-auto mt-5 mb-10'>
                                            <Link to={'/upload'}>
                                                <Abutton
                                                    text={"Please Upload bills"}
                                                    Mheight={44}
                                                    Mwidth={270}
                                                    DHeight={44}
                                                    DWidth={299}
                                                />
                                            </Link>
                                        </div>

                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyData;

import React from 'react'
import OcrStatus from '../OcrStatus/OcrStatus'

const Footer = () => {
    return (
        <div>
            <OcrStatus />
        </div>
    )
}

export default Footer

import React, { useState, useEffect } from "react";
import FutureLoadMonthlyEnergyView from "./futureLoadSimulation/monthly/FutureLoadMonthlyEnergyView";
import { BillConsumptionService } from "../services/graphs/billConsumptionService";
import FutureLoadMonthlyCostView from "./futureLoadSimulation/monthly/futureLoadMonthlyCostView";
import ASelect from "../components/select/ASelect";
import LoaderComponent from "../components/loaderCompent/LoaderComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BackButton from "../components/backButton/BackButton";
import AnalyticsSidebar from "../components/sidebar/AnalyticsSidebar";
import Sidebar from "../components/sidebar/Sidebar";
const billService = new BillConsumptionService();
const FutureLoadSimulationView = () => {
  const [energyMonthlyData, setEnergyMonthlyData] = useState({});
  const [organisationList, setOrganisationList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [factoryList, setFactoryList] = useState([]);
  const [selectedFactory, setSelectedFactory] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [organisationTreeData, setOrganisationTreeData] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState("");
  const [selectedFutureLoadView, setselectedFutureLoadView] =
    useState("energy");
  const [years, setYears] = useState([2022, 2023, 2024]);
  const [selectedFilterYear, setSelectedFilterYear] = useState("");
  const [unitData, setUnitData] = useState("");
  const [slotnames, setSlotnames] = useState([]);
  const [slotColors, setSlotColors] = useState({});
  const [slotConfigData, setslotConfigData] = useState("")


  const fetchMonthLyGraphData = async (year, org) => {
    //    console.log({ year, org });

    const data = await billService.getMonthlyConsumptionTodSlotWithYearAndOrg(
      year.toString(),
      selectedMeter.toString(),
      slotnames,
      slotColors,
      slotConfigData
    );
    //    console.log({ selectedEnergyYearData: data });
    if (data !== undefined) {
      const meterId = Object.keys(data)[0];
      const year = Object.keys(data[meterId])[0];
      const energyData = data[meterId][year].energyMonthLyData;
      const costData = data[meterId][year].costMonthLyData;
      const updatedData = {
        ...energyMonthlyData,
        [meterId]: {
          ...energyMonthlyData[meterId],
          [year]: {
            energyMonthLyData: energyData,
            costMonthLyData: costData,
          },
        },
      };
      setEnergyMonthlyData(updatedData);
      return data;
    }
  };

  function getFinancialYearStart() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // getMonth() returns 0-11 for Jan-Dec

    let startYear;

    if (month < 4) {
      // January, February, March
      startYear = year - 1;
    } else {
      // April to December
      startYear = year;
    }

    return startYear;
  }

  useEffect(() => {
    const year = getFinancialYearStart();
    setSelectedFilterYear(year);
  }, []);

  const handleSelectYear = (date) => {
    const year = date.getFullYear();
    const newDate = new Date(year, 0);
    setStartDate(newDate);
    setSelectedFilterYear(year);
  };

  useEffect(() => {
    const getOrgansiationList = async () => {
      const organisationList = await billService.getAllOrganisations();
      //      console.log({ organisationList });
      setOrganisationList(organisationList);
      if (organisationList.length > 0 && selectedOrg === "") {
        setSelectedOrg(organisationList[0]._id);
      }
    };
    getOrgansiationList();
  }, []);

  useEffect(() => {
    //    console.log({ mdg: "slected org changed", selectedOrg });
    if (selectedOrg !== "") {
      const getfactoryList = async () => {
        // const factoryList = await billService.getAllFactories(selectedOrg);
        const organisationTreeData = await billService.getOrganisationtree(
          selectedOrg
        );
        //        console.log({ organisationTreeData });
        if (organisationTreeData !== undefined) {
          if (organisationTreeData.factories.length !== 0) {
            const extractctedTreeData = organisationTreeData.factories.map(
              (factory) => {
                const factoryInfo = {
                  _id: factory._id,
                  name: factory.name,
                  units: factory.units.map((unit) => {
                    const unitInfo = {
                      _id: unit._id,
                      name: unit.name,
                      stateCode: unit.stateCode,
                      discomCode: unit.discomCode,
                      meters: unit.meters.map((meter) => ({
                        _id: meter._id,
                        consumerNo: meter.consumerNo,
                        meterNo: meter.meterNo,
                      })),
                    };
                    return unitInfo;
                  }),
                };
                return factoryInfo;
              }
            );
            //            console.log({ extractctedTreeData });
            setOrganisationTreeData(extractctedTreeData);
            setFactoryList(extractctedTreeData);

            if (extractctedTreeData.length > 0) {
              setSelectedFactory(extractctedTreeData[0]._id);
            } else {
              setSelectedFactory("");
            }
            setUnitList(extractctedTreeData[0].units);
            if (
              extractctedTreeData[0].units &&
              extractctedTreeData[0].units.length > 0
            ) {
              setSelectedUnit(extractctedTreeData[0].units[0]._id);
            } else {
              setSelectedUnit("");
            }
            setMeterList(extractctedTreeData[0].units[0].meters);
            if (
              extractctedTreeData[0].units[0].meters &&
              extractctedTreeData[0].units[0].meters.length > 0
            ) {
              setSelectedMeter(extractctedTreeData[0].units[0].meters[0]._id);
            } else {
              setSelectedMeter("");
            }
          } else {
            setFactoryList([]);
            setSelectedFactory("");
            setMeterList([]);
            setSelectedMeter("");
            setUnitList([]);
            setSelectedUnit("");
          }
        }
      };
      getfactoryList();
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (selectedFactory !== "") {
      // setunit list
      const factorydata = organisationTreeData.filter(
        (factory) => factory._id === selectedFactory
      );
      //      console.log({ factorydata });

      setUnitList(factorydata[0].units);
      setSelectedUnit(factorydata[0].units[0]._id);
      setMeterList(factorydata[0].units[0].meters);
      setSelectedMeter(factorydata[0].units[0].meters[0]._id);
    } else {
      setUnitList([]);
      setSelectedUnit("");
      setMeterList([]);
      setSelectedMeter("");
    }
  }, [selectedFactory]);

  useEffect(() => {
    let filteredUnits = [];
    if (selectedUnit !== "") {
      organisationTreeData.forEach((factory) => {
        factory.units.filter((unit) => {
          if (unit._id === selectedUnit) {
            filteredUnits.push(unit);
          }
        });
      });
      //      console.log({ filteredUnits });
      if (filteredUnits[0].meters.length > 0) {
        setMeterList(filteredUnits[0].meters);
        setSelectedMeter(filteredUnits[0].meters[0]._id);
      } else {
        setMeterList([]);
        setSelectedMeter("");
      }
    } else {
      setMeterList([]);
      setSelectedMeter("");
    }
  }, [selectedUnit]);

  const getEnergyYearData = async () => {
    //    console.log({ energyMonthlyData });
    //    console.log({ selectedMeter, selectedFilterYear });

    if (selectedMeter !== "" && selectedFilterYear !== "") {
      const isYeardataAvailable =
        energyMonthlyData.hasOwnProperty(selectedMeter) &&
        energyMonthlyData[selectedMeter].hasOwnProperty(selectedFilterYear);
      //      console.log({ isYeardataAvailable });
      //      console.log({ isYeardataAvailable: energyMonthlyData });
      //      // console.log({isYeardataAvailable:energyMonthlyData.hasOwnProperty(selectedOrg)})
      //      // console.log({isYeardataAvailable:energyMonthlyData[selectedOrg].hasOwnProperty(selectedFilterYear)})
      if (!isYeardataAvailable) {
        await fetchMonthLyGraphData(selectedFilterYear, selectedMeter);
      } else {
        return energyMonthlyData[selectedMeter][selectedFilterYear];
      }
    }
  };

  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    if(energyMonthlyData.hasOwnProperty(selectedMeter) &&
    energyMonthlyData[selectedMeter].hasOwnProperty(selectedFilterYear)){
        setIsLoading(true) 
        setTimeout(() => {
            setIsLoading(false)    
        }, 500);
    
    }else{
        setIsLoading(true) 

    }
  }, [energyMonthlyData,selectedMeter,selectedFilterYear])
  

  useEffect(() => {
    if (
      selectedFilterYear !== "" &&
      selectedMeter !== "" &&
      slotnames.length > 0
    ) {
      getEnergyYearData();
    }
  }, [selectedFilterYear, selectedMeter, slotnames, slotColors,slotConfigData]);

  //   useEffect(() => {
  //     if (selectedCostYear !== "") {
  //  //       console.log({ selectedCostYear });
  //       getCostYearData();
  //     }
  //   }, [selectedCostYear]);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    //    console.log({ selectedFutureLoadView });
  }, [selectedFutureLoadView]);

  useEffect(() => {
    if (unitData !== "") {
      const configData = JSON.parse(localStorage.getItem("config"));
      const statecode = unitData[0]["stateCode"];
      const disComCode = unitData[0]["discomCode"];
      //      console.log({ configData: configData });
      //      console.log({ statecode });
      //      console.log({ disComCode });
      if (
        configData &&
        configData.slotConfig &&
        configData.slotConfig[statecode] &&
        configData.slotConfig[statecode][disComCode] &&
        configData.slotConfig[statecode][disComCode]["slotName"] !==
          undefined &&
        configData.slotConfig[statecode][disComCode]["slotColor"] !== undefined
      ) {
        const getSlotNames = Object.keys(
          configData.slotConfig[statecode][disComCode]["slotName"]
        );
        //        console.log({ getSlotNames });
        setSlotnames(getSlotNames);

        const slotColorsData =
          configData.slotConfig[statecode][disComCode]["slotColor"];
        //        console.log({ slotColorsData });
        setSlotColors(slotColorsData);
        const allSlotTimeData = configData.slotConfig[statecode][disComCode] ;
        setslotConfigData(allSlotTimeData);


      }
    }
  }, [unitData]);

  const getUnitData = (id) => {
    //    console.log({ unitList });
    const unitData = unitList.filter((unit) => unit._id === id);

    //    console.log({ unitData });
    setUnitData(unitData);
    return unitData;
  };

  useEffect(() => {
    //    console.log({ unitData });
  }, [unitData]);

  useEffect(() => {
    if (selectedUnit !== "") {
      //      console.log({ selectedUnit });
      getUnitData(selectedUnit);
    }
  }, [selectedUnit]);


  useEffect(() => {
    // console.log({slotConfigData})
  }, [slotConfigData])
  

  return (
    <div className="max-w-[1920px] mt-[58px] lg:mt-[100px] sm:pr-[50px]">
      <div className="relative ">
        <div className="hidden sm:block">
          {/* <AnalyticsSidebar /> */}
          <Sidebar type={2} />
        </div>
        {/* </div>
    <div className={`${isSidebarOpen ? "ml-[320px]" : "ml-[150px]"}`}> */}
        <div
          data-tab-content=""
          className={`sm:pb-[20px] sm:ml-28 mx-[10px]`}
        >
        <div className=" mx-5 mb-10">
          <div className="w-full flex sm:hidden justify-end mt-[80px] ">
            <BackButton
              smpx={"sm:px-4"}
              smpy={"sm:py-3"}
              py={"py-1"}
              px={"px-2"}
            />
          </div>
          <div className="flex mt-[10px] gap-2 sm:mt-[100px]">
            <div className="sm:bg-white sm:border sm:border-gray-200 min-h-[55px]  mb-6 sm:mb-0 w-full shadow-sm sm:py-2 items-center grid grid-cols-2 md:flex  gap-2 sm:gap-5 sm:px-8 rounded-lg">
              <div className="md:min-w-[80px]">Filters</div> <div></div>
              <div className={` w-full max-w-[200px]`}>
                <ASelect
                  label={"Organisation"}
                  height={"45px"}
                  IconBottom={"bottom-4"}
                  options={organisationList?.map((org) => {
                    return { label: org.name, value: org._id };
                  })}
                  onChange={(e) => setSelectedOrg(e.target.value)}
                  value={selectedOrg}
                />
              </div>
              <div className={` w-full`}>
                {" "}
                <ASelect
                  label={"Factory"}
                  height={"45px"}
                  IconBottom={"bottom-4"}
                  options={factoryList?.map((factory) => {
                    return { label: factory.name, value: factory._id };
                  })}
                  onChange={(e) => setSelectedFactory(e.target.value)}
                  value={selectedFactory}
                />
              </div>
              <div className={` w-full`}>
                <ASelect
                  label={"Unit"}
                  height={"45px"}
                  IconBottom={"bottom-4"}
                  options={unitList?.map((unit) => {
                    return { label: unit.name, value: unit._id };
                  })}
                  onChange={(e) => setSelectedUnit(e.target.value)}
                  value={selectedUnit}
                />
              </div>
              <div className={` w-full`}>
                <ASelect
                  label={"Consumer No"}
                  options={meterList?.map((meter) => {
                    return { label: meter.consumerNo, value: meter._id };
                  })}
                  height={"45px"}
                  IconBottom={"bottom-4"}
                  onChange={(e) => setSelectedMeter(e.target.value)}
                  value={selectedMeter}
                />
              </div>
              {/* <div className={` w-full max-w-[200px]`}>
          <ASelect
            label={"year"}
            height={"45px"}
            IconBottom={"bottom-4"}
            options={years?.map((org) => {
              return { label: org, value: org };
            })}
            onChange={(e) => setSelectedFilterYear(e.target.value)}
            value={selectedFilterYear}
          />
        </div> */}
              <div className="w-[100%] futureLoad ">
                <DatePicker
                  selected={startDate}
                  onChange={handleSelectYear}
                  minDate={new Date()}
                  dateFormat="yyyy"
                  showYearPicker
                  wrapperClassName="year-picker-wrapper"
                  placeholderText="select Year"
                  popperPlacement="auto"
                  className="hide-dropdown-icon"
                />
              </div>
              {/* <div>
          <LoaderComponent width={25} height={25} />
        </div> */}
            </div>
            <div className="absolute sm:relative hidden  sm:right-0 right-1  text-xs sm:text-[14px] sm:flex justify-center items-center w-[100px] ">
              <BackButton
                smpx={"sm:px-4"}
                smpy={"sm:py-4"}
                py={"py-1"}
                px={"px-2"}
              />
            </div>
          </div>
          <div className="w-full flex justify-center">
          <div className="sm:w-[70%]">
          <FutureLoadMonthlyEnergyView
            energyMonthlyData={energyMonthlyData}
            selectedFutureLoadView={selectedFutureLoadView}
            setselectedFutureLoadView={setselectedFutureLoadView}
            selectedFilterYear={selectedFilterYear}
            setSelectedFilterYear={setSelectedFilterYear}
            selectedOrg={selectedOrg}
            organisationList={organisationList}
            setSelectedOrg={setSelectedOrg}
            years={years}
            isLoading={isLoading}
            selectedMeter={selectedMeter}
            selectedUnit={selectedUnit}
            slotConfigData={slotConfigData}
          />

          <FutureLoadMonthlyCostView
            energyMonthlyData={energyMonthlyData}
            selectedFutureLoadView={selectedFutureLoadView}
            setselectedFutureLoadView={setselectedFutureLoadView}
            selectedFilterYear={selectedFilterYear}
            setSelectedFilterYear={setSelectedFilterYear}
            selectedOrg={selectedOrg}
            organisationList={organisationList}
            setSelectedOrg={setSelectedOrg}
            years={years}
            isLoading={isLoading}
            selectedMeter={selectedMeter}
            selectedUnit={selectedUnit}
            slotConfigData={slotConfigData}
          />
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default FutureLoadSimulationView;

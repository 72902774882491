import React, { useState, useEffect } from "react";
import useInputBox from "../../hooks/useInputBox";

const InputBoxV2 = ({
  showError,
  placeholder,
  label,
  type,
  validationtypes,
  value,
  setInputValue,
  handleErrors,
  fieldName,
  maxWidth600
}) => {
  const { error } = useInputBox(value, validationtypes, label, fieldName);
  useEffect(() => {
    if (error) {
      handleErrors(error);
    } else {
    }
  }, [error, value]);
  return (
    <div className={`flex flex-col sm:flex-row w-full sm:justify-between sm:items-center ${showError ? "mb-[30px]" : ""} `}>
      <div className="py-[25px] pl-[30px] pb-[18px] p-0  font-normal text-[18px] leading-[22px] md:min-w-[212px] md:max-w-[212px] ">
        {label}{validationtypes[0] == "required" ? <span className="text-[red] ml-[4px]">*</span> : ""}
      </div>
      <div className="w-full">
        <input
          className={`border font-normal w-full rounded-[20px] text-[16px] leading-[20px] border-[#B9B9B9] py-[25px] px-[20px] lg:ml-[10px] ${maxWidth600 ? " md:max-w-[600px]" : " md:max-w-[545px]"} `}
          type={type}
          value={value}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
        />

        {showError ? (
          <div className="text-red-500 absolute capitalize px-[20px] py-[10px]">{error[`${fieldName}Error`]}</div>
        ) : null}
      </div>
    </div>
  );
};

export default InputBoxV2;

import React, { useState } from 'react'
import InputBox from "../../components/inputbox/InputBox";
import Abutton from "../../components/button/Abutton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { setNewPassword } from '../../services/apis/user';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';



export default function SetNewPassword() {

    const [showPassword, setShowPassword] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.email;


    const handleErrors = (errorObj) => {
        const key = Object.keys(errorObj)[0];
        const value = errorObj[key];

        const existingIndex = errors.findIndex(error => Object.keys(error)[0] === key);

        if (existingIndex !== -1) {
        const updatedErrors = [...errors];
        updatedErrors[existingIndex][key] = value;
        setErrors(updatedErrors);
        } else {
        setErrors(prevErrors => [...prevErrors, { [key]: value }]);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const payload = {
            "password": password,
        };

        if(payload.password != ""){
            try {
                const response = await setNewPassword(email, password);
                
                if (response.success) {
                    toast.success('New Password Set Successfully!.', {
                        position: "top-center"
                    });
                    navigate("/login");
                }
            } catch (error) {
                console.error("Error Setting Password:", error);
            }
        }
    }

    return (
        <div className="flex h-screen flex-col justify-center items-center w-full max-w-2xl m-auto">
            <div className="max-w-[961px] h-screen md:max-h-[780px] pt-[18px] px-[30px] w-full flex flex-col justify-center items-center shadow-lg bg-[#F7FCFA]">
                <div>
                    <img src="/logo.svg" />
                </div>
                <div className="w-full relative">
                    <InputBox
                        placeholder="Enter New Password"
                        id="password"
                        fieldName={"Password"}
                        type={showPassword ? "text" : "password"}
                        validationtypes={["password", "required"]}
                        showError={formSubmitted}
                        label={"New Password"}
                        value={password}
                        setInputValue={setPassword}
                        handleErrors={handleErrors}
                    />
                    <button
                        className="absolute top-[65%] right-[5rem] focus:outline-none"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                    {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} className="h-5 w-5 text-gray-400" />
                    ) : (
                        <FontAwesomeIcon icon={faEye} className="h-5 w-5 text-gray-400" />
                    )}
                    </button>
                </div>
                <div className="mt-[28px]">
                    <Abutton
                        text={"Set New Password"}
                        handleOnclick={(e) => {
                            handleSubmit(e);
                        }}
                        Mheight={44}
                        Mwidth={170}
                        DHeight={44}
                        DWidth={299}
                    />
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={3000} />
        </div>
    )
}

import React from 'react'

const DashboardPage = () => {
  return (
    <div>
      <div className='w-[30%] mx-auto flex justify-center items-center mt-16 mb-10'>
        <img src='/dashboard.png' alt='dashboard' />
      </div>
      <p className='text-3xl font-medium text[#000000] text-center '>"Dashboard" - provides users with a comprehensive overview of their energy analytics, including key metrics, charts, and insights. Users can quickly assess their energy consumption patterns and performance metrics from this centralized dashboard.</p>
    </div>
  )
}

export default DashboardPage

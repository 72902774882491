import React, { useEffect, useState } from "react";
import useInputBox from "../../hooks/useInputBox";

const ASelectv1 = ({
  options,
  defaultValue,
  onChange,
  label,
  showLable,
  validationtypes,
  maxWidth600,
  isflexed, 
  value,
  onClick,
  fieldName,
  handleErrors,showError
}) => {
  const [isInteracted, setIsInteracted] = useState(false);

  const handleChange = (event) => {
    setIsInteracted(true);
    onChange(event);
  };

  const { error } = useInputBox(value, validationtypes, label, fieldName);
  useEffect(() => {
    if (error) {

      handleErrors(error);
    } else {
    }
  }, [error, value]);
  // console.log(error);
  return (
    <div className="flex flex-col sm:flex-row sm:justify-between w-full sm:items-center ">
      <div className="pl-[30px] pb-[18px] sm:pb-0 md:min-w-[212px] ">
        <div className={` ${showLable ? "block" : "hidden"} `}>
          <div
            className={` ${showLable ? "block" : "hidden"
              }  font-medium text-[#000000]  text-[16px] leading-[21px] `}
          >
            {label}{validationtypes == "required" ? <span className="text-[red] ml-[4px]">*</span> : ""}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-start lg:ml-[10px]">
        {" "}
        <div
          className={` ${isflexed ? "flex justify-center" : ""
            } ${maxWidth600 ? "max-w-[600px]" : "max-w-[540px]"} relative bg-inherit w-full pb-100`}
        >
          <div className="w-full  ">
            <select
              onClick={onClick}
              onChange={handleChange}
              defaultValue={defaultValue}
              value={value}
              className={` block appearance-none rounded-[20px] pr-[45px] h-[72px] w-full leading-[21px] bg-white border border-[#B9B9B9] hover:border-gray-500 px-4 py-2 shadow focus:outline-none focus:shadow-outline ${isInteracted ? "text-black" : "text-gray-500"
                }`}
            >
              <option key={""} value={""}>
                {" "}{label}
              </option>
              {options?.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <div className="absolute right-[4%] bottom-7 flex items-center justify-end ">
              <div>
                <img src="/select.svg" className="h-[9px] w-[15px]" />
              </div>
            </div>
      {showError ? (
        <div className="text-red-500 absolute capitalize px-[20px] py-[10px]">{error[`${fieldName}Error`]}</div>
      ) : null}
          </div>
          
        </div>
      </div>
    </div>
  );
};
export default ASelectv1;

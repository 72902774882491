"use client";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  faCaretUp,
  faUser,
  faSignOut,
  faCaretDown,
  faXmark,
  faBars,
  faSquarePollHorizontal,
  faAngleDown,
  faServer,
  faArrowUpFromBracket,
  faCodeCompare,
  faCirclePlay,
  faTableList,
  faList,
  faChartLine,
  faCircleUser,
  faRightToBracket,
  faChartSimple,
  faDatabase,
  faBolt,
  faMapLocationDot,
  faNetworkWired,
  faPiggyBank
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwtDecode from "jwt-decode";

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { isAuthenticated, login, logout } = useAuth();
  const location = useLocation();

  // console.log(user);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    let decodedToken = "";
    if (token) {
      decodedToken = jwtDecode(token);
      const username = decodedToken.username;
      setUserName(username);
    }
  }, [login, logout, isAuthenticated]);

  const navigate = useNavigate();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileDropdownVisible, setMobileDropdownVisible] = useState(false);
  const [isAnalyticsDropdownVisible, setAnalyticsDropdownVisible] =
    useState(false);
  const [isSubOptionsVisible, setIsSubOptionsVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showAnalyticsDropdown = () => {
    setDropdownVisible(false);
    setAnalyticsDropdownVisible(true);
  };

  const hideAnalyticsDropdown = () => {
    setAnalyticsDropdownVisible(false);
  };

  const showSubOptions = () => {
    setIsSubOptionsVisible(true);
  };

  const hideSubOptions = () => {
    setIsSubOptionsVisible(false);
  };

  const handleOptionSelect = (prop) => {
    navigate("/analytics", { state: { prop } });
  };

  const handleSubOptionSelect = (prop, submenu) => {
    navigate("/analytics", { state: { prop, submenu } });
  };

  return (
    <>
      <div className="custom-class -tracking-tighter lg:max-w-[100%] md:max-w-[1920px] z-50 fixed top-0 h-[58px] md:h-[70px] md:px-[50px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] items-center w-full m-auto flex justify-between bg-[#E2F5EC]">
        <div>
          <Link
            to="/"
            className="flex title-font font-medium items-center text-gray-900 md:mb-0"
          >
            <img
              src="/logo.png"
              alt="Power Marketplace Logo"
              className="w-[95px] h-[45px] mt-[5px] md:mt-0 ml-[10px] md:ml-0 md:w-[166px] md:h-[78px]"
            />
          </Link>
        </div>
        <div className="sm:hidden flex justify-between ml-[210px] mt-[5px]">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="hover:bg-[#D2EDDC] p-1.5 rounded focus:outline-none focus:border-transparent duration-300 transition-transform"
          >
            <FontAwesomeIcon
              icon={!isMenuOpen ? faBars : faXmark}
              className="text-[20px] transform transition-transform"
            />
          </button>
        </div>
        <div
          className={`absolute top-10 w-[100%] md:hidden shadow-lg ease-in-out font-medium duration-300 text-[#000000] ${isMenuOpen ? "translate-x-0" : "translate-x-full xl:translate-x-0 "
            }`}
        >
          <ul className="flex flex-col p-1.5 h-screen font-medium mt-4 bg-white border border-b">

            <li className="border-b" onClick={() => setIsMenuOpen(false)}>
              <Link to="/dashboard" className="block py-2 px-3 ">
                <span>
                  <FontAwesomeIcon
                    icon={faSquarePollHorizontal}
                    className=" text-[#000000] text-[15px] mr-3 font-medium transition-all ease"
                  />
                </span>
                Dashboard
              </Link>
            </li>
            <li className="border-b" onClick={() => setIsMenuOpen(false)}>
              <Link to="/recommendation" className="block py-2 px-3 ">
                <span>
                  <FontAwesomeIcon
                    icon={faPiggyBank}
                    className=" text-[#000000] text-[15px] mr-3 font-medium transition-all ease"
                  />
                </span>
                Recommendation
              </Link>
            </li>
            {
              // (location.pathname == "/dashboard" ||
              //   location.pathname == "/my-data" ||
              //   location.pathname == "/compare-ocr-bills" ||
              //   location.pathname == "/upload" ||
              //   location.pathname == "/demo" ||
              //   location.pathname == "/power-consumption" ||
              //   location.pathname == "/unit-list" ||
              //   location.pathname == "/state-list" ||
              //   location.pathname == "/discom-list")
              location.pathname !== "/analytics" &&
              location.pathname !== "/analytics/customisedata" &&
              location.pathname !== "/analytics/futuredata" &&
              location.pathname !== "/analytics/aggregated-view" &&
              location.pathname !== "/unit-list" && (
                <div className="py-2 px-2 text-[14px] text-gray-700">
                  <li
                    className="border-b"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Link to="/my-data" className="block py-2 px-3 ">
                      <span>
                        <FontAwesomeIcon
                          icon={faServer}
                          className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                        />
                      </span>
                      My Data
                    </Link>
                  </li>
                  <li
                    className="border-b"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Link
                      to="/compare-ocr-bills"
                      className="block py-2 px-3 "
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faCodeCompare}
                          className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                        />
                      </span>
                      Compare OCR Bills
                    </Link>
                  </li>
                  <li
                    className="border-b"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Link to="/upload" className="block py-2 px-3 ">
                      <span>
                        <FontAwesomeIcon
                          icon={faArrowUpFromBracket}
                          className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                        />
                      </span>
                      Upload Bills/Excel
                    </Link>
                  </li>
                  <li
                    className="border-b"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Link to="/demo" className="block py-2 px-3 ">
                      <span>
                        <FontAwesomeIcon
                          icon={faCirclePlay}
                          className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                        />
                      </span>
                      Demo
                    </Link>
                  </li>
                  <li
                    className="border-b"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Link
                      to="/power-consumption"
                      className="block py-2 px-3 "
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faTableList}
                          className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                        />
                      </span>
                      Power Consumption
                    </Link>
                  </li>
                  {/* <li className="border-b" onClick={() => setIsMenuOpen(false)}>
                  <Link to="/unit-list" className="block py-2 px-3 ">
                    <span>
                      <FontAwesomeIcon
                        icon={faList}
                        className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                      />
                    </span>
                    Unit List
                  </Link>
                </li> */}
                  {/* <li className="border-b" onClick={() => setIsMenuOpen(false)}>
                  <Link to="/state-list" className="block py-2 px-3 ">
                    <span>
                      <FontAwesomeIcon
                        icon={faMapLocationDot}
                        className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                      />
                    </span>
                    State List
                  </Link>
                </li>
                <li className="border-b" onClick={() => setIsMenuOpen(false)}>
                  <Link to="/discom-list" className="block py-2 px-3 ">
                    <span>
                      <FontAwesomeIcon
                        icon={faNetworkWired}
                        className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                      />
                    </span>
                    Discom List
                  </Link>
                </li> */}
                </div>
              )
            }
            <li className="border-b" onClick={() => setIsMenuOpen(false)}>
              <Link to="/analytics" className="block py-2 px-3 ">
                <span>
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className=" text-[#000000] text-[15px] mr-3 font-medium transition-all ease"
                  />
                </span>
                Analytics
              </Link>
            </li>
            {(location.pathname === "/analytics" ||
              location.pathname === "/analytics/customisedata" ||
              location.pathname === "/analytics/futuredata" ||
              location.pathname === "/analytics/aggregated-view" ||
              location.pathname === "/unit-list") && (
                <div className="py-2 text-[14px] text-gray-700">
                  <Link to="/analytics">
                    <div
                      className={` py-2 px-6 hover:bg-[#D2EDDC] flex flex-row cursor-pointer border-b`}
                      role="tab"
                      aria-controls="app"
                      onClick={() => {
                        // handleOptionSelect("app");
                        setIsMenuOpen(false);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        className=" text-gray-700 mr-2 text-xl font-medium transition-all ease"
                      />
                      <span className=" transition-all ease transition-delay-300">
                        Bill Consumption{" "}
                      </span>
                    </div>
                  </Link>
                  <Link to="/analytics/customisedata">
                    <div
                      className={` py-2 px-6 hover:bg-[#D2EDDC] flex flex-row cursor-pointer border-b`}
                      role="tab"
                      aria-controls="message"
                      onClick={() => { setIsMenuOpen(false) }}
                    >
                      <FontAwesomeIcon
                        icon={faDatabase}
                        className=" text-gray-700 mr-2 text-xl font-medium transition-all ease"
                      />
                      <span className=" transition-all ease transition-delay-300">
                        Customized Data{" "}
                      </span>
                    </div>
                  </Link>
                  <Link to="/analytics/futuredata">
                    <div
                      className={` py-2 px-6 hover:bg-[#D2EDDC] flex flex-row cursor-pointer border-b`}
                      role="tab"
                      aria-controls="settings"
                      onClick={() => { setIsMenuOpen(false) }}
                    >
                      <FontAwesomeIcon
                        icon={faBolt}
                        className=" text-gray-700 mr-2 text-xl font-medium transition-all ease"
                      />
                      <span className=" transition-all ease transition-delay-300">
                        Future Load Simulation{" "}
                      </span>
                    </div>
                  </Link>
                  <Link to="/analytics/aggregated-view">
                    <div
                      className={` py-2 px-6 hover:bg-[#D2EDDC] flex flex-row cursor-pointer border-b`}
                      role="tab"
                      aria-controls="graphs"
                      onClick={() => { setIsMenuOpen(false) }}
                    >
                      <FontAwesomeIcon
                        icon={faCodeCompare}
                        className=" text-gray-700 mr-2 text-xl font-medium transition-all ease"
                      />
                      <span className=" transition-all ease transition-delay-300">
                        Aggregated & Comparison{" "}
                      </span>
                    </div>
                  </Link>
                  <Link to="/unit-list">
                    <div
                      className={` py-2 px-6 hover:bg-[#D2EDDC] flex flex-row cursor-pointer border-b`}
                      role="tab"
                      aria-controls="graphs"
                      onClick={() => { setIsMenuOpen(false) }}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        className=" text-gray-700 mr-2 text-xl font-medium transition-all ease"
                      />
                      <span className=" transition-all ease transition-delay-300">
                        Unit List
                      </span>
                    </div>
                  </Link>

                  {/* <div className="border-b" onClick={() => setIsMenuOpen(false)}>
                  <Link to="/unit-list" className="block py-2 px-3 ">
                    <span>
                      <FontAwesomeIcon
                        icon={faList}
                        className=" text-gray-700 text-[15px] mr-2 font-medium transition-all ease"
                      />
                    </span>
                    Unit List
                  </Link>
                </div> */}
                </div>
              )}
            <li>
              {isAuthenticated ? (
                <div className="block py-2 px-3 border-b">
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    className=" text-[#000000] text-[15px] mr-3 font-medium transition-all ease"
                  />
                  <span
                    onClick={() =>
                      setMobileDropdownVisible(!mobileDropdownVisible)
                    }
                  >
                    {userName?.charAt(0).toUpperCase() + userName?.slice(1)}
                    <FontAwesomeIcon
                      icon={mobileDropdownVisible ? faCaretUp : faCaretDown}
                      className="w-5 h-5 ml-1"
                    />
                  </span>
                </div>
              ) : (
                <Link to="/login" className="block py-2 px-3 border-b">
                  <span>
                    <FontAwesomeIcon
                      icon={faRightToBracket}
                      className=" text-[#000000] text-[15px] mr-3 font-medium transition-all ease"
                    />
                  </span>
                  Login
                </Link>
              )}
            </li>
            {mobileDropdownVisible && (
              <>
                <li onClick={() => setIsMenuOpen(false)}>
                  <Link
                    to="/my-profile"
                    className="block text-[14px] py-1 px-3 pl-8 border-b"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faUser}
                        className=" text-[#000000] text-[14px] mr-3 font-medium transition-all ease"
                      />
                    </span>
                    My Profile
                  </Link>
                </li>
                <li>
                  <div className="block text-[14px] py-2 px-3 pl-8 border-b"
                    onClick={() => {
                      logout();
                      navigate("/login");
                    }}>
                    <span>
                      <FontAwesomeIcon
                        icon={faSignOut}
                        className=" text-[#000000] text-[14px] mr-3 font-medium transition-all ease"
                      />
                    </span>
                    Logout
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="flex">
          <nav className="md:ml-auto sm:flex flex-wrap items-center justify-center font-medium text-sm sm:text-lg hidden">

            <Link
              to="/dashboard"
              className={`mr-5 hover:text-[#F1C93B] ${location.pathname === "/dashboard" ? "text-[#F1C93B]" : ""
                }`}
            >
              Dashboard
            </Link>
            <Link
              to="/recommendation"
              className={`mr-5 hover:text-[#F1C93B] ${location.pathname === "/recommendation" ? "text-[#F1C93B]" : ""
                }`}
            >
              Recommendation
            </Link>
            <Link
              to="/analytics"
              className={`mr-5 hover:text-[#F1C93B] ${location.pathname === "/analytics" ? "text-[#F1C93B]" : ""
                }`}
            >
              Analytics
            </Link>
            {/* <div className="relative">
            <div 
              className="mr-5 hover:text-gray-900 cursor-pointer" 
              // onMouseEnter={showAnalyticsDropdown}
              // onMouseLeave={hideAnalyticsDropdown}
            >
              Analytics
            </div>
          </div> */}
            <div className="relative">
              {isAuthenticated ? (
                <div
                  ref={dropdownRef}
                  className="cursor-pointer header-nav-item"
                >
                  <div className="flex items-center" onClick={toggleDropdown}>
                    {userName?.charAt(0).toUpperCase() + userName?.slice(1)}
                    <FontAwesomeIcon
                      icon={dropdownVisible ? faCaretUp : faCaretDown}
                      className="w-5 h-5 ml-1"
                    />
                  </div>
                  {dropdownVisible && (
                    <div className="absolute top-[42px] left-[-90px] mt-2 bg-white py-5 px-5 flex flex-col w-[170px] border shadow-xl border-[#B9B9B9] font-medium text-sm sm:text-lg">
                      <div
                        onClick={() => {
                          navigate("/my-profile");
                          toggleDropdown();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className=" text-black mr-2 "
                        />
                        My Profile
                      </div>
                      <div
                        className=""
                        onClick={() => {
                          logout();
                          navigate("/login");
                          toggleDropdown();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faSignOut}
                          className=" text-black mt-3 mr-2"
                        />
                        Logout
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <a
                  href="/login"
                  className="mr-5 hover:text-gray-900 border rounded-[20px] bg-[#B7E4C7] p-2"
                >
                  Login{" "}
                </a>
              )}
            </div>
          </nav>
        </div>
      </div>
      {isAnalyticsDropdownVisible && (
        <div
          className="absolute z-[55] top-[64px] right-[10rem]"
          onMouseEnter={showAnalyticsDropdown}
          onMouseLeave={hideAnalyticsDropdown}
        >
          <div className="mb-1 h-[32px] bg-transparent"></div>
          <div className="border rounded-xl border-[#B9B9B9] ">
            <ul
              className=" flex list-none rounded-xl shadow-lg divide-x bg-[#F2FBF7]"
              data-tabs="tabs"
            >
              <Link to="/analytics">
                <li className=" hover:bg-gray-200 hover:rounded-l-lg flex-auto px-5 text-center">
                  <div
                    className={`  flex items-center justify-center w-full px-0 py-2 mb-0 transition-colors ease-in-out border-0 rounded-lg cursor-pointer text-black `}
                    role="tab"
                    aria-controls="app"
                  //   onClick={() => handleOptionSelect("app")}
                  >
                    <span className="ml-1 text-[16px] font-medium leading-[24px] py-2">
                      Bill Consumption
                    </span>
                  </div>
                </li>
              </Link>
              <li
                className=" hover:bg-gray-200 flex-auto px-5 text-center"
              //   onMouseEnter={showSubOptions}
              //   onMouseLeave={hideSubOptions}
              >
                <Link to="/analytics/customisedata">
                  <div
                    className={` flex items-center justify-center w-full px-0 py-2 mb-0 transition-colors ease-in-out border-0 rounded-lg cursor-pointer text-black `}
                    role="tab"
                    aria-controls="message"
                  // onClick={() => handleSubOptionSelect("message","app")}
                  // onMouseEnter={showSubOptions}
                  // onMouseLeave={hideSubOptions}
                  >
                    <span className="ml-1 text-[16px] font-medium leading-[24px] py-2 ">
                      Customized Data{" "}
                    </span>
                  </div>
                </Link>
              </li>
              <li className=" hover:bg-gray-200 flex-auto px-5  text-center">
                <Link to="/analytics/futuredata">
                  <div
                    className={` flex items-center justify-center w-full px-0 py-2 mb-0 transition-colors ease-in-out border-0 rounded-lg cursor-pointer text-black `}
                    role="tab"
                    aria-controls="settings"
                  // onClick={() => handleOptionSelect("settings")}
                  >
                    <span className="ml-1 text-[16px] font-medium leading-[24px] py-2">
                      Future Load Simulation
                    </span>
                  </div>
                </Link>
              </li>
              <li className=" hover:bg-gray-200 hover:rounded-r-lg flex-auto px-5  text-center">
                <Link to="/analytics/aggregated-view">
                  <div
                    className={` flex items-center justify-center w-full px-0 py-2 mb-0 transition-colors ease-in-out border-0 rounded-lg cursor-pointer text-black `}
                    role="tab"
                    aria-controls="graphs"
                    onClick={() => handleOptionSelect("graphs")}
                  >
                    <span className="ml-1 text-[16px] font-medium leading-[24px] py-2">
                      Aggregated & Comparison
                    </span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;

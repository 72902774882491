import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";

export default function ProtectedRoute({ children }) {
    const { user, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const isOtpVerified =localStorage.getItem("isOtpVerified");
    useEffect(() => {
      if (isOtpVerified  !== 'true' ) {
        navigate("/login");
      }
    }, [isAuthenticated]);
  
    return children;
  }
import React, { useEffect, useState } from "react";
import LineChartEnergyView from "../../containers/graphs/LineChartEnergyView";
import { ConstantUtils } from "../../utils/ConstantUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import {
  faCircleInfo,
  faExpand,
  faInfo,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import LegendComponent from "../../components/chart/LegendComponent";
import { GraphUtils } from "../../utils/graphUtils";

const billService = new BillConsumptionService();
const graphUtils = new GraphUtils();

const EnenrgyAnnualView = ({
    yearsList,
  annualData,
  handleEnergygraph,
  selectedEnergyGraph,
  slotConfigData
}) => {
  const constantUtils = new ConstantUtils();

  const [energyData, setEnergyData] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setmonths] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
//    // console.log(first)
    if (annualData !== undefined) {
      setEnergyData(annualData.energyData);
      setYears(annualData.years);
      setmonths(annualData.months);
    }
  }, [annualData]);
  const callfunct = (data) => {
//    console.log({ data });
    handleEnergygraph("Monthly", data, "");
//    console.log({ data });
  };
  const [options] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return value === 0 ? 'N/A' : value?.toLocaleString('en-IN');
          },
          title: function (context) {
            const year = context[0]?.dataset?.label?.split(" ")[1]?.split("-")[0];
            const title = context[0].label + "-" + year??"";
            return title;
          },
        },
      },
    
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Energy(kwh)",
        },
        grid: {
          display: false,
        },
        ticks: {
        callback: function(value) {
          if (value >= 1000) {
            return value / 1000 + 'k'; // Converts 1000 to 1k, 2000 to 2k, etc.
          }
          return value;
        }
      }
      },
    },
    
    elements: {
      line: {
        tension: 0.4, // This will make the line curve
      },
    },
  });
  const [labels, setLabels] = useState(months);

  useEffect(() => {
    setLabels(months);
  }, [months]);

  const data = {
    labels,
    datasets: [
      ...years.map((data, index) => ({
        label: years[index],
        data: energyData[index],
        type: "line",
        borderWidth: 2,
        borderColor: constantUtils.getLineGraphColors()[index],
        backgroundColor: constantUtils.getLineGraphColors()[index],
        datalabels: {
          anchor: "top",
        },
      })),
    ],
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (labels.length > 0 && years.length > 0 && energyData.length > 0) {
        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }
  }, [labels, years, energyData]);

  const [full, setFull] = useState(false);

  useEffect(() => {
// console.log({data})
  }, [data])



  useEffect(() => {
// console.log({slotConfigData})
  }, [slotConfigData])
  
  

  return (
    <div
      className={`${
        selectedEnergyGraph === "Annual" ? "flex" : "hidden"
      }  flex-col  shadow-[0_4px_4px_0_#00000040]  border border-[#00000040] rounded-[30px] sm:min-h-[400px]  md:w-full`}
    >
      <div className={` px-[20px] sm:px-[35px]`}>
        <div className={` flex justify-between   py-[25px]  w-full`}>
          <div>
        Annual Comparison: Energy Consumption : Discom<div></div>
          </div>

          <div className="flex items-center gap-4">
            <button onClick={() => setFull(true)}>
              <FontAwesomeIcon icon={faExpand} />
            </button>
            <CustomTooltop
              disable={
                constantUtils.getGraphToolTipData().annualEnergy.disableToolTip
              }
              text={constantUtils.getGraphToolTipData().annualEnergy.text}
            />
          </div>
        </div>
        <LegendComponent data={data} columns={"grid-cols-3"} slotConfigData={slotConfigData}/>
      </div>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <LoaderComponent />
        </div>
      ) : (
        <>
          {/* <div className="">
         
            <div className="flex w-full  px-[35px]  justify-end gap-2"> */}
          {/* {data.datasets.map((dataset) => (
                <button
                  className="bg-gray-800 text-white font-bold text-[12px]  px-2 rounded"
                  key={dataset.label}
                  onClick={() => callfunct(dataset.label)}
                >
                  {dataset.label}
                </button>
              ))} */}

          {/* </div>
          </div> */}
          <div className="sm:px-[35px] pb-[20px] sm:py-[25px]">
            <LineChartEnergyView
              data={data}
              options={options}
              full={full}
              setFull={setFull}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EnenrgyAnnualView;

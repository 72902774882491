import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import InputBox from "../../components/inputbox/InputBox";
import Abutton from "../../components/button/Abutton";
import SignUpPopup from '../../components/popup/SignUpPopUp';
import { sendOtpForgotPassword } from '../../services/apis/user';
import { toast } from 'react-toastify';
import VerifyOTPForgotPassword from '../../components/popup/VerifyOTPForgotPassword';


export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [errors, setErrors] = useState([]);


    const handleErrors = (errorObj) => {
        const key = Object.keys(errorObj)[0];
        const value = errorObj[key];

        const existingIndex = errors.findIndex(error => Object.keys(error)[0] === key);

        if (existingIndex !== -1) {
            const updatedErrors = [...errors];
            updatedErrors[existingIndex][key] = value;
            setErrors(updatedErrors);
        } else {
            setErrors(prevErrors => [...prevErrors, { [key]: value }]);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const payload = {
            "email": email,
        };

        if (payload.email != "") {
            try {
                const otpResponse = await sendOtpForgotPassword(email);
                // console.log(otpResponse);
                if (otpResponse.result === "User Not found") {
                    toast.error('Invalid Email.', {
                        position: "top-center"
                    });
                } else if (otpResponse.result) {
                    toast.success('OTP Sent.', {
                        position: "top-center"
                    });
                    setOpenPopup(true);
                } else {
                    console.error("Error sending OTP:", otpResponse.error);
                }
            } catch (error) {
                console.error("Error sending OTP:", error);
            }
        }

    }

    return (
        <>
            <div className="flex h-screen flex-col justify-center items-center w-full max-w-2xl m-auto">
                <div className="max-w-[961px] h-screen md:max-h-[780px] pt-[18px] px-[30px] w-full flex flex-col justify-center items-center shadow-lg bg-[#F7FCFA]">
                    <div className="w-[90%] sm:w-[60%] h-[20%] sm:h-[30%] mx-auto">
                        <img src="/logo.png" alt="Ampera" className="w-[100%] h-[100%] mx-auto" />
                    </div>
                    <div className="w-full">
                        <InputBox
                            placeholder="Enter Email Id"
                            id="email"
                            type="email"
                            validationtypes={["email", "required"]}
                            showError={formSubmitted}
                            label={"Email"}
                            value={email}
                            fieldName={"Email"}
                            setInputValue={setEmail}
                            handleErrors={handleErrors}
                        />
                    </div>
                    <div className="mt-[28px]">
                        <Abutton
                            text={"Send OTP"}
                            handleOnclick={(e) => {
                                handleSubmit(e);
                            }}
                            Mheight={44}
                            Mwidth={170}
                            DHeight={44}
                            DWidth={299}
                        />
                    </div>
                    <div className="font-normal mt-[33px]">
                        <Link to="/login">Remember your password? Log in</Link>
                    </div>
                </div>
            </div>
            <VerifyOTPForgotPassword setOpenPopup={setOpenPopup} openPopUp={openPopup} email={email} />
        </>
    )
}

import React, { useState } from 'react'
import { useJobProgress } from '../../hooks/billsJob';
import { Link } from 'react-router-dom';
import LoaderComponent from '../loaderCompent/LoaderComponent';
import { RotatingLines } from "react-loader-spinner";
import { useAuth } from '../../hooks/useAuth';

const OcrStatus = () => {
    const { jobErrorMessage, jobIdLength, succesfullJobLength, helpText,errorLength } = useJobProgress();
    const [isLinkClicked, setIsLinkClicked] = useState(false);
    const [isErrorLinkClicked, setIsErrorLinkClicked] = useState(false);
    const [isMyDataLinkClicked, setIsMyDataLinkClicked] = useState(false);
    const { setIsSidebarOpen, isSidebarOpen } = useAuth();
// console.log(succesfullJobLength,errorLength);
// console.log(jobIdLength);
    return (
        <div className='max-w-[1920px] m-auto flex'>
            <div className='w-[100%]'>
                {!isMyDataLinkClicked && jobIdLength > 0 && jobIdLength != succesfullJobLength && jobIdLength != (succesfullJobLength+errorLength) ?
                    <div className={`fixed bottom-0 bg-[#B7E4C7] h-[74px] w-full text-[#008000] text-center font-medium text-3xl ${isSidebarOpen ? 'ml-[150px]' : "ml-[20px]"} ml-[150px]  z-0 flex flex-row items-center justify-center`}>
                        <span>
                            <RotatingLines
                                visible={true}
                                height={"60"}
                                width={"60"}
                                color="red"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </span>

                        <span className=''  onClick={() => setIsMyDataLinkClicked(true)}>
                            {succesfullJobLength} out of {jobIdLength} bills processed by Ampera Ai {succesfullJobLength > 0 ? <> Please check <Link to={'/my-data'} className='text-[blue] underline'>My Data</Link></> : ""}
                        </span>

                    </div>
                    : ""}

                {(helpText && !isLinkClicked) ?
                    <div className={`fixed bottom-0 bg-[#B7E4C7] h-[74px] w-full text-[#008000] text-center font-medium text-xl ${isSidebarOpen ? 'ml-[150px]' : 'ml-[20px]'} z-0 flex flex-row items-center justify-center`}>
                        <span className=''>
                            Data from your bills has been read by Ampera Ai. Please validate the data here.
                            <button className='text-white bg-[#067C4E] p-3 rounded-lg'>
                                <Link to={'/compare-ocr-bills'} className='' onClick={() => setIsLinkClicked(true)}>Compare OCR Bills</Link>.
                            </button>
                        </span>
                    </div>
                    : ""}

                {!isErrorLinkClicked && jobErrorMessage != "" ?
                    <div className={`fixed bottom-[75px] bg-[#B7E4C7] h-[74px] w-full text-[red] text-center font-medium text-3xl ${isSidebarOpen ? 'ml-[150px]' : 'ml-[20px]'} pt-[17px] z-0`}>{jobErrorMessage}
                        <button className='text-white bg-[#067C4E] px-3 py-1 rounded-lg'>
                            <Link to={'/compare-ocr-bills'} className='' onClick={() => setIsErrorLinkClicked(true)}>Compare Bills</Link>
                        </button>
                    </div>
                    : ""}
            </div>
        </div>
    )
}

export default OcrStatus

import React, { useEffect, useState, useMemo } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import ASelect from "../../components/select/ASelect";
import { getPowerConsumptionList } from "../../services/apis/powerConsumption";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getData, postData, updateData } from "../../api/apiDataExchange";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "../../components/pagination/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./PowerConsumption.css";
import { useAuth } from "../../hooks/useAuth";
import GoTODashboard from "../../components/button/GoTODashboard";
import moment from "moment/moment";
import FilterSelect from "../../components/select/FilterSelect";



const PowerConsumption = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [consumerNos, setConsumerNos] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [org, setOrg] = useState("");
  const [factory, setfactory] = useState("")
  const [unitlist, setUnitlist] = useState([])
  const [meterlist, setMeterlist] = useState([])
  const [factorylist, setFactorylist] = useState([])
  const [treeViewList, setTreeViewList] = useState([])
  const [unit, setunit] = useState("")
  const [meter, setMeter] = useState("")
  const [selectedLF, setSelectedLF] = useState("")
  const [lfType, setLfType] = useState("")
  const [organisationList, setOrganisationList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [meterIdsArray, setMeterIdsArray] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'meterId', direction: 'asc' });
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [filters, setFilters] = useState({
    organisationId: '',
    factoryId: '',
    unitId: '',
    meterId: '',
    lfPercentage: '',
    lfType: 'lteq',
    startDate: '',
    endDate: ''
  })

  var LoadFactors = [
    { value: '10', label: '10%' },
    { value: '20', label: '20%' },
    { value: '30', label: '30%' },
    { value: '40', label: '40%' },
    { value: '50', label: '50%' },
    { value: '60', label: '60%' },
    { value: '70', label: '70%' },
    { value: '80', label: '80%' },
    { value: '90', label: '90%' },
    { value: '100', label: '100%' }
  ];

  const tableHeaders = {
    'meterId': "Meter Name",
    'organisationId': "Organisation Name",
    'factoryId': "Factory Name",
    'unitId': "Unit Name",
    'billMonthOfLastDay': "Bill Date",
    'units.value': "Consumption (KVAH)",
    'totalBillAmount.value': "Total Bill (Rs.)",
    'rate.value': "Rate (per/unit)",
    'LF.value': "Load Factor",
    'action': 'Action'
  };

  const convertToStartDate = (dateString) => {
    const [month, year] = dateString.split('/');
    // Create a moment object for the first day of the specified month (UTC)
    const firstDayOfMonth = moment.utc(`${year}-${month}-01`, 'YYYY-MM-DD').startOf('day');
    return firstDayOfMonth.toDate();
  };

  const convertToEndDate = (dateString) => {
    const [month, year] = dateString.split('/');
    // Convert to the last day of the month using momentP
    const date = moment.utc(`${year}-${month}-01`, 'YYYY-MM-DD').endOf('month').toDate();
    return date;
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const orgFromQuery = query.get('org');
    const stateFromQuery = query.get('state');
    const consumersFromQuery = query.get('consumers');
    const loadFactorFromQuery = query.get('lf');
    const lfTypeFromQuery = query.get('lfType');
    const startDateFromQuery = query.get('startDate');
    const endDateFromQuery = query.get('endDate');

    const formattedStartDate = startDateFromQuery ? convertToStartDate(startDateFromQuery) : null;

    const formattedEndDate = endDateFromQuery ? convertToEndDate(endDateFromQuery) : null;
    console.log(formattedStartDate, formattedEndDate, 'dates')

    setOrg(orgFromQuery);
    setSelectedLF(loadFactorFromQuery);
    setLfType(lfTypeFromQuery);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    let updatedFilters = {};
    // let updatedFilters = { ...prevFilters };
    if (orgFromQuery) {
      updatedFilters.organisationId = orgFromQuery;
    }
    if (loadFactorFromQuery) {
      updatedFilters.lfPercentage = parseInt(loadFactorFromQuery);
    }
    if (formattedStartDate) {
      updatedFilters.startDate = formattedStartDate;
    }
    if (formattedEndDate) {
      updatedFilters.endDate = formattedEndDate;
    }
    // return updatedFilters;
    setFilters(prevFilters => ({ ...prevFilters, ...updatedFilters }));

  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      if (location.search !== "" && filters.organisationId === "") {
        return;
      }
      const queryParams = {
        ...filters,
        sortBy: sortConfig.key,
        sortOrder: sortConfig.direction === 'asc' ? 'asc' : 'desc',
        page: currentPage,
        pageSize: limit,
        buyerId: localStorage.getItem('userId'),
      };
      console.log(queryParams, 'queryParams');
      const powerConsumptionData = await getPowerConsumptionList(queryParams);
      const data = powerConsumptionData?.data?.results;
      setTotalCount(powerConsumptionData?.data?.total)
      // console.log(data,'data')
      const meterIds = data?.map(item => item?.meterId?._id);
      setMeterIdsArray(meterIds);
      const tableData = data.map(item => ({
        "Meter Name": item?.meterId?.meterNo,
        "Organisation Name": item?.organisationId?.name,
        "Factory Name": item?.factoryId?.name,
        "Unit Name": item?.unitId?.name,
        "Bill Date": new Date(item?.billMonthOfLastDay).toLocaleDateString('en-US', { year: 'numeric', month: 'short' }),
        "Consumption (KVAH)": item?.units?.value,
        "Total Bill (Rs.)": item?.totalBillAmount?.value,
        "Rate (per/unit)": item?.rate?.value,
        "Load Factor": item?.LF?.value,
        "Consumer Number": item?.meterId?.consumerNo,
        "billUploadSoruce": item.billUploadSoruce,
        "Previous Reading Date": new Date(item?.billMonthOfLastDay).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' }),
        "Current Reading Date": new Date(item?.billMonthOfLastDay).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit'}),
      }));
      // console.log(tableData,'tableData')
      const consumerNos = data?.map(item => ({
        consumerNo: item?.meterId?.consumerNo
      }))
      setConsumerNos(consumerNos);
      setData(tableData);
      setTotalData(tableData);

    }
    fetchData();
  }, [filters, sortConfig, currentPage])

  // useEffect(() => {
  //   // if (org !== null || selectedLF !== null || lfType !== null || startDate !== null || endDate !== null) {
  //     handleSearch();
  //   // }
  // }, [ selectedLF, lfType]);

  useEffect(() => {
    const getdata = async () => {

      const allStateData = {
        buyerId: localStorage.getItem('userId'),
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        allStateData
      );
      setOrganisationList(response?.data?.data?.results);
    };
    getdata();
  }, []);

  useEffect(() => {
    setUnitlist([])
    setunit("")
    setfactory("")

    const getTreeViewData = async () => {
      if (org !== "" && org !== null) {
        const response = await getData(
          `${process.env.REACT_APP_API_URL}/organisation/tree/${org}`,
        );
        const allFactories = response?.data?.factories?.flatMap(factories => factories || []);
        setFactorylist(allFactories);
      }
    };
    getTreeViewData();
  }, [org]);

  useEffect(() => {
    const selectedFactoryIds = factory

    const filteredFactories = factorylist?.filter(factory =>
      selectedFactoryIds?.includes(factory?._id)
    );
    setUnitlist(filteredFactories?.flatMap(factory => factory.units));
  }, [factory]);

  useEffect(() => {
    const selectedUnitIds = unitlist?.map(unit => unit._id);
    const filteredUnits = unitlist?.filter(unit =>
      selectedUnitIds?.includes(unit?._id)
    )
    setMeterlist(filteredUnits?.flatMap(unit => unit.meters))

  }, [unit])

  useEffect(() => {
    const getMeterData = async () => {
      if (unit !== "") {
        const meterData = {
          unitId: unit,
        };
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/meter/list`,
          meterData
        );
        setMeterlist(response?.data?.data?.results);
      }
    };
    getMeterData();
  }, [unit]);

  const handleView = (conNo, startDate, endDate) => {
    navigate(`/compare-ocr-bills?conNo=${conNo}&startDate=${startDate}&endDate=${endDate}`);
  };

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    // Update sorting configuration
    setSortConfig({ key, direction });

  };


  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ?
        <FontAwesomeIcon icon={faArrowUp} /> :
        <FontAwesomeIcon icon={faArrowDown} />;
    }
    return null;
  };

  const formateDatetoMonth = (dateString) => {
    return moment(dateString).format('MM/YYYY');
  }
  
  const handleSearch = async () => {
    setCurrentPage(1)

    setFilters(prevFilters => {
      let updatedFilters = { ...prevFilters };
      if (org) {
        updatedFilters.organisationId = org;
      }
      if (factory) {
        updatedFilters.factoryId = factory;
      }
      if (unit) {
        updatedFilters.unitId = unit;
      }
      if (meter) {
        updatedFilters.meterId = meter;
      }
      if (selectedLF) {
        updatedFilters.lfPercentage = parseInt(selectedLF);
      }
      if (startDate) {
        updatedFilters.startDate = formateDatetoMonth(startDate);
      }
      if (endDate) {
        updatedFilters.endDate = formateDatetoMonth(endDate);
      }
      return updatedFilters;
    });

  }

  const handleReset = () => {
    setUnitlist([]);
    setMeterlist([]);
    setFactorylist([]);
    setOrg("");
    setfactory("");
    setunit("");
    setMeter("");
    setSelectedLF("")
    setStartDate("");
    setEndDate("");
    setFilteredData([]);
    setSortConfig({ key: 'meterId', direction: 'asc' });
    setFilters({
      organisationId: '',
      factoryId: '',
      unitId: '',
      meterId: '',
      startDate: '',
      endDate: ''
    })
  };

  function capitalizeWords(str) {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <div className="max-w-[1920px] relative mt-[70px] flex ">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen ? "sm:pl-[315px]" : "sm:pl-[95px]"} w-[100%] px-2 mb-8 mr-1 transition-width duration-300`}>
        {" "}
        <div className=" rounded-lg pb-[20px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:mt-[30px]">
          <div className="flex flex-row">
            <div className="px-4 pt-4 pb-2 text-[20px] sm:text-[28px] font-medium leading-[42px]">
              Power Consumption List
            </div>
            <div className="leading-[42px] py-2 pr-0 sm:pr-2 text-[18px] ml-auto">
              <GoTODashboard />
            </div>
          </div>
          <div className="flex flex-wrap gap-2 px-2">
            <div className="border rounded-[10px] outline-none border-gray-500 sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[19%]">
              <FilterSelect
                label={"Organisation"}
                value={org}
                onChange={(e) => {
                  setOrg(e.target.value);
                }}
                options={organisationList?.map((organisation) => {
                  return { label: organisation.name, value: organisation._id };
                })}
                maxWidth={120}
              />
            </div>
            <div className="border rounded-[10px] outline-none border-gray-500 sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[19%]">
              <FilterSelect
                label={"Factory"}
                value={factory}
                onChange={(e) => {
                  setfactory(e.target.value);
                }}
                options={factorylist?.map((factory) => {
                  return { label: factory.name, value: factory._id };
                })}
              />
            </div>
            <div className="border rounded-[10px] outline-none border-gray-500 sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[19%]">
              <FilterSelect
                label={"Unit"}
                value={unit}
                onChange={(e) => {
                  setunit(e.target.value);
                }}
                options={unitlist?.map((unit) => {
                  return { label: unit.name, value: unit._id };
                })}
              />
            </div>
            <div className="border rounded-[10px] outline-none border-gray-500 sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[19%]">
              <FilterSelect
                label={"Meter"}
                value={meter}
                onChange={(e) => {
                  setMeter(e.target.value);
                }}
                options={meterlist?.map((meter) => {
                  return { label: meter.meterNo, value: meter._id };
                })}
              />
            </div>
            <div className="border rounded-[10px] outline-none border-gray-500 sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[19%]">
              <FilterSelect
                options={LoadFactors?.map((LF) => {
                  return { label: LF.label, value: LF.value };
                })}
                value={selectedLF}
                onChange={(e) => {
                  setSelectedLF(e.target.value);
                }}
                label={"Load Factor"}
              />
            </div>
            <div className="lg:mt-0 sm:ml-[10px] lg:ml-0 compare-ocr w-[100%] sm:w-[40%] lg:w-[19%]">
              <div className="customDatePickerWidth">
                <DatePicker
                  placeholderText="Select Start Month"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  showMonthYearPicker
                  dateFormat="MMM-yyyy"
                  startDate={startDate}
                  endDate={endDate}
                  className="w-full"
                />
              </div>
              
            </div>
            <div className="lg:mt-0 sm:ml-[10px] lg:ml-0 compare-ocr w-[100%] sm:w-[40%] lg:w-[19%]">
              <div className="customDatePickerWidth">
                <DatePicker
                  placeholderText="Select End Month"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  showMonthYearPicker
                  dateFormat="MMM-yyyy"
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="w-full"
                />
              </div>
            </div>
          </div>


          <div className="flex flex-row justify-between px-2 item-center">
          {<div className="flex justify-end items-center text-sm  mx-2 md:mx-6 mt-4">
            Total Consumptions {totalCount}
          </div>}
          <div className="flex justify-end my-2 mx-2 md:mx-6">
            <button onClick={handleSearch} className="text-white bg-[#067C4E] hover:bg-[#045036] w-[100px] py-2 px-4 rounded-lg">
              Filter
            </button>
            <button onClick={handleReset} className="bg-white border-[#067C4E] hover:bg-gray-200 text-[#067C4E] border-2 border-solid w-[100px] py-2 px-4 ml-3 rounded-lg">
              Reset
            </button>
          </div>
          </div>

          <div className="w-[100%] relative overflow-x-auto">
            <table className="w-full mt-2  shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] border-[#EFEFEF] text-center ">
              <thead className={`bg-[#EFEFEF] h-[69px]`}>
                <tr>
                  {Object.entries(tableHeaders)?.map(([key, value], index) => (
                    <th
                      key={index}
                      className="cursor-pointer w-[300px] font-medium"
                      onClick={() => sortData(key)}
                    >
                      {value}{" "}{renderSortArrow(key)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={Object.keys(tableHeaders).length} // Adjust this to match the number of columns
                      className="w-screen px-6 py-4 text-sm leading-5 text-gray-500 text-center"
                    >
                      No Data Available
                    </td>
                  </tr>
                    ) : (
                  data?.map((rowData, rowIndex) => {
                    return (
                      <tr key={rowIndex} className="h-[50px] w-[250px] text-gray-800 font-thin">
                        {Object.entries(tableHeaders)?.map(([key, header], cellIndex) => {
                          if (key === 'action') {
                            return (
                              <td key={cellIndex} className="p-1">
                                {rowData?.billUploadSoruce === "ocr" &&
                                  <button
                                    className="cursor-pointer text-[blue] underline px-3 py-1"
                                    onClick={() => handleView(rowData["Consumer Number"], rowData["Previous Reading Date"], rowData["Current Reading Date"])}
                                  >
                                    View Bill
                                  </button>
                                }
                              </td>
                            );
                          }
                          const value = rowData[header];

                          return (
                            <td key={cellIndex} className="p-1">
                              {(header === "Organisation Name" || header === "Factory Name" || header === "Unit Name" || header === "Bill Date") ? capitalizeWords(value) : value}
                            </td>
                          );
                        })}
                        {/* <td >
                        <button className="cursor-pointer text-[blue] underline" onClick={() => handleView()}>
                          View Bill
                        </button>
                      </td> */}
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
          {data?.length !== 0 && 
            <div className="pagination-controls border-t-2 border-[#abaaaa] flex flex-row items-center justify-end pt-2">
              <button className={`bg-[#e1e0e0] p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
              {/* <span className="mx-4">{Math.min(currentPage * limit, totalCount)-9} to {Math.min(currentPage * limit, totalCount)} off {totalCount}</span> */}
              <span className="mx-4">
                {/* {`${(currentPage - 1) * limit + 1} to ${Math.min(currentPage * limit, totalCount)} of ${totalCount}`} */}
                {totalCount > 0 ?
                  `${(currentPage - 1) * limit + 1} to ${Math.min(currentPage * limit, totalCount)} of ${totalCount}`
                  : "0"
                }
              </span>

              <button className="bg-[#e1e0e0] p-3 rounded-xl mr-2" disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
            </div>
          }
          
        </div>
      </div>
    </div>
  );
};

export default PowerConsumption;

import React, { useEffect, useRef } from 'react';
import loadGoogleMapsApi from '../../utils/googleMapApi';

const GoogleMapDashboard = ({ address }) => {
  const mapRef = useRef(null);
  const markerRef = useRef([]);

  useEffect(() => {
    let map;

    const initializeMap = async () => {
      try {
        await loadGoogleMapsApi();

        // Center the map on India and set a zoom level to show the complete country
        map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 23.0, lng: 80.9629 }, // Center on India
          zoom: 4.39, // Zoom level to show the entire country
          mapTypeControl: false,
          scrollwheel: false 
        });

        if (address && address.length > 0) {
          // Clear existing markers
          markerRef.current.forEach(marker => marker.setMap(null));
          markerRef.current = [];

          // Add markers for each address
          address.forEach((addr) => {
            const location = { lat: addr.coordinates[0], lng: addr.coordinates[1] };
            const marker = new window.google.maps.Marker({
              map,
              position: location,
              title: addr.name,
           
            });
            markerRef.current.push(marker);
          });
        }
      } catch (error) {
        console.error('Error loading Google Maps API', error);
      }
    };

    initializeMap();

    return () => {
      if (markerRef.current) {
        markerRef.current.forEach(marker => marker.setMap(null));
      }
    };
  }, [address]);

  return (
    <div className="bg-black bg-opacity-50 flex justify-center items-center z-50 relative w-[100%] h-[100%]">
      <div ref={mapRef} className="w-[100%] h-[100%]"></div>
    </div>
  );
};

export default GoogleMapDashboard;

import React, { useEffect, useRef, useState } from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import BillPopup from '../../components/popup/BillsPopup'
import Abutton from '../../components/button/Abutton';
import { Link, json, useNavigate } from "react-router-dom";
import readXlsxFile from 'read-excel-file';
import { uploadExcelData } from '../../api/uploadFile';
import { faCircleDown, faTimesCircle, faCheck, faAngleUp, faAngleDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DownLoadbutton from '../../components/button/DownLoadbutton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { getData, postData, postImageData, postList, postOcrList, updateData } from '../../api/apiDataExchange';
import AmperaPlatform from '../../components/demoComponents/AmperaPlatform';
import DemoPage from '../../components/demoComponents/DemoPage';
import UploadPage from '../../components/demoComponents/UploadPage';
import AnalyticsPage from '../../components/demoComponents/AnalyticsPage';
import MyDataPage from '../../components/demoComponents/MyDataPage';
import DashboardPage from '../../components/demoComponents/DashboardPage';
import PowerConsumptionPage from '../../components/demoComponents/PowerConsumptionPage';
import LoaderComponent from '../../components/loaderCompent/LoaderComponent';
import { useJobProgress } from '../../hooks/billsJob';
import AbuttonDisable from '../../components/button/AbuttonDisable';
import { useAuth } from "../../hooks/useAuth";
import { convertImageToPdf } from '../../services/apis/media';

const Upload = () => {
  // console.log(states);
  const { jobErrorMessage, jobIdLength, succesfullJobLength, extractKeysAndValues, processCompletedJob, processJobIds, discomCode, setDiscomCode, consumerType, setConsumerType, fieldNames } = useJobProgress();
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isloading, setIsloading] = useState(false);
  // const [activeTab, setActiveTab] = useState(true);
  const [activeTab, setActiveTab] = useState('uploadBills');
  const [extractedData, setExtractedData] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const [ebills, setEBills] = useState({});
  const [allUploaded, setAllUploaded] = useState(false);
  const [billsData, setBillsData] = useState([]);
  const fileInputRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [eExcel, setEExcel] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [organisations, setOrganisations] = useState([]);
  const [billsUploadError, setBillsUploadError] = useState("");
  const [ocrLoader, setOcrLoader] = useState(false);
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [discomListLoader, setDisocmListLoader] = useState(false);
  const [discomCodeList, setDiscomCodeList] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [eTariff, setETariff] = useState('');
  const [eState, setEstate] = useState("");
  const [eDiscom, setEDiscom] = useState("");
  const [selectedStateCode, setSelectedStateCode] = useState('');
  const [selectedDiscomCode, setSelectedDiscomCode] = useState('');
  const [fadeClass, setFadeClass] = useState('fade-enter');
  const fadeRef = useRef();
  // const [consumerType, setConsumerType] = useState('');

  function organizeData(discoms) {
    let activeStateDiscoms = discoms.filter(discom => discom.stateId.status === 'active');

    let states = [];

    activeStateDiscoms.forEach(discom => {

      let state = states.find(s => s._id === discom.stateId._id);
      if (!state) {
        // Create a new state entry if it doesn't exist
        state = {
          _id: discom.stateId._id,
          name: discom.stateId.name,
          code: discom.stateId.code,
          discoms: [] // this will store the DISCOMs of this state
        };
        states.push(state);
      }
      // Add the DISCOM to the state's list
      state.discoms.push({
        _id: discom._id,
        name: discom.name,
        code: discom.code
      });
    });
    states.forEach(state => {
      state.discoms.sort((a, b) => a.name.localeCompare(b.name));
    });

    // Sort states by name
    states.sort((a, b) => a.name.localeCompare(b.name));

    return states;
  }

  useEffect(() => {
    setDisocmListLoader(true);
    const fetchData = async () => {
      try {
        const payload = {
          "status": 'active'
        }
        const disComCodeList = await postList(`${process.env.REACT_APP_API_URL}/disComCode/list`, payload);
        if (disComCodeList) {
          const organizedData = organizeData(disComCodeList?.data?.results);
          setDiscomCodeList(organizedData);
          setDisocmListLoader(false);
          // console.log(JSON.stringify(organizedData, null, 2));
          // console.log(disComCodeList);
        }

      }
      catch (error) {
        console.error("An error occurred while fetching data:", error);
        setDisocmListLoader(false);

      }
    };
    fetchData();
  }, []);


  const handleStateChange = (e) => {
    const stateCode = e.target.value;
    setSelectedStateCode(stateCode);
    setSelectedDiscomCode(''); // Reset discom selection when state changes
  };

  const handleDiscomChange = (e) => {
    setSelectedDiscomCode(e.target.value);
  };

  useEffect(() => {
    // Reset discom selection when state changes
    if (selectedStateCode) {
      const stateIndex = discomCodeList.findIndex(state => state.code === selectedStateCode);
      handleStateClick(stateIndex, selectedStateCode);
    }
  }, [selectedStateCode]);

  useEffect(() => {
    if (selectedDiscomCode && selectedStateCode) {
      handleDiscomClick(selectedDiscomCode, selectedStateCode);
    }
  }, [selectedDiscomCode]);



  //api call of organisation list to check is there orgnisation data
  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.allSettled([
          await postList(`${process.env.REACT_APP_API_URL}/organisation/list`),

        ])
          .then(responses => {
            const [organisationsList, factoriesList, unitsList, metersList] = responses;
            setOrganisations(organisationsList?.value?.data?.results);
          })
      } catch (error) {
        console.error("An error occurred while fetching data:", error);

      }
    };
    fetchData();
  }, []);

  const [fileNames, setFileNames] = useState([]);
  //onchange function for bill upload 
  const handleFileChange = (event) => {
    setBillsUploadError("");
    const selectedFiles = event.target.files;
    const newErrors = {};
    const newFiles = [];
    const fileNames = [];


    Array.from(selectedFiles).forEach(file => {
      if (!['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
        newErrors[file.name] = 'Invalid file type. Only JPEG,PNG and PDF are allowed.';
      } else {
        newFiles.push(file);
        fileNames.push(file.name);
      }
    });
    console.log(fileNames);
    setFiles(newFiles);
    setEBills(newErrors);
    setFileNames(fileNames);
    // console.log(ebills);
    setUploadStatus({});
  };

  const removeFile = (indexToRemove) => {
    const newFiles = files.filter((_, index) => index !== indexToRemove);
    const newFileNames = fileNames.filter((_, index) => index !== indexToRemove);
    setFiles(newFiles);
    setFileNames(newFileNames);
  };

  const updateFileInput = () => {
    const dataTransfer = new DataTransfer();
    files.forEach(file => dataTransfer.items.add(file));
    fileInputRef.current.files = dataTransfer.files;
  };

  React.useEffect(() => {
    updateFileInput();
  }, [files]);

  const validateFunction = () => {
    let isValid = true;

    if (consumerType === '') {
      setETariff("Please select tariff value");
      isValid = false;
    } else {
      setETariff('');
    }

    if (selectedStateCode === '') {
      setEstate("Please select state");
      isValid = false;
    } else {
      setEstate('');
    }

    if (selectedDiscomCode === '') {
      setEDiscom("Please select Discom");
      isValid = false;
    } else {
      setEDiscom('');
    }

    return isValid;
  };

  function separateFiles(files) {
    const images = [];
    const pdfs = [];

    files.forEach(file => {
      const extension = file.name.split('.').pop().toLowerCase();

      if (['png', 'jpeg', 'jpg'].includes(extension)) {
        images.push(file);
      } else if (extension === 'pdf') {
        pdfs.push(file);
      }
    });

    return { images, pdfs };
  }

  const fetchBinaryFile = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}`);
    }
    const blob = await response.blob();
    const fileName = url.split('/').pop(); // Extract file name from URL
    return new File([blob], fileName, { type: blob.type });
  };

  const convertUrlsToFiles = async (urls) => {
    const files = await Promise.all(urls.map(url => fetchBinaryFile(url)));
    return files;
  };

  const uploadFiles = async () => {

    const uploadAllImages = async () => {
      try {

        const isValid = validateFunction();

        if (!isValid) {
          return; // Exit if validation fails
        }
        setOcrLoader(true);

        const billfiles = Array.from(files);

        const { images, pdfs } = separateFiles(billfiles);
        let allPdfs = []
        let imagesWithPdfUrls = [];
        // console.log('Images:', images);
        // console.log('PDFs:', pdfs);
        if (images.length > 0) {
          const imageToPdfResponse = await convertImageToPdf(images);
          const imagePdfs = imageToPdfResponse.data.paths;
          console.log(imagePdfs, 'imagePdfs');
          const imagePdfsAsFiles = await convertUrlsToFiles(imagePdfs);
          console.log(imagePdfsAsFiles, 'imagePdfsAsFiles');
          allPdfs = [...pdfs, ...imagePdfsAsFiles];
          imagesWithPdfUrls = [...pdfs, ...imagePdfs]
        } else {
          allPdfs = [...pdfs]
          imagesWithPdfUrls = [...pdfs]

        }


        // return;

        const disComData = {
          stateCode: selectedStateCode,
          discomCode: selectedDiscomCode,
          consumerType: consumerType,
        }
        console.log(allPdfs, 'allPdfs');
        console.log(imagesWithPdfUrls, 'imagesWithPdfUrls');
        // return;
        const response = await postImageData(`${process.env.REACT_APP_API_URL_OCR}/upload-document`, allPdfs, disComData);
        // console.log(response);
        if (response) {
          setOcrLoader(false);
          try {

            // const jobIds = ["f13ad073a64b1f3e5aab8c546b49276cc57a6bd9010f95336f9025375c5063d8","470a5dfef049c807029f40c63e822cacbad7b8c6ac5cda1120e0fabcc6ce8dbd"]
            // const receivedOcrData = await processJobIds(jobIds,imagesWithPdfUrls, selectedStateCode, selectedDiscomCode, consumerType);

            let parsedJobIds = JSON.parse(response?.data?.jobId);
            const receivedOcrData = await processJobIds(response.data.jobId, imagesWithPdfUrls, selectedStateCode, selectedDiscomCode, consumerType);
            if (organisations?.length > 0) {
              navigate('/my-data');
            } else {
              setCurrentStep(1);
            }

            if (receivedOcrData) {
              console.log("Received OCR Data for completed jobs:", receivedOcrData);
            } else {
              // console.log("No completed jobs found.");
            }
          } catch (error) {
            console.error("Error processing job IDs:", error);
          }
        }

      } catch (error) {
        setBillsUploadError("Error while uploading bills")
      }
    };

    if (files.length == 0) {
      setBillsUploadError("Please select bill")
    } else if (files.length > 0) {
      uploadAllImages();
    }
  }

  const handleNext = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  // useEffect(() => {
  //   if (currentStep > 0) {
  //     if (currentStep < 7) {
  //       const timer = setTimeout(() => {
  //         setCurrentStep(currentStep + 1);
  //       }, 5000);

  //       return () => clearTimeout(timer);
  //     } else {
  //       navigate('/demo');
  //     }
  //   }
  // }, [currentStep]);

  useEffect(() => {
    // Start the enter transition for the first screen
    // if (currentStep === 1) {
      setTimeout(() => {
        setFadeClass('fade-enter-active');
      }, 10);
    // }
  }, [currentStep]);

  
  useEffect(() => {
    if (currentStep > 0) {
      if (currentStep < 7) {
        // Start the exit transition
        setFadeClass('fade-exit-active');
  
        const timer = setTimeout(() => {
          // After exit transition is done, update the step and start the enter transition
          setCurrentStep(currentStep + 1);
          setFadeClass('fade-enter');
  
          // Start the enter transition
          setTimeout(() => {
            setFadeClass('fade-enter-active');
          }, 10);
        }, 5000);
  
        return () => clearTimeout(timer);
      } else {
        navigate('/demo');
      }
    }
  }, [currentStep, navigate]);
  


  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <AmperaPlatform />;
      case 2:
        return <DemoPage />;
      case 3:
        return <UploadPage />;
      case 4:
        return <AnalyticsPage />;
      case 5:
        return <MyDataPage />;
      case 6:
        return <DashboardPage />;
      case 7:
        return <PowerConsumptionPage />;
      default:
        return null;
    }
  };


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSkipClick = () => {
    if (activeTab === "uploadExcel") {
      setActiveTab("uploadBills");
    } else if (activeTab === "uploadBills") {
      setActiveTab("uploadExcel");

    }
  }

  const handleSubmit = (event, otp) => {
    event.preventDefault();
    navigate('/demo');

  };

  const compareExcelColumns = (uploadedData, referenceData) => {
    const secondUploadedRow = uploadedData[1];
    const secondReferenceRow = referenceData[1];

    for (let j = 0; j < secondUploadedRow.length; j++) {
      const uploadedValue = secondUploadedRow[j] !== null && secondUploadedRow[j] !== undefined ? secondUploadedRow[j] : '∅';
      const referenceValue = secondReferenceRow[j] !== null && secondReferenceRow[j] !== undefined ? secondReferenceRow[j] : '∅';

      if (uploadedValue !== '∅' && referenceValue !== '∅' && uploadedValue !== referenceValue) {
        return false;
      }
    }
    return true;
  };

  const checkRequiredFields = (excelData) => {
    // Filter out null values from the excelData[1]
    const filteredData = excelData[1].filter(value => value !== null);
    const fieldsInExcelData = new Set(filteredData);

    // console.log('entry');
    // console.log(fieldsInExcelData);
    // console.log(fieldNames);
    // Check if all required fields are present in fieldsInExcelData
    for (let field of fieldNames) {
      if (field !== null) {
        if (!fieldsInExcelData.has(field)) {
          return false;
        }
      }
    }

    return true;
  };


  const handleFileUpload = async (event) => {
    setExtractedData(null);
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const extension = file.name.split('.').pop();

      if (extension === 'xlsx') {
        setLoader(true);
        setEExcel("")
        try {
          const excelData = await readXlsxFile(file);
          const referenceData = await fetch('/Billdata_Format-_Analysis-MM.xlsx')
            .then(response => response.blob())
            .then(blob => readXlsxFile(blob));

          if (!checkRequiredFields(excelData)) {
            setEExcel('Selected consumer type and uploaded excel do not match');
            setLoader(false);
            return;
          }

          // if (compareExcelColumns(excelData, referenceData)) {
            setExtractedData(excelData);
          // } else {
          //   setEExcel('Excel format is not correct. The columns do not match.');
          // }
          setLoader(false);

        } catch (error) {
          console.error('Error reading Excel file:', error);
        }
      } else {
        setEExcel('Invalid file type. Please upload an Excel file (.xlsx).');
      }
    } else {
      setEExcel('No file selected.');
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    document.getElementById('file-input').value = '';
    setExtractedData(null);
    setLoader(false);
    setEExcel('');
  };

  const handleSubmitExcelData = async (event) => {
    const isValid = validateFunction();

    if (!isValid) {
      return; // Exit if validation fails
    }

    setLoader(true);
    event.preventDefault();
    if (extractedData && extractedData.length > 0) {
      try {
        const data = {
          "buyerId": localStorage.getItem("userId"),
          "stateCode": selectedStateCode,
          "discomCode": selectedDiscomCode,
          billData: extractedData,
          "consumerType": consumerType,
          "billUploadSoruce": "excel"
        }
        // console.log(data);
        const response = await uploadExcelData(`${process.env.REACT_APP_API_URL}/bill/processRawData`, data);
        if (response) {
          setConsumerType('');
          setLoader(false);
          console.log('Chunk uploaded:', response);
          if (response?.statusCode == 201) {
            navigate("/my-data");
          } else {
            setEExcel('Error in uploaded excel : ' + response.message)
          }
        }
      } catch (error) {
        setLoader(false);
        console.error('Error uploading chunk:', error);
      }
    } else {
      setLoader(false);
      setEExcel('No file selected.');
    }
  };

  const handleDownload = () => {
    const fileName = "Billdata_Format-_Analysis-MM.xlsx";
    const fileUrl = process.env.PUBLIC_URL + "/" + fileName;
    // console.log(fileUrl);
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };



  const handleStateClick = (index, stateCode) => {
    setSelectedStateCode(stateCode);
    setSelectedDiscomCode(''); // Reset discom code when state changes
    if (stateCode === "") {
      setEstate("Please select state");
    } else {
      setEstate("");
    }
  };

  const handleDiscomClick = (discomCode, stateCode) => {
    setSelectedStateCode(stateCode);  // Also set the state code when a discom is selected
    setSelectedDiscomCode(discomCode);
    setDiscomCode(discomCode);
    if (discomCode === "") {
      setEDiscom("Please select Discom");
    } else {
      setEDiscom("");
    }
  };

  const handleTariffChange = (e) => {
    setConsumerType(e.target.value);
    if (e.target.value === "") {
      setETariff("Please select tariff value");
    } else {
      setETariff("");
    }
  };

  return (
    <div className='max-w-[1920px] relative sm:pr-[50px]'>
      <div className='max-w-[1920px] m-auto flex'>
        <div className='hidden sm:block'>
          <Sidebar />
        </div>
        {currentStep == 0 ?
          <div className={`${isSidebarOpen ? "sm:ml-[315px]" : "sm:ml-[95px]"} w-full mt-[70px] items-center h-[500px] transition-width duration-300`}>
            {/* <div className=' ml-[350px] w-full m-[100px] items-center h-[600px]'> */}
            <div className='w-full'>
              <div className='sm:px-12 px-[10px] sm:mx-[40px] py-6 flex flex-row'>
                <div>
                  <button
                    onClick={() => handleTabClick('uploadBills')}
                    className={`text-center rounded-[10px] p-[10px] text-[16px] font-medium leading-[24px] ${activeTab === 'uploadBills' ? 'text-white bg-[#067C4E] ' : 'text-black bg-[white] border-2 border-gray-500'} h-[44px] w-[150px] md:h-[44px] md:w-[150px]`}
                  >
                    Upload Bills
                  </button>
                </div>
                <div className='ml-6'>
                  <button
                    onClick={() => handleTabClick('uploadExcel')}
                    className={`text-center rounded-[10px] p-[10px] text-[16px] font-medium leading-[24px] ${activeTab === 'uploadExcel' ? 'text-white bg-[#067C4E] ' : 'text-black bg-[white] border-2 border-gray-500'} h-[44px] w-[150px] md:h-[44px] md:w-[150px]`}
                  >
                    Upload Excel
                  </button>
                </div>
              </div>
              <div className="relative align-center rounded-lg text-left">
                <div className='flex flex-col lg:flex-row w-[100%] mx-auto'>
                  <div className="mx-[10px] state-list lg:mx-[89px] lg:w-[45%] lg:min-h-[450px] lg:max-h-[500px]  overflow-y-auto rounded  bg-white">
                    <div className='sm:p-9'>
                      {discomListLoader ? <LoaderComponent /> :
                        <>
                          <div className="mb-2 sm:mb-4">
                            <label htmlFor="stateSelect" className="block mb-1 sm:mb-2 text-sm sm:text-xl font-medium">Select State<span className="text-[red] ml-[4px]">*</span></label>
                            <select
                              id="stateSelect"
                              value={selectedStateCode}
                              onChange={handleStateChange}
                              className="w-full border border-gray-300 rounded-[10px] p-2 text-base font-normal"
                            >
                              <option value="" className='text-[10px] sm:text-[14px] font-normal'>-- Select State --</option>
                              {discomCodeList.map((state, index) => (
                                <option key={index} value={state.code} className='text-[14px] font-normal'>{state.name}</option>
                              ))}
                            </select>
                            {eState && <div className="text-red-500 text-[10px] capitalize px-[20px] py-[10px]">{eState}</div>}

                          </div>
                          {
                            <div className="my-2 sm:my-9">
                              <label htmlFor="discomSelect" className="block mb-1 sm:mb-2 text-sm sm:text-xl">Select Discom<span className="text-[red] ml-[4px]">*</span></label>
                              <select
                                id="discomSelect"
                                value={selectedDiscomCode}
                                onChange={handleDiscomChange}
                                className="w-full border border-gray-300 rounded-[10px] p-2 text-base font-normal"
                              >
                                <option value="" className='text-[10px] sm:text-[14px] font-normal'>-- Select Discom --</option>
                                {discomCodeList.find(state => state.code === selectedStateCode)?.discoms.map((discom, index) => (
                                  <option key={index} value={discom.code} className='text-[14px] font-normal'>{discom.name}</option>
                                ))}
                              </select>
                              {eDiscom && <div className="text-red-500 text-[10px] capitalize px-[20px] py-[10px]">{eDiscom}</div>}

                            </div>
                          }
                          {
                            <div className="mb-2 sm:mb-4">
                              <label htmlFor="discomSelect" className="block mb-1 sm:mb-2 text-sm sm:text-xl">Select Consumer Type<span className="text-[red] ml-[4px]">*</span> </label>
                              <select
                                id="tariffSelect"
                                value={consumerType}
                                onChange={handleTariffChange}
                                className="w-full border border-gray-300 rounded-[10px] p-2 text-base font-normal"
                              >
                                <option value="" className='text-[10px] sm:text-[14px] font-normal'>-- Select Consumer Type --</option>
                                <option value="INDUSTRIAL" className='text-[14px] font-normal'>INDUSTRIAL</option>
                                <option value="COMMERCIAL" className='text-[14px] font-normal'>COMMERCIAL</option>
                                <option value="RESIDENTIAL" className='text-[14px] font-normal'>RESIDENTIAL</option>

                              </select>
                              {eTariff && <div className="text-red-500 text-[10px] capitalize px-[20px] py-[10px]">{eTariff}</div>}
                            </div>
                          }
                        </>

                        // discomCodeList?.map((state, index) => (
                        //   <div key={index} className='border-b-[1px] border-[#dfdcdc] '>
                        //     <div className='flex flex-row items-center cursor-pointer w-[100%]'>
                        //       <div className={`w-[100%] py-3 text-base ${state.code === selectedStateCode ? 'bg-gray-200 px-2' : ''}`} onClick={() => handleStateClick(index, state.code)}>{state.name}</div>
                        //       <div className='' onClick={() => toggleDiscoms(index)}>
                        //         <FontAwesomeIcon icon={openIndex === index ? faAngleUp : faAngleDown} className="text-lg text-[#acabab] cursor-pointer" />
                        //       </div>
                        //     </div>
                        //     {openIndex === index && (
                        //       <ul className='my-1'>
                        //         {state.discoms.map((discom, discomIndex) => (
                        //           <li key={discomIndex} value={discom.code} onClick={() => handleDiscomClick(discom.code, state.code)} className={`text-base ml-3 py-1 cursor-pointer text-[#525151] ${discom.code === selectedDiscomCode ? 'bg-gray-200 px-2' : ''}`}>{discom.name}</li>
                        //         ))}
                        //       </ul>
                        //     )}
                        //   </div>
                        // ))


                      }


                    </div>
                  </div>
                  {/* <div className='w-[45%] min-h-[450px] mx-[20px]  sm:mx-[89px]  max-h-[450px]'>

                      <div style={{ border: "1px solid #B9B9B9" }} className="  pb-[40px] min-h-[450px] max-h-[450px] sm:pb-[40px] rounded  bg-white">

                        <div className="mt-[18px] sm:px-[55px] sm:mt-[21px] px-[14px]">
                          <input
                            ref={fileInputRef}
                            className="input-placeholder text-[14px] w-full bg-white border pt-[10px]  pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                            type="file"
                            multiple
                            onChange={handleFileChange}
                          />
                        </div>


                      </div>
                      <div className="mt-[18px] px-[14px] sm:px-[55px] sm:mt-[20px] flex font-normal justify-center sm:justify-around gap-[16px]">
                        <Abutton
                          text={"Upload"}
                          handleOnclick={(e) => {
                            uploadFiles(e, "121212")
                          }}
                          Mheight={44}
                          Mwidth={150}
                          DHeight={44}
                          DWidth={129}
                        />

                      </div>
                    </div> */}
                  {activeTab === 'uploadBills' ?
                    <div className="mx-[10px] sm:mx-[89px] xl:m-auto lg:w-[47%] pb-[40px] lg:min-h-[450px] lg:max-h-[500px] lg:pb-[40px] rounded bg-white">
                      <div className="px-[14px] sm:px-[55px] font-medium text-center text-[16px] sm:text-xl leading-[24px] pt-[30px] lg:pt-[35px]">
                        Upload Bills
                      </div>
                      <div className="mt-3 sm:mt-[40px] w-full flex justify-center items-center ">
                        {ocrLoader ?
                          <div>
                            <div><LoaderComponent /></div>
                            <div className='text-[red]'>Uploading Bills please wait</div>
                          </div>
                          : <img src="/uploadIcon.svg" alt='' />
                        }
                      </div>
                      <div className="mt-[18px] sm:px-[55px] sm:mt-[21px] px-[14px]">
                        <input
                          ref={fileInputRef}
                          className="input-placeholder text-[14px] w-full bg-white border pt-[10px]  pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                          type="file"
                          multiple
                          onChange={handleFileChange}
                        />
                      </div>
                      <div>
                        <ul className='sm:ml-4'>
                          {Object.entries(ebills).map(([fileName, error], index) => (
                            <li key={index} className='mt-2 text-[red] w-full max-w-[500px] sm:ml-8 font-medium rounded-2xl'>{`${fileName}: ${error}`}</li>
                          ))}
                        </ul>
                        <ul className='sm:ml-4'>
                          {billsUploadError != "" ?
                            <li className='mt-2 text-[red] w-full max-w-[500px] sm:ml-8 font-bold p-2 rounded-2xl'>{billsUploadError}</li>
                            : ""}
                        </ul>
                        {allUploaded ? <ul className='mt-3 mx-auto w-full text-left sm:ml-4'>
                          {Object.entries(uploadStatus).map(([fileName, status], index) => (

                            <li key={index} className='mt-2 text-[green] w-full max-w-[500px] sm:ml-8 font-bold border-2 bg-[#E2F5EC] border-green-500 p-2 rounded-2xl'>  <FontAwesomeIcon icon={faCheck} /> {`${fileName} : ${status}`}</li>
                          ))}
                        </ul> : ""}
                        {fileNames.length > 1 &&
                          <div className='w-[90%] mx-auto h-[100px] upload-bills overflow-y-auto'>
                            <ul className='flex flex-row flex-wrap sm:pl-[12px]'>
                              {fileNames?.map((fileName, index) => (
                                <li key={index} className='mt-1 text-[green] flex flex-row items-center w-[170px] sm:ml-1 font-bold p-2 rounded-2xl'>
                                  <span className='' title={fileName.length > 10 ? fileName : null}>
                                    {fileName.length > 10 ? fileName.slice(0, 10) + "..." : fileName}
                                  </span>
                                  <span>
                                    <FontAwesomeIcon icon={faXmark} className='text-[red] ml-4 mt-[6px] cursor-pointer' onClick={() => removeFile(index)} />
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        }

                      </div>
                      {
                        ocrLoader ? <div className={`${fileNames.length > 4 ? 'mt-[20px]' : 'mt-[40px]'} px-[14px] sm:px-[55px] flex font-normal justify-center sm:justify-around gap-[16px]`}>
                          <AbuttonDisable
                            text={"Submit"}
                            Mheight={44}
                            Mwidth={170}
                            DHeight={44}
                            DWidth={299}
                          />
                          <AbuttonDisable
                            text={"Skip"}
                            Mheight={44}
                            Mwidth={170}
                            DHeight={44}
                            DWidth={299}
                          />
                        </div> :

                          <div className={`${fileNames.length > 4 ? 'mt-[20px]' : 'mt-[40px]'} px-[14px] sm:px-[55px] flex font-normal justify-center sm:justify-around gap-[16px]`}>
                            <Abutton
                              text={"Submit"}
                              handleOnclick={(e) => {
                                uploadFiles(e, "121212")
                              }}
                              Mheight={44}
                              Mwidth={170}
                              DHeight={44}
                              DWidth={299}
                            />
                            <Abutton
                              text={"Skip"}
                              handleOnclick={(e) => {
                                handleSkipClick(e, '1222');
                              }}
                              Mheight={44}
                              Mwidth={170}
                              DHeight={44}
                              DWidth={299}
                            />
                          </div>
                      }
                    </div>
                    :
                    <div className="mx-[10px] sm:mx-[89px] xl:m-auto sm:w-[47%] pb-[40px] sm:min-h-[450px] sm:max-h-[500px] sm:pb-[40px] rounded bg-white">
                      <div className='flex flex-col sm:flex-row-reverse items-center'>
                        <div className='mx-auto w-fit mt-10'>
                          <DownLoadbutton
                            text={"Download Excel Template"}
                            handleOnclick={(e) => {
                              handleDownload(e)
                            }}
                            Mheight={44}
                            Mwidth={270}
                            DHeight={44}
                            DWidth={299}
                            icon={faCircleDown}
                          />
                        </div>
                        <div className="px-[14px] sm:px-[55px] font-medium text-center text-[16px] sm:text-[19px] leading-[24px] pt-[20px] sm:pt-[42px]">
                          Upload Excel Sheet
                        </div>
                      </div>

                      <div className="mt-[10px] sm:mt-[40px] w-full flex justify-center items-center ">
                        {loader ?
                          <div className="flex flex-col justify-center items-center">
                            <div className=""><LoaderComponent /></div>
                            <div className='text-[red]'>Uploading please wait</div>
                          </div> :
                          <img src="/uploadIcon.svg" alt='' />
                        }
                      </div>
                      <div className="mt-[30px] sm:px-[55px] sm:mt-[21px] px-[14px] relative">
                        <input
                          id="file-input"
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={handleFileUpload}
                          className='text-[14px] w-full bg-white border pt-[10px]  pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]'
                        />
                        {selectedFile && (
                          <button
                            onClick={handleRemoveFile}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '10px',
                              transform: 'translateY(-50%)',
                              background: 'none',
                              border: 'none',
                              cursor: 'pointer', color: 'red', fontSize: '30px'
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </button>
                        )}
                        {eExcel && <div className='text-[red]'>{eExcel}</div>}
                      </div>
                      {loader ? <div className="mt-[33px] px-[14px] sm:px-[55px] sm:mt-[40px] flex font-normal justify-center sm:justify-around gap-[16px]">
                        <AbuttonDisable
                          text={"Submit"}
                          Mheight={44}
                          Mwidth={170}
                          DHeight={44}
                          DWidth={299}
                        />
                        <AbuttonDisable
                          text={"Skip"}
                          Mheight={44}
                          Mwidth={170}
                          DHeight={44}
                          DWidth={299}
                        />
                      </div> :
                        <div className="mt-[33px] px-[14px] sm:px-[55px] sm:mt-[40px] flex font-normal justify-center sm:justify-around gap-[16px]">
                          <Abutton
                            text={"Submit"}
                            handleOnclick={(e) => {
                              handleSubmitExcelData(e, "121212")
                            }}
                            Mheight={44}
                            Mwidth={170}
                            DHeight={44}
                            DWidth={299}
                          />
                          <Abutton
                            text={"Skip"}
                            handleOnclick={(e) => {
                              handleSkipClick(e, '22112');
                            }}
                            Mheight={44}
                            Mwidth={170}
                            DHeight={44}
                            DWidth={299}
                          />
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>
          :
          <div className={`${isSidebarOpen ? "ml-[315px]" : "ml-[95px]"} w-full mt-[100px] items-center h-[700px] pt-12`}>
            <div style={{ border: "1px solid #B9B9B9" }} className="px-20 mx-[20px] sm:mx-[89px] h-[700px] xl:m-auto pb-[40px] sm:pb-[40px] drop-shadow-[0px_10px_5px_rgba(0,0,0,0.25)] rounded  bg-white">

              <div
                className={fadeClass}
              >
                {renderStep()}
              </div>              {/* <div className="mt-10 w-full mx-auto flex items-center justify-around">
                {currentStep > 1 && (
                  <button onClick={handlePrev} className="text-center rounded-[10px] p-[20px] text-[28px] font-medium leading-[24px] text-white bg-[#067C4E]">
                    Previous
                  </button>
                )}
                {currentStep < 7 && (
                  <button onClick={handleNext} className="text-center rounded-[10px] p-[20px] text-[28px] font-medium leading-[24px] text-white bg-[#067C4E]">
                    Next
                  </button>
                )}
                {currentStep >= 7 && (
                  <Link to={'/demo'}>
                    <button className="text-center rounded-[10px] p-[20px] text-[28px] font-medium leading-[24px] text-white bg-[#067C4E]">
                      Next
                    </button>
                  </Link>
                )}
              </div> */}
            </div>
          </div>
        }
      </div >
    </div>
  )
}


export default Upload
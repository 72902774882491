import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const YearPicker = ({ handleYearData,placeholderText, date, label }) => {
  return (
    <div className="w-full flex flex-col sm:flex-row pt-10 sm:items-center sm:justify-between">
      <div className="pl-[30px] pb-[18px] sm:pb-0 md:min-w-[220px]">{label}</div>
      <div className="flex justify-start w-full">
        <div className="relative w-full  max-w-[545px] ">
          <DatePicker
            className="w-full org "
            onChange={(date) => handleYearData(date)}
            showYearPicker
            selected={date}
            placeholderText={placeholderText?placeholderText:"Select Year"}
            dateFormat="yyyy"
            yearItemNumber={9}
          />

          <div className="absolute right-[8%] bottom-6 flex items-center ">
            <div>
              <img src="/calenderIcon.svg" className="h-[18px] w-[18px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearPicker;

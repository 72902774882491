import React from "react";
import SolarAnnualEnergyView from "./solar/annual/SolarAnnualEnergyView";
import SolarAnnualCostView from "./solar/annual/SolarAnnualCostView";

const SolarEnergyView = ({ annualData }) => {
  return (
    <div className={`flex flex-col lg:flex-row gap-5`}>
      <SolarAnnualEnergyView annualData={annualData} />
      <SolarAnnualCostView annualData={annualData} />
    </div>
  );
};

export default SolarEnergyView;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { UnitService } from "../../services/unit/UnitService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
  faClone,
  faCogs,
  faDatabase,
  faEdit,
  faFileEdit,
  faGear,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AnalyticsSidebar from "../../components/sidebar/AnalyticsSidebar";
import GoTODashboard from "../../components/button/GoTODashboard";
import { Tooltip } from "react-tooltip";
import CustomToolTipICon from "../../components/tooltips/CustomToolTipICon";
import BackButton from "../../components/backButton/BackButton";

const unitservice = new UnitService();
const UnitList = () => {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [meters, setMeters] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [unitListExcludingselectedUnit, setUnitListExcludingselectedUnit] =
    useState([]);

  const getAllMetersData = async () => {
    const response = await unitservice.getAllUnitsWithBuyerId();
    // console.log({ response });
    setMeters(response);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloneClick = (data) => {
    // console.log({ data });
    setSelectedUnit(data);
    setIsModalOpen(true);
  };

  const getunitListExcludingselectedUnit = () => {
    const unitListExcludingselectedUnit = meters.filter(
      (unit) => unit._id !== selectedUnit._id
    );
    // console.log({ unitListExcludingselectedUnit });
    return unitListExcludingselectedUnit;
  };

  useEffect(() => {
    const units = getunitListExcludingselectedUnit();
    setUnitListExcludingselectedUnit(units);
  }, [selectedUnit]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getAllMetersData();
  }, []);

  const [selectedUnits, setSelectedUnits] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedUnits((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((unitId) => unitId !== value);
      }
    });
  };

  const handleCloneData = async () => {
    // console.log(selectedUnit.settings);
    if (selectedUnit.settings === undefined) {
      toast.info("Selected Unit Has No Settings Data!", {
        position: "top-center",
      });
      return;
    }

    const response = await unitservice.cloneUnitSettingsData(
      selectedUnit,
      selectedUnits
    );
    // console.log({ response });

    if (response) {
      toast.success("Unit Cloned Successfully!", {
        position: "top-center",
      });
    } else {
      toast.success("Unit Clone failed!", {
        position: "top-center",
      });
    }
    closeModal();
  };

  useEffect(() => {
    // console.log({ selectedUnits });
  }, [selectedUnits]);

  function capitalizeWords(str) {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[100px] pb-2">
      <div className="hidden sm:block">
        {/* <AnalyticsSidebar /> */}
        <Sidebar type={2} />
      </div>
      <div
        className={`${
          isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"
        }  sm:px-[50px] px-[15px] transition-width duration-300`}
      >
        <div className={`sm:hidden flex absolute gap-2 right-0 px-[15px] `}>
          <GoTODashboard /> <BackButton  py={"py-1"}  px={"px-1"} />
        </div>
        <div className="flex  w-full items-center justify-between">
          <div className="p-4 mt-[40px] text-[20px] sm:text-[28px] font-medium leading-[42px]">
            Unit List
          </div>
          <div
            className={`hidden sm:flex justify-end sm:justify-end items-center w-full gap-4 sm:w-[160px] `}
          >
            <GoTODashboard /><BackButton smpy={"sm:py-3"} py={"py-3"}  px={"px-4"} />
          </div>

        </div>{" "}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-center  rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs uppercase bg-green-700 text-white ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Organisation
                </th>
                <th scope="col" className="px-6 py-3">
                  Factory
                </th>
                <th scope="col" className="px-6 py-3">
                  Unit
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  existingSolarRooftop
                </th>
 */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {meters.map((unit, index) => (
                <tr
                  className={` border-b text-black ${
                    (index + 1) % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
                  } `}
                  key={unit.id}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  whitespace-nowrap "
                  >
                    {capitalizeWords(unit.organisationId.name)}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  whitespace-nowrap "
                  >
                    {capitalizeWords(unit.factoryId.name)}
                  </th>
                  <td className="px-6 py-4">{capitalizeWords(unit.name)}</td>
                  {/*  <td className="px-6 py-4 uppercase">
                    {unit.existingSolarRooftop}
                  </td> */}

                  <td className="px-6 py-4 flex gap-4 ">
                    <div>
                      <Link to={`/edit-unit/${unit._id}`}>
                        <div>
                          <CustomToolTipICon
                            icon={faEdit}
                            color={"black"}
                            text={"Edit Unit Data"}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="relative group">
                      <Link to={`/update-unit-setting/${unit._id}`}>
                        <div>
                          <CustomToolTipICon
                            icon={faTools}
                            color={"black"}
                            text={"Customise Units"}
                          />
                        </div>
                      </Link>
                      &ensp; &ensp;
                      {/* <div className="absolute bottom-4  overflow-y-auto  hidden z-50 py-2 bg-green-600 px-2 text-white rounded-lg group-hover:block">
                      Customise units
                      </div> */}
                    </div>
                    <div className="relative group">
                      <div onClick={() => handleCloneClick(unit)}>
                        <CustomToolTipICon
                          icon={faClone}
                          color={"black"}
                          text={"Replicate customisation"}
                        />
                      </div>
                      {/* <FontAwesomeIcon
                        icon={faClone}
                        onClick={() => handleCloneClick(unit)}
                      /> */}

                      {/* <div className="absolute bottom-4 right-0  hidden z-50 py-2 bg-green-600 px-2 text-white rounded-lg group-hover:block">
                        clone settings
                      </div> */}
                    </div>
                    {isModalOpen && (
                      <div className="fixed inset-0 flex items-center justify-center ">
                        <div className="w-full fixed opacity-30 z-10 h-screen bg-black"></div>
                        <div className="bg-white z-50 rounded-lg p-8">
                          {/* Modal content */}
                          <h2 className="text-xl font-semibold mb-4">
                          Replicate customisation
                          </h2>
                          <div className="py-2 text-left">
                            selected Unit : {selectedUnit.name}
                          </div>
                          <hr />
                          <div className=" py-2">
                            <h4 className="text-left">
                            Replicate unit settings data to :
                            </h4>
                            {unitListExcludingselectedUnit.map((unit) => (
                              <div className="py-2 pl-4 text-left">
                                <input
                                  type="checkbox"
                                  id={unit._id}
                                  name={unit._id}
                                  value={unit._id}
                                  className="mr-2 py-2"
                                  onChange={handleCheckboxChange}
                                />
                                <label htmlFor={unit.id}>{unit.name}</label>

                                <br />
                              </div>
                            ))}
                          </div>
                          <p className="mb-4"></p>
                          <button
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                            onClick={closeModal}
                          >
                            Close
                          </button>{" "}
                          <button
                            className={`${
                              selectedUnits.length > 0
                                ? "bg-blue-500"
                                : "bg-blue-400"
                            }  text-white px-4 py-2 rounded`}
                            onClick={handleCloneData}
                          >
                            Clone
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UnitList;

import React from "react";
import { useState } from "react";
import SummaryView from "./aggregatedView/SummaryView";
import AccordionItem from "../components/Accordion/AccordionItem";
import YearlyTodView from "../components/yearlyTodView/YearlyTodView";
import AggregatedTodView from "./aggregatedView/AggregatedTodView";
import GoTODashboard from "../components/button/GoTODashboard";
import AnalyticsSidebar from "../components/sidebar/AnalyticsSidebar";
import { useAuth } from "../hooks/useAuth";
import Sidebar from "../components/sidebar/Sidebar";

const AggregatedAndComparisonView = () => {
  const [openAccordion, setOpenAccordion] = useState("Accordion 1");
  const [todToggle, setTodToggle] = useState(true);
  const { isSidebarOpen } = useAuth();
  const handleTodToggle = () => {
    setTodToggle(!todToggle);
  };
  const handleToggle = (title) => {
    setOpenAccordion(title);
  };

  return (
    <>
      <div className="lg:max-w-[1920px] pb-[20px] mt-[58px] bg-[#ebedeb] sm:mt-[70px] items-center m-auto flex">
        <div className="hidden sm:block ">
          {/* <AnalyticsSidebar /> */}
          <Sidebar type={2} />
        </div>
        <div
          className={`sm:pl-[100px] w-full px-[10px] sm:px-[50px]   mt-8 transition-width duration-300`}
        >
          <div className="mx-2 sm:mx-5">
            <div className={`  flex justify-end items-center w-full `}>
              <GoTODashboard />
            </div>

            <dl className="sm:mt-6 mb-[20px]  sm:flex sm:space-x-4">
              <AccordionItem
                className={`bg-white`}
                title="Comparison"
                defaultOpen={openAccordion === "Accordion 1"}
                onToggle={handleToggle}
              >
                <div className="lg:px-[25px]">
                  {" "}
                  <SummaryView />
                </div>
              </AccordionItem>

              {/* <div>
          <button onClick={handleTodToggle}>
            {todToggle ? 'ON' : 'OFF'}
          </button>
        </div> */}

              <AccordionItem
                title={todToggle ? "TOD Energy" : "TOD Cost"}
                defaultOpen={openAccordion === "Accordion 3"}
                onToggle={handleToggle}
                toggleAction={handleTodToggle}
                toggleState={todToggle}
              >
                <div className=" ">
                  <AggregatedTodView todToggle={todToggle} />
                </div>
              </AccordionItem>
            </dl>
          </div>
        </div>
      </div>
    </>



  );
};

export default AggregatedAndComparisonView;

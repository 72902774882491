import React from "react";
import InputBox from "../components/inputbox/InputBox";
import Abutton from "../components/button/Abutton";
import ASelect from "../components/select/ASelect";

const Test = () => {
  return (
    <div>
      <InputBox
        placeholder="Enter Email"
        id="test"
        showError={true}
        label={"Email"}
      />


      <br/>
      <br/>
      <br/>
      <br/>

      <Abutton text={"Login"} handleOnclick={() => {alert("button clicked")} } Mheight={44} Mwidth={170} DHeight={44} DWidth={299}  />


      <br/>
      <br/>
      <br/>
      <br/>
      <ASelect label={"Factory Name"} defaultValue={"Select Factory"} onChange={() => {alert("select clicked")} } options={[{label:"Select Factory",value:"Factory"},{label:"Register",value:"Register"}]}/>
    </div>
  );
};

export default Test;

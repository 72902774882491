import React, { useEffect, useRef, useState } from "react";
import ASelect from "../components/select/ASelect";
import LoaderComponent from "../components/loaderCompent/LoaderComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoTODashboard from "../components/button/GoTODashboard";
import EnergyTabs from "../components/tabs/EnergyTabs";
import SolarEnergyView from "./billConsumption/SolarEnergyView";
import TolalEnergyView from "./billConsumption/TolalEnergyView";
import { BillConsumptionService } from "../services/graphs/billConsumptionService";
import { ConstantUtils } from "../utils/ConstantUtils";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import CostAnnualView from "./annually/CostAnnualView";
import EnenrgyAnnualView from "./annually/EnenrgyAnnualView";
import CostMonthlyView from "./monthly/CostMonthlyView";
import EnergyMonthlyView from "./monthly/EnergyMonthlyView";
import MonthlyTodCostView from "./monthly/MonthlyTodCostView";
import MonthlyTodEnergyView from "./monthly/MonthlyTodEnergyView";
import { GraphUtils } from "../utils/graphUtils";
import CostDistrubutionView from "./costDistrubutionView/CostDistrubutionView";
import { useSearchParams } from "react-router-dom";
import { getData, postData } from "../api/apiDataExchange";
import { MultiSelect } from "react-multi-select-component";

const billService = new BillConsumptionService();
const constantUtils = new ConstantUtils();
const graphUtils = new GraphUtils();

const BillConsumptionTest = () => {
  // graphType Variable
  const [selectedEnergyGraph, setSelectedEnergyGraph] = useState("Annual");
  const [selectedCostGraph, setselectedCostGraph] = useState("Annual");

  const [graphSpecificData, setGraphSpecificData] = useState({
    energy: {
      year: "",
      month: "",
      unit: "",
      meter: "",
    },
    cost: {
      year: "",
      month: "",
      unit: "",
      meter: "",
    },
  });

  // filter variables
  const [years, setyears] = useState(getCurrentAndLastThreeYears());
  const [selectedFilterYear, setSelectedFilterYear] = useState("");
  const [selectedFilterMonth, setSelectedFilterMonth] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedFilterMeter, setSelectedFilterMeter] = useState("");
  const [filterCheckBoxSelected, setFilterCheckBoxSelected] = useState(true);
  // extra  Variable
  const [loading, setLoading] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const [labels, setLabels] = useState(
    Object.keys(constantUtils.getMonthsFromApril())
  );
  const [lableValue, setLableValue] = useState(
    constantUtils.getAllStaticMonthsFromApril()
  );
  const [metersList, setMetersList] = useState([]);
  const [SolarDataPresent, setSolarDataPresent] = useState(true);
  const [unitData, setUnitData] = useState("");
  const [slotnames, setSlotnames] = useState([]);
  const [slotTimelables, setSlotTimelables] = useState([]);
  const [slotColors, setSlotColors] = useState([]);
  // annual graph variables
  const [annualData, setAnnualData] = useState({
    years: [],
    months: [],
    energyData: [],
    costData: [],
  });

  //   monthly variables
  const [monthlyData, setMonthlyData] = useState({
    years: [],
    months: [],
    energyData: [],
    costData: [],
  });
  const [energyMonthlyData, setEnergyMonthlyData] = useState({});
  const [costMonthlyData, setCostMonthlyData] = useState({});
  const [billingDemand, setBillingDemand] = useState([]);
  const [energyBillingDemand, setEnergyBillingDemand] = useState([]);
  const [costBillingDemand, setCostBillingDemand] = useState([]);

  // tod variables
  const [costMonthlyBillingDemand, setCostMonthlyBillingDemand] = useState([]);
  const [energyMonthlyBillingDemand, setEnergyMonthlyBillingDemand] = useState(
    []
  );
  const [selectedCostMeterData, setSelectedCostMeterData] = useState([]);
  const [monthlyTodEnergyData, setMonthlyTodEnergyData] = useState([]);
  const [unitTodLabels, setUnitTodLabels] = useState([]);

  const [slotConfigData, setslotConfigData] = useState("");
  const [org, setOrg] = useState("");
  const [organisationList, setOrganisationList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [meterWRTState, setMeterWRTState] = useState([]);
  const [meterWRTStateIdList, setMeterWRTStateIdList] = useState([]);
  const [stateCode, setStateCode] = useState([]);
  const [selectedStateCode, setSelectedStateCode] = useState([]);

  const [filters, setFilters] = useState({
    // startDate: formatDateToString(startDate),
    // endDate: formatDateToString(endDate),
    // meterId: meter,
    // priceType: priceType,
  });

  useEffect(() => {
    const getdata = async () => {
      const payload = {
        buyerId: localStorage.getItem("userId"),
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        payload
      );

      if (response?.data?.data?.results?.length > 0) {
        setOrganisationList(response?.data?.data?.results);

        if (queryParams["orgId"] && queryParams["stateCode"]) {
          setOrg(queryParams["orgId"]);
        } else {
          setOrg(response?.data?.data?.results[0]?._id);
        }
      }
    };
    getdata();
  }, []);

  const getTreeViewData = async () => {
    if (org !== "") {
      const response = await getData(
        `${process.env.REACT_APP_API_URL}/organisation/tree/${org}`
      );
      const allFactories = response?.data?.factories?.flatMap(
        (factories) => factories || []
      );
      setFactoryList(allFactories);

      const allUnits = allFactories.flatMap((factory) => factory.units);

      setUnitList(allUnits);
      setSelectedUnit(allUnits[0]?._id);

      if (filterCheckBoxSelected) {
        setGraphSpecificData((prevState) => ({
          ...prevState,
          energy: {
            ...prevState.energy,
            unit: allUnits[0]._id,
          },
          cost: {
            ...prevState.cost,
            unit: allUnits[0]._id,
          },
        }));
      } else {
        setGraphSpecificData((prevState) => ({
          ...prevState,
          energy: {
            ...prevState.energy,
            unit: allUnits[0]._id,
          },
        }));
      }

      const unitsData = allUnits
        ?.map((unit) => ({
          name: unit?.name,
          coordinates: unit?.location?.coordinates,
        }))
        .filter(
          (unit) =>
            unit?.coordinates &&
            unit?.coordinates.length === 2 &&
            unit?.coordinates?.every(
              (coord) => coord !== null && coord !== undefined
            )
        );
      setUnitData(unitsData);
      // const extractedInfo = allUnits?.map(unit => unit.state) || [];

      const extractedInfo = Array.from(
        new Map(allUnits.map((unit) => [unit.stateCode, unit.state])).values()
      );

      setStateList(extractedInfo);

      //   console.log({ extractedInfo });

      // Collect all meters directly from the selected organizations
      const allMeters = allFactories.flatMap((factory) =>
        factory.units.flatMap((unit) => unit.meters)
      );

      const allMeterIds = allMeters.map((meter) => meter._id);
      const allStateCodes = extractedInfo.map((stateCode) => {
        return { label: stateCode.name, value: stateCode.code };
      });
      console.log({ allStateCodes });
      const allMetersSelected = allMeters?.map((meter) => ({
        label: meter.consumerNo,
        value: meter._id,
      }));

      // filter by state
      const allMetersWRTState = allUnits.map((e) => {
        return {
          stateCode: e.stateCode,
          meters: e.meters,
        };
      });

      //   const allMeterIdsWRTState = allMetersWRTState[0]?.meters.map(
      //     (meter) => meter._id
      //   );

      let meterIds = [];

      allMetersWRTState.forEach((state) => {
        state.meters.forEach((meter) => {
          meterIds.push(meter._id);
        });
      });

      setStateCode(allStateCodes);

      if (queryParams["orgId"] && queryParams["stateCode"]) {
        let resultArray = [];
        const matchingState = allStateCodes.find(
          (state) => state.value === queryParams["stateCode"]
        );

        if (matchingState) {
          resultArray.push(matchingState);
        }

        setSelectedStateCode(resultArray);
      } else {
        setSelectedStateCode(allStateCodes);
        setMeterWRTState(allMetersWRTState);
        setMeterWRTStateIdList(meterIds);
      }
    }
  };

  useEffect(() => {
    getTreeViewData();
  }, [org]);

  //   ==== as state filter updated update units and meters

  useEffect(() => {
    if (!selectedStateCode) return;

    const allUnits = factoryList.flatMap((factory) => factory.units);

    const stateCodes = selectedStateCode.map((state) => state.value);
    // Filter units that match any of the given state codes
    const unitsOfState = allUnits.filter((unit) =>
      stateCodes.includes(unit.stateCode)
    );
    // const unitsOfState = allUnits?.filter(
    //   (unit) => unit?.stateCode === stateCode
    // );
    setUnitList(unitsOfState);
    setSelectedUnit(unitsOfState[0]?._id);
    if (unitsOfState.length > 0) {
      if (filterCheckBoxSelected) {
        setGraphSpecificData((prevState) => ({
          ...prevState,
          energy: {
            ...prevState.energy,
            unit: unitsOfState[0]._id,
          },
          cost: {
            ...prevState.cost,
            unit: unitsOfState[0]._id,
          },
        }));
      } else {
        setGraphSpecificData((prevState) => ({
          ...prevState,
          energy: {
            ...prevState.energy,
            unit: unitsOfState[0]._id,
          },
        }));
      }

      const metersOfState = unitsOfState?.flatMap((unit) => unit.meters);

      const meterIds = metersOfState.map((meter) => meter._id);

      setMeterWRTStateIdList(meterIds);
    }
  }, [selectedStateCode]);

  // //as unit changes change meters of that unit

  //   useEffect(() => {
  //     console.log({selectedUnit})
  //     const metersOfUnit =  unitList.find(u => u._id === selectedUnit);

  //     console.log({metersOfUnit});
  //     setMetersList(metersOfUnit ? metersOfUnit.meters : []);
  //     if(metersOfUnit &&metersOfUnit.meters && metersOfUnit.meters.length > 0) {
  //         setSelectedFilterMeter( metersOfUnit.meters[0]._id);
  //         setGraphSpecificData((prevState) => ({
  //             ...prevState,
  //             cost: {
  //               ...prevState.cost,
  //               meter: metersOfUnit.meters[0]._id,
  //             },
  //             energy: {
  //               ...prevState.energy,
  //               meter: metersOfUnit.meters[0]._id,
  //             },
  //           }));

  //     }

  //   },[selectedUnit]);

  //   =====query params

  const [searchParams] = useSearchParams();

  const [queryParams, setQueryParams] = useState({
    unitId: searchParams.get("unitId"),
    type: searchParams.get("type"),
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
    orgId: searchParams.get("orgId"),
    stateCode: searchParams.get("stateCode"),
  });

  //   console.log({ entry: searchParams.get("unitId") });
  //   console.log({ entry: searchParams.get("type") });
  //   console.log({ entry: searchParams.get("startDate") });
  //   Annual Data functions

  const fetchGraphData = async () => {
    const data = await billService.GetGraphdata(meterWRTStateIdList);
    //      console.log({ apidata: data });
    if (data !== undefined) {
      setAnnualData(data);
    }
  };

  //   Annual Data useeffects
  useEffect(() => {
    fetchGraphData();
  }, [meterWRTStateIdList]);
  // graph type function
  const handleEnergygraph = (type, selectedYear, selectedMonth) => {
    setSelectedEnergyGraph(type);
  };

  const handleCostGraph = (type, year, month) => {
    setselectedCostGraph(type);
  };
  // montly function
  //   getting unit list
  //   const getUnitListFromApi = async () => {
  //     const getUnitListFromApi = await billService.getAllUnitsByUserId();
  //        console.log({ getUnitListFromApi });
  //     setUnitList(getUnitListFromApi);
  //     if (getUnitListFromApi && getUnitListFromApi.length > 0) {
  //     //   console.log({ length: Object.keys(queryParams).length });
  //     //   console.log({queryParams})
  //       if (queryParams.type === null) {
  //         setSelectedUnit(getUnitListFromApi[0]._id);
  //         if (filterCheckBoxSelected) {
  //             setGraphSpecificData((prevState) => ({
  //               ...prevState,
  //               energy: {
  //                 ...prevState.energy,
  //                 unit: getUnitListFromApi[0]._id,
  //               },
  //               cost: {
  //                 ...prevState.cost,
  //                 unit: getUnitListFromApi[0]._id,
  //               },
  //             }));
  //           } else {
  //             setGraphSpecificData((prevState) => ({
  //               ...prevState,
  //               energy: {
  //                 ...prevState.energy,
  //                 unit: getUnitListFromApi[0]._id,
  //               },
  //             }));
  //           }
  //       }
  //     }
  //   };
  //   useEffect(() => {
  //     getUnitListFromApi();
  //   }, []);

  //   getting query string data

  const handleStateChange = (selectedOptions) => {
    // Set the selected state code only if options are selected
    if (selectedOptions && selectedOptions.length > 0) {
      setSelectedStateCode(selectedOptions);
      setFilters({ ...filters, stateCode: selectedOptions });
    } else {
      setSelectedStateCode(null);
      setFilters({ ...filters, stateCode: null });
    }
  };

  useEffect(() => {
    const updatedQueryParams = {};
    for (let [key, value] of searchParams.entries()) {
      updatedQueryParams[key] = value;
    }
    setQueryParams(updatedQueryParams);
  }, [searchParams]);

  useEffect(() => {
    // console.log({selectedUnit})
  }, [selectedUnit]);

  useEffect(() => {
    // console.log({ queryParams });
    console.log({ first: queryParams["unitId"], second: queryParams["type"] });
    if (
      queryParams["unitId"] &&
      queryParams["type"] &&
      queryParams["startDate"] &&
      Object.keys(queryParams).length > 0
    ) {
      // setSelectedUnit(queryParams["unitId"]);
      // setSelectedEnergyGraph("Monthly");
      // setselectedCostGraph("Monthly")
      let date = new Date(queryParams["startDate"]);

      // Get the year from the Date object
      let year = date.getFullYear();

      handleEnergygraph("Monthly");
      handleCostGraph("Monthly");
      setTimeout(() => {
        setSelectedFilterYear(year);
        setSelectedUnit(queryParams["unitId"]);
        setGraphSpecificData((prevState) => ({
          ...prevState,
          energy: {
            ...prevState.energy,
            year: year,
            unit: queryParams["unitId"],
          },
          cost: {
            ...prevState.cost,
            year: year,
            unit: queryParams["unitId"],
          },
        }));
      }, 1000);
    }
  }, [queryParams]);
  //   getting selected unit data

  const getUnitData = (id) => {
    const unitData = unitList.filter((unit) => unit._id === id);

    //    console.log({ unitData });
    setUnitData(unitData);
    return unitData;
  };
  useEffect(() => {
    if (selectedUnit !== "" && selectedFilterYear !== "") {
      const unitData = getUnitData(selectedUnit);
      const slotsData = setSLotData(unitData);
      //   console.log({ slotsData });
      getBiilingDemand(selectedFilterYear, selectedUnit);
      if (
        slotsData &&
        slotsData.getSlotNames !== undefined &&
        slotsData.slotColorsData !== undefined
      ) {
        fetchMonthLyGraphData(
          selectedFilterYear,
          slotsData.getSlotNames,
          selectedUnit,
          slotsData.slotColorsData,
          slotsData.allSlotTimeData
        );
      } else {
        if (filterCheckBoxSelected) {
          setEnergyMonthlyData([]);
          setCostMonthlyData([]);
        } else {
          setEnergyMonthlyData([]);
        }
      }
    }
  }, [selectedUnit, selectedFilterYear]);

  const setSLotData = (selectdUnitData) => {
    // console.log({ selectdUnitData });
    if (
      selectdUnitData !== "" &&
      selectdUnitData !== undefined &&
      selectdUnitData.length > 0 &&
      selectdUnitData[0]["stateCode"] !== undefined &&
      selectdUnitData[0]["discomCode"] !== undefined
    ) {
      //   console.log({ stateCode: selectdUnitData[0]["stateCode"] });
      const configData = JSON.parse(localStorage.getItem("config"));
      const statecode = selectdUnitData[0]["stateCode"];
      const disComCode = selectdUnitData[0]["discomCode"];
      //      console.log({ configData: configData });
      //      console.log({ statecode });
      //      console.log({ disComCode });
      const unitId = selectdUnitData[0]["_id"];
      if (
        configData &&
        configData.slotConfig &&
        configData.slotConfig[statecode] &&
        configData.slotConfig[statecode][disComCode] &&
        configData.slotConfig[statecode][disComCode]["slotName"] !==
          undefined &&
        configData.slotConfig[statecode][disComCode]["slotColor"] !== undefined
      ) {
        const getSlotNames = Object.keys(
          configData.slotConfig[statecode][disComCode]["slotName"]
        );
        const getSlotTimeLabels =
          configData.slotConfig[statecode][disComCode]["slotTimeLabels"];

        const allSlotTimeData = configData.slotConfig[statecode][disComCode];
        setslotConfigData(allSlotTimeData);
        //        console.log({ getSlotNames });
        //        console.log({unitId})
        const unitIdSlotNamesExists = slotnames.some((slot) => unitId in slot);
        //        console.log({unitIdExists})
        if (!unitIdSlotNamesExists) {
          setSlotnames((prevData) => [...prevData, { [unitId]: getSlotNames }]);
        }

        const unitIdSlotTimeLabelsExists = slotTimelables.some(
          (slot) => unitId in slot
        );
        if (!unitIdSlotTimeLabelsExists) {
          setSlotTimelables((prevData) => [
            ...prevData,
            { [unitId]: getSlotTimeLabels },
          ]);
        }

        const slotColorsData =
          configData.slotConfig[statecode][disComCode]["slotColor"];
        //        console.log({ slotColorsData });
        const unitIdSlotColorsExists = slotColors.some(
          (slot) => unitId in slot
        );
        if (!unitIdSlotColorsExists) {
          setSlotColors((prevData) => [
            ...prevData,
            { [unitId]: slotColorsData },
          ]);
        }

        return {
          getSlotNames,
          getSlotTimeLabels,
          slotColorsData,
          allSlotTimeData,
        };
      } else {
        const getSlotNames = Object.keys(
          configData.slotConfig["COMMERCIAL"]["slotName"]
        );

        const getSlotTimeLabels =
          configData.slotConfig["COMMERCIAL"]["slotTimeLabels"];

        const allSlotTimeData = configData.slotConfig["COMMERCIAL"];
        setslotConfigData(allSlotTimeData);

        const unitIdSlotNamesExists = slotnames.some((slot) => unitId in slot);
        if (!unitIdSlotNamesExists) {
          setSlotnames((prevData) => [...prevData, { [unitId]: getSlotNames }]);
        }

        const unitIdSlotTimeLabelsExists = slotTimelables.some(
          (slot) => unitId in slot
        );
        if (!unitIdSlotTimeLabelsExists) {
          setSlotTimelables((prevData) => [
            ...prevData,
            { [unitId]: getSlotTimeLabels },
          ]);
        }

        const slotColorsData = configData.slotConfig["COMMERCIAL"]["slotColor"];
        const unitIdSlotColorsExists = slotColors.some(
          (slot) => unitId in slot
        );

        if (!unitIdSlotColorsExists) {
          setSlotColors((prevData) => [
            ...prevData,
            { [unitId]: slotColorsData },
          ]);
        }

        return {
          getSlotNames,
          getSlotTimeLabels,
          slotColorsData,
          allSlotTimeData,
        };
      }
    } else {
      setSlotColors(undefined);
    }
  };

  const fetchMonthLyGraphData = async (
    year,
    slotnames,
    unit,
    slotcolors,
    allSlotTimeData
  ) => {
    //    console.log({ year });
    //console.log({existingUnitData})
    if (slotnames && slotcolors !== undefined) {
      const data = await billService.getMonthlyConsumptionTodSlot(
        year.toString(),
        slotnames,
        unit,
        slotcolors,
        allSlotTimeData
      );
      //   console.log({ selectedEnergyYearData: data });
      if (data !== undefined) {
        setMonthlyData(data);
        if (filterCheckBoxSelected) {
          setEnergyMonthlyData(data);
          setCostMonthlyData(data);
        } else {
          setEnergyMonthlyData(data);
        }
      }
    }
  };

  const getBiilingDemand = async (year, unit) => {
    const billingData = await billService.getBilledDemand(
      year.toString(),
      unit
    );
    // console.log({ billingData });
    if (billingData !== undefined) {
      setBillingDemand(billingData);
      if (filterCheckBoxSelected) {
        setEnergyBillingDemand(billingData);
        setCostBillingDemand(billingData);
      } else {
        setEnergyBillingDemand(billingData);
      }
    } else {
      if (filterCheckBoxSelected) {
        setEnergyBillingDemand([]);
        setCostBillingDemand([]);
      } else {
        setEnergyBillingDemand([]);
      }
    }
  };
  //   tod functions

  const getMetersList = async () => {
    const data = await billService.getMetersListWithUserNameAndOrganisationName(
      selectedUnit
    );
    console.log({ getMetersListWithUserNameAndOrganisationName: data });
    if (data && data !== undefined && data.length > 0) {
      setMetersList(data);
      setSelectedFilterMeter(data[0]._id);
      setGraphSpecificData((prevState) => ({
        ...prevState,
        cost: {
          ...prevState.cost,
          meter: data[0]._id,
        },
        energy: {
          ...prevState.energy,
          meter: data[0]._id,
        },
      }));
    }
  };

  useEffect(() => {
    getMetersList();
  }, [selectedUnit]);

  const getTodData = async (
    year,
    month,
    meter,
    slotnamesParams,
    slotColorsParams
  ) => {
    console.log({ year, month, meter });
    if (year && month && meter !== "") {
      const data = await billService.getSpecificMonthTodData(
        year.toString(),
        month.toString().toLowerCase(),
        meter,
        slotnamesParams,
        slotColorsParams,
        slotConfigData
      );
      // console.log({ getTodData: data });
      if (data && data !== undefined) {
        // console.log({ selectedMeterData: data });

        if (filterCheckBoxSelected) {
          setMonthlyTodEnergyData(data);
          setSelectedCostMeterData(data);
        } else {
          setMonthlyTodEnergyData(data);
        }
      } else {
        setMonthlyTodEnergyData([]);
        setSelectedCostMeterData([]);
      }
    }
  };

  useEffect(() => {
    console.log("getting tod data");
    if (selectedFilterYear !== "" && selectedFilterMonth !== "") {
      getMonthlyBiilingDemand(
        selectedFilterYear,
        selectedFilterMonth,
        selectedFilterMeter
      );
      const selectedMeterData = metersList.filter(
        (meter) => meter._id === selectedFilterMeter
      );
      if (
        selectedEnergyGraph === "TOD" &&
        selectedMeterData[0]?.unitId?._id !== undefined
      ) {
        const selectedmeterUnit = selectedMeterData[0]?.unitId?._id;

        const unitData = getUnitData(selectedmeterUnit);
        setSelectedUnit(selectedmeterUnit);
        const slotsData = setSLotData(unitData);
        // console.log({ slotsData });
        if (
          slotsData &&
          slotsData.getSlotNames !== undefined &&
          slotsData.slotColorsData !== undefined
        ) {
          //   console.log({
          //     selectedmeterUnit,
          //     unitData,
          //     selectedUnit,
          //     selectedFilterMeter,
          //   });

          getTodData(
            selectedFilterYear,
            selectedFilterMonth,
            selectedFilterMeter,
            slotsData.getSlotNames,
            slotsData.slotColorsData
          );
          const todLabels = [];
          slotsData["getSlotNames"].forEach((element) => {
            todLabels.push(
              graphUtils.fomatSlotNameWithTimeLabels(
                element,
                slotsData.getSlotTimeLabels,
                slotsData.allSlotTimeData
              )
            );
          });
          setUnitTodLabels(todLabels);

          // getMonthlyBiilingDemand(
          //   selectedFilterYear,
          //   selectedFilterMonth,
          //   selectedFilterMeter,
          // );

          // console.log({ selectedMeterData });
          // getTodData(
          //   selectedEnergyYear,
          //   selectedEnergyMonth,
          //   selectedEnergyMeter,
          //   "energy",
          //   selectedmeterUnit
          // );
          // getMonthlyBiilingDemand(
          //   selectedEnergyYear,
          //   selectedEnergyMonth,
          //   "energy",
          //   selectedEnergyMeter
          // );
        } else {
          if (filterCheckBoxSelected) {
            setMonthlyTodEnergyData([]);
            setSelectedCostMeterData([]);
            setEnergyMonthlyBillingDemand([]);
            setCostMonthlyBillingDemand([]);
          } else {
            setMonthlyTodEnergyData([]);
            setEnergyMonthlyBillingDemand([]);
          }
        }
      }
    }
  }, [
    selectedFilterYear,
    selectedFilterMonth,
    selectedFilterMeter,
    selectedEnergyGraph,
    selectedCostGraph,
  ]);

  //   filter functions

  function getCurrentAndLastThreeYears() {
    const currentYear = new Date().getFullYear();
    return [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
  }

  const handleYearFilter = (e) => {
    toggleRef.current = 0;
    setSelectedFilterYear(e.target.value);

    setLoading(false);
    if (filterCheckBoxSelected) {
      if (selectedEnergyGraph === "Annual") {
        handleEnergygraph("Monthly", e.target.value);
        handleCostGraph("Monthly", e.target.value);
      }
      setGraphSpecificData((prevState) => ({
        ...prevState,
        energy: {
          ...prevState.energy,
          year: e.target.value,
        },
        cost: {
          ...prevState.cost,
          year: e.target.value,
        },
      }));
    } else {
      if (selectedEnergyGraph === "Annual") {
        handleEnergygraph("Monthly", e.target.value);
      }
      setGraphSpecificData((prevState) => ({
        ...prevState,
        energy: {
          ...prevState.energy,
          year: e.target.value,
        },
      }));
    }
  };

  let nextYearMonths = ["JAN", "FEB", "MAR"];

  const toggleRef = useRef(0);
  useEffect(() => {
    if (
      nextYearMonths.includes(selectedFilterMonth) &&
      toggleRef.current === 0
    ) {
      setSelectedFilterYear((prev) => (parseInt(prev) + 1).toString());
      toggleRef.current = 1;
    } else if (
      !nextYearMonths.includes(selectedFilterMonth) &&
      toggleRef.current === 1
    ) {
      setSelectedFilterYear((prev) => (parseInt(prev) - 1).toString());
      toggleRef.current = 0;
    }
  }, [selectedFilterMonth, selectedFilterYear]);

  const handleMonthFilter = (e) => {
    setSelectedFilterMonth(e.target.value);
    setLoading(false);
    if (filterCheckBoxSelected) {
      if (selectedEnergyGraph === "Monthly") {
        handleEnergygraph("TOD");
        handleCostGraph("TOD");
      }
      setGraphSpecificData((prevState) => ({
        ...prevState,
        energy: {
          ...prevState.energy,
          month: e.target.value,
        },
        cost: {
          ...prevState.cost,
          month: e.target.value,
        },
      }));
    } else {
      if (selectedEnergyGraph === "Monthly") {
        handleEnergygraph("TOD");
      }
      setGraphSpecificData((prevState) => ({
        ...prevState,
        energy: {
          ...prevState.energy,
          month: e.target.value,
        },
      }));
    }
  };

  const handleMeterFilter = (e) => {
    setSelectedFilterMeter(e.target.value);
    if (filterCheckBoxSelected) {
      setGraphSpecificData((prevState) => ({
        ...prevState,
        cost: {
          ...prevState.cost,
          meter: e.target.value,
        },
        energy: {
          ...prevState.energy,
          meter: e.target.value,
        },
      }));
    } else {
      setGraphSpecificData((prevState) => ({
        ...prevState,
        energy: {
          ...prevState.energy,
          meter: e.target.value,
        },
      }));
    }
  };

  const handleBackButton = () => {
    if (selectedEnergyGraph === "Monthly") {
      setSelectedFilterYear("");
      if (filterCheckBoxSelected) {
        handleEnergygraph("Annual");
        handleCostGraph("Annual");
      } else {
        handleEnergygraph("Annual");
      }
    } else if (selectedEnergyGraph === "TOD" && selectedCostGraph === "TOD") {
      setSelectedFilterMonth("");
      if (filterCheckBoxSelected) {
        handleCostGraph("Monthly");

        setTimeout(() => {
          handleEnergygraph("Monthly");
        }, 400);
        setGraphSpecificData((prevState) => ({
          ...prevState,
          cost: {
            ...prevState.cost,
            year: selectedFilterYear,
            unit: selectedUnit,
          },
          energy: {
            ...prevState.energy,
            year: selectedFilterYear,
            unit: selectedUnit,
          },
        }));
      } else {
        setTimeout(() => {
          handleEnergygraph("Monthly");
          setGraphSpecificData((prevState) => ({
            ...prevState,
            energy: {
              ...prevState.energy,
              year: selectedFilterYear,
              unit: selectedUnit,
            },
          }));
        }, 400);
      }
    } else if (selectedCostGraph === "BillDistrubutionView") {
      handleEnergygraph("TOD");
      setTimeout(() => {
        handleCostGraph("TOD");
      }, 400);

      // setGraphSpecificData((prevState) => ({
      //     ...prevState,
      //     cost: {
      //       ...prevState.cost,
      //       year: selectedFilterYear,
      //       month:selectedFilterMonth,
      //       unit:selectedUnit,
      //       meter:selectedFilterMeter
      //     },
      //     energy: {
      //         ...prevState.energy,
      //         year: selectedFilterYear,
      //         month:selectedFilterMonth,
      //         unit:selectedUnit,
      //         meter:selectedFilterMeter

      //       },
      //   }));
      //   getMonthlyBiilingDemand(selectedFilterYear,selectedFilterMonth,selectedFilterMeter);
    }
  };

  const getMonthlyBiilingDemand = async (year, month, meter) => {
    // console.log({ year, month, meter });
    if (year !== undefined && month !== undefined && meter !== undefined) {
      const NewMonthlybillingData = await billService.getMonthlyBilledDemand(
        year.toString(),
        month,
        meter
      );

      if (NewMonthlybillingData) {
        if (filterCheckBoxSelected) {
          setEnergyMonthlyBillingDemand(NewMonthlybillingData);
          setCostMonthlyBillingDemand(NewMonthlybillingData);
        } else {
          setEnergyMonthlyBillingDemand(NewMonthlybillingData);
        }
      } else {
        if (filterCheckBoxSelected) {
          setEnergyMonthlyBillingDemand([]);
          setCostMonthlyBillingDemand([]);
        } else {
          setEnergyMonthlyBillingDemand([]);
        }
      }
    } else {
      setEnergyMonthlyBillingDemand([]);
      setCostMonthlyBillingDemand([]);
    }
  };

  //   handle checkbox
  useEffect(() => {
    if (selectedEnergyGraph === "Annual") {
      setselectedCostGraph("Annual");
    } else if (selectedEnergyGraph === "Monthly") {
      handleCostGraph("Monthly");
      setGraphSpecificData((prevState) => ({
        ...prevState,
        cost: {
          ...prevState.cost,
          year: graphSpecificData.energy.year,
          unit: graphSpecificData.energy.unit,
        },
      }));
      setCostMonthlyData(energyMonthlyData);
      setCostBillingDemand(energyBillingDemand);
    } else if (selectedEnergyGraph === "TOD") {
      handleCostGraph("TOD");

      setGraphSpecificData((prevState) => ({
        ...prevState,
        cost: {
          ...prevState.cost,
          year: graphSpecificData.energy.year,
          unit: graphSpecificData.energy.unit,
          meter: graphSpecificData.energy.meter,
          month: graphSpecificData.energy.month,
        },
      }));
      setSelectedCostMeterData(monthlyTodEnergyData);
      //   console.log({energyMonthlyBillingDemand})
      setTimeout(() => {
        setCostMonthlyBillingDemand(energyMonthlyBillingDemand);
      }, 500);
    }
  }, [filterCheckBoxSelected]);

  function containsNonZero(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        if (arr[i][j] !== 0) {
          return true; // Found a non-zero value
        }
      }
    }
    return false; // All values are 0
  }

  useEffect(() => {
    if (annualData.solarEnergyData !== undefined) {
      const result = containsNonZero(annualData.solarEnergyData);
      setSolarDataPresent(result);
    }
  }, [annualData]);

  useEffect(() => {
    // console.log({slotConfigData})
  }, [slotConfigData]);

  const handleOrgChange = (selectedOrg) => {
    setOrg(selectedOrg);
    if (selectedOrg) {
      setFilters({ ...filters, organisationId: selectedOrg });
    }
  };

  const renderEnergyTabContent = () => {
    if (selectedCostGraph !== "BillDistrubutionView") {
      switch (selectedEnergyGraph) {
        case "Annual":
          return (
            <EnenrgyAnnualView
              annualData={annualData}
              selectedEnergyGraph={selectedEnergyGraph}
              handleEnergygraph={handleEnergygraph}
              slotConfigData={slotConfigData}
            />
          );
        case "Monthly":
          return (
            <EnergyMonthlyView
              selectedUnit={graphSpecificData.energy.unit}
              energyMonthlyData={energyMonthlyData}
              selectedEnergyYear={graphSpecificData.energy.year}
              energyBillingDemand={energyBillingDemand}
              selectedEnergyGraph={selectedEnergyGraph}
              handleEnergygraph={handleEnergygraph}
              slotConfigData={slotConfigData}
            />
          );
        case "TOD":
          return (
            <MonthlyTodEnergyView
              energyMonthlyBillingDemand={energyMonthlyBillingDemand}
              monthlyTodEnergyData={monthlyTodEnergyData}
              selectedEnergyYear={graphSpecificData.energy.year}
              metersList={metersList}
              annualData={annualData}
              selectedEnergyMeter={graphSpecificData.energy.meter}
              selectedEnergyMonth={graphSpecificData.energy.month}
              selectedEnergyGraph={selectedEnergyGraph}
              handleEnergygraph={handleEnergygraph}
              unitTodLabels={unitTodLabels}
              slotConfigData={slotConfigData}
            />
          );
        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  };

  const renderCostTabContent = () => {
    switch (selectedCostGraph) {
      case "Annual":
        return (
          <CostAnnualView
            annualData={annualData}
            selectedCostGraph={selectedCostGraph}
            handleCostGraph={handleCostGraph}
          />
        );
      case "Monthly":
        return (
          <CostMonthlyView
            costBillingDemand={costBillingDemand}
            selectedUnit={graphSpecificData.cost.unit}
            costMonthlyData={costMonthlyData}
            selectedCostYear={graphSpecificData.cost.year}
            selectedCostMonth={selectedFilterMonth}
            selectedCostGraph={selectedCostGraph}
            handleCostGraph={handleCostGraph}
            slotConfigData={slotConfigData}
          />
        );
      case "TOD":
        return (
          <MonthlyTodCostView
            costMonthlyBillingDemand={costMonthlyBillingDemand}
            selectedCostMeterData={selectedCostMeterData}
            selectedCostYear={graphSpecificData.cost.year}
            metersList={metersList}
            annualData={annualData}
            selectedCostMeter={graphSpecificData.cost.meter}
            selectedUnit={graphSpecificData.cost.unit}
            selectedCostMonth={graphSpecificData.cost.month}
            selectedCostGraph={selectedCostGraph}
            handleCostGraph={handleCostGraph}
            slotnames={slotnames}
            slotTimelables={slotTimelables}
            unitTodLabels={unitTodLabels}
          />
        );

      case "BillDistrubutionView":
        return (
          <CostDistrubutionView
            selectedEnergyMeter={selectedFilterMeter}
            selectedCostGraph={selectedCostGraph}
            handleCostGraph={handleCostGraph}
            selectedCostYear={selectedFilterYear}
            selectedCostMonth={selectedFilterMonth}
            slotConfigData={slotConfigData}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <div
      className={`mt-[80px] md:mt-[20px] flex  flex-col  justify-between sm:gap-[20px] `}
    >
      <div className="flex  sm:flex-row flex-col-reverse justify-end gap-2 py-2 md:py-0 md:gap-10 items-center">
        <div className="bg-white border border-gray-200  min-h-[55px] w-full  shadow-lg py-2 items-center grid grid-cols-2 md:flex  sm:flex-row gap-5 px-8 rounded-lg">
          <div className="md:min-w-[80px]">Filters</div>
          <div></div>
          <div
            className={`${
              (loading && selectedEnergyGraph === "Monthly") ||
              (loading && selectedEnergyGraph === "Annual") ||
              (loading && selectedEnergyGraph === "TOD")
                ? "hidden"
                : ""
            }`}
          >
            <ASelect
              label={"organisation"}
              height={"45px"}
              maxWidth="200px"
              IconBottom={"bottom-4"}
              options={organisationList?.map((organisation) => {
                return {
                  label: organisation?.name,
                  value: organisation?._id,
                };
              })}
              //   onChange={handleYearFilter}
              onChange={(e) => {
                handleOrgChange(e.target.value);
              }}
              value={org}
            />
          </div>

          <div
            className={`${
              (loading && selectedEnergyGraph === "Monthly") ||
              (loading && selectedEnergyGraph === "Annual") ||
              (loading && selectedEnergyGraph === "TOD")
                ? "hidden"
                : ""
            }`}
          >
            <MultiSelect
              labelledBy="Select"
              height={"45px"}
              IconBottom={"bottom-4"}
              disableSearch={true}
              className="custom-multi-select max-w-[200px]"
              options={[
                ...(stateList?.map((state) => ({
                  label: state?.name,
                  value: state?.code,
                })) || []),
              ]}
              onChange={(selectedOptions) => {
                handleStateChange(selectedOptions);
              }}
              value={selectedStateCode ?? []}
            />
          </div>
          <div
            className={`${
              selectedEnergyGraph === "Monthly" || selectedEnergyGraph === "TOD"
                ? "block"
                : "hidden"
            } max-w-[180px]`}
          >
            <ASelect
              label={"Unit"}
              height={"45px"}
              IconBottom={"bottom-4"}
              options={unitList?.map((unit) => {
                return {
                  label: `${unit.name}  (${unit.stateCode})`,
                  value: unit._id,
                };
              })}
              onChange={(e) => {
                setSelectedUnit(e.target.value);
                if (filterCheckBoxSelected) {
                  setGraphSpecificData((prevState) => ({
                    ...prevState,
                    energy: {
                      ...prevState.energy,
                      unit: e.target.value,
                    },
                    cost: {
                      ...prevState.cost,
                      unit: e.target.value,
                    },
                  }));
                } else {
                  setGraphSpecificData((prevState) => ({
                    ...prevState,
                    energy: {
                      ...prevState.energy,
                      unit: e.target.value,
                    },
                  }));
                }
              }}
              value={selectedUnit}
            />
          </div>
          <div
            className={`${
              (selectedEnergyGraph === "TOD" && !loading) ||
              (selectedCostGraph === "BillDistrubutionView" && !loading)
                ? "block"
                : "hidden"
            }`}
          >
            {" "}
            <ASelect
              label={"Consumer No"}
              height={"45px"}
              IconBottom={"bottom-4"}
              options={metersList?.map((meter) => {
                console.log({ meter });
                return {
                  label: `${meter.consumerNo} - ${meter.unitId.name}`,
                  value: meter._id,
                };
              })}
              onChange={handleMeterFilter}
              value={selectedFilterMeter}
            />
          </div>
          <div
            className={`${
              (loading && selectedEnergyGraph === "Monthly") ||
              (loading && selectedEnergyGraph === "Annual") ||
              (loading && selectedEnergyGraph === "TOD")
                ? "hidden"
                : ""
            }`}
          >
            <ASelect
              label={"year"}
              height={"45px"}
              IconBottom={"bottom-4"}
              options={years?.map((year) => {
                return {
                  label: billService.getFiscalYearLabel(year),
                  value: year,
                };
              })}
              onChange={handleYearFilter}
              value={
                toggleRef.current ? selectedFilterYear - 1 : selectedFilterYear
              }
            />
          </div>

          <div
            className={`${
              (selectedCostGraph === "Monthly" ||
                selectedEnergyGraph === "Monthly" ||
                selectedCostGraph === "TOD" ||
                selectedEnergyGraph === "TOD") &&
              !loading &&
              (selectedEnergyGraph === "Monthly" ||
                selectedEnergyGraph === "TOD")
                ? "block"
                : "hidden"
            }`}
          >
            <ASelect
              label={"month"}
              height={"45px"}
              IconBottom={"bottom-4"}
              options={labels?.map((month) => {
                return { label: month, value: month };
              })}
              onChange={handleMonthFilter}
              value={selectedFilterMonth}
            />
          </div>

          <div
            className={`${
              selectedCostGraph === "BillDistrubutionView" ||
              (loading && selectedEnergyGraph === "Monthly") ||
              (loading && selectedEnergyGraph === "Annual") ||
              (loading && selectedEnergyGraph === "TOD")
                ? "hidden"
                : ""
            } flex items-center gap-1 `}
          >
            <div>
              <input
                type="checkbox"
                onChange={(e) => setFilterCheckBoxSelected(e.target.checked)}
                className="w-[25px] h-[25px] mt-2"
                checked={filterCheckBoxSelected}
              />
            </div>
            <div className={` text-gray-400 text-sm capitalize `}>
              Apply To Both
            </div>
          </div>

          <div
            className={`   ${
              (loading &&
                selectedCostGraph === "Monthly" &&
                selectedEnergyGraph === "Monthly") ||
              (loading &&
                selectedCostGraph === "TOD" &&
                selectedEnergyGraph === "TOD") ||
              (loading &&
                selectedCostGraph === "Annual" &&
                selectedEnergyGraph === "Annual") ||
              (loading && selectedCostGraph === "BillDistrubutionView")
                ? ""
                : "hidden"
            }`}
          >
            <LoaderComponent width={25} height={25} />
          </div>
        </div>
        <div className="sm:hidden gap-2 flex w-full justify-end text:xs">
          {" "}
          <GoTODashboard />{" "}
          <div
            onClick={handleBackButton}
            className={`${
              selectedEnergyGraph === "Annual" ? "hidden" : ""
            }  bg-white border text-xs md:text-md  justify-end border-gray-200 md:shadow-lg px-1  py-1 md:px-8 md:py-4 flex items-center gap-2 cursor-pointer rounded-lg `}
          >
            Back
            <FontAwesomeIcon icon={faBackward} size={"xs"} />
          </div>
        </div>
        <div className="  sm:relative mt-2 right-[5%] md:right-0 md:mt-0 hidden  sm:flex  w-full md:w-auto justify-end">
          {" "}
          <div
            onClick={handleBackButton}
            className={`${
              selectedEnergyGraph === "Annual" ? "hidden" : ""
            }  bg-white border text-xs md:text-md  justify-end border-gray-200 md:shadow-lg px-1  py-1 md:px-8 md:py-4 flex items-center gap-2 cursor-pointer rounded-lg `}
          >
            Back
            <FontAwesomeIcon icon={faBackward} size={"xs"} />
          </div>
        </div>
        <div
          className={`hidden  sm:flex justify-end sm:justify-center items-center w-full sm:w-[100px] `}
        >
          <GoTODashboard />
        </div>
      </div>{" "}
      <div className="flex flex-col lg:flex-row gap-5">
        {/*<EnergyTabs
          selectedEnergyGraph={selectedEnergyGraph}
          annualData={annualData}
          unitList={unitList}
          slotTimelables={slotTimelables}
          handleEnergygraph={handleEnergygraph}
          energyBillingDemand={energyBillingDemand}
          selectedUnit={selectedUnit}
          energyMonthlyData={energyMonthlyData}
          selectedEnergyYear={selectedEnergyYear}
          getTodData={getTodData}
          slotnames={slotnames}
          energyMonthlyBillingDemand={energyMonthlyBillingDemand}
          monthlyTodEnergyData={monthlyTodEnergyData}
          selectedEnergyMonth={selectedEnergyMonth}
          metersList={metersList}
          selectedEnergyMeter={selectedEnergyMeter}
          setSelectedEnergyMeter={setSelectedEnergyMeter}
          selectedCostGraph={selectedCostGraph}
        /> */}
        {renderEnergyTabContent()}
        {renderCostTabContent()}
      </div>
      <div
        className={`${
          selectedEnergyGraph === "Annual" && SolarDataPresent
            ? "block"
            : "hidden"
        }     `}
      >
        <SolarEnergyView annualData={annualData} />
      </div>
      <div
        className={`${
          selectedEnergyGraph === "Annual" && SolarDataPresent
            ? "block"
            : "hidden"
        }`}
      >
        <TolalEnergyView annualData={annualData} />{" "}
      </div>
    </div>
  );
};

export default BillConsumptionTest;

import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;


export const getPowerConsumptionList = async (filterParams) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user?.result?.token;
        const response = await axios.post(`${apiUrl}/powerConsumtion/list`, filterParams, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
        })
        return response?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}
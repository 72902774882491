import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { textvalidation } from '../../services/validations/textvalidation';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Abutton from "../../components/button/Abutton";
import ASelect from "../../components/select/ASelect";
import ASelectv1 from "../../components/select/ASelectV1";

import YearPicker from "../../components/yearPicker/YearPicker";
import ViewBox from '../../components/viewBox/ViewBox';
import { getData, updateData } from '../../api/apiDataExchange';
import { useAuth } from '../../hooks/useAuth';
const ViewOrganisation = () => {

    const { id } = useParams();
    const { setIsSidebarOpen, isSidebarOpen } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const organisation = await getData(`${process.env.REACT_APP_API_URL}/organisation/${id}`);
            if (organisation.result.name) {
                setName(organisation.result.name);
            }
            if (organisation.result.organisationType) {
                setType(organisation.result.organisationType);
            }
            if (organisation.result.isitListed) {
                setIsItListed(organisation.result.isitListed);
            }
            if (organisation.result.organisationType) {
                setType(organisation.result.organisationType);
            }
            if (organisation.result.stock) {
                setStock(organisation.result.stock);
            }
            if (organisation.result.CIN) {
                setCIN(organisation.result.CIN);
            }
            if (organisation.result.PAN) {
                setPAN(organisation.result.PAN);
            }
            if (organisation.result.TAN) {
                setTAN(organisation.result.TAN);
            }
            if (organisation.result.GST) {
                setGST(organisation.result.GST);
            }
            if (organisation.result.carbonGoal) {
                setCarbonGoal(organisation.result.carbonGoal);
            }
            if (organisation.result.creditRating?.agencyName) {
                setagencyName(organisation.result.creditRating?.agencyName);
            }
            if (organisation.result.creditRating?.rating) {
                setrating(organisation.result.creditRating?.rating);
            }
            if (organisation.result.BRSRRequirements) {
                setBssrYear(organisation.result.BRSRRequirements);
            }
            if (organisation.result.carbonFootprintingExercise) {
                setCarbonFootprintYear(organisation.result.carbonFootprintingExercise);
            }
            if (organisation.result.location?.coordinates) {
                setLatitude(organisation.result.location?.coordinates[0]);
            }
            if (organisation.result.location?.coordinates) {
                setLongitude(organisation.result.location?.coordinates[1]);
            }
        };
        fetchData().catch(console.error);
    }, [id]);
    const [name, setName] = useState("");

    const [type, setType] = useState("");

    const [isItListed, setIsItListed] = useState({
        field: "isItListed",
        data: "",
        isChanged: false,
    });

    const [stock, setStock] = useState("");

    const [CIN, setCIN] = useState("");

    const [PAN, setPAN] = useState("");

    const [TAN, setTAN] = useState("");

    const [GST, setGST] = useState("");



    const [carbonGoal, setCarbonGoal] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");


    const [agencyName, setagencyName] = useState("");
    const [rating, setrating] = useState("");

    const [errors, setErrors] = useState([]);

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);


    const [bssrYear, setBssrYear] = useState();
    const [carbonFootprintYear, setCarbonFootprintYear] = useState();

    const handleBrsrYear = (date) => {
        setBssrYear(date);
    };

    const handleCarbonFootprintYear = (date) => {
        setCarbonFootprintYear(date);
    };
    
    return (
        <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[170px] pb-20">
            <div className='hidden sm:block'>
                <Sidebar />
            </div>
            <div className={`${isSidebarOpen?'sm:ml-[300px]':'sm:ml-[80px]'}`}>
                <div className=" rounded-lg bg-[#E2F5EC]  w-full flex flex-col pb-[50px] shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">
                    <div className="text-[30px] py-8 text-center font-medium leading-[42px]">
                        {" "}
                        Organisation Details
                    </div>
                    <div className="w-full">
                        <ViewBox
                            label={"Name"}
                            value={name}
                        />
                    </div>
                    <br />
                    <div className="w-full">
                        <ViewBox
                            label={"Organization Type"}
                            value={type}
                        />
                    </div>
                    <br />
                    <div className=" w-full">
                        {/* <ViewBox
                            label={"Is It Listed"}
                            value={isItListed}
                        /> */}
                    </div>
                    <br />
                    <div className="w-full">
                        <ViewBox
                            label={"Stock"}
                            value={stock}
                        />
                    </div>{" "}
                    <br />
                    <div className="w-full">
                        <ViewBox
                            label={"GST"}
                            value={GST}
                        />
                    </div>{" "}
                    <br />
                    <div className="w-full">
                        <ViewBox
                            label={"CIN"}
                            value={CIN}
                        />
                    </div>{" "}
                    <br />
                    <div className="w-full">
                        <ViewBox
                            label={"PAN"}
                            value={PAN}
                        />
                    </div>{" "}
                    <br />
                    <div className="w-full">
                        <ViewBox
                            label={"TAN"}
                            value={TAN}
                        />
                    </div>
                    <br />
                    <div className="w-full">
                        <ViewBox
                            label={"BRSR Requirements"}
                            value={bssrYear}
                        />
                    </div>
                    <br />
                    <div className="w-full">
                        <ViewBox
                            label={"Carbon Footprint Exercise"}
                            value={carbonFootprintYear}
                        />
                    </div>
                    <br />
                    <div className="flex justify-between items-center">
                        {/* <div className="pl-[30px] md:min-w-[220px]">Credit Rating</div> */}
                        <div className="flex w-full">
                            <div className="relative w-full   max-w-[540px] ">
                                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                                    <div>
                                        <ViewBox
                                            label={"Agency Name"}
                                            value={agencyName}
                                        />
                                    </div>
                                    <div>
                                        <ViewBox
                                            label={"Rating"}
                                            value={rating}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className=" w-full">
                        <ViewBox
                            label={"Carbon Goal"}
                            value={carbonGoal}
                        />
                    </div>
                    <br />
                    {/* <div className=" w-full">
                        <ViewBox
                            label={"Latitude"}
                            value={latitude}
                        />
                    </div>
                    <br />
                    <div className=" w-full">
                        <ViewBox
                            label={"Longitude"}
                            value={longitude}
                        />
                    </div> */}
                    <div className="mt-[33px] w-full px-[14px] sm:px-[55px] sm:mt-[70px] flex font-normal justify-center gap-[80px]">
                        <Link to={'/my-data'}>
                            <Abutton
                                text={"Cancel"}
                                Mheight={44}
                                Mwidth={170}
                                DHeight={44}
                                DWidth={299}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewOrganisation;







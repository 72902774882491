import React from "react";

const Abutton = ({ text, Mheight, Mwidth, DHeight, DWidth, handleOnclick }) => {
  return (
    <button
      onClick={handleOnclick}
      className={`text-center rounded-[10px] p-[10px] text-[16px] font-medium leading-[24px] text-white bg-[#067C4E] h-[${Mheight}px] w-[${Mwidth}px] md:h-[${DHeight}]px md:w-[${DWidth}px] `}
    >
      {text}
    </button>
  );
};

export default Abutton;

import React, { useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';

const AnimatedCount = ({ value, duration, formatNumber }) => {
  const [springProps, setSpringProps] = useSpring(() => ({
    number: 0,
    config: { duration: duration * 500 }, // converting duration to milliseconds
  }));

  useEffect(() => {
    setSpringProps({ number: value });
  }, [value, setSpringProps]);

  return (
    <animated.span>
      {springProps.number.to((n) => formatNumber(n))}
    </animated.span>
  );
};

export default AnimatedCount;

import React from "react";
import TotalEnergyAnnualView from "./totalEnergy/TotalEnergyAnnualView";
import SolarTotalCostAnnualView from "./totalEnergy/annual/SolarTotalCostAnnualView";
import SolarTotalEnergyAnnualView from "./totalEnergy/annual/SolarTotalEnergyAnnualView";

const TolalEnergyView = ({ annualData }) => {
  return (
    <div className={`flex flex-col lg:flex-row gap-5`}>
      <SolarTotalEnergyAnnualView annualData={annualData} />
      <SolarTotalCostAnnualView annualData={annualData} />
    </div>
  );
};

export default TolalEnergyView;

import React, { useState, useEffect } from "react";
import CostAnnualView from "./CostAnnualView";
import { BillConsumptionService } from "../../../services/graphs/billConsumptionService";
import CustomisedDataMonthlyView from "./CustomisedDataMonthlyView";
import CustomisedDataDayView from "./CustomisedDataDayView";

const CustomisedDataVisulization = () => {
  const billService = new BillConsumptionService();

  //   usestate variables
  const [annualEnergyconsumptionData, setAnnualEnergyconsumptionData] =
    useState([]);
  const [annualEnergyConsumptionLabels, setAnnualConsumptionLabels] = useState(
    []
  );
  const [selectedGraphType, setSelectedGraphType] = useState("Annual");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  // useeffectes
  useEffect(() => {
    const data = billService.getAnnualBillConsumption();
//    console.log({ data });
    if (data !== "" && data !== undefined) {
      setAnnualEnergyconsumptionData(data);
    }
  }, []);

  useEffect(() => {
    const data = billService.getannualEnergyConsumptionLabels();
//    console.log({ data });
    if (data !== "" && data !== undefined) {
      setAnnualConsumptionLabels(data);
    }
  }, []);

  useEffect(() => {
//    console.log({ annualEnergyconsumptionData, annualEnergyConsumptionLabels });
  }, [annualEnergyconsumptionData, annualEnergyConsumptionLabels]);

  const handleCustomDataVisualizationgraph = (
    type,
    selectedYear,
    selectedMonth
  ) => {
    setSelectedGraphType(type);
    setSelectedYear(selectedYear);
    setSelectedMonth(selectedMonth);
//    console.log({ type, selectedYear, selectedMonth });
  };

  return (
    <div className={`mt-[58px] lg:mt-[85px]  `}>

      <CustomisedDataDayView
        handleCustomDataVisualizationgraph={handleCustomDataVisualizationgraph}
        selectedGraphType={selectedGraphType}
      />
    </div>
  );
};

export default CustomisedDataVisulization;

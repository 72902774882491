import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const api = process.env.REACT_APP_API_URL;

const ConfigContext = createContext();

// Provider component
export const ConfigProvider = ({ children }) => {
  const [config] = useState(null);

  const storeConfig = async () => {
    try {
      const response = await axios.get(`${api}/config`);
      if (response.status === 200) {
        const configData = response.data.data;
        localStorage.setItem("config", JSON.stringify(configData));
      } else {
        throw new Error("Error in fetching config");
      }
    } catch (error) {
      console.error("Error in setting config", error);
    }
  };

  // Fetch config from server on initial render
  useEffect(() => {
    storeConfig();
  }, []);

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};

// Create a custom hook to use the ConfigContext
export const useConfig = () => {
  return useContext(ConfigContext);
};

import React from 'react'

const PowerConsumptionPage = () => {
    return (
        <div>
            <div className='w-[30%] mx-auto flex justify-center items-center mt-16 mb-10'>
                <img src='/power-consumption.png' alt='power-consumption' />
            </div>
            <p className='text-3xl font-medium text[#000000] text-center '>"Power Consumption" - Lists all meters data associated with organisation</p>
        </div>
    )
}

export default PowerConsumptionPage

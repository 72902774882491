import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

import InputBoxV2 from "../../components/inputbox/InputboxV2";
import Abutton from "../../components/button/Abutton";
import ASelectv1 from "../../components/select/ASelectV1";

import YearPicker from "../../components/yearPicker/YearPicker";
import InputBoxV1 from "../../components/inputbox/InputBoxV1";
import { postData } from "../../api/apiDataExchange";
import { useAuth } from "../../hooks/useAuth";


const AddOrganisation = () => {
  const navigate = useNavigate();
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  const [name, setName] = useState("");

  const [type, setType] = useState("");

  const [isItListed, setIsItListed] = useState({
    field: "isItListed",
    data: "",
    isChanged: false,
  });

  const [stock, setStock] = useState("");

  const [CIN, setCIN] = useState("");

  const [PAN, setPAN] = useState("");

  const [TAN, setTAN] = useState("");

  const [GST, setGST] = useState("");

  const [carbonGoal, setCarbonGoal] = useState("");

  const [agencyName, setagencyName] = useState("");
  const [rating, setrating] = useState("");

  const [errors, setErrors] = useState([]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const [bssrYear, setBssrYear] = useState();
  const [carbonFootprintYear, setCarbonFootprintYear] = useState();

  // const [latitude, setLatitude] = useState("");
  // const [longitude, setLongitude] = useState("");

  const handleBrsrYear = (date) => {
    setBssrYear(date);
  };

  const handleCarbonFootprintYear = (date) => {
    setCarbonFootprintYear(date);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log({ errors });
    const isErrorResolved =
      errors.some((item) => item["EmailError"] === "") &&
      errors.some((item) => item["PasswordError"] === "");

    const allStateData = {
      buyerId: localStorage.getItem("userId"),
      name,
      organisationType: type,
      isItListed,
      stock,
      CIN,
      PAN,
      TAN,
      GST,
      carbonGoal,
      creditRating: {
        rating: rating,
        agencyName: agencyName,
      },
      BRSRRequirements: bssrYear,
      carbonFootprintingExercise: carbonFootprintYear,
      // location: {
      //   type: "Point",
      //   coordinates: [latitude, longitude],
      // },
    };
    // console.log(allStateData);
    if (
      allStateData.name != "" &&
      allStateData.buyerId != "" &&
      allStateData.organisationType != "" &&
      allStateData.stock != "" &&
      allStateData.CIN != "" &&
      allStateData.PAN != "" &&
      allStateData.TAN != "" &&
      allStateData.GST != ""
    ) {
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation`,
        allStateData
      );

      // console.log(response);
      if (response?.data?.error?.code === 200) {
        toast.success("Organisation Successfully created", {
          position: "top-center",
          autoClose: 3000,
        });
        navigate("/my-data", { replace: true });
      } else {
        toast.error(response.error.response.data.error.message);
      }

      if (isErrorResolved) {
        setOpenPopup(true);
      }
    }
  };

  const handleErrors = (errorObj) => {
    console.log(errorObj);

    const key = Object.keys(errorObj)[0];
    const value = errorObj[key];

    const existingIndex = errors.findIndex(
      (error) => Object.keys(error)[0] === key
    );

    if (existingIndex !== -1) {
      const updatedErrors = [...errors];
      updatedErrors[existingIndex][key] = value;
      setErrors(updatedErrors);
    } else {
      setErrors((prevErrors) => [...prevErrors, { [key]: value }]);
    }
  };

  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[170px] pb-20">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"}`}>
        <div className=" rounded-lg bg-[#E2F5EC] px-4 sm:px-0 w-full flex flex-col pb-[50px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">
          <div className="text-[22px] py-8 text-center font-medium leading-[42px]">
            {" "}
            Add Organisation
          </div>
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter organization name"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Name"}
              value={name}
              fieldName={"Name"}
              setInputValue={setName}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter organization type"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Organization Type"}
              value={type}
              fieldName={"Type"}
              setInputValue={setType}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Is It Listed"}
              isflexed={true}
              // defaultValue={"Select option"}
              imputBoxMaxWidth={545}
              showLable={true}
              value={isItListed}
              fieldName={"IsItListed"}
              onChange={(e) => {
                setIsItListed(e.target.value);
              }}
              options={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter stock"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Stock"}
              value={stock}
              fieldName={"Stock"}
              setInputValue={setStock}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter gst number"
              id="test"
              type="email"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"GST"}
              value={GST}
              fieldName={"GST"}
              setInputValue={setGST}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="160230547570"
              id="test"
              type="email"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"CIN"}
              value={CIN}
              fieldName={"CIN"}
              setInputValue={setCIN}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="AABCM7851P"
              id="test"
              type="email"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"PAN"}
              value={PAN}
              fieldName={"PAN"}
              setInputValue={setPAN}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <InputBoxV2
              placeholder="AABCM7851P"
              id="test"
              type="email"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"TAN"}
              value={TAN}
              fieldName={"TAN"}
              setInputValue={setTAN}
              handleErrors={handleErrors}
            />
          </div>
          <div className="w-full">
            <YearPicker
              placeholderText={"Select Year"}
              handleYearData={handleBrsrYear}
              date={bssrYear}
              label={"BRSR Requirements"}
            />
          </div>
          <div className="w-full">
            <YearPicker
              placeholderText={"Select Year"}
              handleYearData={handleCarbonFootprintYear}
              date={carbonFootprintYear}
              label={"Carbon Footprint Exercise"}
            />
          </div>
          <br />
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
            <div className="pl-[30px] pb-[18px] sm:pb-0 md:min-w-[220px]">Credit Rating</div>
            <div className="flex justify-start w-full">
              <div className="relative w-full max-w-[545px] ">
                <div className="flex flex-col sm:flex-row sm:justify-between ">
                  <div className="pb-[18px] sm:pb-0">
                    <InputBoxV1
                      placeholder="Enter agency name"
                      id="test"
                      type="text"
                      showError={formSubmitted}
                      label={"AgencyName"}
                      value={agencyName}
                      fieldName={"AgencyName"}
                      displayLabel={false}
                      maxWidth220={true}
                      setInputValue={setagencyName}
                      handleErrors={handleErrors}
                    />
                  </div>
                  <div>
                    <InputBoxV1
                      placeholder="Enter rating"
                      id="test"
                      type="text"
                      showError={formSubmitted}
                      label={"Rating"}
                      value={rating}
                      fieldName={"Rating"}
                      displayLabel={false}
                      setInputValue={setrating}
                      handleErrors={handleErrors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Carbon Goal"}
              isflexed={true}
              // defaultValue={"Carbon Goal"}
              imputBoxMaxWidth={545}
              showLable={true}
              onChange={(e) => {
                setCarbonGoal(e.target.value);
              }}
              options={[
                { label: "10", value: 10 },
                { label: "20", value: 20 },
                { label: "30", value: 30 },
                { label: "40", value: 40 },
              ]}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          {/* Add Latitude and Longitude inputs here */}
          {/* Latitude Input */}
          {/* <div className="w-full">
            <InputBoxV2
              placeholder="Enter latitude"
              id="latitude"
              type="text"
              validationtypes={[""]}
              showError={formSubmitted}
              label="Latitude"
              value={latitude}
              fieldName="Latitude"
              setInputValue={setLatitude}
              handleErrors={handleErrors}
            />
          </div>
          <br /> */}
          {/* Longitude Input */}
          {/* <div className="w-full">
            <InputBoxV2
              placeholder="Enter longitude"
              id="longitude"
              type="text"
              validationtypes={[""]}
              showError={formSubmitted}
              label="Longitude"
              value={longitude}
              fieldName="Longitude"
              setInputValue={setLongitude}
              handleErrors={handleErrors}
            />
          </div> */}
          <div className="mt-[33px] w-full px-[14px] sm:px-[55px] sm:mt-[70px] flex font-normal justify-center gap-[10px] sm:gap-[80px]">
            <Abutton
              handleOnclick={handleSubmit}
              text={"Save & Next"}
              Mheight={44}
              Mwidth={170}
              DHeight={44}
              DWidth={299}
            />
            <Link to={"/my-data"}>
              <Abutton
                text={"Cancel"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={299}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOrganisation;

import React, { useState, useEffect } from "react";
import { ConstantUtils } from "../../utils/ConstantUtils";
import LineChartEnergyView from "../../containers/graphs/LineChartEnergyView";
import LegendComponent from "../../components/chart/LegendComponent";
import CustomTooltop from "../../components/tooltips/CustomTooltop";

const constantUtils = new ConstantUtils();

const DashBoardPaymentView = ({ graphData }) => {
  

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
        legend: {
          display: false,
        },
      },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Amount (Rs)",
        },
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // This will make the line curve
      },
    },
  });

  const [propsData, setPropsData] = useState({
    labels: [],
    datasets: [
      {
        label: "ifPaidUptoAmount",
        data: [],
        borderWidth: 2,
        type:"line",
        borderColor: constantUtils.getLineGraphColors()[0],
        backgroundColor: constantUtils.getLineGraphColors()[0],
        datalabels: {
          anchor: "top",
        },
      },
      {
        label: "ifPaidAfterAmount",
        data: [],
        borderWidth: 2,
        type:"line",
        borderColor: constantUtils.getLineGraphColors()[1],
        backgroundColor: constantUtils.getLineGraphColors()[1],
        datalabels: {
          anchor: "top",
        },
      },
      {
        label: "lastPaymentMonth",
        data: [],
        borderWidth: 2,
        type:"line",
        borderColor: constantUtils.getLineGraphColors()[2],
        backgroundColor: constantUtils.getLineGraphColors()[2],
        datalabels: {
          anchor: "top",
        },
      },
    ],
  });
  const [full, setFull] = useState(false);

  useEffect(() => {
    if (graphData !== undefined && graphData.dates.length > 0) {
      const updatedData = {
        ...propsData,
        labels: graphData.dates,
        datasets: [
          {
            ...propsData.datasets[0],
            data: graphData.ifPaidUptoAmountArray,
          },
          {
            ...propsData.datasets[1],
            data: graphData.ifPaidAfterAmountArray,
          },
          {
            ...propsData.datasets[2],
            data: graphData.lastPaymentMonthArray,
          },
        ],
      };
      setPropsData(updatedData);
    }
  }, [graphData]);

  useEffect(() => {

  }, [propsData]);
  return (
    <div className="px-5">
      <div className="flex justify-between  py-2 uppercase font-medium  ">
        <div>Late payment Surcharge</div>
        <div><CustomTooltop
              disable={
                constantUtils.getGraphToolTipData().DashboardView.disableToolTip
              }
              text={constantUtils.getGraphToolTipData().DashboardView.text}
            /></div>
      </div>
      <div className="flex justify-center">
      <LegendComponent data={propsData} />
      </div>

        <LineChartEnergyView
          data={propsData}
          options={options}
          full={full}
          setFull={setFull}
        />
    
    </div>
  );
};

export default DashBoardPaymentView;

import React from 'react'

const MyDataPage = () => {
    return (
        <div>
            <div className='w-[30%] mx-auto flex justify-center items-center mt-16 mb-10'>
                <img src='/my-data.png' alt='my-data' />
            </div>
            <p className='text-3xl font-medium text[#000000] text-center '>"My Data" - Enables user to maintain the personal data as well as uploaded bills data.</p>
        </div>
    )
}

export default MyDataPage

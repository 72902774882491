import React, { useEffect, useState } from "react";
import InputBox from "../../components/inputbox/InputBox";
import Abutton from "../../components/button/Abutton";
import { Link, useNavigate } from "react-router-dom";
import { getData } from "../../api/apiDataExchange";

const ComparisonView = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [billDoc, setBillDoc] = useState();
  const [billImage, setBillImage] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log({ errors });
    const isErrorResolved =
      errors.some((item) => item["EmailError"] === "") &&
      errors.some((item) => item["PasswordError"] === "");

    if (isErrorResolved) {
      setOpenPopup(true);
    }
  };

  const handleErrors = (errorObj) => {
    // console.log(errorObj);

    // const key = Object.keys(errorObj)[0];
    // const value = errorObj[key];

    // const existingIndex = errors.findIndex(
    //   (error) => Object.keys(error)[0] === key
    // );

    // if (existingIndex !== -1) {
    //   const updatedErrors = [...errors];
    //   updatedErrors[existingIndex][key] = value;
    //   setErrors(updatedErrors);
    // } else {
    //   setErrors((prevErrors) => [...prevErrors, { [key]: value }]);
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bills = await getData(`${process.env.REACT_APP_API_URL}/media/661a2772fa131fc90763efaa`);
        if (bills?.result?.type == "document") {
          setBillDoc(bills.result.url);
        } else if (bills?.result?.type == "image") {
          setBillImage(bills.result.url);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);

      }
    }
    fetchData();
  }, [])

  return (
    <div className="mt-[180px] mb-[80px] px-[20px] py-[50px] flex  m-auto max-w-screen-xl justify-between bg-[#E2F5EC] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]">
      {billDoc ?
        <div className="w-[45%]">
          <iframe src={billDoc} width="100%" height="600px"></iframe>
        </div>
        :
        <div className="hidden w-[45%] h-[500px] overflow-y-auto">
          <img
            className="w-[100%]"
            src="/bill.png"
          />
          <img
            className="w-[100%] h-[600px] mt-2"
            src="/bill.png"
          />
        </div>
      }
      <div className="w-[50%] h-[500px] overflow-y-auto">
        <div>
          <InputBox
            placeholder="Enter Organization Name"
            id="test"
            type="email"
            validationtypes={["required"]}
            showError={formSubmitted}
            label={"Organization Name"}
            value={email}
            setInputValue={setEmail}
            handleErrors={handleErrors}
          />
        </div>
        <br />
        <div className="w-full">
          <InputBox
            placeholder="Enter Factory Name"
            id="test"
            type="password"
            validationtypes={["required"]}
            showError={formSubmitted}
            label={"Factory Name"}
            value={password}
            setInputValue={setPassword}
            handleErrors={handleErrors}
          />
        </div>
        <br />

        <div className="w-full">
          <InputBox
            placeholder="Enter  Factory Address"
            id="test"
            type="password"
            validationtypes={["required"]}
            showError={formSubmitted}
            label={"Factory Address"}
            value={password}
            setInputValue={setPassword}
            handleErrors={handleErrors}
          />
        </div>
        <br />

        <div className="w-full">
          <InputBox
            placeholder="Enter Unit Name"
            id="test"
            type="password"
            validationtypes={["required"]}
            showError={formSubmitted}
            label={"Unit Name"}
            value={password}
            setInputValue={setPassword}
            handleErrors={handleErrors}
          />
        </div>
        <br />

        <div className="w-full">
          <InputBox
            placeholder="Enter Meter number"
            id="test"
            type="password"
            validationtypes={["required"]}
            showError={formSubmitted}
            label={"Meter number"}
            value={password}
            setInputValue={setPassword}
            handleErrors={handleErrors}
          />
        </div>

      </div>

      {/* <div>    buttons are added here</div> */}
    </div>
  );
};

export default ComparisonView;

import React from "react";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Abutton from "../button/Abutton";
import { sendOTP } from "../../services/apis/user";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from "../../hooks/useAuth";
import { postList } from "../../api/apiDataExchange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Popup = ({setOpenPopup, openPopUp, handleOpenPopup, userId }) => {
  // console.log(openPopUp,'openPopUp');
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [optSuccess, setOptSuccess] = useState(false)
  const navigate = useNavigate();
  const { user, isAuthenticated, login, logout, verifyOTP, isMyDataAvailable, storeConfig } = useAuth();

  useEffect(() => {
    validateForm();
  }, [otp]);
  useEffect(() => {
    // console.log(openPopUp)
    setIsOpen(openPopUp);
  }, [openPopUp]);
  const validateForm = () => {
    let errors = {};

    if (!otp) {
      errors.otpErrorMsg = 'OTP is required.';
    } else if (otp.length < 6) {
      errors.otpErrorMsg = 'OTP must be at least 6 characters.';
    }
    setErrors(errors);
    setIsFormValid(Object.keys(errors).length === 0);

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsFormSubmitted(true)
    if (isFormValid) {

      setIsloading(true);
      try {
        // const userData = await getUserById()
        const otpResponse = await verifyOTP(userId, otp);
        setIsloading(false)
        // console.log(otpResponse.response,'otpResponse.response.data');
        if (otpResponse.success) {
          localStorage.setItem("isOtpVerified", true)
          // toast.success('Login successful!', {
          //   position: "top-center"
          // });
          setOptSuccess(true);
          const orgData = await postList(`${process.env.REACT_APP_API_URL}/organisation/list`)
          if (orgData?.data?.results?.length > 0) {
            navigate('/dashboard');
          } else {
            navigate('/my-data');
          }

          const config = JSON.parse(localStorage.getItem('config'));
          if (!config) {
            await storeConfig();
          }


        } else {
          toast.error(otpResponse.response.data.error.message, {
            position: "top-center"
          });
          //   console.error("Error verifying OTP:", otpResponse.error);

        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        if (error.message) {
          toast.error(error.message, {
            position: "top-center"
          });
        }
      }
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };

  const handleResendOTP = async () => {
    try {

      const otpResponse = await sendOTP(userId);
      if (otpResponse.success) {
        toast.success('OTP resent successfully.', {
          position: "top-center"
        });
        console.log("OTP resend successfully");
      } else {
        console.error("Error resending OTP:", otpResponse.error);

      }
    } catch (error) {
      console.error("Error resending OTP:", error);

    }
  };

console.log(isOpen,'isOpen');

  return (
    <div className="">
      {isOpen && (
        <div
          className="fixed z-10 inset-0 flex items-center justify-center"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center   text-center ">
            <div
              className="fixed inset-0 bg-gray-500  bg-opacity-50 transition-opacity"
              aria-hidden="true"
            >

            </div>
            <div className="absolute  w-full   align-bottom rounded-lg text-left    mt-20">
              <div style={{ border: "1px solid #B9B9B9" }} className="mx-[20px] sm:mx-[89px] xl:m-auto h-[375px] xl:w-[600px] pb-[40px] sm:pb-[40px] drop-shadow-[0px_10px_5px_rgba(0,0,0,0.25)] rounded  bg-white">
                <div className="px-[14px] sm:px-[55px] font-bold text-center text-[16px] sm:text-[19px] leading-[24px] pt-[34px] sm:pt-[42px]">
                  OTP Send to email and mobile
                </div>
                <div className="absolute top-1 right-0 cursor-pointer" onClick={()=>setOpenPopup(!openPopUp)}>
                  <FontAwesomeIcon icon={faClose} className=" text-[#000000] mr-2 text-xl font-medium  transition-all ease-out" />
                </div>

                <div className="px-[14px] sm:px-[55px] sm:text-[15px] mt-[24px] sm:mt-[21px] font-[400] text-[14px] leading-[21px]">
                  Enter OTP<span className="text-red-600"> *</span>
                </div>
                <div className="mt-[18px] sm:px-[55px] sm:mt-[30px] px-[14px]">
                  <input
                    placeholder="Enter otp"
                    className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                    type="text"
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                  />
                  {isFormSubmitted && errors.otpErrorMsg && <p className="text-red-500 m-[10px]">{errors.otpErrorMsg}</p>}

                </div>
                <div className="mt-[33px] px-[14px] sm:px-[55px] sm:mt-[50px] flex font-normal justify-center sm:justify-start gap-[16px]">

                  <Abutton
                    text={"Submit"}
                    handleOnclick={(e) => {
                      handleSubmit(e, otp);
                    }}
                    Mheight={44}
                    Mwidth={170}
                    DHeight={44}
                    DWidth={299}
                  />
                  <Abutton
                    text={"Resend OTP"}
                    handleOnclick={(e) => {
                      handleResendOTP(e)
                    }}
                    Mheight={44}
                    Mwidth={170}
                    DHeight={44}
                    DWidth={299}
                  />

                </div>
                <div className={`${optSuccess ? "block" : "hidden"}`}>
                  <div className="mt-[38px] font-[400] text-center text-[14px] leading-[21px]">
                    OTP verified successfully
                  </div>
                </div>
                <div className={`${isloading ? "block" : "hidden"}`}>

                  <div className="mt-[14px] flex justify-center text-center">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                  <div className="mt-[4px] font-[400] text-center text-[14px] leading-[21px]">
                    Loading...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;

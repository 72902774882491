import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faCamera, faCircleDown, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserById, updateUserById } from '../../services/apis/user';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import { deleteImageById, getImageById, uploadImage } from '../../services/apis/media';

export default function MyProfile() {

    const navigate = useNavigate();

    var userId = localStorage.getItem('userId');

    const [errors, setErrors] = useState({});
    const [count, setCount] = useState(1);
    const [imageId, setImageId] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [photoUrl, setPhotoUrl] = useState('');
    const [govtIdUrl, setGovtIdUrl] = useState('');
    const [companyIdUrl, setCompanyIdUrl] = useState('');
    const [photoUrlDb, setPhotoUrlDb] = useState('');
    const [govtIdUrlDb, setGovtIdUrlDb] = useState('');
    const [companyIdUrlDb, setCompanyIdUrlDb] = useState('');
    const [photoId, setPhotoId] = useState('');
    const [govtPhotoId, setGovtPhotoId] = useState('');
    const [companyPhotoId, setCompanyPhotoId] = useState('');
    const [formData, setFormData] = useState({
        username: "",
        companyName: "",
        designation: "",
        email: "",
        companyWebsite: "",
        mobile: "",
        companyLandLineNumber: "",
        password: "",
        photo: null,
        companyId: null,
        govtId: null
    });

    const handleChange = async (e) => {
        const { name, value } = e.target;
        let newErrors = { ...errors };
        var type = 'image';
        var objectType = 'user';

        // Check if the input field is empty after clearing with backspace
    if (value.trim() === '') {
        // Show validation message based on the field name
        switch (name) {
            case 'username':
                newErrors.username = "Name is required";
                break;
            case 'companyName':
                newErrors.companyName = "Company Name is required";
                break;
            case 'designation':
                newErrors.designation = "Designation is required";
                break;
            case 'email':
                newErrors.email = "Company Email is required";
                break;
            case 'mobile':
                newErrors.mobile = "Mobile Number is required";
                break;
            case 'companyLandLineNumber':
                newErrors.companyLandLineNumber = "Company Landline Number is required";
                break;
            case 'photo':
                newErrors.photo = "Photo is required";
                break;
            case 'govtId':
                newErrors.govtId = "Photo Id is required";
                break;
            case 'companyId':
                newErrors.companyId = "Company I-Card is required";
                break;
            default:
                break;
        }
    } else {
        // Clear the validation message if the input field is not empty
        switch (name) {
            case 'username':
                delete newErrors.username;
                break;
            case 'companyName':
                delete newErrors.companyName;
                break;
            case 'designation':
                delete newErrors.designation;
                break;
            case 'email':
                delete newErrors.email;
                break;
            case 'mobile':
                delete newErrors.mobile;
                break;
            case 'companyLandLineNumber':
                delete newErrors.companyLandLineNumber;
                break;
            case 'photo':
                delete newErrors.photo;
                break;
            case 'govtId':
                delete newErrors.govtId;
                break;
            case 'companyId':
                delete newErrors.companyId;
                break;
            default:
                break;
        }
    }
    
        if (name === 'photo' || name === 'companyId' || name === 'govtId') {
            if (e.target.files) {
                const file = e.target.files[0];
                setSelectedImage(file);
                if(file){
                    const response = await uploadImage(file, objectType, type);
                    
                    setImageId(response?.result?._id);
                    
                    const getImage = await getImageById(response?.result?._id);
                    
                    if (name === 'photo') {
                        setPhotoUrl(getImage?.result?.url);
                        delete newErrors.photo;
                    } else if (name === 'govtId') {
                        setGovtIdUrl(getImage?.result?.url);
                        delete newErrors.govtId;
                    } else if (name === 'companyId') {
                        setCompanyIdUrl(getImage?.result?.url);
                        delete newErrors.companyId;
                    }
                    const imageIds = Array.isArray(formData[name]) ? formData[name] : [];
                    imageIds.push(response?.result?._id);
                    console.log(imageIds,'imageids')
                    setFormData({
                        ...formData,
                        [name]: imageIds
                    });
                }
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
        setErrors(newErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!formData.username.trim()) {
            newErrors.username = "Name is required";
        }
        if (!formData.companyName.trim()) {
            newErrors.companyName = "Company Name is required";
        }
        if (!formData.designation.trim()) {
            newErrors.designation = "Designation is required";
        }
        if (!formData.email.trim()) {
            newErrors.email = "Company Email is required";
        }
        // if (!formData.website.trim()) {
        //     newErrors.website = "Website is required";
        // }
        if (!formData.mobile.trim()) {
            newErrors.mobile = "Mobile Number is required";
        }
        if (!formData.mobile.trim()) {
            newErrors.mobile = "Mobile Number is required";
        }
        // if (!formData.landline.trim()) {
        //     newErrors.landline = "Landline is required";
        // }
        // if (!formData.password.trim()) {
        //     newErrors.password = "Password is required";
        // }
        if (!formData.photo) {
            newErrors.photo = "Photo is required";
        }
        if (!formData.govtId) {
            newErrors.govtId = "Photo Id is required";
        }
        if (!formData.companyId) {
            newErrors.companyId = "Company I-Card is required";
        }
        console.log(formData,'formdata')
        console.log(formData.photo,'formData.photo')
        console.log(formData.govtId,'formData.govtId')
        console.log(formData.companyId,'formData.companyId')
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {

            const updatedFormData = {
                ...formData,
                userPhoto: formData.photo || photoId, // Use the old ID if new one is not available
                documentPhoto: formData.govtId || govtPhotoId,
                companyIdCard: formData.companyId || companyPhotoId
            };
            console.log(updatedFormData,'updatedFormData')

            try {
                const response = await updateUserById(userId, updatedFormData);

                if(response.success == true){
                    setFormData({
                        username: "",
                        companyName: "",
                        designation: "",
                        email: "",
                        companyWebsite: "",
                        mobile: "",
                        companyLandLineNumber: "",
                        password: "",
                        photo: null,
                        companyId: null,
                        govtId: null
                    })
                    setErrors({});

                    toast.success("Profile Updated Successfully!", {
                        position: "top-center",
                    });
                        setTimeout(() => {
                        navigate("/my-data");
                    }, 3000);
                } else {
                    toast.error(`${response?.error?.message}`, {
                      position: "top-center",
                    });
                  }

            } catch (error) {
                console.error("Error:", error);
          
                toast.error("Failed to update blog. Please try again later.", {
                    position: "top-center",
                });
            }
        }

    }

    const handleCancel = () => {
        // Reset form data to initial state
        setFormData({
            username: "",
            companyName: "",
            designation: "",
            email: "",
            companyWebsite: "",
            mobile: "",
            companyLandLineNumber: "",
            password: "",
            photo: null,
            companyId: null,
            govtId: null
        });
    
        // Clear any existing errors
        setErrors({});
        navigate("/my-data");
    };

    useEffect(() => {
        
        const fetchUserData = async () => {
            try {
                const response = await getUserById(userId);
                const userData = response?.result;
                console.log(response?.result,'response')
                setFormData({
                    username: userData?.username,
                    companyName: userData?.companyName || "",
                    designation: userData?.designation || "",
                    email: userData?.email || "",
                    companyWebsite: userData?.companyWebsite || "",
                    mobile: userData?.mobile || "",
                    companyLandLineNumber: userData?.companyLandLineNumber || "",
                    // password: userData?.password || "",
                    photo: userData?.userPhoto[0] || null,
                    companyId: userData?.companyIdCard[0] || null,
                    govtId: userData?.documentPhoto[0] || null
                });
                setPhotoId(userData?.userPhoto[0]);
                setGovtPhotoId(userData?.documentPhoto[0]);
                setCompanyPhotoId(userData?.companyIdCard[0]);
            } catch (error) {
                console.error('Error fetching user data:', error);
                // Handle error
            }
        };

        fetchUserData();
    }, [count]);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
                if(photoId){
                    const userPhoto = await getImageById(photoId);
                    setPhotoUrlDb(userPhoto?.result?.url)
                    console.log(userPhoto,'userphoyo')
                }
            } catch (error) {
                
            }
        }
        const fetchDocumentPhoto = async () => {
            try {
                if(govtPhotoId){
                    const documentPhoto = await getImageById(govtPhotoId);
                    setGovtIdUrlDb(documentPhoto?.result?.url)
                }
            } catch (error) {
                
            }
        }
        const fetchCompanyIdPhoto = async () => {
            try {
                if(companyPhotoId){
                    const companyIdPhoto = await getImageById(companyPhotoId);
                    setCompanyIdUrlDb(companyIdPhoto?.result?.url)
                }
            } catch (error) {
                
            }
        }
        fetchPhoto();
        fetchDocumentPhoto();
        fetchCompanyIdPhoto();
    },[photoId, govtPhotoId, companyPhotoId])

    const handleDeletePhoto = async (e, id, type) => {
        // alert('clicked!')
        e.preventDefault();
        console.log(type,'type');
        // return;

        let response;

        confirmAlert({
            title: '',
            closeOnClickOutside: false,
            message: `Are you sure to delete image`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        response = await deleteImageById(id);
                        
                        if (response.success) {
                            toast.success(`Image deleted successfully`, {
                                position: "top-center",
                                autoClose: 1500,
                            });
                            if(type == 'userPhoto'){
                                setPhotoUrlDb("");
                                setFormData({
                                    ...formData,
                                    photo: null
                                });
                            }
                            if(type == 'docPhoto'){
                                setGovtIdUrlDb("");
                                setFormData({
                                    ...formData,
                                    govtId: null
                                });
                            }
                            if(type == 'CompPhoto'){
                                setCompanyIdUrlDb("");
                                setFormData({
                                    ...formData,
                                    companyId: null
                                });
                            }
                            // updateCount();
                            // setCount(prevCount => prevCount + 1);
                        } else {
                            toast.error(`Image could not be deleted`, {
                                position: "top-center",
                                autoClose: 1500,
                            });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        // Do nothing
                    }
                }
            ]
        });
    }

    const updateCount = () => {
        setCount(prevCount => prevCount + 1);
    }

    return (
        <>
            <div className="max-w-[1800px] m-auto ">
                <div className="px-[10px] sm:px-0 mt-[50px] md:mt-[27px] mx-[20px]  xl:mx-[177px] pb-[32px] md:pb-[50px] xl:mt-[130px] bg-[#E2F5EC] rounded-[20px] shadow-md flex  justify-center ">
                    <div className="w-full">
                    <div id="titleText" className="text-[#067C4E] mt-[31px]   md:mt-[38px] xl:mt-[41px] text-[28px] font-medium leading-[36px] text-center">
                            My Profile
                        </div>

                        <div className="mt-[50px] w-full md:px-[115px] xl:px-[184px]">
                            <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                Name<span className="text-red-600"> *</span>
                            </div>
                            <div className="mt-[12px]">
                                <input
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                                    type="text"
                                />
                                <br />
                                {errors.username && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.username}</p>}
                            </div>
                        </div>
                        <div className="mt-[32px] w-full md:px-[115px] xl:px-[184px]">
                            <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                Company Name<span className="text-red-600"> *</span>
                            </div>
                            <div className="mt-[12px]">
                                <input
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    placeholder="Enter Company Name"
                                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                                    type="text"
                                />
                                <br />
                                {errors.companyName && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.companyName}</p>}
                            </div>
                        </div>
                        <div className="mt-[32px] w-full md:px-[115px] xl:px-[184px]">
                            <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                Designation<span className="text-red-600"> *</span>
                            </div>
                            <div className="mt-[12px]">
                                <input
                                    name="designation"
                                    value={formData.designation}
                                    onChange={handleChange}
                                    placeholder="Enter Company Name"
                                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                                    type="text"
                                />
                                <br />
                                {errors.designation && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.designation}</p>}
                            </div>
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px]">
                            <div className="mt-[32px] w-full">
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Company Email<span className="text-red-600"> *</span>
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        disabled
                                        name="email"
                                        value={formData.email}
                                        placeholder="Enter Company Email"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="email"
                                        onChange={handleChange}
                                    />
                                    <br />
                                    {errors.email && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.email}</p>}
                                </div>
                            </div>
                            <div className="mt-[32px] w-full ">
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Website (Same as Email Domain)
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="companyWebsite"
                                        value={formData.companyWebsite}
                                        placeholder="Enter Website"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="text"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px]">
                            <div className="mt-[32px] w-full ">
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Mobile<span className="text-red-600"> *</span>
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="mobile"
                                        value={formData.mobile}
                                        placeholder="Enter Mobile Number"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="number"
                                        onChange={handleChange}
                                    />
                                    {errors.mobile && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.mobile}</p>}
                                </div>
                            </div>
                            <div className="mt-[32px] w-full">
                                <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Company Landline Number
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="companyLandLineNumber"
                                        value={formData.companyLandLineNumber}
                                        placeholder="Enter Company Landline Number"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="email"
                                        onChange={handleChange}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                        {/* <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px]">
                            <div className="mt-[32px] w-full">
                                <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Password<span className="text-red-600"> *</span>
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        disabled
                                        name="password"
                                        placeholder="Enter Password"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    {errors.password && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.password}</p>}
                                </div>
                            </div>
                            
                        </div> */}
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px] mt-[42px] w-full">
                            <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                                Upload User Photo<span className="text-red-600"> *</span>
                            </div>
                            {photoUrlDb ? (
                                <div className="mt-[-18px] md:ml-[350px] flex items-center absolute">
                                    <div className="absolute top-[-10px] right-[-10px] mr-2  cursor-pointer" onClick={(e) => handleDeletePhoto(e, photoId, 'userPhoto')}>
                                        <FontAwesomeIcon icon={faCircleXmark} className="text-red-600 mt-[-10px] font-bold" />
                                    </div>
                                    <img className="w-[60px] h-[60px] " src={photoUrlDb} alt="" />
                                </div>
                            ) : (
                                <div className="ml-0 md:ml-[300px] flex items-center absolute">
                                    <label htmlFor="photo-upload" className="cursor-pointer mr-4">
                                        <FontAwesomeIcon icon={faArrowUpFromBracket} className="h-6 w-6 text-[#067C4E]" />
                                    </label>
                                    <span className="mr-4">OR</span> 
                                    <label htmlFor="camera-upload" className="cursor-pointer mr-4">
                                        <FontAwesomeIcon icon={faCamera} className="h-6 w-6 text-[#067C4E]" />
                                    </label>
                                    <input
                                        name="photo"
                                        id="photo-upload"
                                        className="hidden"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleChange}
                                    />
                                    <input
                                        name="photo"
                                        id="camera-upload"
                                        className="hidden"
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        onChange={handleChange}
                                    />
                                </div>
                            )}
                            {photoUrl && (
                                <img className="w-[50px] h-[50px] md:ml-[550px] mt-[-10px] absolute" src={photoUrl} alt="photo"/>
                            )}
                            {errors.photo && <p className="text-red-500 md:ml-[450px] mt-[2px] absolute">{errors.photo}</p>}
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px] mt-[42px] w-full">
                            <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                                Upload Photo ID<span className="text-red-600"> *</span>
                            </div>
                            {govtIdUrlDb ? (
                                <div className="mt-[-10px] md:ml-[350px] flex items-center absolute">
                                    <div className="absolute top-[-10px] right-[-10px] mr-2  cursor-pointer" onClick={(e) => handleDeletePhoto(e, govtPhotoId, 'docPhoto')}>
                                        <FontAwesomeIcon icon={faCircleXmark} className="text-red-600 mt-[-10px] font-bold" />
                                    </div>
                                    <img className="w-[60px] h-[60px] " src={govtIdUrlDb} alt="doc-photo"/>
                                </div>
                            ) : (
                                <>
                                    <div className="ml-0 md:ml-[300px] flex items-center absolute">
                                        <label htmlFor="govtId-photo-upload" className="cursor-pointer mr-4">
                                            <FontAwesomeIcon icon={faArrowUpFromBracket} className="h-6 w-6 text-[#067C4E]" />
                                        </label>
                                        <span className="mr-4">OR</span> 
                                        <label htmlFor="govtId-camera-upload" className="cursor-pointer mr-4">
                                            <FontAwesomeIcon icon={faCamera} className="h-6 w-6 text-[#067C4E]" />
                                        </label>
                                        <input
                                            name="govtId"
                                            id="govtId-photo-upload"
                                            className="hidden"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleChange}
                                        />
                                        <input
                                            name="govtId"
                                            id="govtId-camera-upload"
                                            className="hidden"
                                            type="file"
                                            accept="image/*"
                                            capture="environment"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <p className="text-black text-[12px] leading-[21px] font-normal ml-[250px] mt-[40px] absolute">
                                        Upload photo of PAN or Aadhaar card
                                    </p>
                                </>
                            )}
                            {govtIdUrl && (
                                <img className="w-[50px] h-[50px] md:ml-[550px] mt-[-10px] absolute" src={govtIdUrl} alt="doc-photo"/>
                            )}
                            {errors.govtId && <p className="text-red-500 md:ml-[450px] mt-[2px] absolute">{errors.govtId}</p>}
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px] mt-[70px] w-full">
                            <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                                Upload Company I-Card<span className="text-red-600"> *</span>
                            </div>
                            {companyIdUrlDb ? (
                                <div className="mt-[-10px] md:ml-[350px] flex items-center absolute">
                                    <div className="absolute top-[-10px] right-[-10px] mr-2  cursor-pointer" onClick={(e) => handleDeletePhoto(e, companyPhotoId, 'CompPhoto')}>
                                        <FontAwesomeIcon icon={faCircleXmark} className="text-red-600 mt-[-10px] font-bold" />
                                    </div>
                                    <img className="w-[60px] h-[60px] " src={companyIdUrlDb} alt="comp-id-photo"/>
                                </div>
                            ) : (
                                <div className="ml-0 md:ml-[300px] flex items-center absolute">
                                    <label htmlFor="companyId-photo-upload" className="cursor-pointer mr-4">
                                        <FontAwesomeIcon icon={faArrowUpFromBracket} className="h-6 w-6 text-[#067C4E]" />
                                    </label>
                                    <span className="mr-4">OR</span> 
                                    <label htmlFor="companyId-camera-upload" className="cursor-pointer mr-4">
                                        <FontAwesomeIcon icon={faCamera} className="h-6 w-6 text-[#067C4E]" />
                                    </label>
                                    <input
                                        name="companyId"
                                        id="companyId-photo-upload"
                                        className="hidden"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleChange}
                                    />
                                    <input
                                        name="companyId"
                                        id="companyId-camera-upload"
                                        className="hidden"
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        onChange={handleChange}
                                    />
                                </div>
                            )}
                            {companyIdUrl && (
                                <img className="w-[50px] h-[50px] md:ml-[550px] mt-[-10px] absolute" src={companyIdUrl} alt="comp-id-photo"/>
                            )}
                            {errors.companyId && <p className="text-red-500 md:ml-[450px] mt-[2px] absolute">{errors.companyId}</p>}
                        </div>

                        <div className="mt-[50px] flex justify-center">
                            <button 
                                onClick={handleSubmit} 
                                className="h-[44px] rounded-[100px] py-[10px] px-[25px] hover:bg-[#045036] text-white text-[16px] leading-[24px] bg-[#067C4E]"
                            >
                                Save
                            </button>
                            <button 
                                onClick={handleCancel} 
                                className="h-[44px] ml-4 rounded-[100px] hover:bg-gray-200 py-[10px] px-[25px] text-[#067C4E] text-[16px] leading-[24px] bg-white border-[#067C4E] border-2 border-solid"
                            >
                                Cancel
                            </button>
                        </div>
                    </div >
                </div>
            </div> 
            <ToastContainer position="top-center" autoClose={3000}/>
        </>
    )
}

import React, { useEffect, useState } from "react";
import InputBox from "../../components/inputbox/InputBox";
import Abutton from "../../components/button/Abutton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getData, postList, postOcrList } from "../../api/apiDataExchange";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import { ConstantUtils } from "../../utils/ConstantUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/monthYearPicker/MonthYearPicker.css";
import moment from "moment/moment";
import FilterSelect from "../../components/select/FilterSelect";

const CompareOcrBills = () => {
  const constantUtils = new ConstantUtils();

  const [ocrData, setOcrData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [meterNo, setMeterNo] = useState("");
  // const [meterFlag, setMeterFlag] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [consumerName, setConsumerName] = useState("");
  const [consumerNo, setConsumerNo] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endReadingDate, setEndReadingDate] = useState(null);
  const [startReadingDate, setStartReadingDate] = useState(null);
  const [consumerNoOptions,setConsumerNameOptions]=useState([])
  const [filters, setFilters] = useState({
    consumerName: '',
    consumerNo: '',
    status: ["COMPLETED", "ADDED", "MODIFIED", "VALIDATED"],
    startReadingDate: "",
    endReadingDate: ""
  })
  const handleConsumerNameChange = (event) => {
    setConsumerName(event.target.value);
  };

  const handleConsumerNoChange = (event) => {
    setConsumerNo(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const consumerNoFromQuery = query.get('conNo');
    const startReadingDate = query.get('startDate');
    const endReadingDate = query.get('endDate');

    // const startReadingDate = convertDateValue(startReadingDateFromQuery);
    // const endReadingDate = convertDateValue(endReadingDateFromQuery);

    if (consumerNoFromQuery && startReadingDate && endReadingDate) {
      fetchDataByConsumerNo(consumerNoFromQuery, startReadingDate, endReadingDate);
    }
  }, [location.search]);

  const fetchDataByConsumerNo = async (consumerNo, startReadingDate, endReadingDate) => {
    try {

      const convertTimeStart = (dateStr) => {
        const date = moment(dateStr, 'MM/YYYY').startOf('month').utc();
        return date.format('YYYY-MM-DDT00:00:00.000') + 'Z';
    };
    
    const convertTimeEnd = (dateStr) => {
        const date = moment(dateStr, 'MM/YYYY').endOf('month').utc();
        return date.format('YYYY-MM-DDT00:00:00.000') + 'Z';
    };

      const filters = {
        consumerNo,
        startReadingDate: convertTimeStart(startReadingDate),
        endReadingDate: convertTimeEnd(endReadingDate)
      };
      console.log(startReadingDate, endReadingDate,filters,"filter111")
      
      const data = { filter: filters, page: 1, limit: 1 };
      // console.log(data,'data')
      const res = await postOcrList(`${process.env.REACT_APP_API_URL}/ocr/list`, data);

      if (res.data.results.length > 0) {
        const jobId = res.data.results[0].jobId;
        navigate(`/compare-ocr-bills/${jobId}`, { replace: true });
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        // console.log(filters);
        const data = { filter: filters, page: currentPage, limit: limit }
        const res = await postOcrList(`${process.env.REACT_APP_API_URL}/ocr/list`, data);
        // console.log(res,'res')
        setOcrData(res.data.results);
        setTotalCount(res.data.total)
        setLoading(false);
      }
      catch (error) {
        console.error("An error occurred while fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, filters]);


  const handleSearch = async () => {
    // console.log(consumerName);
    // console.log(consumerNo);
    setCurrentPage(1)
    let updatedFilters = { ...filters }; // Create a copy of filters

    // Update the copy with consumerName if it exists
    if (consumerName !== "") {
      updatedFilters = { ...updatedFilters, consumerName: consumerName };
    }

    // Update the copy with consumerNo if it exists
    if (consumerNo !== "") {
      updatedFilters = { ...updatedFilters, consumerNo: consumerNo };
    }

    if (status) {
      updatedFilters = { ...updatedFilters, status: status };
    }
    if (startDate) {
      updatedFilters = { ...updatedFilters, startReadingDate: startReadingDate,endReadingDate:endReadingDate };
    }
    console.log(updatedFilters);
    setFilters(updatedFilters); // Set filters with the merged changes
    // console.log(updatedFilters);
  };


  const handleReset = () => {
    setConsumerName("")
    setConsumerNo("");
    setStatus("");
    setStartDate(null)
    setEndDate(null)
    setFilters({
      consumerName: '',
      consumerNo: '',
      status: ["COMPLETED", "ADDED", "MODIFIED", "VALIDATED"],
      startReadingDate: "",
      endReadingDate: ""
    })
  }

  function capitalizeWords(str) {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const formatDateToString = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${month}/${year}`;
  };

  const handleStartDateChange = (date) => {
    let givenDate = new Date(date);
    let month = givenDate.getMonth();
    let year = givenDate.getFullYear();
    let nextMonth = new Date(year, month + 1, 1);
    let lastDateOfMonth = new Date(nextMonth - 1);
    // let firstDayOfMonth = new Date(year, month, 1);
    let firstDayOfMonth = new Date(Date.UTC(year, month, 1));

    setStartDate(lastDateOfMonth);
    setStartReadingDate(firstDayOfMonth)
    // setEndReadingDate(lastDateOfMonth)
  };

  const handleEndDateChange = (date) => {
    let givenDate = new Date(date);
    let month = givenDate.getMonth();
    let year = givenDate.getFullYear();
    let nextMonth = new Date(year, month + 1, 1);
    let lastDateOfMonth = new Date(nextMonth - 1);
    // let firstDayOfMonth = new Date(Date.UTC(year, month, 1));
    setEndDate(lastDateOfMonth);
    setEndReadingDate(lastDateOfMonth)
  };

  const convertIsoToDate = (date)=>{
    if (!moment(date, moment.ISO_8601, true).isValid()) {
        return ""
    }
    return moment(date).format('MM/YYYY');
  }

  useEffect(() => {
    const fetchData = async () => {
        try {
            const organisationsResponse = await postList(`${process.env.REACT_APP_API_URL}/organisation/list`);
            const organisationsList = organisationsResponse?.data?.results;
            const organisationIds = organisationsList.map(org => org._id);
            if (organisationIds?.length > 0) {

              const organisationsData = await Promise.all(
                  organisationIds.map(orgId =>
                      getData(`${process.env.REACT_APP_API_URL}/organisation/tree/${orgId}`)
                  )
              );

              const allFactories = organisationsData?.flatMap(data =>
                  (data?.data?.factories || []).map(factory => ({
                      ...factory,
                      organisationId: data.data._id,
                      name:data.name
                  }))
              );

              const allUnits = allFactories?.flatMap(data =>
                  (data?.units || []).map(unit => ({
                      ...unit,
                      factoryId: data._id
                  }))
              );

              const allMeters = allUnits?.flatMap(data =>
                  (data?.meters || []).map(meter => ({
                      ...meter,
                      unitId: data._id,
                      name:data.name
                  }))
              );

              const uniqueConsumerOptions = allMeters.reduce((accumulator, current) => {  
                if (!accumulator.some(option => option.value === current.consumerNo)) {
                  accumulator.push({
                    label: current.consumerNo + " ("+ current.name +")",
                    value: current.consumerNo
                  });
                }
                return accumulator;
              }, []);

              setConsumerNameOptions(uniqueConsumerOptions)
            }
            setLoading(false);
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
            setLoading(false);
        }
    };
    fetchData();
}, []);

  return (
    <div className="sm:max-w-[1920px] mt-[70px] sm:mt-[100px]">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen ? "sm:ml-[315px]" : "sm:ml-[95px]"} flex-1 sm:mb-8 sm:mr-1 transition-width duration-300`}>
        {/* <div className="ml-[315px] flex-1 mb-8 mr-1"> */}
        {" "}
        <div className="p-4 rounded-lg w-[100%] flex flex-col sm:pb-[20px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:mt-[75px]">
          <div className="text-xl sm:text-[28px] font-medium leading-[42px]">
            Compare OCR Bills
          </div>
          <div className="flex flex-col sm:flex-row sm:flex-wrap lg:justify-around sm:items-center w-full">
            <div className="w-[100%] sm:w-[40%] lg:w-[19%]">
              <input
                type="text"
                value={consumerName}
                placeholder="Search by consumer name"
                onChange={handleConsumerNameChange}
                className="border border-[gray] h-10 w-full rounded-lg p-1"
              />
            </div>
            <div className="border rounded-[10px] outline-none border-gray-500 mt-4 sm:mt-0 w-[100%] sm:ml-[10px] lg:ml-0 sm:w-[40%] lg:w-[19%]">
              <FilterSelect
                options={consumerNoOptions}
                value={consumerNo}
                onChange={handleConsumerNoChange}
                label="Consumer No"
              />
            </div>
            <div className="border rounded-[10px] outline-none border-gray-500 mt-4 lg:mt-0 w-[100%] sm:w-[40%] lg:w-[19%]">
              <FilterSelect
                options={[
                  { value: 'COMPLETED', label: 'Completed' },
                  { value: 'ADDED', label: 'Added' },
                  { value: 'MODIFIED', label: 'Modified' },
                  { value: 'VALIDATED', label: 'Validated' },
                ]}
                value={status}
                onChange={handleStatusChange}
                label="by status"
              />
               
              {/* <select
                value={status}
                placeholder="Search by status"
                onChange={handleStatusChange}
                className="border border-[gray] h-10 w-full rounded-lg p-1"
              >
                <option value="" disabled>Search by status</option>
                <option value="COMPLETED">Completed</option>
                <option value="ADDED">Added</option>
                <option value="MODIFIED">Modified</option>
                <option value="VALIDATED">Validated</option>
              </select> */}
            </div>

            <div className="mt-4 lg:mt-0 sm:ml-[10px] lg:ml-0 compare-ocr w-[100%] sm:w-[40%] lg:w-[19%]">
              <div className="customDatePickerWidth">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="MMM-yyyy"
                  selectsStart
                  showMonthYearPicker
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Select Start Month"
                  maxDate={new Date()}
                  className="w-full"
                />
              </div>
            </div>
            <div className="mt-4 lg:mt-0 compare-ocr w-[100%] sm:w-[40%] lg:w-[19%]">
              <div className="customDatePickerWidth">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="MMM-yyyy"
                  showMonthYearPicker
                  placeholderText="Select End Month"
                  maxDate={new Date()}
                  className="w-full"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end my-2 mx-2 md:mx-2">
            <button onClick={handleSearch} className="text-white bg-[#067C4E] hover:bg-[#045036] w-[100px] py-2 px-4 rounded-lg">
              Filter
            </button>
            <button onClick={handleReset} className="bg-white border-[#067C4E] hover:bg-gray-200 text-[#067C4E] border-2 border-solid w-[100px] py-2 px-4 ml-3 rounded-lg">
              Reset
            </button>
          </div>
          {loading ? <LoaderComponent /> :
            <>
              <div className="bg-white relative overflow-x-auto">
                <table className="w-full">
                  <thead className="p-5">
                    <tr className="text-left bg-[#EFEFEF]">
                      <th className="p-4">Meter Number</th>
                      <th>Consumer Name</th>
                      <th>Consumer No</th>
                      <th>Reading Date</th>
                      <th>Bill Month</th>
                      <th className="py-7 sm:py-4 flex items-center justify-start space-x-2">
                        <span>Status</span>
                        <CustomTooltop text={constantUtils.getStatusToolTipData().Status.text} size="sm" />
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {ocrData.length === 0 ? (
                        <tr>
                          <td
                            colSpan={7}
                            className="w-screen px-6 py-4 text-sm leading-5 text-gray-500 text-center"
                          >
                            No Data Available
                          </td>
                        </tr>
                          ):
                    
                    (ocrData?.map((item, index) => {
                      // console.log(item,'item');
                      return (
                        <tr key={index} className="text-left text-[#5d5c5c]">
                          <td className="p-4">{item.data?.meterNo?.value}</td>
                          <td>{capitalizeWords(item.data.consumerName.value)}</td>
                          <td>{item.data.consumerNo.value}</td>
                          <td>{item.data?.readingDate?.value}</td>
                          <td>{item.data?.readingDate?.value?convertIsoToDate(item?.billMonthOfLastDay):""}</td>
                          <td>{capitalizeWords(item.data.status)}</td>
                          <td className="cursor-pointer text-[blue]">
                            <Link to={`/compare-ocr-bills/${item.jobId}`}>
                              Validate
                            </Link>
                          </td>
                        </tr>
                      )
                    }))}
                  </tbody>
                </table>
              </div>
              {ocrData.length !== 0 && <div className="pagination-controls border-t-2  border-[#abaaaa] w-full flex flex-row items-center justify-end pt-2">
                <button className={`bg-[#e1e0e0] p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                <span className="mx-4">
                  {/* {`${(currentPage - 1) * limit + 1} to ${Math.min(currentPage * limit, totalCount)} of ${totalCount}`} */}
                  {totalCount > 0 ?
                    `${(currentPage - 1) * limit + 1} to ${Math.min(currentPage * limit, totalCount)} of ${totalCount}`
                    : "0"
                  }
                </span>
                {/* <span className="mx-4">{Math.min(currentPage * limit, totalCount)-9} to {Math.min(currentPage * limit, totalCount)} of {totalCount}</span> */}
                <button className="bg-[#e1e0e0] p-3 rounded-xl" disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
              </div>}
            </>
          }


        </div>
      </div>
    </div>
  );
};

export default CompareOcrBills;

import React, { useEffect } from "react";
import {
  faClone,
  faClose,
  faCompress,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Line } from "react-chartjs-2";
import LegendComponent from "../../components/chart/LegendComponent";
import CustomToolTipICon from "../../components/tooltips/CustomToolTipICon";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  
  
const LineChartEnergyView = ({ options, data, full, setFull }) => {
  useEffect(() => {
    //    console.log({data});
  }, [data]);

  if (full === true) {
    return (
      <div className="fixed left-0 top-0 bg-white w-screen sm:min-w-[700px] sm:w-full z-50 flex flex-col justify-center h-screen items-center">
        {/* <div
          className="left-0 sm:left-[95%] sm:right-0 top-0 absolute px-10 pt-2 sm:pt-6 cursor-pointer"
          onClick={() => setFull(false)}
        >
          <FontAwesomeIcon icon={faCompress} />
        </div> */}
        <div
          className="left-0 sm:left-[94%] z-40 sm:right-0 top-1 absolute px-5 sm:px-10  pt-4 sm:pt-2 cursor-pointer"
          onClick={() => setFull(false)}
        >
          <CustomToolTipICon
            icon={faClose}
            size="xl"
            color={"black"}
            text={"close"}
          />
        </div>
        <div className="absolute hidden  top-4 w-full md:flex justify-center">
          <LegendComponent data={data} columns={"grid-cols-4"} />
        </div>
        <div
          // style={{
          //   position: "relative",
          //   margin: "auto",
          //   width: "90vw",
          //   height: "90vh",
          // }}
          className="line-graph"
        >
          <div className=" w-full flex md:hidden justify-center">
            <LegendComponent data={data} columns={"grid-cols-4"} />
          </div>
          <Line options={options} data={data} />
        </div>{" "}
      </div>
    );
  } else {
    return (
      <div>
        <Line options={options} data={data} />
      </div>
    );
  }
};

export default LineChartEnergyView;

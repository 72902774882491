import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import CostAnnualView from "./annually/CostAnnualView";
import CostMonthlyView from "./monthly/CostMonthlyView";
import MonthlyTodCostView from "./monthly/MonthlyTodCostView";
import { faBackward, faBackwardStep } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CostDistrubutionView from "./costDistrubutionView/CostDistrubutionView";
import { BillConsumptionService } from "../services/graphs/billConsumptionService";
import EnergyTabs from "../components/tabs/EnergyTabs";
import ASelect from "../components/select/ASelect";
import { ConstantUtils } from "../utils/ConstantUtils";
import LoaderComponent from "../components/loaderCompent/LoaderComponent";
import SolarEnergyView from "./billConsumption/SolarEnergyView";
import TolalEnergyView from "./billConsumption/TolalEnergyView";
import BackButton from "../components/backButton/BackButton";
import GoTODashboard from "../components/button/GoTODashboard";

const billService = new BillConsumptionService();
const constantUtils = new ConstantUtils();

const BillConsumptionView = () => {
  const [selectedEnergyGraph, setSelectedEnergyGraph] = useState("Annual");
  const [selectedCostGraph, setselectedCostGraph] = useState("Annual");
  const [selectedEnergyYear, setselectedEnergyYear] = useState("");
  const [selectedEnergyMonth, setSelectedEnergyMonth] = useState("");
  const [billingDemand, setBillingDemand] = useState([]);
  const [energyBillingDemand, setEnergyBillingDemand] = useState([]);
  const [costBillingDemand, setCostBillingDemand] = useState([]);
  const [filterCheckBoxSelected, setFilterCheckBoxSelected] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [slotnames, setSlotnames] = useState([]);
  const [slotTimelables, setSlotTimelables] = useState([]);
  const [slotColors, setSlotColors] = useState({});
  const [monthlyData, setMonthlyData] = useState([])
  const renderCostTabContent = () => {
    switch (selectedCostGraph) {
      case "Annual":
        return (
          <CostAnnualView
            annualData={annualData}
            selectedCostGraph={selectedCostGraph}
            handleCostGraph={handleCostGraph}
          />
        );
      case "Monthly":
        return (
          <CostMonthlyView
            costBillingDemand={costBillingDemand}
            selectedUnit={selectedUnit}
            energyMonthlyData={energyMonthlyData}
            selectedCostYear={selectedCostYear}
            selectedCostMonth={selectedCostMonth}
            selectedCostGraph={selectedCostGraph}
            handleCostGraph={handleCostGraph}
          />
        );
      case "TOD":
        return (
          <MonthlyTodCostView
            getTodData={getTodData}
            costMonthlyBillingDemand={costMonthlyBillingDemand}
            selectedCostMeterData={selectedCostMeterData}
            selectedCostYear={selectedCostYear}
            metersList={metersList}
            annualData={annualData}
            selectedCostMeter={selectedCostMeter}
            selectedCostMonth={selectedCostMonth}
            selectedCostGraph={selectedCostGraph}
            handleCostGraph={handleCostGraph}
            setSelectedCostMeter={setSelectedCostMeter}
            slotnames={slotnames}
            slotTimelables={slotTimelables}
          />
        );

      case "BillDistrubutionView":
        return (
          <CostDistrubutionView
            selectedEnergyMeter={selectedEnergyMeter}
            selectedCostGraph={selectedCostGraph}
            handleCostGraph={handleCostGraph}
            selectedCostYear={selectedCostYear}
            selectedCostMonth={selectedCostMonth}
          />
        );

      default:
        return <p>Aggregated Component</p>;
    }
  };

//   //   ===================================================
  const [searchParams] = useSearchParams();

  const [queryParams, setQueryParams] = useState({
    unitId: searchParams.get("unitId"),
    type: searchParams.get("type"),
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
  });

  console.log({ entry: searchParams.get("unitId") });
  console.log({ entry: searchParams.get("type") });
  console.log({ entry: searchParams.get("startDate") });
  // =====================================================
  // billing demand

  useEffect(() => {
    const updatedQueryParams = {};
    for (let [key, value] of searchParams.entries()) {
      updatedQueryParams[key] = value;
    }
    setQueryParams(updatedQueryParams);
  }, [searchParams]);

  useEffect(() => {
    console.log({ queryParams });
    if (
      queryParams["unitId"] !== undefined &&
      queryParams["type"] !== undefined &&
      queryParams["startDate"] !== undefined
    ) {
        // setSelectedUnit(queryParams["unitId"]);
        if(queryParams["type"] === "month"){
            // setSelectedEnergyGraph("Monthly");
            // setselectedCostGraph("Monthly")
        }

        
    
  
    }
  }, [queryParams]);

  const getBiilingDemand = async (year, type, unit) => {
    //    console.log({ billingData: "func" });

    let existingData = billingDemand.find(
      (item) => item.year === year.toString() && item.unit === unit.toString()
    );

    if (existingData) {
      //      console.log({ existingData: unit });
      //      console.log({ existingData });
      if (type === "energy") {
        setEnergyBillingDemand(existingData);
      } else if (type === "cost") {
        //        console.log({ costMonthlyData: existingData });
        setCostBillingDemand(existingData);
      }
    } else {
      const billingData = await billService.getBilledDemand(
        year.toString(),
        selectedUnit
      );

      //      console.log({ billingData });
      if (billingData !== undefined) {
        setBillingDemand((prevItems) => [...prevItems, billingData]);
      }
      if (type === "energy") {
        setEnergyBillingDemand(billingData);
        setCostBillingDemand(billingData);
      } else if (type === "cost") {
        //        console.log({ costMonthlyData: billingData });
        setCostBillingDemand(billingData);
      }
    }
  };

  const [montlyBillingDemand, setMontlyBillingDemand] = useState([]);
  const [energyMonthlyBillingDemand, setEnergyMonthlyBillingDemand] = useState(
    []
  );
  const [costMonthlyBillingDemand, setCostMonthlyBillingDemand] = useState([]);

  const getMonthlyBiilingDemand = async (year, month, type, meter) => {
    //    console.log({ year, month, type, meter });
    if (
      year !== undefined &&
      month !== undefined &&
      type !== undefined &&
      meter !== undefined
    ) {
      let existingData = montlyBillingDemand.find(
        (item) =>
          item.year === year?.toString() && item.month === month?.toString()
      );
      //      console.log({ existingData });
      if (existingData) {
        if (type === "energy") {
          setEnergyMonthlyBillingDemand(existingData);
        } else if (type === "cost") {
          //          console.log({ costMonthlyData: existingData });
          setCostMonthlyBillingDemand(existingData);
        }
      } else {
        const NewMonthlybillingData = await billService.getMonthlyBilledDemand(
          year.toString(),
          month,
          meter
        );

        //        console.log({ NewMonthlybillingData });
        if (NewMonthlybillingData !== undefined) {
          setMontlyBillingDemand((prevItems) => [
            ...prevItems,
            NewMonthlybillingData,
          ]);

          if (type === "energy") {
            setEnergyMonthlyBillingDemand(NewMonthlybillingData);
          } else if (type === "cost") {
            //            console.log({ costMonthlyBillingDemand: NewMonthlybillingData });
            setCostMonthlyBillingDemand(NewMonthlybillingData);
          }
        } else {
          setEnergyMonthlyBillingDemand([]);
          setCostMonthlyBillingDemand([]);
        }
      }
    }
  };

  useEffect(() => {
    //    console.log({ costMonthlyBillingDemand: costMonthlyBillingDemand });
  }, [costMonthlyBillingDemand]);

  //========================================================
  // energy
  // ========================================================
  const handleEnergygraph = (type, selectedYear, selectedMonth) => {
    setSelectedEnergyGraph(type);
    setselectedEnergyYear(selectedYear);
    setSelectedEnergyMonth(selectedMonth);
    //    console.log({ type, selectedYear, selectedMonth });
  };
  // ======================================================================
  // annual energy consumption data
  // ======================================================================
  // year labels

  const [selectedCostYear, setSelectedCostYear] = useState("");

  const [selectedCostMonth, setSelectedCostMonth] = useState("");

  const handleCostGraph = (type, year, month) => {
    //    console.log({ type, year, month });
    setselectedCostGraph(type);
    setSelectedCostYear(year);
    setSelectedCostMonth(month);
  };
  // ==========================================================================
  //  annual data

  const [annualData, setAnnualData] = useState({
    years: [],
    months: [],
    energyData: [],
    costData: [],
  });

  useEffect(() => {
    const fetchGraphData = async () => {
      const data = await billService.GetGraphdata();
      //      console.log({ apidata: data });
      if (data !== undefined) {
        setAnnualData(data);
      }
    };
    fetchGraphData();
  }, []);

  function updateData(existingData, newData) {
    for (let year in newData) {
      if (newData.hasOwnProperty(year)) {
        // Check if the year exists in the existing data
        if (existingData.hasOwnProperty(year)) {
          for (let unitId in newData[year]) {
            if (newData[year].hasOwnProperty(unitId)) {
              // Check if the unit ID exists for the given year in the existing data
              if (existingData[year].hasOwnProperty(unitId)) {
                // Update the existing unit data
                existingData[year][unitId].energyMonthLyData =
                  newData[year][unitId].energyMonthLyData;
                existingData[year][unitId].costMonthLyData =
                  newData[year][unitId].costMonthLyData;
              } else {
                // Add new unit ID with the provided data
                existingData[year][unitId] = {
                  energyMonthLyData: newData[year][unitId].energyMonthLyData,
                  costMonthLyData: newData[year][unitId].costMonthLyData,
                };
              }
            }
          }
        } else {
          // Add new year with the unit data
          existingData[year] = newData[year];
        }
      }
    }

    return existingData;
  }

  const [energyMonthlyData, setEnergyMonthlyData] = useState({});
  const [costMonthlyData, setCostMonthlyData] = useState({});

  const fetchMonthLyGraphData = async (year, unit) => {
    //    console.log({ year });
    const existingUnitData = slotnames.find((slot) => unit in slot);
    //console.log({existingUnitData})
    if (existingUnitData && existingUnitData[unit] !== undefined) {
      const data = await billService.getMonthlyConsumptionTodSlot(
        year.toString(),
        existingUnitData[unit],
        unit,
        slotColors,
        existingUnitData
      );
      console.log({ selectedEnergyYearData: data });
      if (data !== undefined) {

        setMonthlyData({
          ...energyMonthlyData,
          [selectedEnergyYear]: {
            ...energyMonthlyData[selectedEnergyYear],
            [selectedUnit]: {
              ...data[selectedEnergyYear][selectedUnit],
            },
          },
        });

        if(filterCheckBoxSelected){
            setEnergyMonthlyData(data);
            setCostMonthlyData(data)
        }else{
            setEnergyMonthlyData(data);
        }
      }
    }
  };

  const getEnergyYearData = async (unit) => {
    //    console.log({ energyMonthlyData });

    const isYeardataAvailable =
    monthlyData.hasOwnProperty(selectedEnergyYear) &&
    monthlyData[selectedEnergyYear].hasOwnProperty(selectedUnit);
    //    console.log({ isYeardataAvailable });
    if (!isYeardataAvailable) {
      await fetchMonthLyGraphData(selectedEnergyYear, unit);
    } else {
      //      console.log({ energyMonthlyData });
      return monthlyData[selectedEnergyYear][selectedUnit];
    }
  };

  const getCostYearData = async (unit) => {
    //    console.log({ energyMonthlyData });
    const isyearDataAvailable =
    monthlyData.hasOwnProperty(selectedCostYear) &&
    monthlyData[selectedCostYear].hasOwnProperty(selectedUnit);
    //    console.log({ isyearDataAvailable });
    if (!isyearDataAvailable) {
      await fetchMonthLyGraphData(selectedCostYear, unit);
      if (isyearDataAvailable) {
        return monthlyData.selectedCostYear;
      }
    } else {
      return monthlyData[selectedCostYear][selectedUnit];
    }
  };

  useEffect(() => {
       console.log({ monthlyData: monthlyData });
  }, [monthlyData]);

  useEffect(() => {
       console.log({selectedEnergyYear,selectedUnit,slotnames})
    if (selectedEnergyYear !== "") {
      //      console.log({ func: "call" });
      if (selectedEnergyGraph === "Monthly") {
        //        console.log("getting monthly data")
        if(filterCheckBoxSelected){
            getEnergyYearData(selectedUnit);
            getBiilingDemand(selectedEnergyYear, "energy", selectedUnit);
            getCostYearData(selectedUnit);
            getBiilingDemand(selectedEnergyYear, "cost", selectedUnit);
        }else{
            getEnergyYearData(selectedUnit);
            getBiilingDemand(selectedEnergyYear, "energy", selectedUnit);
        }
        
      }
    } else {
    }
  }, [selectedEnergyYear, selectedUnit, slotnames]);

//   useEffect(() => {
//     if (selectedCostYear !== "") {
//            console.log({ selectedCostGraph, selectedCostYear });
//       if (selectedCostGraph === "Monthly" ) {
//         getCostYearData(selectedUnit);
//         getBiilingDemand(selectedCostYear, "cost", selectedUnit);
//       }
//     }
//   }, [selectedCostYear, selectedUnit,slotnames]);

  // ===================================================
  //  energy tod data
  const [monthlyTodEnergyData, setMonthlyTodEnergyData] = useState([]);

  useEffect(() => {
    //    console.log({ monthlyTodEnergyData });
  }, [monthlyTodEnergyData]);

  const [monthlyTodData, setMonthlyTodData] = useState([]);

  const [metersList, setMetersList] = useState([]);

  const [selectedEnergyMeter, setSelectedEnergyMeter] = useState("");
  const [selectedCostMeterData, setSelectedCostMeterData] = useState([]);
  const [selectedCostMeter, setSelectedCostMeter] = useState("");

  const getMetersList = async () => {
    const data =
      await billService.getMetersListWithUserNameAndOrganisationName();
    //    console.log({ getMetersListWithUserNameAndOrganisationName: data });
    if (data && data !== undefined) {
      setMetersList(data);
      setSelectedEnergyMeter(data[0]._id);
      setSelectedFilterMeter(data[0]._id);
      setSelectedCostMeter(data[0]._id);
    }
  };

  const getTodData = async (year, month, meter, type, unit) => {
    //    console.log({ year, month, meter });
    if (year && month && type && meter !== "") {
      console.log({ monthlyTodData, year, month, meter });
      let existingData = monthlyTodData.find(
        (item) =>
          item.year === year.toString() &&
          item.month === month.toLowerCase() &&
          item.meter === meter
      );
      console.log({ existingData });
      if (existingData) {
        if (type === "energy") {
          setMonthlyTodEnergyData(existingData);
        //   setSelectedCostMeterData(existingData);
        }
        if ( filterCheckBoxSelected) {
          setSelectedCostMeterData(existingData);
        }
      } else {
        const existingUnitData = slotnames.find((slot) => unit in slot);

        if (existingUnitData !== undefined) {
          console.log({ existingUnitData });

          const data = await billService.getSpecificMonthTodData(
            year.toString(),
            month.toString().toLowerCase(),
            meter,
            existingUnitData[unit],
            slotColors
          );
          console.log({ getTodData: data });
          if (data && data !== undefined) {
            console.log({ selectedMeterData: data });
            setMonthlyTodData((prevItems) => [...prevItems, data]);

            setMonthlyTodEnergyData(data);
            if (type === "energy") {
                setMonthlyTodEnergyData(data);
              //   setSelectedCostMeterData(existingData);
              }
              if (filterCheckBoxSelected) {
                setSelectedCostMeterData(data);
              }
          } else {
            setMonthlyTodEnergyData([]);
            setSelectedCostMeterData([]);
          }
        }
      }
    }
  };

  useEffect(() => {}, [selectedEnergyMeter]);

  useEffect(() => {
    console.log({ metersList });

    if (selectedEnergyYear !== "" && selectedEnergyMonth !== "") {
      const selectedMeterData = metersList.filter(
        (meter) => meter._id === selectedEnergyMeter
      );
      if (
        selectedEnergyGraph === "TOD" &&
        selectedMeterData[0]?.unitId?._id !== undefined
      ) {
        const selectedmeterUnit = selectedMeterData[0]?.unitId?._id;
        console.log({ selectedmeterUnit });

        console.log({ selectedmeterUnit, selectedUnit, selectedEnergyMeter });
        const unitData = getUnitData(selectedmeterUnit);
        setSLotData(unitData);
        setSelectedUnit(selectedmeterUnit);

        console.log({ selectedMeterData });
        getTodData(
          selectedEnergyYear,
          selectedEnergyMonth,
          selectedEnergyMeter,
          "energy",
          selectedmeterUnit
        );
        getMonthlyBiilingDemand(
          selectedEnergyYear,
          selectedEnergyMonth,
          "energy",
          selectedEnergyMeter
        );
      }
      if (metersList.length === 0) {
        getMetersList();
      }
    }
  }, [
    selectedEnergyYear,
    selectedEnergyMonth,
    selectedUnit,
    selectedEnergyMeter,
  ]);

  // =======================================================
  //  cost monthly data
  const [selectedYearMOnthlyCostData, setSelectedYearMOnthlyCostData] =
    useState([]);
  //   const fetchMonthLyCostGraphData = async () => {
  // //    console.log({ selectedCostYear });
  //     const data = await billService.getMonthlyCostConsumptionTodSlot(
  //       selectedCostYear.toString()
  //     );
  // //    console.log({ selectedCostYear: data });
  //     if (data !== undefined) {
  //       setSelectedYearMOnthlyCostData(data);
  //     }
  //   };

    useEffect(() => {
      if (selectedCostYear !== "" && selectedCostMonth !== "") {
        //      console.log({ selectedCostMeter });
        // const meterId=selectedCostMeter!== ''?selectedCostMeter.id:''

        getTodData(
          selectedCostYear,
          selectedCostMonth,
          selectedCostMeter,
          "cost"
        );
        getMonthlyBiilingDemand(
          selectedCostYear,
          selectedCostMonth,
          "cost",
          selectedCostMeter
        );
        if (metersList.length === 0) {
          getMetersList();
        }
      }
    }, [selectedCostYear, selectedCostMonth, selectedCostMeter,selectedUnit]);

  const [lableValue, setLableValue] = useState(
    constantUtils.getAllStaticMonthsFromApril()
  );

  const [labels, setLabels] = useState(
    Object.keys(constantUtils.getMonthsFromApril())
  );
  const [selectedFilterYear, setSelectedFilterYear] = useState("");
  const [selectedFilterMonth, setSelectedFilterMonth] = useState("");
  const [selectedFilterMeter, setSelectedFilterMeter] = useState(
    selectedEnergyMeter.meterNo
  );
  const [loading, setLoading] = useState(true);

  const handleYearFilter = (e) => {
    //    console.log({ first: e.target.value });
    //    console.log({ first: filterCheckBoxSelected });
    setSelectedFilterYear(e.target.value);
    setLoading(true);
    if (filterCheckBoxSelected) {
      if (selectedEnergyGraph === "Annual") {
        handleEnergygraph("Monthly", e.target.value);
        setTimeout(() => {
          setselectedCostGraph(
            "Monthly",
            selectedEnergyYear,
            selectedEnergyMonth
          );
        }, 400);
      }
      setselectedEnergyYear(e.target.value);
      setSelectedCostYear(e.target.value);
    } else {
      if (selectedEnergyGraph === "Annual") {
        handleEnergygraph("Monthly", e.target.value);
      }
      setselectedEnergyYear(e.target.value);

      //   if (selectedCostGraph === "Annual") {
      //     handleCostGraph("Monthly", selectedEnergyYear);
      //   }
    }
  };

  const handleMonthFilter = (e) => {
    //    console.log({ first: e.target.value });
    //    console.log({ first: filterCheckBoxSelected });
    setSelectedFilterMonth(e.target.value);
    setLoading(true);
    if (filterCheckBoxSelected) {
      if (selectedEnergyGraph === "Monthly") {
        setSelectedEnergyGraph("TOD");
        setselectedCostGraph("TOD");
      }
      setSelectedEnergyMonth(lableValue[e.target.value]);
      setSelectedCostMonth(lableValue[e.target.value]);
    } else {
      setSelectedEnergyMonth(lableValue[e.target.value]);
      if (selectedEnergyGraph === "Monthly") {
        setSelectedEnergyGraph("TOD");
      }
    }
  };

  const handleMeterFilter = (e) => {
    //    console.log({ first: e.target.value });
    setSelectedFilterMeter(e.target.value);
    if (filterCheckBoxSelected) {
      setSelectedEnergyMeter(e.target.value);
      setSelectedCostMeter(e.target.value);
    } else {
      setSelectedEnergyMeter(e.target.value);
    }
  };

  const handleBackButton = () => {
    if (selectedEnergyGraph === "Monthly") {
      setSelectedFilterYear("");
      if (filterCheckBoxSelected) {
        handleEnergygraph("Annual", selectedEnergyYear);
        handleCostGraph("Annual", selectedEnergyYear);
      } else {
        handleEnergygraph("Annual", selectedEnergyYear);
      }
    } else if (selectedEnergyGraph === "TOD" && selectedCostGraph === "TOD") {
      setSelectedFilterMonth("");
      if (filterCheckBoxSelected) {
        handleCostGraph("Monthly", selectedEnergyYear, selectedEnergyMonth);

        setTimeout(() => {
          handleEnergygraph("Monthly", selectedEnergyYear, selectedEnergyMonth);
        }, 400);
      } else {
        setTimeout(() => {
          handleEnergygraph("Monthly", selectedEnergyYear, selectedEnergyMonth);
        }, 400);
      }
    } else if (selectedCostGraph === "BillDistrubutionView") {
      if (filterCheckBoxSelected) {
        handleEnergygraph("TOD", selectedEnergyYear, selectedEnergyMonth);
        handleCostGraph("TOD", selectedEnergyYear, selectedEnergyMonth);
      } else {
        handleCostGraph("TOD", selectedEnergyYear, selectedEnergyMonth);
      }
    }
  };

  useEffect(() => {
    //    console.log({ slotColors });
  }, [slotColors]);

  useEffect(() => {
    if (selectedEnergyGraph === "Annual") {
      setselectedCostGraph("Annual");
    } else if (selectedEnergyGraph === "Monthly") {
      handleCostGraph("Monthly", selectedEnergyYear, selectedEnergyMonth);
    } else if (selectedEnergyGraph === "TOD") {
      handleCostGraph("TOD", selectedEnergyYear, selectedEnergyMonth);
    }
  }, [filterCheckBoxSelected]);

  useEffect(() => {
    if (selectedEnergyGraph === "Monthly") {
      //      console.log({ energyMonthlyData: energyMonthlyData[selectedEnergyYear] });

      if (
        energyMonthlyData[selectedEnergyYear] !== undefined ||
        energyMonthlyData !== undefined
      ) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else if (selectedEnergyGraph === "Annual") {
      if (annualData.years.length > 0) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else if (selectedEnergyGraph === "TOD") {
      if (
        monthlyTodEnergyData.year === selectedEnergyYear.toString() &&
        monthlyTodEnergyData.month === selectedEnergyMonth.toLowerCase()
      ) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
  }, [
    energyMonthlyData,
    monthlyTodEnergyData,
    selectedEnergyYear,
    selectedEnergyMonth,
    annualData,
    selectedEnergyGraph,
  ]);

  const [unitList, setUnitList] = useState([]);
  const [unitData, setUnitData] = useState("");

  const getUnitListFromApi = async () => {
    const getUnitListFromApi = await billService.getAllUnitsByUserId();
    //    console.log({ getUnitListFromApi });
    setUnitList(getUnitListFromApi);
    if (getUnitListFromApi && getUnitListFromApi.length > 0) {
      setSelectedUnit(getUnitListFromApi[0]._id);
    }
  };

  const setSLotData = (selectdUnitData) => {
    console.log({ selectdUnitData });
    if (
      selectdUnitData !== "" &&
      selectdUnitData !== undefined &&
      selectdUnitData.length > 0 &&
      selectdUnitData[0]["stateCode"] !== undefined &&
      selectdUnitData[0]["discomCode"] !== undefined
    ) {
      console.log({ stateCode: selectdUnitData[0]["stateCode"] });
      const configData = JSON.parse(localStorage.getItem("config"));
      const statecode = selectdUnitData[0]["stateCode"];
      const disComCode = selectdUnitData[0]["discomCode"];
      //      console.log({ configData: configData });
      //      console.log({ statecode });
      //      console.log({ disComCode });
      const unitId = selectdUnitData[0]["_id"];
      if (
        configData &&
        configData.slotConfig &&
        configData.slotConfig[statecode] &&
        configData.slotConfig[statecode][disComCode] &&
        configData.slotConfig[statecode][disComCode]["slotName"] !==
          undefined &&
        configData.slotConfig[statecode][disComCode]["slotColor"] !== undefined
      ) {
        const getSlotNames = Object.keys(
          configData.slotConfig[statecode][disComCode]["slotName"]
        );
        const getSlotTimeLabels =
          configData.slotConfig[statecode][disComCode]["slotTimeLabels"];

        //        console.log({ getSlotNames });

        //        console.log({unitId})
        const unitIdExists = slotnames.some((slot) => unitId in slot);
        //        console.log({unitIdExists})
        if (!unitIdExists) {
          setSlotnames((prevData) => [...prevData, { [unitId]: getSlotNames }]);
        }

        setSlotTimelables(getSlotTimeLabels);
        const slotColorsData =
          configData.slotConfig[statecode][disComCode]["slotColor"];
        //        console.log({ slotColorsData });
        setSlotColors(slotColorsData);
      } else {
        const unitIdExists = slotnames.some((slot) => unitId in slot);
        //        console.log({unitIdExists})
        if (!unitIdExists) {
          setSlotnames((prevData) => [...prevData, { [unitId]: [] }]);
        }
        setSlotColors([]);
      }
    } else {
      setSlotColors(undefined);
    }
  };

  useEffect(() => {
    console.log({ unitData });
    setSLotData(unitData);
  }, [unitData]);

  useEffect(() => {
    //    console.log({ slotnames });
  }, [slotnames]);

  useEffect(() => {
    getUnitListFromApi();
  }, []);

  const getUnitData = (id) => {
    const unitData = unitList.filter((unit) => unit._id === id);

    //    console.log({ unitData });
    setUnitData(unitData);
    return unitData;
  };

  useEffect(() => {
    console.log({ unitData });
  }, [unitData]);

  useEffect(() => {
    if (selectedUnit !== "") {
      getUnitData(selectedUnit);
    }
  }, [selectedUnit]);

  function containsNonZero(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        if (arr[i][j] !== 0) {
          return true; // Found a non-zero value
        }
      }
    }
    return false; // All values are 0
  }

  const [SolarDataPresent, setSolarDataPresent] = useState(true);

  useEffect(() => {
    if (annualData.solarEnergyData !== undefined) {
      const result = containsNonZero(annualData.solarEnergyData);
      setSolarDataPresent(result);
    }
  }, [annualData]);

  useEffect(() => {
    //    console.log({ slotnames });
  }, [slotnames]);

  return (
    <div
      className={`mt-[80px] md:mt-[50px] flex  flex-col  justify-between sm:gap-[50px] `}
    >
      <div className="flex  sm:flex-row flex-col-reverse justify-end gap-2 py-2 md:py-0 md:gap-10 items-center">
        <div className="bg-white border border-gray-200  min-h-[55px] w-full  shadow-lg py-2 items-center grid grid-cols-2 md:flex  sm:flex-row gap-5 px-8 rounded-lg">
          <div className="md:min-w-[80px]">Filters</div>
          <div></div>
          <div
            className={`${
              (loading && selectedEnergyGraph === "Monthly") ||
              (loading && selectedEnergyGraph === "Annual") ||
              (loading && selectedEnergyGraph === "TOD")
                ? "hidden"
                : ""
            }`}
          >
            <ASelect
              label={"year"}
              height={"45px"}
              IconBottom={"bottom-4"}
              options={annualData.years?.map((org) => {
                return { label: org, value: org };
              })}
              onChange={handleYearFilter}
              value={selectedFilterYear}
            />
          </div>
          <div
            className={`${
              selectedCostGraph === "Monthly" ||
              selectedEnergyGraph === "Monthly"
                ? "block"
                : "hidden"
            } max-w-[180px]`}
          >
            <ASelect
              label={"Unit"}
              height={"45px"}
              IconBottom={"bottom-4"}
              options={unitList?.map((unit) => {
                return { label: unit.name, value: unit._id };
              })}
              onChange={(e) => setSelectedUnit(e.target.value)}
              value={selectedUnit}
            />
          </div>
          <div
            className={`${
              (selectedCostGraph === "Monthly" ||
                selectedEnergyGraph === "Monthly" ||
                selectedCostGraph === "TOD" ||
                selectedEnergyGraph === "TOD") &&
              !loading &&
              (selectedEnergyGraph === "Monthly" ||
                selectedEnergyGraph === "TOD")
                ? "block"
                : "hidden"
            }`}
          >
            <ASelect
              label={"month"}
              height={"45px"}
              IconBottom={"bottom-4"}
              options={labels?.map((month) => {
                return { label: month, value: month };
              })}
              onChange={handleMonthFilter}
              value={selectedFilterMonth}
            />
          </div>

          <div
            className={`${
              (selectedEnergyGraph === "TOD" && !loading) ||
              (selectedCostGraph === "TOD" && !loading) ||
              (selectedCostGraph === "BillDistrubutionView" && !loading)
                ? "block"
                : "hidden"
            }`}
          >
            {" "}
            <ASelect
              label={"Consumer No"}
              height={"45px"}
              IconBottom={"bottom-4"}
              options={metersList?.map((meter) => {
                return { label: meter.consumerNo, value: meter._id };
              })}
              onChange={handleMeterFilter}
              value={selectedFilterMeter}
            />
          </div>

          <div
            className={`${
              selectedCostGraph === "BillDistrubutionView" ||
              (loading && selectedEnergyGraph === "Monthly") ||
              (loading && selectedEnergyGraph === "Annual") ||
              (loading && selectedEnergyGraph === "TOD")
                ? "hidden"
                : ""
            } flex items-center gap-1 `}
          >
            <div>
              <input
                type="checkbox"
                onChange={(e) => setFilterCheckBoxSelected(e.target.checked)}
                className="w-[25px] h-[25px] mt-2"
                checked={filterCheckBoxSelected}
              />
            </div>
            <div className={` text-gray-400 text-sm capitalize `}>
              Apply To Both
            </div>
          </div>

          <div
            className={`   ${
              (loading &&
                selectedCostGraph === "Monthly" &&
                selectedEnergyGraph === "Monthly") ||
              (loading &&
                selectedCostGraph === "TOD" &&
                selectedEnergyGraph === "TOD") ||
              (loading &&
                selectedCostGraph === "Annual" &&
                selectedEnergyGraph === "Annual") ||
              (loading && selectedCostGraph === "BillDistrubutionView")
                ? ""
                : "hidden"
            }`}
          >
            <LoaderComponent width={25} height={25} />
          </div>
        </div>
        <div className=" absolute md:relative mt-2 right-[5%] md:right-0 md:mt-0  flex  w-full md:w-auto justify-end">
          {" "}
          <div
            onClick={handleBackButton}
            className={`${
              selectedEnergyGraph === "Annual" ? "hidden" : ""
            }  bg-white border text-xs md:text-md  justify-end border-gray-200 md:shadow-lg px-1  py-1 md:px-8 md:py-4 flex items-center gap-2 cursor-pointer rounded-lg `}
          >
            Back
            <FontAwesomeIcon icon={faBackward} size={"xs"} />
          </div>
        </div>
        <div
          className={` ${
            selectedEnergyGraph !== "Annual" ? "hidden" : ""
          } flex justify-end sm:justify-center items-center w-full sm:w-[100px] `}
        >
          <GoTODashboard />
        </div>
      </div>{" "}
      <div className="flex flex-col lg:flex-row gap-5">
        <EnergyTabs
          selectedEnergyGraph={selectedEnergyGraph}
          annualData={annualData}
          unitList={unitList}
          slotTimelables={slotTimelables}
          handleEnergygraph={handleEnergygraph}
          energyBillingDemand={energyBillingDemand}
          selectedUnit={selectedUnit}
          energyMonthlyData={energyMonthlyData}
          selectedEnergyYear={selectedEnergyYear}
          getTodData={getTodData}
          slotnames={slotnames}
          energyMonthlyBillingDemand={energyMonthlyBillingDemand}
          monthlyTodEnergyData={monthlyTodEnergyData}
          selectedEnergyMonth={selectedEnergyMonth}
          metersList={metersList}
          selectedEnergyMeter={selectedEnergyMeter}
          setSelectedEnergyMeter={setSelectedEnergyMeter}
          selectedCostGraph={selectedCostGraph}
        />
        {renderCostTabContent()}
      </div>
      <div
        className={`${
          selectedEnergyGraph === "Annual" && SolarDataPresent
            ? "block"
            : "hidden"
        }     `}
      >
        <SolarEnergyView annualData={annualData} />
      </div>
      <div
        className={`${
          selectedEnergyGraph === "Annual" && SolarDataPresent
            ? "block"
            : "hidden"
        }`}
      >
        <TolalEnergyView annualData={annualData} />{" "}
      </div>
    </div>
  );
};

export default BillConsumptionView;

import React from "react";
import { Link } from "react-router-dom";

class Home extends React.Component {
  render() {
    return (
      <div className="m-5 h-screen flex justify-center flex-col items-center">
        Home works
      </div>
    );
  }
}

export default Home;

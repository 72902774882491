import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

const CustomTooltopDashboard = ({ text, size, disable }) => {

  const [tooltipId, setTooltipId] = useState('');

  useEffect(() => {
    setTooltipId(`my-tooltip-${Math.random().toString(36).substr(2, 9)}`);
  }, []);
  return (
    <div className={disable ? "hidden" : ""}>
      <button data-tooltip-id={tooltipId}>
      <div className="icon-container">
          <FontAwesomeIcon
            className="w-4 h-4 top-[4px]"
            // size={size ? size : "lg"}
            style={{ color: "gray", position: 'relative', zIndex: 0 }}
            icon={faCircleInfo}
            place={'top'}
          />
        </div>
      </button>
      <div>
        <Tooltip
          style={{ backgroundColor: "green", color: "white", opacity: "100" }}
          id={tooltipId}
          place="bottom" 
        >
          <div
            className=""
            style={{
              display: "flex",
              maxWidth: "220px",
              flexDirection: "column",
              zIndex: '40'
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default CustomTooltopDashboard;

import React from "react";
import PropTypes from "prop-types";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const MainLayout = ({ children }) => (
  <div>
    <header>
      <Header />
    </header>
    <main>{children}</main>
    <footer>
      <Footer />
    </footer>
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;

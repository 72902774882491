import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDown } from "@fortawesome/free-solid-svg-icons";

const DownLoadbutton = ({ text, Mheight, Mwidth, DHeight, DWidth, handleOnclick,icon }) => {
  return (
    <button
      onClick={handleOnclick}
      className={`text-center rounded-[10px] p-[10px] text-[12px] font-medium leading-[24px] flex items-center text-white bg-[#067C4E] h-[${Mheight}px] w-[${Mwidth}px] md:h-[${DHeight}]px md:w-[${DWidth}px] `}
    >
      <FontAwesomeIcon icon={icon} className=" text-white text-xl mr-2" />{text}
    </button>
  );
};

export default DownLoadbutton;

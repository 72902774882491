import React, { useState } from "react";
import CustomTooltop from "../tooltips/CustomTooltop";
import { ConstantUtils } from "../../utils/ConstantUtils";

const constantUtils = new ConstantUtils();
const AccordionItem = ({
  title,
  children,
  defaultOpen,
  onToggle,
  toggleAction,
  toggleState,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    onToggle(title);
  };
  return (
    <div className="bg-white border  sm:border-0 mt-5 sm:mt-20 lg:mt-0 sm:w-[50%]  rounded-md">
      <dt className="text-lg">
        <div className="sm:flex cursor-pointer items-center  sm:min-h-[140px]  p-[25px] justify-between w-full text-left ">
          <div className="min-h-[100px] sm:min-h-0">
            {title === "Comparison"
              ? "Energy Consumption By Various Consumer Numbers"
              : title === "TOD Energy"
              ? "Annual : ToD Slot Wise Energy Comsumption : Consumer Number"
              : "Annual : ToD Slot Wise Cost  : Consumer Number"}
          </div>
          <div className="flex flex-row items-center   justify-end">
            <div className="flex text-sm items-center mb-2 gap-2 mx-5">
              <CustomTooltop
                size={"lg"}
                text={
                  title === "Comparison"
                    ? constantUtils.getGraphToolTipData().comparisonView.text
                    : title === "TOD Energy"
                    ? constantUtils.getGraphToolTipData().comparisonViewEnergy
                        .text
                    : title === "TOD Cost"
                    ? constantUtils.getGraphToolTipData().comparisonViewCost
                        .text
                    : "Default text"
                }
              />
            </div>
            {isOpen && title !== "Comparison" ? (
              <div className="">
                <label className={`inline-flex   items-center cursor-pointer`}>
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={toggleState}
                    onChange={toggleAction}
                  />
                  <div className="relative w-[100px] h-7 bg-gray-200 rounded-full peer dark:bg-blue-700 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-[72px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute   after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all dark:border-gray-600 peer-checked:bg-green-600">
                    <span
                      className={`${
                        !toggleState
                          ? "right-[10px]  "
                          : "left-[10px]"
                      }  top-[-1px] absolute text-white `}
                    >
                      {toggleState?"cost":"energy"}
                    </span>
                  </div>
                </label>
              </div>
            ) : (
              <></>
            )}
            {/* <div className=" flex items-center" onClick={toggleOpen}>
              {isOpen ? (
                <img alt="dsd" src="/minus.svg" />
              ) : (
                <img alt="dsd" src="/addIcon.svg" />
              )}
            </div> */}
          </div>
        </div>
      </dt>
      {isOpen && (
        <dd className={`${title === "TOD Cost" ? "sm:pt-[10px]" : ""} pb-10`}>
          <p className="text-base text-gray-500">{children}</p>
        </dd>
      )}
    </div>
  );
};
export default AccordionItem;

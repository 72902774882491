import { useState, useEffect } from "react";

const useInputBox = (inputValue, validationTypes, label,fieldName) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    setError({ [`${fieldName}Error`]: "" }); // Reset the error state
    // console.log({ inputValue, validationTypes });
    if (Array.isArray(validationTypes)) {
      // console.log(validationTypes);

      validationTypes.map((type) => {
        // console.log({ type });

        if (type === "required") {
          if (inputValue === "") {
            setError({ [`${fieldName}Error`]: `${fieldName} cannot be empty` });
          }
        }
          else if (type === "email") {
            let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(inputValue)) {
                // console.log({validEmail:inputValue})
              setError({ [`${fieldName}Error`]: `Please enter valid email` });
            }
          } else if (type === "password") {
            let passwordRegex =
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            if (!passwordRegex.test(inputValue)) {
              setError({
                [`${fieldName}Error`]: `Password should be at least 8 characters, contain at least one number and one special character`,
              });
            }
          }
        }
      );
    }
    // console.log({error});
  }, [inputValue]);

  return { error };
};

export default useInputBox;

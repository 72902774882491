import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Abutton from "../../components/button/Abutton";

import { getData, postData, updateData } from "../../api/apiDataExchange";
import { Link, useNavigate, useParams } from 'react-router-dom';
import ViewBox from "../../components/viewBox/ViewBox";
import { useAuth } from "../../hooks/useAuth";
const ViewFactory = () => {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  const [name, setName] = useState("");
  const [org, setOrg] = useState("");
  const [orgName, setOrgName] = useState("");
  const [organisationList, setOrganisationList] = useState([]);

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const [bssrYear, setBssrYear] = useState();
  const [carbonFootprintYear, setCarbonFootprintYear] = useState();
  const [sector, setSector] = useState("");
  const [landOwnedBy, setLandOwnedBy] = useState("");
  const [leaseTenure, setLeaseTenure] = useState("");
  const [landPart, setLandPart] = useState("");

  const [outstandingDuties, setOutstandingDuties] = useState("");
  const [existingSolarTop, setExistingSolarTop] = useState("");
  const [solarCapacity, setSolarCapacity] = useState("");
  const [solarNetBilling, setSolarNetBilling] = useState("");
  const [solarNetMetering, setSolarNetMetering] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const factory = await getData(`${process.env.REACT_APP_API_URL}/factory/${id}`);
      
      if (factory.result.name) {
        setName(factory.result.name);
      }
      if (factory.result.organisationId._id) {
        setOrg(factory.result.organisationId._id);
      }
      if (factory.result.organisationId.name) {
        setOrgName(factory.result.organisationId.name);
      }
      if (factory.result.address) {
        setAddress(factory.result.address);
      }
      if (factory.result.city) {
        setCity(factory.result.city);
      }
      if (factory.result.district) {
        setDistrict(factory.result.district);
      }
      if (factory.result.state) {
        setState(factory.result.state);
      }
      if (factory.result.country) {
        setCountry(factory.result.country);
      }
      if (factory.result.pinCode) {
        setPincode(factory.result.pinCode);
      }
      if (factory.result.bssrYear) {
        setBssrYear(factory.result.bssrYear);
      }
      if (factory.result.landOwnedBy) {
        setLandOwnedBy(factory.result.landOwnedBy);
      }
      if (factory.result.leaseTenure) {
        setLeaseTenure(factory.result.leaseTenure);
      }
      if (factory.result.landPart) {
        setLandPart(factory.result.landPart);
      }
      if (factory.result.sector) {
        setLandPart(factory.result.sector);
      }
      if (factory.result.outstandingDuesFromDISCOM) {
        setOutstandingDuties(factory.result.outstandingDuesFromDISCOM);
      }
      if (factory.result.existingSolarRooftop) {
        setExistingSolarTop(factory.result.existingSolarRooftop);
      }
      if (factory.result.solarRooftopDetails?.Capacity) {
        setSolarCapacity(factory.result.solarRooftopDetails.Capacity);
      }
      if (factory.result.solarRooftopDetails?.netBilling) {
        setSolarCapacity(factory.result.solarRooftopDetails.netBilling);
      }
      if (factory.result.solarRooftopDetails?.netMetering) {
        setSolarNetMetering(factory.result.solarRooftopDetails.netMetering);
      }
      if(factory?.result?.location?.coordinates){
        setLatitude(factory?.result?.location?.coordinates[0])
      }
      if(factory?.result?.location?.coordinates){
        setLongitude(factory?.result?.location?.coordinates[1])
      }
    };
    fetchData().catch(console.error);
  }, [id]);


  const handleBrsrYear = (date) => {
    setBssrYear(date);
  };

  const handleCarbonFootprintYear = (date) => {
    setCarbonFootprintYear(date);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    // console.log({ errors });
    const isErrorResolved =
      errors.some((item) => item["EmailError"] === "") &&
      errors.some((item) => item["PasswordError"] === "");

    const allStateData = {
      buyerId: localStorage.getItem('userId'),
      organisationId: org,
      name: name,
      landPart: landPart,
      address: address,
      location: {
        type: "Point",
        coordinates: [40.712776, -74.005974],
      },
      sector: sector,
      country: country,
      state: state,
      district: district,
      pinCode: pincode,
      landOwnedBy: landOwnedBy,
      leaseTenure: leaseTenure,
      outstandingDuesFromDISCOM: outstandingDuties,
      existingSolarRooftop: existingSolarTop,
      solarRooftopDetails: {
        Capacity: solarCapacity,
        netBilling: solarNetBilling,
        netMetering: solarNetMetering,
      },
    };
    const response = await updateData(
      `${process.env.REACT_APP_API_URL}/factory/${id}`,
      allStateData
    );

    if (response?.data?.error?.code === 200) {
      toast.success('Factory Successfully Updated', {
        position: "top-center",
        autoClose: 3000
      })
      navigate('/my-data');
    } else {
      toast.error(response.error.response.data.error.message);
    }

  };

  useEffect(() => {
    const getdata = async () => {
      const allStateData = {
        buyerId: localStorage.getItem('userId'),
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        allStateData
      );
      setOrganisationList(response?.data?.data?.results);
    };
    getdata();
  }, []);

  const handleErrors = (errorObj) => {
    // console.log(errorObj);

    const key = Object.keys(errorObj)[0];
    const value = errorObj[key];

    const existingIndex = errors.findIndex(
      (error) => Object.keys(error)[0] === key
    );

    if (existingIndex !== -1) {
      const updatedErrors = [...errors];
      updatedErrors[existingIndex][key] = value;
      setErrors(updatedErrors);
    } else {
      setErrors((prevErrors) => [...prevErrors, { [key]: value }]);
    }
  };
  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[170px] pb-20">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen?'sm:ml-[300px]':'sm:ml-[80px]'}`}>
        <div className=" rounded-lg bg-[#E2F5EC] w-full flex flex-col pb-[50px] shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">
          <div className="text-[30px] py-8 text-center font-medium leading-[42px]">
            {" "}
            Factory Details
          </div>
          <div className="w-full">
            <ViewBox
              validationtypes={["required"]}
              label={"Name"}
              value={name}
              maxWidth600={true}
            />
          </div>
          <br />
          <div className=" w-full">
            {" "}
            <ViewBox
              label={"Organisation"}
              maxWidth600={true}
              value={orgName}
              options={organisationList.map((organisation) => {
                return { label: organisation.name, value: organisation._id };
              })}
            />
          </div>
          <br />
          <br />
          <div className=" w-full">
            {" "}
            <ViewBox
              label={"Land Part"}
              maxWidth600={true}
              value={landPart}
            />
          </div>
          <br />
          <div className="flex justify-between items-center">
            <div className="flex justify-between w-full">
              <div className="relative  w-full   max-w-[600px] ">
                <div className="">
                  <div className=" w-full">
                    <ViewBox
                      placeholder="Enter country"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"Country"}
                      maxWidth220={true}
                      value={country}
                      fieldName={"Country"}
                      displayLabel={false}
                      setInputValue={setCountry}
                      handleErrors={handleErrors}
                    />
                  </div>
                  <div className="flex  w-full justify-between items-center">
                    <ViewBox
                      placeholder="Enter state"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"State"}
                      maxWidth220={true}
                      value={state}
                      fieldName={"State"}
                      displayLabel={false}
                      setInputValue={setState}
                      handleErrors={handleErrors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="flex justify-between items-center">
            <div className="flex justify-between w-full">
              <div className="relative  w-full   max-w-[600px] ">
                <div className="  w-full">
                  <div className=" w-full">
                    <ViewBox
                      placeholder="Enter District"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"District"}
                      maxWidth220={true}
                      value={district}
                      fieldName={"District"}
                      displayLabel={false}
                      setInputValue={setDistrict}
                      handleErrors={handleErrors}
                    />
                  </div>
                  <br/>
                  <div className="flex  w-full justify-between items-center">
                    <ViewBox
                      placeholder="Enter Pincode"
                      id="test"
                      type="text"
                      validationtypes={["required"]}
                      showError={formSubmitted}
                      label={"Pincode"}
                      maxWidth220={true}
                      value={pincode}
                      fieldName={"Pincode"}
                      displayLabel={false}
                      setInputValue={setPincode}
                      handleErrors={handleErrors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Enter Address"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Address"}
              value={address}
              maxWidth600={true}
              fieldName={"Address"}
              setInputValue={setAddress}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Enter Sector"
              id="test"
              type="email"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Sector"}
              maxWidth600={true}
              value={sector}
              fieldName={"Sector"}
              setInputValue={setSector}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <br />
          <div className=" w-full">
            {" "}
            <ViewBox
              label={"Land Owned By"}
              isflexed={true}
              defaultValue={"Select option"}
              imputBoxMaxWidth={545}
              maxWidth600={true}
              value={landOwnedBy}
              showLable={true}
              onChange={(e) => {
                setLandOwnedBy(e.target.value);
              }}
              options={[
                { label: "Lease", value: "Lease" },
                { label: "organisation", value: "Organisation" },
              ]}
            />
          </div>
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="20 years"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Lease Tenure"}
              value={leaseTenure}
              maxWidth600={true}
              fieldName={"Lease Tenure"}
              setInputValue={setLeaseTenure}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="10 years"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              maxWidth600={true}
              label={"Outstanding duties from DISCOM"}
              value={outstandingDuties}
              fieldName={"Outstanding duties"}
              setInputValue={setOutstandingDuties}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className=" w-full">
            {" "}
            <ViewBox
              label={"Existing Solar Rooftop"}
              isflexed={true}
              defaultValue={"Carbon Goal"}
              maxWidth600={true}
              value={existingSolarTop}
              showLable={true}
              onChange={(e) => {
                setExistingSolarTop(e.target.value);
              }}
              options={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
            />
          </div>
          <br />
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
            <div className="flex flex-col sm:flex-row justify-start w-full">
              <div className="relative w-full max-w-[600px] ">
                <div className="flex flex-col sm:flex-row justify-between ">
                  <div>
                    <ViewBox
                      placeholder="Enter Capacity"
                      id="test"
                      type="text"
                      validationtypes={""}
                      showError={formSubmitted}
                      label={"Solar Capacity"}
                      value={solarCapacity}
                      fieldName={"Solar Capacity"}
                      displayLabel={false}
                      maxWidth220={true}
                      setInputValue={setSolarCapacity}
                      handleErrors={handleErrors}
                    />
                  </div>
                  <div>
                    <ViewBox
                      placeholder="Enter Net Billing"
                      id="test"
                      type="text"
                      validationtypes={""}
                      showError={formSubmitted}
                      label={"TAN"}
                      value={solarNetBilling}
                      fieldName={"Rating"}
                      displayLabel={false}
                      setInputValue={setSolarNetBilling}
                      handleErrors={handleErrors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Net Metering"
              id="test"
              type="text"
              validationtypes={""}
              showError={formSubmitted}
              label={"Net Metering"}
              value={solarNetMetering}
              fieldName={"PAN"}
              maxWidth600={true}
              setInputValue={setSolarNetMetering}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Latitude"
              id="test"
              type="text"
              validationtypes={""}
              showError={formSubmitted}
              label={"Latitude"}
              value={latitude}
              fieldName={"Latitude"}
              maxWidth600={true}
              setInputValue={setLatitude}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <br />
          <div className="w-full">
            <ViewBox
              placeholder="Longitude"
              id="test"
              type="text"
              validationtypes={""}
              showError={formSubmitted}
              label={"Longitude"}
              value={longitude}
              fieldName={"Longitude"}
              maxWidth600={true}
              setInputValue={setLongitude}
              handleErrors={handleErrors}
            />
          </div>{" "}
          <div className="mt-[33px] w-full px-[14px] sm:px-[55px] sm:mt-[70px] flex font-normal justify-center gap-[80px]">
            <Link to={'/my-data'}>
              <Abutton
                text={"Cancel"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={299}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFactory;

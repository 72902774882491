import axios from 'axios';
import jwtDecode from "jwt-decode";
// const user = JSON.parse(localStorage.getItem('user'));
// const token = user?.result?.token
// let decodedToken = ""
// if (token) {
//     decodedToken = jwtDecode(token)
// }

export const postMediaList = async (url, data) => {

    try {
        let payload = {}
        if (data) {
            payload = {
                "objectId": data.objectId,
            }
        }
        console.log(payload);
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user?.result?.token;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(url, { "buyerId": localStorage.getItem('userId'), ...payload },);
        return response.data;
    } catch (error) {
        console.error(`Error in getting list: ${error}`);
        return { error };
    }
};


export const uploadExcelData = async (url, extractedData) => {
    // console.log(extractedData);
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user?.result?.token
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(url, extractedData);
        // console.log(response,'ddkd');
        return response.data;
    } catch (error) {
        console.error(`Error in upladExcelData: ${error}`);
        return error.response.data;
    }
};

export const uploadImage = async (url, file) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user?.result?.token
        const formData = new FormData();
        formData.append('file', file);

        if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg") {
            formData.append('type', 'image');
        } else if (file.type == "application/pdf") {
            formData.append('type', 'document');
        }

        formData.append('objectType', "bill");
        formData.append('buyerId', localStorage.getItem('userId'));
        formData.append('storage', process.env.REACT_APP_MEDIA_STORAGE);

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(url, formData);
        return response.data;
    } catch (error) {
        console.error('Error uploading image:', error);
        return error;
        // throw new Error('Failed to upload image');
    }
};

export const uploadOcrImage = async (url, data) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user?.result?.token
        const formData = new FormData();
        if (data.file) {
            formData.append('file', data.file);
        } else if (data.url) {
            formData.append('url', data.url);
        }

        // if (data?.file.type == "image/png" || data?.file.type == "image/jpg" || data?.file.type == "image/jpeg") {
        //     formData.append('type', 'image');
        // } else if (data?.file.type == "application/pdf") {
        //     formData.append('type', 'document');
        // }

        formData.append('type', 'document');
        formData.append('objectType', data.objectType);
        formData.append('objectId', data.objectId);
        formData.append('buyerId', localStorage.getItem('userId'));
        formData.append('storage', process.env.REACT_APP_MEDIA_STORAGE);

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(url, formData);
        return response.data;
    } catch (error) {
        console.error('Error uploading image:', error);
        return error;
        // throw new Error('Failed to upload image');
    }
};
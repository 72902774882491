import "./App.css";
import React from 'react'
import { AuthProvider } from "./hooks/useAuth";
import AppRoutes from "./routes/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider } from "./hooks/config";

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <ConfigProvider>
          <AppRoutes />
          <ToastContainer />
        </ConfigProvider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;

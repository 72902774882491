import React, { useState, useEffect } from "react";
import ASelect from "../select/ASelect";
import readXlsxFile from "read-excel-file";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderComponent from "../loaderCompent/LoaderComponent";
import { ToastContainer, toast } from "react-toastify";
function UploadExelPopUp({
  organisationList,
  setSelectedOrg,
  selectedOrg,
  factoryList,
  setSelectedFactory,
  selectedFactory,
  unitList,
  setSelectedUnit,
  selectedUnit,
  meterList,
  setSelectedMeter,
  selectedMeter,
  setUploadFileData,
  uploadFile,
  uploadFileData,
  uploadFileResponse,
  setUploadFileResponse,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ meter: "", file: "" });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    let errorsData = {};
    if (selectedMeter !== "") {
      errorsData = {
        ...errorsData,
        meter: "",
      };
    }

    if (uploadFileData !== null) {
      errorsData = {
        ...errorsData,
        file: "",
      };
    }

    setErrors(errorsData);
  }, [selectedMeter, uploadFileData]);

  const handleUploadFile = () => {
    setFormSubmitted(true);
    setLoading(true);
    let errors = {};

    if (selectedMeter === "") {
      errors = {
        ...errors,
        meter: "Please select a meter",
      };
    }

    if (uploadFileData === null) {
      errors = {
        ...errors,
        file: "Please select a file",
      };
    }

    // console.log({ uploadFileData });
    const isErrorPresent = Object.values(errors).some((error) => error !== "");
    // console.log({ isErrorPresent });

    if (isErrorPresent) {
      setLoading(false);
      setErrors(errors);
      return;
    } else {
      uploadFile();

      setErrors({ meter: "", file: "" });
      // handleClose();
    }
  };

  useEffect(() => {
    // console.log({ errors });
    // console.log({ uploadFileResponse });
    // console.log({ formSubmitted });
    // console.log({ response: uploadFileResponse === "" });
    // console.log({ response: formSubmitted });
    if (uploadFileResponse !== "") {
      if (uploadFileResponse === undefined) {
        toast.error("File Uploaded Failed!", {
          position: "top-center",
        });
      } else if (uploadFileResponse.data?.length > 0) {
        toast.success("File Uploaded Successfully!", {
          position: "top-center",
        });
      } else {
        toast.error("File Upload Failed!", {
          position: "top-center",
        });
      }
      setUploadFileResponse("");
      setFormSubmitted(false);
      setUploadFileData("");
      setLoading(false);
      handleClose();
    }
  }, [errors, formSubmitted, uploadFileResponse]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    try {
      const rows = await readXlsxFile(file);
    //   console.log({ rows });
      setUploadFileData(rows);
    } catch (error) {
    //   console.log("Error reading Excel file:", error);
    }
  };

  return (
    <div>
      <button
        className="bg-white text-black text-xs sm:text-[12px] min-w-[100px]  sm:min-w-[150px] border shadow-lg border-gray-300 hover:bg-gray-100  font-medium py-2 px-4 rounded-lg"
        onClick={handleOpen}
      >
        Upload Excel&nbsp; &nbsp;
        <FontAwesomeIcon
          size="xl"
          icon={faCloudArrowUp}
          style={{ color: "black", cursor: "pointer" }}
        />
      </button>
      {isOpen && (
        <div
          className="fixed z-1 inset-0 top-5 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 z-1 bg-gray-800 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Upload Excel
                    </h3>
                    <div className="mt-2">
                      <p className="py-2 text-gray-400">Organisation</p>

                      <div className={` w-full`}>
                        <ASelect
                          label={"Organisation"}
                          height={"45px"}
                          IconBottom={"bottom-4"}
                          options={organisationList?.map((org) => {
                            return { label: org.name, value: org._id };
                          })}
                          onChange={(e) => setSelectedOrg(e.target.value)}
                          value={selectedOrg}
                        />
                      </div>

                      <div className={` w-full`}>
                        <p className="py-2 text-gray-400">Factory</p>

                        <ASelect
                          label={"Factory"}
                          options={factoryList?.map((factory) => {
                            return { label: factory.name, value: factory._id };
                          })}
                          height={"45px"}
                          IconBottom={"bottom-4"}
                          onChange={(e) => setSelectedFactory(e.target.value)}
                          value={selectedFactory}
                        />
                      </div>
                      <div className={` w-full`}>
                        <p className="py-2 text-gray-400">Unit</p>
                        <ASelect
                          label={"Unit"}
                          height={"45px"}
                          IconBottom={"bottom-4"}
                          options={unitList?.map((unit) => {
                            return { label: unit.name, value: unit._id };
                          })}
                          onChange={(e) => setSelectedUnit(e.target.value)}
                          value={selectedUnit}
                        />
                      </div>
                      <div className={` w-full`}>
                        <p className="py-2 text-gray-400">Meter</p>
                        <ASelect
                          label={"Meter"}
                          options={meterList?.map((meter) => {
                            return { label: meter.meterNo, value: meter._id };
                          })}
                          height={"45px"}
                          IconBottom={"bottom-4"}
                          onChange={(e) => setSelectedMeter(e.target.value)}
                          value={selectedMeter}
                        />

                        {errors.meter !== "" && formSubmitted ? (
                          <p className="text-red-500 text-sm ">
                            {errors.meter}
                          </p>
                        ) : null}
                      </div>

                      <label className="block text-sm font-medium text-gray-700">
                        <p className="py-2 text-gray-400">Select File</p>
                        <input
                          type="file"
                          name="excelFile"
                          accept=".xlsx, .xls"
                          className="w-full border rounded-[15px] border-gray-300 px-4 py-2 my-1"
                          onChange={handleFileChange}
                        />
                      </label>
                      {errors.file !== "" && formSubmitted ? (
                        <p className="text-red-500 text-sm ">{errors.file}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium   focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleUploadFile}
                >
                  {loading ? (
                    <>
                      {" "}
                      Uploading... &nbsp; &nbsp;{" "}
                      <LoaderComponent height={20} width={20} />{" "}
                    </>
                  ) : (
                    <span>Upload File</span>
                  )}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadExelPopUp;

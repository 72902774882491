import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CustomTooltop from "../tooltips/CustomTooltop";
import { Tooltip } from "react-tooltip";

const BackButton = ({ backlevel,text,px,py ,smpy ,smpx }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(backlevel ? backlevel : -1)}
      className={`flex border border-gray-300 shadow-lg ${smpy?smpy:"sm:py-2"} ${smpx?smpx:"sm:px-2"} ${px?px:"px-1"} ${py?py:"py-1"} rounded-md justify-center cursor-pointer items-center gap-2`}
    >
      <FontAwesomeIcon size="xl" icon={faCircleChevronLeft} />
      <span>{text?text: "Back"}</span>

    </div>
  );
};

export default BackButton;

import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

const api = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMyDataAvailable, setIsMyDataAvailable] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
  }, [user]);

  const login = async (userData) => {
    try {
      const response = await axios.post(`${api}/user/login`, userData);
      if (response.status === 200) {
        setUser(response?.data);

        
        
        const token = response?.data?.result?.token;
        let decodedToken = null;

        //TODO:remove when add otp functionality
        if (token) {

          decodedToken = jwtDecode(token);
          localStorage.setItem("userId", decodedToken?._id);

          localStorage.setItem("user", JSON.stringify(response?.data));
          localStorage.setItem("isOtpVerified", true);
        }
        return response.data;
      } else {
        return new Error("Failed to log in");
      }
    } catch (error) {
      console.error("Login Error:", error);
      return error;
    }
  };

  const verifyOTP = async (userId, otp) => {
    const token = user?.result?.token;
    const payload = {
      userId: userId,
      otp: otp,
    };
    try {
      const response = await axios.post(`${api}/user/verifyOTP`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
      });
      if (response.data.error.code == 200) {
        localStorage.setItem("user", JSON.stringify(user));
        const decodedToken = jwtDecode(token);
        localStorage.setItem("userId", decodedToken?._id);
        return response?.data;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.setItem("isOtpVerified", false);
    localStorage.removeItem("userId");
    localStorage.removeItem("config");
  };

  const storeConfig = async () => {
    try {
      const response = await axios.get(`${api}/config`);
      if (response.status === 200) {
        localStorage.setItem("config", JSON.stringify(response.data.data));
        return response.data.data;
      } else {
        return new Error("Error in fetching config");
      }
    } catch (error) {
      console.error("Error  in setting config", error);
      return error;
    }
  };

  const contextValue = {
    user,
    login,
    logout,
    isAuthenticated: user,
    verifyOTP,
    setIsSidebarOpen,
    isSidebarOpen,
    isMyDataAvailable,
    setIsMyDataAvailable,
    storeConfig,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Custom hook to access the authentication context
export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

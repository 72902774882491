import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import { useAuth } from "../../hooks/useAuth";
import GoTODashboard from '../../components/button/GoTODashboard';


const Demo = () => {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  return (
    <div className='max-w-[1920px] mt-[100px] m-auto flex'>
      <div className='hidden sm:block'>
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"} w-full flex flex-col justify-center items-center transition-width duration-300 p-4`}>
        <div className='mb-4 sm:mb-7 w-fit ml-auto pr-[40px]'>
         <GoTODashboard text={' Go TO Dashboard'}/>
        </div>
        {/* <div className='w-full ml-[300px] flex justify-center items-center'> */}
        <img src='/demoImg.png' alt='demo' />
      </div>
    </div>
  )
}

export default Demo
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputBoxV2 from "../../components/inputbox/InputboxV2";
import Abutton from "../../components/button/Abutton";
import ASelectv1 from "../../components/select/ASelectV1";
import InputBoxV1 from "../../components/inputbox/InputBoxV1";
import { getData, postData, updateData } from "../../api/apiDataExchange";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";

const EditMeter = () => {
  const [name, setName] = useState("");
  const [org, setOrg] = useState("");
  const [organisationList, setOrganisationList] = useState([]);
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  const [factorylist, setFactorylist] = useState([])
  const [unitlist, setUnitlist] = useState([])
  const [factory, setfactory] = useState("")
  const [unit, setunit] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [errors, setErrors] = useState([]);


  const [isOpen, setIsOpen] = useState(false);
  const [optSuccess, setOptSuccess] = useState(false);
  const [otp, setOtp] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [selectedTariff, setSelectedTariff] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const meter = await getData(`${process.env.REACT_APP_API_URL}/meter/${id}`);
      console.log(meter);
      setName(meter.result.consumerNo);
      setOrg(meter.result.organisationId._id);
      setfactory(meter.result.factoryId._id);
      setunit(meter.result.unitId._id);
      // console.log(meter.result);
      setSelectedTariff(meter.result.consumerType)
    };
    fetchData().catch(console.error);
  }, [id]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    console.log({ errors });
    const isErrorResolved =
      errors.some((item) => item["EmailError"] === "") &&
      errors.some((item) => item["PasswordError"] === "");

    const allStateData = {
      buyerId: localStorage.getItem('userId'),
      organisationId: org,
      factoryId: factory,
      unitId: unit,
      consumerNo: name.toString(),
      consumerType: selectedTariff,
    };
    console.log(allStateData);
    if (allStateData.factoryId != "" && allStateData.organisationId != "" && allStateData.unitId != "" && allStateData.buyerId != "" && allStateData.meterNo != "") {

      const response = await updateData(
        `${process.env.REACT_APP_API_URL}/meter/${id}`,
        allStateData
      );

      if (response?.data?.error?.code === 200) {
        toast.success('Meter Successfully Updated', {
          position: "top-center",
          autoClose: 3000
        })
        navigate('/my-data');
      } else {
        toast.error(response.error.response.data.error.message);
      }
      if (isErrorResolved) {
        setOpenPopup(true);
      }
    }
  };

  useEffect(() => {
    const getdata = async () => {

      const allStateData = {
        buyerId: localStorage.getItem('userId'),
      };
      console.log(allStateData);
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        allStateData
      );
      setOrganisationList(response?.data?.data?.results);
    };
    getdata();
  }, []);


  useEffect(() => {
    const getfactorydata = async () => {
      if (org !== "") {
        const orgdata = {
          organisationId: org,
        };
        console.log(orgdata);
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/factory/list`,
          orgdata
        );
        setFactorylist(response?.data?.data?.results);
      }
    };
    getfactorydata();
  }, [org]);

  useEffect(() => {
    const getmeterdata = async () => {
      if (factory !== "") {
        const meterData = {
          factoryId: factory,
        };
        console.log(meterData);
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/unit/list`,
          meterData
        );
        setUnitlist(response?.data?.data?.results);
      }
    };
    getmeterdata();
  }, [factory]);


  const handleErrors = (errorObj) => {
    const key = Object.keys(errorObj)[0];
    const value = errorObj[key];

    const existingIndex = errors.findIndex(
      (error) => Object.keys(error)[0] === key
    );

    if (existingIndex !== -1) {
      setFormSubmitted(true);

      const updatedErrors = [...errors];
      updatedErrors[existingIndex][key] = value;
      setErrors(updatedErrors);
    } else {
      setErrors((prevErrors) => [...prevErrors, { [key]: value }]);
    }
  };


  const handleTariffChange = (e) => {
    setSelectedTariff(e.target.value);
  };


  return (
    <div className="max-w-[1920px] relative m-auto mt-[170px] pb-20">
      <div>
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen ? 'ml-[300px]' : 'ml-[80px]'}`}>
        <div className=" rounded-lg  bg-[#E2F5EC]    w-full flex flex-col pb-[50px] shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-[900px] md:mt-[75px]">
          <div className="text-[22px] py-8 text-center font-medium leading-[42px]">
            {" "}
            Edit Consumer
          </div>
          <div className="w-full">
            <InputBoxV2
              placeholder="Enter Consumer No"
              id="test"
              type="text"
              validationtypes={["required"]}
              showError={formSubmitted}
              label={"Consumer No"}
              value={name}
              fieldName={"Consumer No"}
              setInputValue={setName}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Select Organisation"}
              isflexed={true}
              // defaultValue={"Select option"}
              validationtypes={"required"}
              showError={formSubmitted}
              fieldName={"Organisation"}
              imputBoxMaxWidth={545}
              showLable={true}
              value={org}
              onChange={(e) => {
                setOrg(e.target.value);
              }}
              options={organisationList.map((organisation) => {
                return { label: organisation.name, value: organisation._id };
              })}
              handleErrors={handleErrors}
            />
          </div>
          <br />
          <br />
          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Select Factory"}
              isflexed={true}
              // defaultValue={"Select factory"}
              validationtypes={"required"}

              imputBoxMaxWidth={545}
              showError={formSubmitted}
              value={factory}
              showLable={true}
              onChange={(e) => {
                setfactory(e.target.value);
              }}
              options={factorylist.map((factory) => {
                return { label: factory.name, value: factory._id };
              })}
              handleErrors={handleErrors}
            />
          </div>
          <br />

          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Select Unit"}
              isflexed={true}
              // defaultValue={"Select unit"}
              validationtypes={"required"}
              fieldName={"Existing Solar Rooftop"}
              imputBoxMaxWidth={545}
              showError={formSubmitted}
              value={unit}
              showLable={true}
              onChange={(e) => {
                setunit(e.target.value);
              }}
              options={unitlist?.map((unit) => {
                return { label: unit.name, value: unit._id };
              })}
              handleErrors={handleErrors}
            />
          </div>
          <br />

          <div className=" w-full">
            {" "}
            <ASelectv1
              label={"Select Consumer Type"}
              isflexed={true}
              // validationtypes={"required"}
              fieldName={"Select Consumer Type"}
              imputBoxMaxWidth={545}
              // showError={formSubmitted}
              value={selectedTariff}
              showLable={true}
              onChange={(e) => {
                setSelectedTariff(e.target.value);
              }}
              options={[
                { label: 'INDUSTRIAL', value: 'INDUSTRIAL' },
                { label: 'COMMERCIAL', value: 'COMMERCIAL' },
                { label: 'RESIDENTIAL', value: 'RESIDENTIAL' }
              ]}
              handleErrors={handleErrors}
            />

          </div>
          <br />


          <div className="mt-[33px] w-full px-[14px] sm:px-[55px] sm:mt-[70px] flex font-normal justify-center gap-[80px]">
            <Abutton
              handleOnclick={handleSubmit}
              text={"Save & Next"}
              Mheight={44}
              Mwidth={170}
              DHeight={44}
              DWidth={299}
            />
            <Link to={'/my-data'}>
              <Abutton
                text={"Cancel"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={299}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMeter;

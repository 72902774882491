import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableList, faList, faCirclePlay, faEllipsisVertical, faArrowUpFromBracket, faServer, faCodeCompare, faBolt, faDatabase, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export default function Sidebar({type=1}) {
  // type 1 - geneal 2 - analytics 
  const [activeMenu, setActiveMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState();
  const location = useLocation();
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  // Set the selected menu item based on the current pathname

  useEffect(() => {
    if (location.pathname === "/my-data") {
      setSelectedMenu(1);
    } else if (location.pathname === "/compare-ocr-bills" || location.pathname.startsWith("/compare-ocr-bills/")) {
      setSelectedMenu(2);
    } else if (location.pathname === "/upload") {
      setSelectedMenu(3);
    } else if (location.pathname === "/demo") {
      setSelectedMenu(4);
    } else if (location.pathname === "/power-consumption") {
      setSelectedMenu(5);
    }  else if (location.pathname === "/state-list") {
      setSelectedMenu(7);
    } else if (location.pathname === "/discom-list") {
      setSelectedMenu(8);
    }else if (location.pathname === "/analytics") {
      setSelectedMenu(11);
    } else if (location.pathname === "/analytics/customisedata" ) {
      setSelectedMenu(12);
    } else if (location.pathname === "/analytics/futuredata") {
      setSelectedMenu(13);
    } else if (location.pathname === "/analytics/aggregated-view") {
      setSelectedMenu(14);
    } else if (location.pathname === "/unit-list") {
      setSelectedMenu(15);
    } 
  }, [location.pathname]);

  // Toggle the sub-menu
  const toggleSubMenu = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  // Animate sidebar items
  useEffect(() => {
    const sidebarItems = document.querySelectorAll('.sidebar-item');
    if (isSidebarOpen) {
      sidebarItems.forEach((item, index) => {
        setTimeout(() => {
          item.style.opacity = 1;
        }, (index + 1) * 100);
      });
    } else {
      sidebarItems.forEach((item) => {
        item.style.opacity = 0;
      });
    }
  }, [isSidebarOpen]);

  return (
    <div className=" fixed sm:flex-row md:z-[50]  sm:top-[70px] text-base -tracking-tighter font-normal">
      <div 
        className={`sidebar-bg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] transition-width duration-300 ${isSidebarOpen ? "sm:w-[300px]" : "sm:w-[80px]"}`}
        onMouseEnter={() => setIsSidebarOpen(true)} onMouseLeave={() => setIsSidebarOpen(false)}

        >
        <div className="flex flex-row sm:flex-col p-1 max-sm:items-center h-12 sm:h-screen w-[100%] ">
        <div className="cursor-pointer" 
        >
          <div className={`absolute bg-[#E2F5EC] transition-all transform translate-x-[-100%] duration-300 shadow-[7.0px_0px_8.0px_rgba(0,0,0,0.38)] sm:w-5 sm:h-[40px] top-[38.6%] right-[0px] ${isSidebarOpen ? "sm:left-[311.5px]" : "sm:left-[91.5px]"} border-tl-[7px] border-bl-[7px] rounded-[0.3rem] `}></div>
          <FontAwesomeIcon className={`absolute opacity-30 hover:opacity-100 transition-all duration-300 cursor-pointer ${isSidebarOpen ? "sm:ml-[295px] rotate-180" : "sm:ml-[75px]"}  sm:h-[23px] top-[39.8%] `} icon={faEllipsisVertical} />
        </div>
          <div className="w-[100%] h-full py-6 px-3 text-xl font-small">
            <Link to="/my-data">
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 1 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 hover:bg-[#D2EDDC] `}>
                  <FontAwesomeIcon icon={faServer} className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <div
                className={`${type!==1 &&"text-gray-400"} ${selectedMenu === 1 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"
                  } py-4 px-3 hover:bg-[#D2EDDC] sidebar-item ${!isSidebarOpen && "hidden"}`}
              >
                <FontAwesomeIcon
                  icon={faServer}
                  className={`${type!==1 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                  <span className={`${!isSidebarOpen && "hidden"}  transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`} >My Data</span>
              </div>
            </Link>
            <Link to="/compare-ocr-bills">
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 2 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 hover:bg-[#D2EDDC] `}>
                  <FontAwesomeIcon icon={faCodeCompare} className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <div
                className={`${type!==1 &&"text-gray-400"} ${selectedMenu === 2 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"
                  } py-4 px-3 hover:bg-[#D2EDDC] sidebar-item ${!isSidebarOpen && "hidden"}`}
              >
                <FontAwesomeIcon
                  icon={faCodeCompare}
                  className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                  <span className={`${!isSidebarOpen && "hidden"}  transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`}>Compare OCR Bills</span>
              </div>
            </Link>
            <Link to="/upload">
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 3 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 hover:bg-[#D2EDDC] `}>
                  <FontAwesomeIcon icon={faArrowUpFromBracket} className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <div
                className={`${type!==1 &&"text-gray-400"} ${selectedMenu === 3 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"
                  } py-4  px-3 hover:bg-[#D2EDDC] sidebar-item ${!isSidebarOpen && "hidden"}`}
              >
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                  <span className={`${!isSidebarOpen && "hidden"} transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`}>Upload Bills/Excel</span>
              </div>
            </Link>
            <Link to="/demo">
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 4 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 hover:bg-[#D2EDDC] `}>
                  <FontAwesomeIcon icon={faCirclePlay} className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <div
                className={`${type!==1 &&"text-gray-400"} ${selectedMenu === 4 ? "bg-[#E2F5EC]" : "sidebar-bg"
                  } py-4  px-3 hover:bg-[#D2EDDC] sidebar-item ${!isSidebarOpen && "hidden"}`}
              >
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                  <span className={`${!isSidebarOpen && "hidden"}  transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`}>Demo</span>
              </div>
            </Link>
            <Link to="/power-consumption">
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 5 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 hover:bg-[#D2EDDC] `}>
                  <FontAwesomeIcon icon={faTableList} className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <div
                className={`${type!==1 &&"text-gray-400"} ${selectedMenu === 5 ? "bg-[#E2F5EC]" : "sidebar-bg"
                  } py-4  px-3 hover:bg-[#D2EDDC] sidebar-item ${!isSidebarOpen && "hidden"}`}
              >
                <FontAwesomeIcon
                  icon={faTableList}
                  className={`${type!==1  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                  <span className={`${!isSidebarOpen && "hidden"}  transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`}>Power Consumption</span>
              </div>
            </Link>
            {/* analytics */}
            <hr className=" border-gray-400 border-2 rounded-full my-2" />
            <div className=" ">
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 11 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 hover:bg-[#D2EDDC] cursor-pointer`}
                >
                  <FontAwesomeIcon icon={faChartSimple} className={`${type!==2  ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <Link to="/analytics"> 
              <div
                className={`${type!==2 &&"text-gray-400"} ${selectedMenu === 11 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"
                  } py-4 px-3 hover:bg-[#D2EDDC] sidebar-item items-center flex flex-row cursor-pointer ${!isSidebarOpen && "hidden"}`}
                role="tab"
                aria-controls="app"
               
              >
                <FontAwesomeIcon
                  icon={faChartSimple}
                  className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                <span className={`${!isSidebarOpen && "hidden"}  transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`} >Bill Consumption</span>
              </div>
             </Link>
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 12 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 cursor-pointer hover:bg-[#D2EDDC] `}
                 
                >
                  <FontAwesomeIcon icon={faDatabase} className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <Link to="/analytics/customisedata">              
              <div
                className={`${type!==2 &&"text-gray-400"} ${selectedMenu === 12 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"
                  } py-4 px-3 hover:bg-[#D2EDDC] sidebar-item flex flex-row items-center cursor-pointer ${!isSidebarOpen && "hidden"}`}
                role="tab"
                aria-controls="message"
              >
                <FontAwesomeIcon
                  icon={faDatabase}
                  className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                <span className={`${!isSidebarOpen && "hidden"}  transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`}> Customized Data</span>
              </div>
              </Link>

              {!isSidebarOpen &&
                <div className={`${selectedMenu === 13 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 cursor-pointer hover:bg-[#D2EDDC] `}
                  role="tab"
                  aria-controls="settings"
                >
                  <FontAwesomeIcon icon={faBolt} className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <Link to="/analytics/futuredata">
              <div
                className={`${type!==2 &&"text-gray-400"} ${selectedMenu === 13 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"
                  } py-4  px-3 hover:bg-[#D2EDDC] cursor-pointer flex flex-row items-center sidebar-item ${!isSidebarOpen && "hidden"}`}
              >
                <FontAwesomeIcon
                  icon={faBolt}
                  className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                <span className={`${!isSidebarOpen && "hidden"} transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`}>Future Load Simulation</span>
              </div>
              </Link>
              <Link to="/analytics/aggregated-view">
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 14 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 cursor-pointer hover:bg-[#D2EDDC] `}
                  role="tab"
                  aria-controls="graphs"
                >
                  <FontAwesomeIcon icon={faCodeCompare} className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <div
                className={`${type!==2 &&"text-gray-400"} ${selectedMenu === 14 ? "bg-[#E2F5EC]" : "sidebar-bg"
                  } py-4  px-3 hover:bg-[#D2EDDC] sidebar-item flex flex-row items-center cursor-pointer ${!isSidebarOpen && "hidden"}`}
              >
                <FontAwesomeIcon
                  icon={faCodeCompare}
                  className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                  <span className={`${!isSidebarOpen && "hidden"}  transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`}>Aggregate & Comparison</span>
              </div>
              </Link>

              <Link to="/unit-list">
              {!isSidebarOpen &&
                <div className={`${selectedMenu === 15 ? "bg-[#E2F5EC] shadow-sm" : "sidebar-bg"} py-4 px-3 cursor-pointer hover:bg-[#D2EDDC] `}
                  role="tab"
                  aria-controls="graphs"
                >
                  <FontAwesomeIcon icon={faList} className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                </div>
              }
              <div
                className={`${type!==2 &&"text-gray-400"} ${selectedMenu === 15 ? "bg-[#E2F5EC]" : "sidebar-bg"
                  } py-4  px-3 hover:bg-[#D2EDDC] sidebar-item flex flex-row items-center cursor-pointer ${!isSidebarOpen && "hidden"}`}
              >
                <FontAwesomeIcon
                  icon={faList}
                  className={`${type!==2 ? "text-gray-400":"text-black"}  mr-2 text-xl font-medium  transition-all ease-out`}/>
                <span className={`${!isSidebarOpen && "hidden"}  transition-all ease transition-delay-300 text-base -tracking-tighter font-normal`}>Unit List</span>
              </div>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import BackButton from "../../../components/backButton/BackButton";
import GoTODashboard from "../../../components/button/GoTODashboard";
import AnalyticsSidebar from "../../../components/sidebar/AnalyticsSidebar";

const CustomisedData = ({ }) => {
  const { isSidebarOpen } = useAuth();
  return (
    <>
      <div className="lg:max-w-[1920px] pb-[200px] mt-[58px] bg-[#ebedeb] sm:mt-[70px] items-center m-auto flex">
        <div className="hidden sm:block">
          {/* <AnalyticsSidebar /> */}
          <Sidebar type={2} />
        </div>
        <div
          className={`${isSidebarOpen ? "lg:ml-[300px]" : "sm:ml-[80px]"
            } w-full px-[10px] sm:px-[50px]   mt-8 transition-width duration-300`}
        >
          <div className="flex justify-end items-center w-full sm:pr-5 ">
            <GoTODashboard />
          </div>


          <div className=" flex gap-2 m-4  sm:flex-row  flex-col-reverse   rounded-md   justify-between w-full ">
            <div className=" w-full p-2 shadow-xl bg-white sm:min-h-[60vh] rounded-md border border-gray-400   ">
              <main>
                <div class="pattern-container  p-4 ">

                  <h2 className="font-bold">Customize your data for more accuracy</h2>
                  <div class="pattern-unit w-[100%] sm:w-[70%] mx-auto">
                    <ul className="list-disc pl-4">
                      <li className="py-4">
                        In the dashboard and analytics you see data based on your bills which are at monthly granularity.
                      </li>
                      <li className="py-1">
                        <strong className="text-[#067C4E]">Ampera AI</strong>  allows you to customize this where you can provide granular information related to
                        weekend usage, Timeslot specific inputs etc which will help you get deeper insights in your energy consumption patterns and bills.
                      </li>
                      <li className="py-1">
                        It will also help Ampera AI to give you accurate estimation of possible savings.
                      </li>
                      <li className="py-1">
                        By clicking the <strong className="text-[blue]"> <Link to="/unit-list">Customize unit level Pattern</Link></strong> button on the right, you can provide unit level customized data easily which can be replicated across different units easily.
                      </li>
                      <li className="py-1">
                        Once customised data is provided, you can view the updated analytics by hitting the <strong className="text-[blue]"> <Link to="/customisedataview">View Customized Analytics</Link></strong> button at the right bottom.
                      </li>
                    </ul>
                  </div>
                </div>

              </main>
            </div>
            <div className="p-4  bg-white shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)]  sm:min-h-[60vh] rounded-lg  flex sm:flex-col gap-[5vh] justify-around h-full border border-gray-400   ">
              <Link to="/unit-list">
                <div className="border bg-[#067C4E] cursor-pointer  font-medium  text-white shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.2)] py-5 text-center p-2  rounded-md  border-gray-400 ">
                  Customise Unit Level Pattern
                </div>
              </Link>
              <Link to="/customisedataview">
                <div className="border bg-[#067C4E]  cursor-pointer font-medium   p-2 text-white shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.2)] py-5 text-center rounded-md  border-gray-400 ">
                  View Customised Analytics
                </div>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default CustomisedData;

import React, { useState, useEffect } from "react";
import LineChartWithCostView from "../../containers/graphs/LineChartWithCostView";
import { ConstantUtils } from "../../utils/ConstantUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import LegendComponent from "../../components/chart/LegendComponent";
const CostAnnualView = ({ annualData, handleCostGraph, selectedCostGraph }) => {
  const constantUtils = new ConstantUtils();

  const [costData, setCostData] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setmonths] = useState([]);

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
        legend: {
          display: false,
        },
        tooltip: {
            callbacks: {
              label: function (context) {
                const value = context.raw;
                return value === 0 ? 'N/A' : value?.toLocaleString('en-IN');
              },
              title: function (context) {
                const year = context[0]?.dataset?.label?.split(" ")[1]?.split("-")[0];
                const title = context[0].label + "-" + year??"";
                return title;
              },
            },
          },
        
      },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: "Amount (Rs)",
        },
        ticks: {
        callback: function(value) {
          if (value >= 1000) {
            return value / 1000 + 'k'; // Converts 1000 to 1k, 2000 to 2k, etc.
          }
          return value;
        }
      }
      },
    },
    elements: {
      line: {
        tension: 0.4, // This will make the line curve
      },
    },
  });

  useEffect(() => {
    if (annualData !== undefined) {
      setCostData(annualData.costData);
      setYears(annualData.years);
      setmonths(annualData.months);
    }
  }, [annualData]);
  const data = {
    labels: months,
    datasets: [
      ...years.map((data, index) => ({
        label: years[index],
        data: costData[index],
        borderColor: constantUtils.getLineGraphColors()[index],
        borderWidth: 2,
        type:"line",
        backgroundColor: constantUtils.getLineGraphColors()[index],
      })),
    ],
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (months.length > 0 && years.length > 0 && costData.length > 0) {
        setTimeout(() => {
            setIsLoading(false);

        }, 800);
    }
  }, [months, years, costData]);
  const [full, setFull] = useState(false);
  return (
    <div
      className={`${
        selectedCostGraph === "Annual" ? "flex" : "hidden"
      }  flex-col shadow-[0_4px_4px_0_#00000040] border border-[#00000040] rounded-[30px] sm:min-h-[400px]  w-full`}
    >
    <div>
      <div
        className={` flex justify-between px-[20px] sm:px-[35px] py-[10px] sm:py-[25px]  w-full`}
      >
        <div>Annual Comparison: Total Bill  : Discom </div>

        <div className="cursor-pointer flex items-center gap-4">
          <button onClick={() => setFull(true)}>
            <FontAwesomeIcon icon={faExpand} />
          </button>
          <CustomTooltop
            disable={
              constantUtils.getGraphToolTipData().annualCost.disableToolTip
            }
            text={constantUtils.getGraphToolTipData().annualCost.text}
          />
        </div>
      </div>
      <LegendComponent data={data} columns={"grid-cols-3"} />
      </div>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <LoaderComponent />
        </div>
      ) : (
        <>
          {/* <div className="">
            <div className="flex w-full px-[35px]  justify-end gap-2"> */}
          {/* {data.datasets.map((dataset) => (
                <button
                  className="bg-gray-800 text-white font-bold text-[12px]  px-2 rounded"
                  key={dataset.label}
                  onClick={() => handleCostGraph("Monthly", dataset.label, "")}
                >
                  {dataset.label}
                </button>
              ))} */}
          {/* </div>
          </div> */}
          <div className=" sm:px-[35px] pb-[20px] sm:py-[25px]">
            <LineChartWithCostView
              options={options}
              data={data}
              full={full}
              setFull={setFull}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CostAnnualView;

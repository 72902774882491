import React, { useState, useEffect } from "react";
import useInputBox from "../../hooks/useInputBox";

const InputBox = ({
  showError,
  placeholder,
  label,
  type,
  validationtypes,
  value,
  setInputValue,
  handleErrors,fieldName
}) => {
  const { error } = useInputBox(value, validationtypes, label,fieldName);
  useEffect(() => {
    if (error ) {

        handleErrors(error);
        // console.log(error)
    }else{
    }
  }, [error,value]);

  return (
    <div className="">
      <div className="pt-[10px]  pb-[18px] p-0  font-normal text-[18px] leading-[22px]  md:p-[10px] md:pb-[19px]">
        {label}
      </div>
      <input
        className="border font-normal w-full rounded-[20px] text-[16px] leading-[20px] border-[#B9B9B9] py-[25px] px-[20px] md:ml-[10px] md:max-w-[545px] "
        type={type}
        value={value}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={placeholder}
      />
      <br />
      {showError ? (
        <p className="text-red-500 capitalize px-[20px] py-[10px]">{error[`${label}Error`]}</p>
      ) : null}
    </div>
  );
};

export default InputBox;

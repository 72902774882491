import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Dataset 1',
      backgroundColor: 'rgba(75,192,192,1)',
      borderColor: 'rgba(75,192,192,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(75,192,192,0.4)',
      hoverBorderColor: 'rgba(75,192,192,1)',
      data: [65, 59, 80, 81, 56, 55, 40],
      pointStyle:"rect"
    },
    {
      label: 'Dataset 2',
      backgroundColor: 'rgba(153,102,255,1)',
      borderColor: 'rgba(153,102,255,1)',
      borderWidth: 1,
      borderDash: [10, 5],
      hoverBackgroundColor: 'rgba(153,102,255,0.4)',
      hoverBorderColor: 'rgba(153,102,255,1)',
      data: [35, 49, 60, 71, 46, 45, 30],
      pointStyle:"line"
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: true,
      position: 'top',
      onClick: (event, legendItem, legend) => {
        const index = legendItem.index;
        const ci = legend.chart;
        const meta = ci.getDatasetMeta(index);
        meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
        ci.update();
      },
      labels: {
        generateLabels: (chart) => {
          const datasets = chart.data.datasets;
          return datasets.map((dataset, i) => ({
            text: dataset.label,
            fillStyle: dataset.backgroundColor,
            strokeStyle: dataset.borderColor,
            lineWidth: dataset.borderWidth,
            hidden: !chart.isDatasetVisible(i),
            index: i,
            pointStyle:dataset.pointStyle
          }));
        },
        font: {
          size: 16,
          family: 'Arial',
          style: 'italic',
          weight: 'bold',
        },
        padding: 20,
        usePointStyle: true,
      },
    },
  },
};

const MyChart = () => (
  <div className='p-4 mt-12'>
    <h2>Bar Example</h2>
    <Bar data={data} options={options} />
  </div>
);

export default MyChart;

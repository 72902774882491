import React from 'react'
import LoaderComponent from '../loaderCompent/LoaderComponent'

const AmperaPlatform = () => {
    return (
        <div>
            <div className='mt-16 mb-10'>
            <LoaderComponent/>
            </div>
            <p className='text-3xl font-medium text[#000000] text-center '>Ampera ai is extracting data from your bills</p>
            <p className='text-3xl font-medium text[#000000] text-center mt-5'>In the meantime, here is a quick guide to navigating the Ampera analytics platform</p>
        </div>
    )
}

export default AmperaPlatform

import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndianRupeeSign,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import FilterSelect from "../../components/select/FilterSelect";
import { getData, postData, updateData } from "../../api/apiDataExchange";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/monthYearPicker/MonthYearPicker.css";
import IndianMap from "../../components/IndianMap/IndianMap";
import DashBoardPaymentView from "../../views/dashBoardPayment/DashBoardPaymentView";
import GoogleMapDashboard from "../../components/googlePlacesMap/GoogleMapDashboard";
import { MultiSelect } from "react-multi-select-component";
import AnimatedCount from "./AnimatedCount";
import CustomTooltopDashboard from "../../components/tooltips/CustomTooltopDashboard";
import { ConstantUtils } from "../../utils/ConstantUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import SavingEnergyPopup from "../../components/popup/SavingEnergyPopup";
import { setOptions } from "leaflet";

const Dashboard = () => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const constantUtils = new ConstantUtils();
  const [activeTab, setActiveTab] = useState(0);
  const fiscalYearStartMonth = 4;
  const lastFiscalYearStartMonth = currentMonth >= fiscalYearStartMonth ? fiscalYearStartMonth : fiscalYearStartMonth - 12;
  const lastFiscalYearStartYear = currentMonth >= fiscalYearStartMonth ? currentYear - 1 : currentYear - 2;
  const lastFiscalYearEndMonth = fiscalYearStartMonth - 1;
  const lastFiscalYearEndYear = currentMonth >= fiscalYearStartMonth ? currentYear : currentYear - 1;
  const [disableFlag,setDisableFlag] = useState(false)
  const [meterWRTState,setMeterWRTState]=useState([])
  const [options,setOptions]=useState([])

  const defaultStartDate = new Date(
    lastFiscalYearStartYear,
    lastFiscalYearStartMonth - 1,
    1
  );
  const defaultEndDate = new Date(lastFiscalYearEndYear, 2, 31);

  const formatDateToString = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${month}/${year}`;
  };

  const formatDateWithMonthAndYear = (date) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString();
    return `${month}-${year}`;
  };

  function formatDate(date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); 
    const yyyy = String(date.getFullYear());

    return `${yyyy}-${mm}-${dd}`;
  }

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [minStartDate, setMinStartDate] = useState(null);
  const [maxStartDate, setMaxStartDate] = useState(new Date());
  const [minEndDate, setMinEndDate] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(new Date());
  const [selectedLF, setSelectedLF] = useState(40);

  const [org, setOrg] = useState("");
  const [meter, setMeter] = useState("");

  const handleStartDateChange = (date) => {
    setDisableFlag(true)
    setStartDate(date);
    const minEndDate = new Date(date);
    const maxEndDate = new Date(date);
    maxEndDate.setMonth(maxEndDate.getMonth() + 11);
    setMinEndDate(minEndDate);
    setMaxEndDate(maxEndDate);
    if (endDate && date > endDate) {
      setEndDate(null);
    }


    // const newEndDate = new Date(date);
    // newEndDate.setFullYear(newEndDate.getFullYear() + 1);
    const newEndDate = new Date(date);
    newEndDate.setMonth(newEndDate.getMonth() + 11);
    setEndDate(newEndDate);

    setFilters({ ...filters, startDate: formatDateToString(date), endDate: formatDateToString(newEndDate) });
    setSelectedRange({
      start: formatDateToString(date),
      end: newEndDate ? formatDateToString(newEndDate) : null,
    });
  };


  const handleEndDateChange = (date) => {
    setDisableFlag(true)
    setEndDate(date);

    const minStartDate = new Date(date);
    minStartDate.setMonth(minStartDate.getMonth() - 11);
    // setMinStartDate(minStartDate);
    setMaxStartDate(date);

    if (startDate && date < startDate) {
      setStartDate(null);
    }


    setFilters({ ...filters, endDate: formatDateToString(date) });
    setSelectedRange({
      start: startDate ? formatDateToString(startDate) : null,
      end: formatDateToString(date),
    });
  };

  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [selectedRange, setSelectedRange] = useState({
    start: null,
    end: null,
  });
  const [stateList, setStateList] = useState([]);
  const [stateCode, setStateCode] = useState();
  const [filters, setFilters] = useState({
    startDate: formatDateToString(startDate),
    endDate: formatDateToString(endDate),
    organisationId: org,
    meterId: meter,
    stateCode: stateCode
  });
  const [organisationList, setOrganisationList] = useState([]);
  const [dashboardStats, setDashboardStats] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [lfData, setLFData] = useState();
  const [selectedUnit, setSelectedUnit] = useState("");
  const [savingsThroughGreen, setSavingsThroughGreen] = useState(0);
  const [savingsThroughGreenMeterWise, setSavingsThroughGreenMeterWise] = useState([]);
  const [savingEnergyLoader, setSavingEnergyLoader] = useState(false);
  const [openGreenSavingsPopup, setOpenGreenSavingsPopup] = useState(false);
  const [filterTrigger, setFilterTrigger] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [disableFilter,setDisableFilter] = useState(false);
  const [startDateToShow, setStartDateToShow] = useState(startDate);
  const [endDateToShow, setEndDateToShow] = useState(endDate);

  useEffect(() => {
    const getdata = async () => {
      const payload = {
        buyerId: localStorage.getItem("userId"),
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        payload
      )

      if (response?.data?.data?.results?.length > 0) {
        setOrg(response?.data?.data?.results[0]?._id);
        setOrganisationList(response?.data?.data?.results);
        setFilters({
          ...filters,
          organisationId: response?.data?.data?.results[0]?._id,
        });
      }
    };
    getdata();
  }, []);

  useEffect(() => {
    const getdata = async () => {

      let data = {};

      if (filters.startDate) {
        data.startDate = filters.startDate;
      }
      if (filters.endDate) {
        data.endDate = filters.endDate;
      }
      if (filters.organisationId) {
        data.organisationId = filters.organisationId;
      }
      if (filters.stateCode) {
        data.stateCode = filters.stateCode;
      }
      if (filters.meterId?.length > 0) {
        data.meterId = filters.meterId;
      }
      if (data.organisationId && data.stateCode && (initialLoad || filterTrigger)) {
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/visualization/stats`,
          data
        );
        setDashboardStats(response?.data?.data);
      }
    };
    if (filterTrigger || initialLoad) {
      getdata();
    }
  }, [filterTrigger, initialLoad, organisationList,stateCode]);



  useEffect(() => {
    const getdata = async () => {
      let data = {};

      // Add properties to data only if they are available
      if (filters.startDate) {
        data.startDate = filters.startDate;
      }
      if (filters.endDate) {
        data.endDate = filters.endDate;
      }
      if (filters.organisationId) {
        data.organisationId = filters.organisationId;
      }
      if (filters.stateCode) {
        data.stateCode = filters.stateCode;
      }
      if (filters.meterId?.length > 0) {
        data.meterId = filters.meterId;
      }

      if (data.organisationId && data.stateCode && (initialLoad || filterTrigger) && !savingEnergyLoader) {
        setSavingEnergyLoader(true);
        setDisableFilter(true);
        const [iexDataResponse, constantPriceResponse] = await Promise.all([
            postData(`${process.env.REACT_APP_API_URL}/recommendation/saveEnergy?pricetype=iexdata`, data),
            postData(`${process.env.REACT_APP_API_URL}/recommendation/saveEnergy?pricetype=constantprice`, data)
          ]);

          if (iexDataResponse?.data?.statusCode === 200 && constantPriceResponse?.data?.statusCode === 200) {
            const totalSaveGreenEnergyByIex =  iexDataResponse?.data?.data?.reduce(
              (total, entry) => total + entry.saveGreenEnergy,
              0
            );
            
            const totalSaveGreenEnergyByConstantPrice =  constantPriceResponse?.data?.data?.reduce(
              (total, entry) => total + entry.saveGreenEnergy,
              0
            );

            if( totalSaveGreenEnergyByIex > totalSaveGreenEnergyByConstantPrice){
              setSavingsThroughGreen(totalSaveGreenEnergyByIex >= 0 ? totalSaveGreenEnergyByIex : 0);
              setSavingsThroughGreenMeterWise(iexDataResponse?.data?.data);
              setSavingEnergyLoader(false);
              setDisableFilter(false);
              setInitialLoad(false);
              setFilterTrigger(false);
            } else {
            setFilterTrigger(false);
            setDisableFilter(false);
            setInitialLoad(false);
            setSavingEnergyLoader(false);
              setSavingsThroughGreen(totalSaveGreenEnergyByConstantPrice >= 0 ? totalSaveGreenEnergyByConstantPrice : 0);
              setSavingsThroughGreenMeterWise(constantPriceResponse?.data?.data);
            }
        
          } else {
            const totalSaveGreenEnergy = iexDataResponse?.data?.data?.reduce(
              (total, entry) => total + entry.saveGreenEnergy,
              0
            );
            setSavingsThroughGreen(totalSaveGreenEnergy >= 0 ? totalSaveGreenEnergy : 0);
            setSavingsThroughGreenMeterWise(iexDataResponse?.data?.data);
            setSavingEnergyLoader(false);
            setDisableFilter(false);
            setFilterTrigger(false);
          } 
      }else {
          // console.log('entry');
          setSavingsThroughGreen(0);
          setSavingEnergyLoader(false);
          setDisableFilter(false);
          setFilterTrigger(false);
      }
    
    };
    if (filterTrigger || initialLoad) {
      getdata();
    }
  }, [filterTrigger, initialLoad, organisationList,stateCode]);

  useEffect(() => {
    const getdata = async () => {
      let data = {};

      if (filters.startDate) {
        data.startDate = filters.startDate;
      }
      if (filters.endDate) {
        data.endDate = filters.endDate;
      }
      if (filters.organisationId) {
        data.organisationId = filters.organisationId;
      }
      if (filters.stateCode) {
        data.stateCode = filters.stateCode;
      }
      if (filters.meterId?.length > 0) {
        data.meterId = filters.meterId;
      }
      if (selectedLF !== 0) {
        data.lfType = "lteq"
        data.lfPercentage = parseInt(selectedLF);
      }
      if (data.organisationId && data.stateCode && (initialLoad || selectedLF)) {
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/visualization/lfStats`,
          data
        );
        
        setLFData(response?.data?.data.totalRecords);
      }
    };
    if (initialLoad || selectedLF) {
      getdata();
    }
  }, [filterTrigger, initialLoad, organisationList, selectedLF,stateCode]);

  console.log(filters.stateCode,"data")

  useEffect(() => {
    if (stateCode || meter?.length > 0) {
      setFilters({
        ...filters,
        meterId: [],
        stateCode: ""
      });
    }
    setStateCode("");
    setMeter([]);

    const getTreeViewData = async () => {
      if (org !== "") {
        const response = await getData(
          `${process.env.REACT_APP_API_URL}/organisation/tree/${org}`,
        );
        const allFactories = response?.data?.factories?.flatMap(factories => factories || []);
        setFactoryList(allFactories);

        const allUnits = allFactories.flatMap(factory => factory.units);

        setSelectedUnit(allUnits[0]?._id)
        const unitsData = allUnits
          ?.map((unit) => ({
            name: unit?.name,
            coordinates: unit?.location?.coordinates
          }))
          .filter(
            (unit) =>
              unit?.coordinates &&
              unit?.coordinates?.length === 2 &&
              unit?.coordinates?.every(
                (coord) => coord !== null && coord !== undefined
              )
          );
        setUnitData(unitsData);

        // const extractedInfo = allUnits?.map(unit =>  unit.state) || [];

        const extractedInfo = Array.from(
          new Map(allUnits.map(unit => [unit.stateCode, unit.state])).values()
        );
        const extractedInfoSorted = extractedInfo.sort((a, b) => {

          if (a.code === 'IN-MH') return -1;
          if (b.code === 'IN-MH') return 1;

          return a.name.localeCompare(b.name);
        });

        setStateList(extractedInfoSorted)

        // Collect all meters directly from the selected organizations
        const allMeters = allFactories.flatMap(factory =>
          factory.units.flatMap(unit => unit.meters)
        );

        // filter by state
        const allMetersWRTState = allUnits.map((e)=>{
          return {
            id:e._id,
            stateCode:e.stateCode,
            meters:e.meters
          }
        })
        setMeterWRTState(allMetersWRTState)

        const allMeterIds = allMeters.map(meter => meter._id);
        const allStateCodes = extractedInfo.map(stateCode => stateCode.code);
        const allMetersSelected = allMeters?.map(meter => ({
          label: meter.consumerNo,
          value: meter._id,
        }));

        const allMeterIdsWRTState = allMetersWRTState
        ?.filter((state) => state.stateCode === "IN-MH")
        ?.flatMap(item => item.meters.map(meter => meter._id));


        setMeter(allMetersWRTState[0]?.meters?.flatMap(meter => ({
          label: meter.consumerNo,
          value: meter._id,
        })));

      
        if(allStateCodes.length > 0 && allStateCodes.includes("IN-MH")){
          setStateCode("IN-MH");
        }else{
          setStateCode(allStateCodes[0]);
        }
        
        setFilters({
          ...filters,
          stateCode: allStateCodes[0],
          meterId:allMeterIdsWRTState
        });
        // setMeter(allMetersSelected);
        // setStateCode(allStateCodes);
        // setFilters({
        //   ...filters,
        //   meterId: allMeterIds,
        //   stateCode: allStateCodes
        // });

        setMeterList(allMeters);
        // setMeter(allMeters[0]?._id);
      }
    };
    getTreeViewData();

  }, [org]);

  console.log(meterWRTState,"meterWRTState")

  const handleRangeChange = (range) => {
    setSelectedRange(range);
  };

  const handleOrgChange = (selectedOrg) => {
    setOrg(selectedOrg);
    if (selectedOrg) {
      setFilters({ ...filters, organisationId: selectedOrg });
    }
  };

  const handleStateChange = (selectedState) => {
    setMeter([])
    setStateCode(selectedState);
    if (selectedState) {
      setFilters({ ...filters, stateCode: selectedState });
    }
  };

  function formatNumber(number) {
    if (typeof number !== "number") return number;

    // Round the number to the nearest integer
    const roundedNumber = Math.round(number);

    // Convert the number to a string and add commas for thousands separators
    // const formattedNumber = roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return roundedNumber.toLocaleString('en-IN');
    // return formattedNumber;
  }

  // const options = meterList?.map(meter => ({
  //   label: meter.consumerNo,
  //   value: meter._id,
  // }));

  useEffect(() => {
    const newOptions = meterWRTState.flatMap(e => {
      if (e.stateCode === stateCode) {
        const id = e.id
        return e.meters.map(meter => ({
          unitId: id,
          label: meter.formattedConsumerName,
          value: meter._id,
        }));
      }
      return [];
    });
    setMeter(newOptions)
    
    setFilters((prev)=>{
      return {...prev,meterId:newOptions.map(e=>e.value)}
    })
    setOptions(newOptions);

    if(newOptions.length > 0){
      setSelectedUnit(newOptions[0].unitId)
    }
  }, [stateCode]);
  

  const handleFilterClick = () => {
    setDisableFlag(false)
    setFilterTrigger(prev => !prev); // Toggle filter trigger state to re-run the useEffect hooks
    setInitialLoad(false);
    setStartDateToShow(startDate);
    setEndDateToShow(endDate);
  };

  const handleFilterReset = () => {
    setOrg('');
    setStateCode('');
    setMeter([]);
    setFilters({
      organisationId: '',
      stateCode: '',
      meterId: []
    });
    setFilterTrigger(false);
  };

  const handleChange = (selectedOptions) => {
    setDisableFlag(true)
    setMeter(selectedOptions);
    setFilters({
      ...filters,
      meterId: selectedOptions.map(consumer => consumer.value)
    });
  };

  const handleSelectChange = (event) => {
    setSelectedLF(event.target.value);
  };

  const buildURL = () => {
    let url = `/power-consumption?org=${filters.organisationId}&lf=${selectedLF}&lfType=lteq&startDate=${formatDateToString(startDate)}&endDate=${formatDateToString(endDate)}`;

    if (filters.stateCode) {
      url += `&state=${filters.stateCode}`;
    }

    if (filters.meterId && filters.meterId?.length > 0) {
      url += `&consumers=[${filters.meterId}]`;
    }

    return url;
  };

  const url = buildURL();

  return (
    <div className="lg:bg-[#ebedeb] mt-0 pt-[58px] sm:pt-[90px] lg:h-screen">
      <div className='lg:max-w-[1920px]  m-auto flex '>
        <div className="hidden sm:block">
          <Sidebar />
        </div>

        <div className={`${isSidebarOpen ? "lg:ml-[300px]" : "sm:ml-[80px]"} w-full max-sm:px-[10px]  lg:pr-[30px] lg:pl-[10px] transition-width duration-300`}>
          <div className="flex mt-3 sm:mt-0 flex-row w-[100%] sm:w-[90%] lg:w-[100%] pl-2 sm:mx-auto flex-wrap justify-between sm:justify-start lg:justify-between dashboard border-gray-700 bg-[white] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] p-2 rounded-xl">
            <div className="w-[45%] sm:w-[30%] lg:w-[17%] z-20">
              <FilterSelect
                label={"Organisation Name"}
                value={org}
                options={organisationList?.map((organisation) => {
                  return { label: organisation?.name, value: organisation?._id };
                })}
                onChange={(e) => {
                  handleOrgChange(e.target.value)
                  setDisableFlag(true)
                }}
              />
            </div>
            <div className="w-[45%] sm:w-[30%] lg:w-[17%] sm:mx-3 lg:mx-0">
              <FilterSelect
                value={stateCode}
                options={[
                  { label: "Select State", value: "" },
                  ...(stateList?.map((state) => ({
                    label: state?.name,
                    value: state?.code
                  })) || [])
                ]}
                onChange={(e) => {
                  handleStateChange(e.target.value)
                  setDisableFlag(true)
                }}
              />
            </div>
            <div className="w-[45%] sm:w-[30%] lg:w-[19%] mt-[10px] sm:mt-0 dashboard z-40 h-[40px]">
              <MultiSelect
                options={options}
                value={meter??[]}
                onChange={handleChange}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select Consumer No",
                }}
                disableSearch={true}
                className="custom-multi-select"
              />
            </div>
            <div className="w-[45%] sm:w-[30%] lg:w-[15%] mt-[10px] lg:mt-0 z-40">
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat="MMM-yyyy"
                showMonthYearPicker
                placeholderText="Start Month"
                minDate={minStartDate}
                maxDate={maxStartDate}
              />
            </div>
            <div className="w-[45%] sm:w-[30%] lg:w-[15%] mt-[10px] lg:mt-0 z-40 sm:mx-3 lg:mx-0">
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                dateFormat="MMM-yyyy"
                showMonthYearPicker
                placeholderText="End Month"
                minDate={startDate}
                maxDate={maxEndDate}
              />
            </div>
            <div className="w-[45%] sm:w-[30%] lg:w-[9%] z-20 ml-3 flex gap-1 justify-end h-[40px] mt-2.5 lg:mt-0">
              {!disableFlag || disableFilter ?
                <button className="px-4 py-2 bg-[#b7d8ca] cursor-default text-white rounded-lg">
                  Apply
                </button>
                : <button onClick={handleFilterClick} className="px-4 py-2 bg-[#067C4E] text-white rounded-lg">
                  Apply
                </button>
              }
              {/* <button onClick={handleFilterReset} className="px-4 py-2 bg-[#067C4E] text-white rounded-lg">
                Reset
              </button> */}
            </div>
          </div>
          <div className={`mt-2 sm:mt-3 lg:w-[100%] sm:w-[95%] flex flex-col lg:flex-row`}>
            <div className="flex flex-col lg:w-[85%] sm:pl-4 lg:pl-0 mb-5 sm:mb-0">


              <div className="flex flex-col sm:flex-row mt-2 w-[100%] h-[100%]">

                <div className="hidden  lg:block sm:w-[95%] h-[100%] sm:mx-auto lg:w-[47%] bg-white p-2 border lg:border-gray-300 rounded-[20px] lg:shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] -z-0 ">
                  {process.env.REACT_APP_ENVIRONMENT === "local" ?
                    <img src="./graph.png" alt="" className="h-[100%] w-[100%]" />
                    :
                    <GoogleMapDashboard
                      address={unitData}
                    />
                  }

                </div>

                <div className="w-[100%] lg:w-[50%] mx-auto flex flex-col border-gray-700 lg:bg-[white] p-2 lg:pt-4 rounded-xl lg:shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)]">

                  <div className="flex flex-row flex-wrap justify-between w-[100%] mx-auto">
                    <div className="w-[48%] sm:w-[32%] bg-[#E2F5EC] p-3 text-center border relative border-gray-300 rounded-[20px]  ">
                      <Link to={`/analytics?orgId=${org}&stateCode=${filters.stateCode}&startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`}>
                        <div className="text-black lg:text-[15px] sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                          Units Consumed
                          <div className="absolute top-0 right-2 z-10 dashboard-tooltip">
                            <CustomTooltopDashboard
                              text={constantUtils.getDashboardToolTipData().DashboardUnitConsumed.text}
                            />
                          </div>
                        </div>
                        <div className="text-lg text-black font-bold mt-2 number-container min-h-10">
                          <span>
                            {dashboardStats?.totalConsumption ? (
                              <AnimatedCount
                                value={dashboardStats.totalConsumption.value}
                                duration={0.6}
                                formatNumber={formatNumber}
                              />
                            ) : (
                              "0"
                            )}
                          </span>
                          
                          <span className="ml-1 break-normal">
                            {dashboardStats?.totalConsumption?.unit}
                          </span>
                        </div>
                      </Link>
                    </div>

                    <div className="w-[48%] sm:w-[32%] bg-[#E2F5EC] p-3 text-center relative border border-gray-300 rounded-[20px]  ">
                      <Link to={`/analytics?orgId=${org}&stateCode=${filters.stateCode}&startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`}>
                        <div className="text-black lg:text-[15px] sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                          Total Bill Amount
                          <div className="absolute top-0 right-2 -z-0 dashboard-tooltip">
                            <CustomTooltopDashboard
                              text={constantUtils.getDashboardToolTipData().DashboardTotalBillAmount.text}
                            />
                          </div>
                        </div>
                        <div className="text-lg text-black font-bold mt-2 number-container min-h-10 ">
                          <div>
                            <FontAwesomeIcon
                              className="mr-1"
                              icon={faIndianRupeeSign}
                            />
                            {dashboardStats?.totalBillAmount ? (
                              <AnimatedCount
                                value={dashboardStats.totalBillAmount.value}
                                duration={0.6}
                                formatNumber={formatNumber}
                              />
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="w-[48%] sm:w-[32%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200 my-5 sm:my-0 rounded-[20px] ">
                      <div className="text-black lg:text-[15px] sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                        TOD Charges
                        <div className="absolute top-0 right-2 dashboard-tooltip">
                          <CustomTooltopDashboard
                            text={constantUtils.getDashboardToolTipData().DashboardTodCharges.text}
                          />
                        </div>
                      </div>

                      <div className={`${dashboardStats?.todTariffEc?.value > 0 > 0 ? 'text-red-500' : 'text-green-500'} font-bold number-container mt-2 min-h-10 `}>
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faIndianRupeeSign}
                        />
                        {dashboardStats?.todTariffEc ? (
                          <AnimatedCount
                            value={dashboardStats.todTariffEc.value}
                            duration={0.6}
                            formatNumber={formatNumber}
                          />
                        ) : (
                          "0"
                        )}
                      </div>
                    </div>

                    <div className="w-[48%] sm:w-[32%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200 my-5 rounded-[20px] ">
                      <Link to={`/analytics?unitId=${selectedUnit}&type=month&startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`}>
                        <div className="text-black lg:text-[15px] sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                          Avg Monthly Bill
                          <div className="absolute top-0 right-2 dashboard-tooltip">
                            <CustomTooltopDashboard
                              text={constantUtils.getDashboardToolTipData().DashboardAvgMonthlyBill.text}
                            />
                          </div>
                        </div>
                        <div className="text-lg text-black font-bold mt-2 number-container min-h-10 ">
                          <div>
                            <FontAwesomeIcon
                              className="mr-1"
                              icon={faIndianRupeeSign}
                            />
                            {dashboardStats?.avgMonthlyBill ? (
                              <AnimatedCount
                                value={dashboardStats.avgMonthlyBill.value}
                                duration={0.6}
                                formatNumber={formatNumber}
                              />
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="w-[48%] sm:w-[32%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200 sm:my-5 rounded-[20px] ">
                      <Link to={`/analytics?unitId=${selectedUnit}&type=month&startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`}>

                        <div className="text-black lg:text-[15px] sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                          Avg Monthly Consumption
                          <div className="absolute top-0 right-2 dashboard-tooltip">
                            <CustomTooltopDashboard
                              text={constantUtils.getDashboardToolTipData().DashboardTodAvgMonthlyConsumption.text}
                            />
                          </div>

                        </div>
                        <div className="text-lg text-black font-bold mt-2 number-container min-h-10 ">

                          {dashboardStats?.avgMonthlyConsumption ? (
                            <AnimatedCount
                              value={dashboardStats.avgMonthlyConsumption.value}
                              duration={0.6}
                              formatNumber={formatNumber}
                            />
                          ) : (
                            "0"
                          )}
                          <span className="ml-1 break-normal">
                            {dashboardStats?.avgMonthlyConsumption?.unit}
                          </span>
                        </div>
                      </Link>
                    </div>

                    <div className="w-[48%] sm:w-[32%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200 sm:my-5 rounded-[20px] ">
                      <div className="text-black lg:text-[15px] sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                        Emission (tCO<sub>2e</sub>)
                        <div className="absolute top-0 right-2 dashboard-tooltip">
                          <CustomTooltopDashboard
                            text={constantUtils.getDashboardToolTipData().DashboardEmmission.text}
                          />
                        </div>
                      </div>
                      <div className="text-lg font-bold mt-2">
                        <div className="text-lg text-black font-bold number-container min-h-10 ">
                          {dashboardStats?.carbonFootprint ? (
                            <AnimatedCount
                              value={dashboardStats.carbonFootprint}
                              duration={0.6}
                              formatNumber={formatNumber}
                            />
                          ) : (
                            "0"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row pb-2">

                    <div className="w-[100%] sm:w-[65%] bg-[#E2F5EC] p-3 text-center mr-2 relative my-5 sm:my-0 border border-gray-300 rounded-[20px] cursor-pointer">

                      <div className="flex flex-row items-center justify-center">
                        <div className="font-medium">Load Factor :</div>
                        <select
                          className="border border-gray-300 bg-[#E2F5EC] w-auto rounded-lg p-2 ml-2"
                          value={selectedLF}
                          onChange={handleSelectChange}
                        >
                          <option value="">Select LF</option>
                          <option value={10}>10%</option>
                          <option value={20}>20%</option>
                          <option value={30}>30%</option>
                          <option value={40}>40%</option>
                          <option value={50}>50%</option>
                          <option value={60}>60%</option>
                          <option value={70}>70%</option>
                          <option value={80}>80%</option>
                          <option value={90}>90%</option>
                          <option value={100}>100%</option>
                        </select>
                      </div>
                      {/* <Link to={`/power-consumption?org=${filters.organisationId}&state=${filters.stateCode}&consumers=[${filters.meterId}]&lf=${selectedLF}&lfType=${'lteq'}&startDate=${formatDateToString(startDate)}&endDate=${formatDateToString(endDate)}`}> */}
                      <Link to={url}>
                        <div className="flex flex-row items-center justify-center mt-4 min-h-10 lg:min-h-14">
                          <div className="text-black lg:text-[15px] mt-2 sm:text-[10px] font-medium ">
                            Number of Bills :  {lfData}
                          </div>
                          <div className="text-lg text-black font-bold">
                          </div>
                        </div>
                        <div className="absolute top-0 right-2 dashboard-tooltip">
                          <CustomTooltopDashboard
                            text={constantUtils.getDashboardToolTipData().DashboardLoadFactor.text}
                          />
                        </div>
                      </Link>
                    </div>

                    <div className={`w-[100%] sm:w-[35%] bg-[#c0eed8] p-3 text-center border rounded-[20px] relative ${(savingEnergyLoader) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                     onClick={() => {
                      if (savingsThroughGreenMeterWise &&savingsThroughGreenMeterWise?.length > 0 && !savingEnergyLoader) {
                        setOpenGreenSavingsPopup(true);
                        setActiveTab(0);
                      }
                    }}
                      >
                      <div className="text-black lg:text-[16px] mt-2 font-bold sm:text-[10px] min-h-12 sm:min-h-15">
                        Saving through green energy
                        <div className="font-medium sm:text-[8px] mt-1 lg:text-[13px] text-center">
                         ({formatDateWithMonthAndYear(startDateToShow)} To {formatDateWithMonthAndYear(endDateToShow)})
                        </div>
                        {/* <div className="font-normal sm:text-[8px] lg:text-[14px] text-left">
                         To: {formatDateWithMonthAndYear(endDate)}
                        </div> */}
                        <div className="absolute top-0 right-2 dashboard-tooltip">
                          <CustomTooltopDashboard
                            text={constantUtils.getDashboardToolTipData().DashboardSaving.text}
                          />
                        </div>
                      </div>
                        
                      <div className="text-lg text-green-500 font-semibold mt-2 lg:mt-6 number-container min-h-10 ">
                        <div>
                          {savingEnergyLoader ?
                            <div className="w-[50%] h-[50%] mx-auto my-auto">
                              <LoaderComponent />
                            </div>
                            :
                            <>
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faIndianRupeeSign}
                              />
                              {Number(savingsThroughGreen)?.toLocaleString('en-IN')}
                            </>
                          }
                        </div>
                      </div>
                      <p className="text-xs text-gray-800">Select "Maharashtra" to get green power recommendation</p>
                    </div>

                  </div>
                  <SavingEnergyPopup setOpenGreenSavingsPopup={setOpenGreenSavingsPopup} openGreenSavingsPopup={openGreenSavingsPopup} savingsThroughGreenMeterWise={savingsThroughGreenMeterWise} savingsThroughGreen={savingsThroughGreen} startDate={startDate} endDate={endDate} filters={filters} activeTab={activeTab} setActiveTab={setActiveTab}/>
                </div>

              </div>
            </div>

            <div className="w-[100%] lg:w-[15%] sm:mt-2 lg:px-3 sm:pl-4 lg:pl-0 lg:bg-white p-2 lg:pt-4 lg:border lg:border-gray-300 rounded-[20px] lg:shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)]">
              <div className="flex flex-col sm:flex-row lg:mt-0">
                <div className="w-[100%] lg:px-3 lg:pl-4">
                  <div className="w-[100%] border-blue-800 flex flex-row justify-between flex-wrap">

                    <div className="w-[100%] sm:w-[48%] lg:w-[100%] bg-[white] p-3 text-center mr-2 lg:mr-0  border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] ">
                      <div className="text-black lg:text-[13px] sm:text-[12px] font-medium min-h-10">
                        Total Number of Locations
                      </div>
                      <div className="text-lg text-black font-bold mt-2">
                        {dashboardStats?.unitCount ? (
                          <AnimatedCount
                            value={dashboardStats.unitCount}
                            duration={0.6}
                            formatNumber={formatNumber}
                          />
                        ) : (
                          "0"
                        )}
                      </div>
                    </div>

                    <div className="w-[100%] sm:w-[48%] lg:w-[100%] my-5 sm:my-0 lg:my-5 bg-white p-3 text-center border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] ">
                      <div className="text-black lg:text-[13px] sm:text-[12px] font-medium min-h-10">
                        Total number of Consumers
                      </div>
                      <div className="text-lg text-black font-bold mt-2">
                        <div>
                          {dashboardStats?.unitCount ? (
                            <AnimatedCount
                              value={dashboardStats.meterCount}
                              duration={0.6}
                              formatNumber={formatNumber}
                            />
                          ) : (
                            "0"
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-[100%] sm:w-[48%] lg:w-[100%] sm:my-5 lg:my-0 bg-white p-3 text-center mr-2 lg:mr-0 border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] ">
                      <div className="text-black lg:text-[13px] sm:text-[12px] font-medium min-h-10">
                        Total Number of Bills for Period
                      </div>
                      <div className="text-lg text-black font-bold mt-2">
                        {dashboardStats?.unitCount ? (
                          <AnimatedCount
                            value={dashboardStats.recordCount}
                            duration={0.6}
                            formatNumber={formatNumber}
                          />
                        ) : (
                          "0"
                        )}
                      </div>
                    </div>

                    <div className="w-[100%] sm:w-[48%] lg:w-[100%] my-5 lg:my-5 bg-white p-3 text-center border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] ">
                      <div className="text-black lg:text-[13px] sm:text-[12px] font-medium min-h-10">
                        Total Number of Bills Analysed
                      </div>
                      <div className="text-lg text-black font-bold mt-2">
                        <div>
                          {dashboardStats?.recordCount ? (
                            <AnimatedCount
                              value={dashboardStats.recordCount}
                              duration={0.6}
                              formatNumber={formatNumber}
                            />
                          ) : (
                            "0"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="block lg:hidden sm:w-[100%] h-[300px] sm:h-[500px] lg:ml-[80px] sm:mx-auto lg:w-[40%] bg-white p-3 border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)]  mb-10 sm:mb-0">
                {process.env.REACT_APP_ENVIRONMENT === "local" ?
                  <img src="./graph.png" alt="" className="h-[100%] w-[100%]" />
                  : <GoogleMapDashboard
                    address={unitData}
                  />
                }
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";

const ASelect = ({ options, defaultValue, onChange, value,height, label,IconBottom, showLable,maxWidth }) => {
  const [isInteracted, setIsInteracted] = useState(false);

  useEffect(() => {
    setIsInteracted(false); 
  }, [value]);

  const handleChange = (event) => {
    setIsInteracted(true);
    onChange(event);
  };

//   console.log({options});

  return (
    <div className={`${ maxWidth ?  `max-w-[${maxWidth}]` : "max-w-[731px]"}  relative bg-inherit w-full pb-100`}  >
      <div className={` ${showLable ? "block" : "hidden"} p-[10px] `}>
        <div
          className={` ${
            showLable ? "block" : "hidden"
          } font-normal text-[#000000] text-[14px] leading-[21px] `}
        >
          {label}
        </div>
      </div>

      <select
        onChange={handleChange}
        defaultValue={defaultValue}
        value={value}
        className={`block appearance-none rounded-[10px] ${height ? height:"h-[56px]"}  w-full leading-[21px] bg-white border border-[#B9B9B9] hover:border-gray-500 px-4 py-2 pr-8 shadow focus:outline-none focus:shadow-outline ${
          isInteracted ? "text-black" : "text-gray-500"
        }`}
      >
        <option key={""} value={""}>
          {"Select "}{label}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className={`absolute right-3 ${IconBottom ? IconBottom :"bottom-6" }  flex items-center justify-end `}>
        <div>
          <img src="./select.svg" className="h-[9px] w-[15px]" />
        </div>
      </div>
    </div>
  );
};
export default ASelect;

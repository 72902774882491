import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getData, postData, updateData } from "../../api/apiDataExchange";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getPowerConsumptionList } from "../../services/apis/powerConsumption";
import DatePicker from "react-datepicker";
import Abutton from "../../components/button/Abutton";
import { useAuth } from "../../hooks/useAuth";

const ViewMeter = () => {
    const { setIsSidebarOpen, isSidebarOpen } = useAuth();
    const [name, setName] = useState("");
  const [org, setOrg] = useState("");
  const [unitlist, setUnitlist] = useState([])
  const [factory, setfactory] = useState("")
  const [unit, setunit] = useState("")
  const [powerConsumptionData, setPowerConsumptionData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [showDatePickers, setShowDatePickers] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const meter = await getData(`${process.env.REACT_APP_API_URL}/meter/${id}`);
      // console.log(meter);
      setName(meter.result.meterNo);
      setOrg(meter.result.organisationId.name);
      setfactory(meter.result.factoryId.name);
      setunit(meter.result.unitId.name);

    };
    fetchData().catch(console.error);
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let filterParams = { meterId: id };
        if (startDate && endDate) {
          filterParams.startDate = startDate;
          filterParams.endDate = endDate;
        }
        const powerConsumptionData = await getPowerConsumptionList(filterParams);
        const data = powerConsumptionData?.data?.results;
        setPowerConsumptionData(data);
      }
    };

    fetchData();
  }, [id, startDate, endDate]);


  const datePickersRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (datePickersRef.current && !datePickersRef.current.contains(event.target)) {
        setShowDatePickers(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickersRef]);

  const handleEditClick = () => {
    setShowDatePickers(true);
  };


  return (
    <div className="max-w-[1920px] relative m-auto mt-[170px] pb-20">
      <div>
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen?'ml-[300px]':'ml-[80px]'}`}>
        <div className={`rounded-lg  bg-[#E2F5EC] w-full flex flex-col pb-[50px] shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:m-auto max-w-screen-lg md:mt-[30px] ${showDatePickers ? 'bg-[gray]' : ""}`}>
          <div className="text-center font-medium text-2xl mt-6">Meter Details</div>
          <div className="">
            <div className="max-w-[844px] mb-[100px] m-auto mt-[50px]">
              <div>
                {" "}
                <div className="text-xl font-medium">Meter No : {name}</div>
                <div className="text-xl font-medium mt-4">{unit} - {org} - {factory}</div>
                <div className="text-sm font-normal mt-1">(unit - orgnisation - factory)</div>
                <div className="flex mt-5 relative">
                  <div>View Bills : </div>
                  <div ref={datePickersRef} className="">
                    <div className="flex flex-row items-center">
                      <div className="ml-2">
                        {startDate && endDate
                          ? `${startDate.toDateString()} - ${endDate.toDateString()}`
                          : ""}
                      </div>
                      <div
                        className="text-[#4f4fec] text-sm ml-4 underline cursor-pointer"
                        onClick={handleEditClick}
                      >
                        Edit Dates
                      </div>
                    </div>
                  </div>
                </div>
                {showDatePickers && (
                  <div ref={datePickersRef} className=" top-[30px] left-0 z-[999] w-fit flex flex-row">
                    <div className="relative w-[30%]">
                      <DatePicker
                        placeholderText="Select Start Date"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        open
                        popperPlacement="bottom-start"
                        startDate={startDate}
                        endDate={endDate}
                        className="w-[60%] py-2 px-4 rounded-md"
                        wrapperClassName="date-picker-wrapper"
                      />
                      <div className="absolute right-0 top-2 mt-[10px] mr-[10px]">
                        <div>
                          <img src="/calenderIcon.svg" className="h-[18px] w-[18px]" />
                        </div>
                      </div>
                    </div>
                    <div className="relative w-[30%]">
                      <DatePicker
                        placeholderText="Select End Date"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        open
                        popperPlacement="bottom-start"
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        className="w-[60%] py-2 px-4 rounded-md !h-[10px]"
                        wrapperClassName="date-picker-wrapper"
                      />
                      <div className="absolute right-0 top-2 mt-[10px] mr-[10px]">
                        <div>
                          <img src="/calenderIcon.svg" className="h-[18px] w-[18px]" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className={`grid grid-cols-4 gap-4 mx-auto mt-10 ${showDatePickers ? '-z-30 bg-[gray]' : ""}`}>
                  {powerConsumptionData?.map((powerData, index) => {
                    console.log(powerData);
                    return (
                      <div key={index}>
                        <Link to={showDatePickers ? '#' : '/comparison/view'} className={showDatePickers ? 'pointer-events-none' : ''}>
                          <div>
                            <img
                              src="/bill.png"
                              alt="description"
                              className="w-[100px] h-[100px] mx-auto"
                            />
                          </div>
                        </Link>
                        <div className="mt-3 text-center">
                          <p>{new Date(powerData.currentReadingDate).toLocaleDateString()}</p>
                        </div>
                        <div>
                          {/* <p>Validated</p> */}
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[20px] w-full px-[14px] sm:px-[55px] sm:mt-[50px] flex font-normal justify-center gap-[80px]">
            <Link to={'/my-data'}>
              <Abutton
                text={"Cancel"}
                Mheight={44}
                Mwidth={170}
                DHeight={44}
                DWidth={299}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMeter;

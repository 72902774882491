import React, { useState, useEffect } from "react";
import useInputBox from "../../hooks/useInputBox";

const InputBoxV1 = ({
  showError,
  placeholder,
  label,
  type,
  validationtypes,
  value,
  setInputValue,
  handleErrors,
  displayLabel,
  fieldName,
  maxWidth220,
  maxWidth600,
}) => {
  const { error } = useInputBox(value, validationtypes, label, fieldName);
  useEffect(() => {
    if (error) {
      if (validationtypes) {
        handleErrors(error);
      }
    } else {
    }
  }, [error, value]);

  return (
    <div className={``}>
      <div
        className={`${
          displayLabel === true ? "" : "hidden"
        } pt-[10px]  pb-[18px] p-0  font-normal text-[18px] leading-[22px]  md:p-[10px] md:pb-[19px] ${
          showError ? "mt-[-20px]" : ""
        }`}
      >
        {label}
      </div>
      <input
        className={`border font-normal w-full ${
          showError ? "mt-5" : ""
        } rounded-[20px] text-[16px] leading-[20px] border-[#B9B9B9] py-[25px] px-[20px] ${
          maxWidth600
            ? "md:max-w-[600px]"
            : maxWidth220
            ? "md:max-w-[220px]"
            : "max-w-[545px]"
        } `}
        type={type}
        value={value}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={placeholder}
      />
      <br />
      {showError ? (
        <p className="text-red-500 capitalize px-[20px] py-[10px]">
          {error[`${fieldName}Error`]}
        </p>
      ) : null}
    </div>
  );
};

export default InputBoxV1;
